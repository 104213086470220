import { styled } from 'styled-components';

import {
  AbundanceBrasilTopLightLogo,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Link,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledLockedProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 327px;
  max-width: 100%;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: hidden;
`;

export const Logo = styled(AbundanceBrasilTopLightLogo)`
  width: 242px;
  max-width: 90%;
  height: 192px;
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('medium')};
`;

export const Body = styled(Text).attrs({
  level: 'bodySmall',
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('regular')};
`;

export const LinkButton = styled(Link).attrs({
  size: 16,
  variant: 'text',
})<StyledLockedProps>`
  height: auto;
  display: inline;
  text-decoration: underline;
  text-transform: lowercase;
  color: ${getThemeColor('white')};
  font-size: 13px;

  ${({ $isUpperCase }) =>
    $isUpperCase &&
    `
      text-transform: uppercase;
    `}
`;
