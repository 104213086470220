import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import { usePurchaseWithoutAccount } from 'state/purchase';
import { createSearchParams } from 'utils/formatting';

import { purchaseWithoutAccountFormSchema } from './constants';
import { PurchaseWithoutAccountFormSchema } from './types';

export type UsePurchaseWithoutAccountForm =
  FormReturn<PurchaseWithoutAccountFormSchema> & {
    isLoading: boolean;
    onSubmit: () => void;
  };

export function usePurchaseWithoutAccountForm(): UsePurchaseWithoutAccountForm {
  const { mutate: purchaseWithoutAccount, isLoading } =
    usePurchaseWithoutAccount();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const form = useForm<PurchaseWithoutAccountFormSchema>({
    resolver: zodResolver(purchaseWithoutAccountFormSchema),
    mode: 'all',
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    ({ email }: PurchaseWithoutAccountFormSchema): void => {
      purchaseWithoutAccount(
        {
          body: {
            email,
          },
        },
        {
          onSuccess: () => {
            navigate({
              pathname: getPathnameFromKey(
                'purchaseWithoutAccountConfirmation',
              ),
              search: createSearchParams({
                email,
              }),
            });
          },
          onError: (error) => {
            addToast({
              message: getErrorMessage(error),
              state: 'error',
            });
          },
        },
      );
    },
    [addToast, navigate, purchaseWithoutAccount],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
