/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { MdPowerSettingsNew } from 'react-icons/md';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Link, useLocation } from 'react-router-dom';

import { Avatar, Navbar, Sidebar } from '@abundance-brasil-wabi/sabi';

import { Company, User } from 'api/services';
import { WithTranslation } from 'i18n/types';
import LanguageSelector from 'components/LanguageSelector';
import { getKeyFromPathname, getPathnameFromKey } from 'router/utils';
import { useSignOut } from 'state/auth';
import { useCompany } from 'state/company';
import { useNavbarActions, useNavbarIsOpen } from 'state/navbar';
import { useHasImpactBalance } from 'state/tree';
import {
  useUser,
  useUserIsValidPhysicalPerson,
  useUserSyntropyLink,
} from 'state/user';

import { getRightNavbarItems } from './constants';
import * as S from './styles';

const RightMenuNavbar: React.FC<WithTranslation> = ({ t }) => {
  const { data: isValidPhysicalPerson = false } =
    useUserIsValidPhysicalPerson();
  const { data: hasBalance = false } = useHasImpactBalance();
  const { data: syntropyLink } = useUserSyntropyLink();
  const { data: user } = useUser();
  const { data: company } = useCompany();

  const { dequeueNavbar } = useNavbarActions();
  const isOpen = useNavbarIsOpen('RightMenu');
  const location = useLocation();
  const signOut = useSignOut();

  const displayInfo = useMemo(
    (): User | Company | undefined =>
      user?.isValidPhysicalPerson ? user : company,
    [company, user],
  );

  if (!displayInfo) return null;

  return (
    <Navbar isOpen={isOpen} side="right">
      <Sidebar
        mode="expanded"
        selectedKey={getKeyFromPathname(location.pathname)}
        onSelection={dequeueNavbar}
      >
        <S.Contents>
          <Avatar>{displayInfo?.shortName}</Avatar>

          <S.PrimaryInfo>
            {t('hi')}, {displayInfo?.name}
          </S.PrimaryInfo>

          <S.SecondaryInfo>{displayInfo?.email}</S.SecondaryInfo>

          <S.SecondaryInfo>
            {formatPhoneNumber((displayInfo?.phoneNumber || '') as any)}
          </S.SecondaryInfo>
        </S.Contents>

        <Sidebar.Divider />

        {getRightNavbarItems({
          isValidPhysicalPerson,
          hasBalance,
          syntropyLink,
        }).map(({ key, icon, activeIcon }) => (
          <Sidebar.Item
            key={key}
            id={key}
            as={Link}
            to={getPathnameFromKey(key)}
            title={t(key)}
            {...{ icon, activeIcon }}
          />
        ))}

        <Sidebar.Divider />

        <S.SignOutButton onPress={signOut}>
          <MdPowerSettingsNew size={24} />

          <S.SignOut>{t('out')}</S.SignOut>
        </S.SignOutButton>

        <S.LanguageSelectorContainer>
          <LanguageSelector />
        </S.LanguageSelectorContainer>
      </Sidebar>
    </Navbar>
  );
};

export default withTranslation('components', {
  keyPrefix: 'navbars:rightMenuNavbar',
})(RightMenuNavbar);
