import { ApplicationAction, ApplicationStore, PageConfig } from './types';

export const actionsSelector = ({
  actions,
}: ApplicationStore): ApplicationAction => actions;

export const pageConfigSelector = ({ state }: ApplicationStore): PageConfig =>
  state.pageConfig;

export const fileLoadingSelector = ({ state }: ApplicationStore): boolean =>
  state.isFileLoading;

export const updatingSubscriptionPlanSelector = ({
  state,
}: ApplicationStore): boolean => state.isUpdatingSubscriptionPlan;
