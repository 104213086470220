import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AreaOutlineIcon,
  FileIcon,
  Heading,
  LocationIcon,
  Text,
  TreeRoundedOutlineIcon,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';

import { hideModal } from '../../../../store/modules/modal/actions';
import * as S from './styles';

const AuroraVerdeHeader: React.FC<WithTranslation> = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToWallet = useCallback((): void => {
    dispatch(hideModal());

    navigate({ pathname: getPathnameFromKey('wallet') });
  }, [dispatch, navigate]);

  return (
    <S.Root>
      <S.Contents>
        <S.TitleSection>
          <S.AuroraVerdeImage />

          <div>
            <Heading level={2}>Aurora Verde</Heading>
            <Text level="bodySmall">{t('forest')}</Text>
          </div>
        </S.TitleSection>

        <S.DescriptionContainer>
          <S.NumberOfTrees>{t('treesAmount')}</S.NumberOfTrees>

          <S.Description>{t('firstParagraph')}</S.Description>

          <S.Description>{t('secondParagraph')}</S.Description>
        </S.DescriptionContainer>

        <S.GoToWalletButton size="56" onPress={redirectToWallet}>
          {t('beARestorer')}
        </S.GoToWalletButton>

        <S.DescriptionItemCar>
          <FileIcon />
          CAR: MG-3111903-BC1C.7812.B481.430D.9BFA.65D1.0106.5323
        </S.DescriptionItemCar>

        <S.HeaderBottom>
          <S.DescriptionItem>
            <AreaOutlineIcon />

            {t('plantingArea')}
          </S.DescriptionItem>

          <S.DescriptionItem>
            <LocationIcon />
            {t('location')}: Cana Verde | Minas Gerais
          </S.DescriptionItem>

          <S.DescriptionItem>
            <TreeRoundedOutlineIcon />
            {t('forestProducer')}:
            <S.VerdeAzulImage />
          </S.DescriptionItem>
        </S.HeaderBottom>
      </S.Contents>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'modal:auroraVerdeModal:auroraVerdeHeader',
})(AuroraVerdeHeader);
