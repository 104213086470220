import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HowDidYouGetHere } from 'api/services';
import { DropdownOption } from 'components/FormFields/Dropdown/types';

export type UseGetFormOptions = {
  socialMedia: DropdownOption[];
};

export function useGetFormOptions(): UseGetFormOptions {
  const { t } = useTranslation('pages', {
    keyPrefix: 'signUp:signUpForm',
  });

  const socialMedia = useMemo((): DropdownOption<HowDidYouGetHere>[] => {
    return [
      {
        label: 'Google',
        value: 'Google',
      },
      {
        label: 'Instagram',
        value: 'Instagram',
      },
      {
        label: 'LinkedIn',
        value: 'LinkedIn',
      },
      {
        label: 'YouTube',
        value: 'YouTube',
      },
      {
        label: 'Facebook',
        value: 'Facebook',
      },
      {
        label: t('press'),
        value: 'Imprensa',
      },
      {
        label: t('recommendation'),
        value: 'Indicação',
      },
    ];
  }, [t]);

  return {
    socialMedia,
  };
}
