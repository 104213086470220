import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Divider, FormComposer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Input } from 'components/FormFields';
import LanguageSelector from 'components/LanguageSelector';
import ReCaptcha from 'components/ReCaptcha';

import { usePurchaseWithoutAccountForm } from './usePurchaseWithoutAccountForm';

const PurchaseWithoutAccountForm: React.FC<WithTranslation> = ({ t }) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { control, handleSubmit, formState, isLoading, onSubmit } =
    usePurchaseWithoutAccountForm();

  return (
    <FormComposer
      fluid
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'purchase-without-account-form-main',
          contents: [
            <Input
              fluid
              key="email"
              name="email"
              type="email"
              label={t('email')}
              placeholder={t('email')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <ReCaptcha key="recaptcha" verifiedAction={setIsCaptchaVerified} />,

            <Button
              fluid
              key="submit"
              type="submit"
              isDisabled={!formState.isValid || !isCaptchaVerified || isLoading}
            >
              {t('send')}
            </Button>,

            <Divider key="divider" />,

            <LanguageSelector key="languageSelector" />,
          ],
        },
      ]}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'purchaseWithoutAccount:purchaseWithoutAccountForm',
})(PurchaseWithoutAccountForm);
