import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import { StatusMessage } from 'api/services';
import { fieldErrorMessages } from 'constants/forms';
import { useToast } from 'hooks/useToast';
import { useAuth, useOnAuthSuccess } from 'state/auth';
import { useEventTracking } from 'state/eventTracking';

import { signInFormSchema, signInRedirectRoutes } from './constants';
import { SignInFormSchema } from './types';
import {
  getSignInSearchParam,
  handleSaveSignInInfoInLocalStorage,
} from './utils';

export type UseSignInForm = FormReturn<SignInFormSchema> & {
  isLoading: boolean;
  onSubmit: () => void;
};

export function useSignInForm(): UseSignInForm {
  const { mutateAsync: signIn, isLoading } = useAuth();
  const { addToast } = useToast();
  const { trackUserSignIn } = useEventTracking();

  const onAuthSuccess = useOnAuthSuccess();
  const navigate = useNavigate();

  const form = useForm<SignInFormSchema>({
    resolver: zodResolver(signInFormSchema),
    mode: 'all',
  });

  const { setError, handleSubmit } = form;

  const submit = useCallback(
    (data: SignInFormSchema): void => {
      signIn(
        {
          body: {
            email: data.email,
            pass: data.password,
          },
        },
        {
          onSuccess: async (response) => {
            const {
              statusMessage = 'Succeeded',
              email,
              phoneNumber,
            } = response;

            trackUserSignIn({ email });

            const status: StatusMessage = statusMessage;

            if (status === 'Succeeded') await onAuthSuccess(response);
            else handleSaveSignInInfoInLocalStorage(email, phoneNumber);

            navigate({
              pathname: signInRedirectRoutes[status],
              search: getSignInSearchParam(status, email),
            });
          },
          onError: (error) => {
            addToast({
              message: getErrorMessage(error),
              state: 'error',
            });

            setError('password', {
              message: fieldErrorMessages.password.blocked,
            });
          },
        },
      );
    },
    [addToast, navigate, onAuthSuccess, setError, signIn, trackUserSignIn],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
