import { withTranslation } from 'react-i18next';

import {
  CloudsOutlineIcon,
  GaugeOutlineIcon,
  ImpactOutlineIcon,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import Benefit from '../Benefit';
import * as S from './styles';

export type PlanBenefitsProps = {
  trees: number;
  tons: string;
  tonsUntil2052: string;
};

const PlanBenefits: React.FC<WithTranslation<PlanBenefitsProps>> = ({
  trees,
  tons,
  tonsUntil2052,
  t,
}) => {
  return (
    <S.Root>
      <Benefit title={t('tree')} value={trees} icon={ImpactOutlineIcon} />

      <Benefit title={t('carbon')} value={tons} icon={CloudsOutlineIcon} />

      <Benefit
        title={t('potential')}
        value={tonsUntil2052}
        icon={GaugeOutlineIcon}
      />
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix:
    'subscriptionPlans:subscriptionPlanCards:subscriptionPlanCard:planBenefits',
})(PlanBenefits);
