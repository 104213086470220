import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pushModal, toggleModalState } from '../store/modules/modal/actions';
import { modalIsModalOpenSelector } from '../store/modules/modal/selectors';
import { ModalsList } from '../store/modules/modal/types';
import {
  apiMethod,
  otpPurpose,
  otpValidationReset,
  requestData,
  validatePasswordAndPurposeRequest,
} from '../store/modules/otpValidation/actions';
import { otpValidationSelector } from '../store/modules/otpValidation/selectors';
import { StartValidation } from '../store/modules/otpValidation/types';

export type UseOTPValdation = {
  startOTPValidation: ({
    requestDataValues,
    purpose,
    passwordBypass,
    method,
    callback,
  }: StartValidation) => void;
};

/**
 * It opens a modal for password verification, and if the password
 * is correct, it opens a modal for OTP validation.
 *
 * @returns The function to start the OTP validation process.
 */
export default function useOTPValidation(): UseOTPValdation {
  const dispatch = useDispatch();

  const { passwordSuccess, otpValidatedSuccessfully } = useSelector(
    otpValidationSelector,
  );
  const isModalOpen = useSelector(modalIsModalOpenSelector);

  const [onSuccess, setOnSuccess] = useState<(() => void) | boolean>();

  const startOTPValidation = useCallback(
    ({
      requestDataValues,
      purpose,
      passwordBypass = '',
      method = 'put',
      callback,
    }: StartValidation): void => {
      if (passwordBypass)
        dispatch(validatePasswordAndPurposeRequest(purpose, passwordBypass));
      else
        dispatch(pushModal({ name: ModalsList.PASSWORD_VERIFICATION_MODAL }));

      dispatch(requestData(requestDataValues));
      dispatch(otpPurpose(purpose));
      dispatch(apiMethod(method));

      setOnSuccess(callback ? () => callback : true);
    },
    [dispatch],
  );

  // If any modal closes, the OTP validation is reset.
  useEffect(() => {
    if (!isModalOpen) dispatch(otpValidationReset());
  }, [dispatch, isModalOpen]);

  // If the password is validated correctly, the token validation modal opens.
  useEffect(() => {
    if (passwordSuccess)
      dispatch(pushModal({ name: ModalsList.OTP_VALIDATION_MODAL }));
  }, [dispatch, passwordSuccess]);

  // Runs callback action if validation was successful. Also, resete validadtion state and closes modal.
  useEffect(() => {
    if (otpValidatedSuccessfully && onSuccess) {
      if (typeof onSuccess === 'function') onSuccess();

      dispatch(otpValidationReset());
      dispatch(toggleModalState());
    }
  }, [otpValidatedSuccessfully, dispatch, onSuccess]);

  return { startOTPValidation };
}
