import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  SyntropyIcon,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled.div`
  width: 100%;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 2px;
  background-color: ${getThemeColor('default')};
`;

export const Content = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
  gap: 4,
})`
  position: relative;

  @media (max-width: 380px) {
    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const Icon = styled(SyntropyIcon)`
  width: 56px;
  height: 56px;
  padding: 16px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 1000px;
  background-color: ${getThemeColor('white')};

  @media (max-width: 1216px) {
    width: 42px;
    height: 42px;
    padding: 8px;
  }

  @media (max-width: 380px) {
    display: none;
  }
`;

export const Elements = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: 4,
})`
  & > :not(:first-child) {
    margin-top: 4px;
  }

  & > :last-child {
    margin-top: 0;
  }

  @media (max-width: 380px) {
    margin-bottom: 0;
  }
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};

  ${breakpointDown('lg')`
    margin-top: 4px;
    margin-bottom: 14px;
  `}
`;

export const GoToSupportButton = styled(LinkButton).attrs({
  variant: 'primary',
  size: 32,
})`
  position: absolute;
  right: 0;

  @media (max-width: 1216px) {
    position: relative;
    top: 6px;
    right: 0;
    display: initial;
    margin-bottom: 8px;
  }

  ${breakpointDown('lg')`
    margin-bottom: 0;
    top: -4px;
  `}

  @media (max-width: 380px) {
    display: table;
  }
`;
