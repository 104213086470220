import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})`
  width: 100%;

  ${breakpointDown('lg')`
    margin-bottom: 24px;
  `}

  @media (max-width: 440px) {
    font-size: 24px;
  }
`;

export const AccountBalance = styled(Heading).attrs({
  level: 2,
})`
  margin-bottom: 32px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'secondary', 'default')};
`;

export const GeneralBalance = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})`
  width: 100%;
`;

export const BalanceSection = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})``;

export const Title = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Value = styled(Text).attrs({
  level: 'body',
})`
  text-align: right;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'primary', 'default')};
`;
