import { PageConfig, PageMode } from 'state/application';

import SideBanner from './SideBanner';
import * as S from './styles';

export const DEFAULT_COVER_PAGE_SETTINGS: PageConfig = {
  mode: 'cover',
  topContent: false,
  sideContent: false,
  hideTitleBar: false,
  hideSideContent: false,
  hideFooter: false,
  isLoading: false,
  bybassInitialLoading: false,
};

export const DEFAULT_FLUID_PAGE_SETTINGS: PageConfig = {
  mode: 'fluid',
  topContent: false,
  sideContent: <S.SideMenu />,
  hideTitleBar: false,
  hideSideContent: false,
  hideFooter: false,
  isLoading: false,
  bybassInitialLoading: false,
};

export const DEFAULT_EXPANDED_PAGE_SETTINGS: PageConfig = {
  mode: 'expanded',
  topContent: false,
  sideContent: false,
  hideTitleBar: false,
  hideSideContent: true,
  hideFooter: false,
  isLoading: false,
  bybassInitialLoading: false,
};

export const DEFAULT_SPLIT_PAGE_SETTINGS: PageConfig = {
  mode: 'split',
  topContent: false,
  sideContent: <SideBanner />,
  hideTitleBar: false,
  hideSideContent: false,
  hideFooter: false,
  isLoading: false,
  bybassInitialLoading: false,
};

export const pageSettingsByMode: Record<PageMode, PageConfig> = {
  cover: DEFAULT_COVER_PAGE_SETTINGS,
  fluid: DEFAULT_FLUID_PAGE_SETTINGS,
  expanded: DEFAULT_EXPANDED_PAGE_SETTINGS,
  split: DEFAULT_SPLIT_PAGE_SETTINGS,
};
