import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import {
  CarbonOffsetCalculation,
  getCurrentUserCarbonOffsetCalculation,
} from 'api/services';
import { getIsAuthenticated } from 'state/auth/utils';
import { useUserId } from 'state/user';

import { carbonOffestQueryKeys } from './queryKeys';
import {
  currentUserCarbonOffsetCalculationIsFullyCompensatedSelector,
  currentUserCarbonOffsetCalculationSelector,
  currentUserCarbonOffsetCalculationUncompensatedPercentageSelector,
  currentUserHasCarbonOffsetCalculationSelector,
} from './selectors';
import { CurrentUserCarbonOffsetCalculationQueryKey } from './types';

export default function useGetCurrentUserCarbonOffsetCalculation<
  TData = CarbonOffsetCalculation,
>(
  options?: QueryOptions<
    CarbonOffsetCalculation,
    TData,
    CurrentUserCarbonOffsetCalculationQueryKey
  >,
) {
  const { data: userId } = useUserId();
  const isAuthenticated = getIsAuthenticated();

  return useQuery<
    CarbonOffsetCalculation,
    unknown,
    TData,
    CurrentUserCarbonOffsetCalculationQueryKey
  >(
    carbonOffestQueryKeys.currentUserCarbonOffsetCalculation(userId),
    async () => {
      const data = await getCurrentUserCarbonOffsetCalculation();

      return data;
    },
    {
      ...options,
      enabled: isAuthenticated,
    },
  );
}

export const useCurrentUserCarbonOffsetCalculation = () =>
  useGetCurrentUserCarbonOffsetCalculation({
    select: currentUserCarbonOffsetCalculationSelector,
  });

export const useCurrentUserHasCarbonOffsetCalculation = () =>
  useGetCurrentUserCarbonOffsetCalculation({
    select: currentUserHasCarbonOffsetCalculationSelector,
  });

export const useCurrentUserCarbonOffsetCalculationUncompensatedPercentage =
  () =>
    useGetCurrentUserCarbonOffsetCalculation({
      select: currentUserCarbonOffsetCalculationUncompensatedPercentageSelector,
    });

export const useCurrentUserCarbonOffsetCalculationIsFullyCompensated = () =>
  useGetCurrentUserCarbonOffsetCalculation({
    select: currentUserCarbonOffsetCalculationIsFullyCompensatedSelector,
  });
