import { styled } from 'styled-components';

import {
  Button,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Message = styled(Text).attrs({
  level: 'bodySmall',
})`
  align-self: flex-start;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const ReSendButton = styled(Button).attrs({
  variant: 'text',
})`
  display: block;
  padding: 0;
  margin: 0;
  font-size: 14px;
  text-decoration-line: underline;
  font-weight: ${getThemeFontWeight('bold')};
`;
