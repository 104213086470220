import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize};

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Bai Jamjuree', sans-serif, Arial, Helvetica, sans-serif;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    scroll-behavior: smooth;
    /* Remove blue highlight that renders over interactable elements when they are tapped. */
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-interpolation-mode: nearest-neighbor;
  }

  *:focus {
    outline: none;
  }

  html {
    padding-right: 0 !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  body {
    height: 100vh;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }
`;
