import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  getThemeBoxShadow,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
})`
  margin: 0;

  ${breakpointDown('lg')`
    overflow: hidden;
  `}
`;

export const Certificate = styled.div`
  width: 538px;
  height: 380px;
  box-shadow: ${getThemeBoxShadow('md')};

  ${breakpointDown('sm')`
    width: 75vw;
    height: 53vw;s
  `}
`;

export const Content = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};

  &:first-of-type {
    margin-top: 48px;
    margin-bottom: 36px;
  }

  ${breakpointDown('sm')`
    &:first-of-type {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  `}
`;

export const ViewButton = styled(Button).attrs({
  variant: 'primary',
})`
  z-index: 10;
`;
