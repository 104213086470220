import BuyTree from 'components/BuyTree';
import ClaimToken from 'components/ClaimToken';
import useSetPageConfig from 'components/Page/useSetPageConfig';
import useTransactionMessages from 'hooks/useTransactionMessages';

import * as S from './styles';
import WalletOverview from './WalletOverview';
import WalletTransactionsHistory from './WalletTransactionsHistory';

const Wallet: React.FC = () => {
  useTransactionMessages({ transactionType: 'purchaseTree' });
  useSetPageConfig({ mode: 'fluid' });

  return (
    <>
      <WalletOverview />

      <S.Root>
        <S.Action>
          <BuyTree returnURL="wallet" />
        </S.Action>

        <S.Action>
          <ClaimToken returnURL="wallet" />
        </S.Action>
      </S.Root>

      <WalletTransactionsHistory />
    </>
  );
};

export default Wallet;
