import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import { Dropdown, Input } from 'components/FormFields';

import * as S from './styles';
import { useTaxInformationForm } from './useTaxInformationForm';

export type TaxInformationFormProps = {
  country: string;
  onCountryChange: (newCountry: string) => void;
  onTinChange: (newTin: string) => void;
  onValidChange: (isValid: boolean) => void;
};

const TaxInformationForm: React.FC<
  WithTranslation<TaxInformationFormProps>
> = ({ country, t, ...props }) => {
  const { formState, control, countriesList, onCountryChange, onTinChange } =
    useTaxInformationForm(props);

  const handleOnTINChange = useCallback(
    (newTin: string | InputEvent): void => {
      if (typeof newTin === 'string') onTinChange(newTin);
    },
    [onTinChange],
  );

  return (
    <S.Root
      fluid
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'tax-information-form-main',
          contents: [
            <Dropdown
              fluid
              key="country"
              name="country"
              label={t('country')}
              placeholder={t('country')}
              defaultValue={country}
              options={countriesList}
              onSelectionChange={onCountryChange}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="taxIdentificationNumber"
              name="taxIdentificationNumber"
              label={t('tin')}
              placeholder={t('tin')}
              onChange={handleOnTINChange}
              {...{ control, formState }}
            />,
          ],
        },
      ]}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'forms:taxInformationForm',
})(TaxInformationForm);
