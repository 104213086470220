import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  getThemeBoxShadow,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Button).attrs({
  variant: 'text',
})`
  width: 120px;
  height: 120px;
  border: none;
  background-color: transparent;
  box-shadow: ${getThemeBoxShadow('md')};
  overflow: hidden;

  @media (max-width: 400px) {
    width: 25vw;
    height: 25vw;
  }

  ${breakpointDown('xxxs')`
    width: 20vw;
    height: 20vw;
  `}
`;
