// Ignoring rule so the text formatting is kept.
/* eslint-disable react/no-danger */

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import * as S from './styles';

export type DescriptionSectionProps = {
  title?: string;
  subtitle?: string;
  text: string;
};

export type DescriptionRoot = {
  children: React.ReactNode;
};

const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  title,
  subtitle,
  text,
}) => {
  return (
    <>
      <RenderWhen condition={!!title}>
        <S.Title>{title}</S.Title>
      </RenderWhen>

      <S.TextSection>
        <RenderWhen condition={!!subtitle}>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </RenderWhen>

        <S.Description dangerouslySetInnerHTML={{ __html: text }} />
      </S.TextSection>
    </>
  );
};

function DescriptionRoot({ children }: DescriptionRoot) {
  return <S.Root>{children}</S.Root>;
}

export const Description = {
  Root: DescriptionRoot,
  Section: DescriptionSection,
};
