import { Document } from 'react-pdf';

import * as S from './styles';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

export type PDFProps = {
  pdfFile: Blob | null;
};

const PDF: React.FC<PDFProps> = ({ pdfFile }) => {
  if (!pdfFile) return null;

  return (
    <S.Root>
      <Document file={pdfFile} loading="" noData="" error="">
        <S.PDFPage pageNumber={1} loading="" noData="" error="" />
      </Document>
    </S.Root>
  );
};

export default PDF;
