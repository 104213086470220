import * as S from './styles';

export type ColorfulBackgroundProps = {
  children: React.ReactNode;
};

const ColorfulBackground: React.FC<ColorfulBackgroundProps> = ({
  children,
}) => {
  return (
    <S.Root>
      <S.Main>{children}</S.Main>
    </S.Root>
  );
};

export default ColorfulBackground;
