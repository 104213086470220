import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import ToastContainer from 'components/Toast/ToastContainer';
import { ToastMessage } from 'hooks/types';

export type ToastContextData = {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
};

export type ToastProviderConfig = {
  children: React.ReactNode;
};

const Toast = createContext<ToastContextData>({} as ToastContextData);

/**
 * It's a React component that receives childpren as a prop, and returns a Toast.Provider component with
 * a value prop that contains the addToast and removeToast functions, and a ToastContainer component
 * that receives the messages array as a prop.
 *
 * @param {ToastProviderConfig} useToast - ToastProviderConfig.
 *
 * @returns A wrapper toast provider.
 */
function ToastProvider({ children }: ToastProviderConfig): React.ReactNode {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ message, state }: Omit<ToastMessage, 'id'>) => {
      const id = uuidv4();

      const toast = {
        id,
        message,
        state,
      };

      setMessages((oldMessages) => [...oldMessages, toast]);
    },
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessages((oldMessages) =>
      oldMessages.filter((message) => message.id !== id),
    );
  }, []);

  const toastProviderValue = useMemo(
    () => ({ addToast, removeToast }),
    [addToast, removeToast],
  );

  return (
    <Toast.Provider value={toastProviderValue}>
      <ToastContainer messages={messages.slice(0, 3)} />
      {children}
    </Toast.Provider>
  );
}

/**
 * It returns the context data from the ToastContext.
 *
 * @returns The context of the ToastProvider.
 */
function useToast(): ToastContextData {
  const context = useContext(Toast);

  if (!context)
    throw new Error('useToast must be used within a ToastProvider.');

  return context;
}

export { ToastProvider, useToast };
