import { useCallback } from 'react';
import { produce } from 'immer';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  updateSubscriptionPlan,
  UpdateSubscriptionPlanArgs,
  User,
} from 'api/services';
import { useApplicationActions } from 'state/application';
import { useUserId } from 'state/user';
import { userQueryKeys } from 'state/user/queryKeys';

export default function useUpdateSubscriptionPlan() {
  const { data: userId } = useUserId();
  const queryClient = useQueryClient();
  const queryKey = userQueryKeys.user(userId);
  const { setUpdatingSubscriptionPlan } = useApplicationActions();

  return useMutation<
    UpdateSubscriptionPlanArgs,
    unknown,
    UpdateSubscriptionPlanArgs,
    unknown
  >({
    mutationFn: useCallback(async (args: UpdateSubscriptionPlanArgs) => {
      await updateSubscriptionPlan(args);

      return args;
    }, []),
    onMutate: () => {
      setUpdatingSubscriptionPlan(true);
    },
    onSuccess: async ({ body, params }: UpdateSubscriptionPlanArgs) => {
      await queryClient.cancelQueries({ queryKey });

      const previousUser: User | undefined = queryClient.getQueryData(queryKey);

      if (!previousUser) return;

      const { units } = body;
      const { id } = params;

      queryClient.setQueryData(
        queryKey,
        produce(previousUser, (draft) => {
          if (!draft.subscriptionPlan) return;

          draft.subscriptionPlan.id = id;
          draft.subscriptionPlan.units = units;
        }),
      );
    },
    onSettled: () => {
      setUpdatingSubscriptionPlan(false);
    },
  });
}
