import i18n from 'i18n/i18n';
import { DEFAULT_APPLICATION_LANGUAGE } from 'constants/application';
import { getItemFromLocalStorage, setItemInLocalStorage } from 'utils/handlers';

export function getUserLanguage(): string {
  return getItemFromLocalStorage('language') || DEFAULT_APPLICATION_LANGUAGE;
}

export function setUserLanguage(language: string): void {
  setItemInLocalStorage('language', language);

  document.documentElement.setAttribute('lang', language);

  i18n.changeLanguage(language);
}

export function getIsUserValidPhysicalPerson(): boolean | undefined {
  return getItemFromLocalStorage('isValidPhysicalPerson');
}

export function setIsUserValidPhysicalPerson(
  isValidPhysicalPerson: boolean,
): void {
  setItemInLocalStorage('isValidPhysicalPerson', isValidPhysicalPerson);
}
