import { useEscClicked } from '@abundance-brasil-wabi/sabi';

import { useNavbarActions } from 'state/navbar';

import LeftMenuInstitutionalNavbar from './LeftMenuInstitutionalNavbar';
import LeftMenuNavbar from './LeftMenuNavbar';
import MetamaskDisclaimerNavbar from './MetamaskDisclaimerNavbar';
import RightMenuNavbar from './RightMenuNavbar';

const Navbars: React.FC = () => {
  const { dequeueNavbar } = useNavbarActions();

  useEscClicked(dequeueNavbar);

  return (
    <>
      <RightMenuNavbar />
      <MetamaskDisclaimerNavbar />
      <LeftMenuNavbar />
      <LeftMenuInstitutionalNavbar />
    </>
  );
};

export default Navbars;
