import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import UserAbsorptionReport from 'components/PDF/UserAbsorptionReport';
import { useAbsorptionReport } from 'state/tree';
import { downloadFile } from 'utils/handlers';

import * as S from './styles';

const UserAbsorptionReportModal: React.FC<WithTranslation> = forwardRef(
  ({ t }, ref) => {
    const { data: absorptionImpactFile } = useAbsorptionReport();

    const handlePrint = useCallback((): void | null => {
      if (!absorptionImpactFile) return;

      downloadFile({
        file: absorptionImpactFile,
        fileName: t('fileName'),
      });
    }, [absorptionImpactFile, t]);

    useImperativeHandle(ref, () => ({
      handlePrint,
      content: () => null,
    }));

    return (
      <S.Root>
        <UserAbsorptionReport />
      </S.Root>
    );
  },
);

export default withTranslation('components', {
  keyPrefix: 'modal:userAbsorptionReportModal',
  withRef: true,
})(UserAbsorptionReportModal);
