import { withTranslation } from 'react-i18next';

import { ContentsHeader } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useSetPageConfig } from 'components/Page';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

const CreateNewPasswordSuccess: React.FC<WithTranslation> = ({ t }) => {
  useSetPageConfig({ mode: 'split' });

  return (
    <S.Root>
      <ContentsHeader
        title={t('title')}
        description={t('youCanAccessYourAccount')}
      />

      <S.AccessButton variant="primary" to={getPathnameFromKey('signIn')}>
        {t('accessMyAccount')}
      </S.AccessButton>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'createNewPasswordSuccess',
})(CreateNewPasswordSuccess);
