import { forwardRef } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, FormComposer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Password } from 'components/FormFields';

import { usePasswordVerificationForm } from './usePasswordVerificationForm';

const PasswordVerificationForm: React.FC<WithTranslation> = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ t }, _ref) => {
    const { control, formState, isLoading, onSubmit } =
      usePasswordVerificationForm();

    return (
      <FormComposer
        fluid
        title={t('title')}
        description={t('description')}
        sections={[
          {
            id: 'password-verification-modal-form-main',
            contents: [
              <Password
                fluid
                key="verifyPassword"
                name="verifyPassword"
                label={t('label')}
                isDisable={isLoading}
                {...{ control, formState }}
              />,

              <Button
                key="submit"
                type="submit"
                variant="primary"
                isDisabled={!formState.isValid || isLoading}
              >
                {t('send')}
              </Button>,
            ],
          },
        ]}
        onSubmit={onSubmit}
      />
    );
  },
);

export default withTranslation('components', {
  keyPrefix: 'modal:passwordVerificationModal:passwordVerificationForm',
})(PasswordVerificationForm);
