import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { changeLanguage, ChangeLanguageArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useChangeLanguage(
  options?: MutationOptions<void, ChangeLanguageArgs>,
) {
  return useMutation<void, ErrorMessage, ChangeLanguageArgs, unknown>(
    changeLanguage,
    options,
  );
}
