import { Dispatch, SetStateAction, useState } from 'react';

export type UseTabNavigationConfig<T> = {
  defaultTabId: T;
};

export type UseTabNavigation<T> = {
  activeTab: T;
  setActiveTab: Dispatch<SetStateAction<T>>;
};

/**
 * Handles the control of tabs.
 *
 * @param {UseTabNavigationConfig}  defaultTabId - The initial tab id to be used.
 *
 * @returns An object with two properties: activeTab and setActiveTab.
 */
export default function useTabNavigation<T>({
  defaultTabId,
}: UseTabNavigationConfig<T>): UseTabNavigation<T> {
  const [activeTab, setActiveTab] = useState<T>(defaultTabId);

  return {
    activeTab,
    setActiveTab,
  };
}
