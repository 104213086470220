import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import {
  manageSubscriptionPlan,
  ManageSubscriptionPlanArgs,
  ManageSubscriptionPlanResponse,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useManageSubscriptionPlan(
  options?: MutationOptions<
    ManageSubscriptionPlanResponse,
    ManageSubscriptionPlanArgs
  >,
) {
  return useMutation<
    ManageSubscriptionPlanResponse,
    ErrorMessage,
    ManageSubscriptionPlanArgs,
    unknown
  >(manageSubscriptionPlan, options);
}
