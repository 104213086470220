import { BASE_URL_ABUNDANCE_API, BASE_URL_MEDIA_API } from 'constants/env';

import {
  abundanceAPI as abundanceAPIType,
  ExternalEndpoint,
  PDFsEndpoint,
} from './types';

export const abundanceAPI: abundanceAPIType = {
  V1: {
    ABUDANCE_WALLET: '/v1/wallets',
    ACCOUNT: '/v1/account',
    CARBON_OFFSET: '/v1/carbon-offsets',
    CLAIMS: '/v1/claims',
    COMPANY: '/v1/companies',
    CUSTOMER: '/v1/customers',
    FILES: BASE_URL_ABUNDANCE_API,
    MEDIA: BASE_URL_MEDIA_API,
    PURCHASE: '/v1/payments',
    SUBSCRIPTION: '/v1/subscription-plans',
    SUPPORT: '/v1/support',
    SYNTROPY: '/v1/syntropies',
    TREE: '/v1/trees',
    VIRTUAL_FOREST: '/v1/virtual-forests',
  },
};

export const externalEndpoints: ExternalEndpoint = {
  ARTICLE: 'https://abundancebrasil.com/wp-json/wp/v2/posts',
  COUNTRY: 'https://restcountries.com/v3.1/all',
};

export const pdfsEndpoints: PDFsEndpoint = {
  ABSORPTION_REPORT: `${abundanceAPI.V1.FILES}/v1/trees/users/current/absorption-report`,
  CERTIFICATE: `${abundanceAPI.V1.FILES}/v1/trees/users/current/certificate`,
  DOCUMENTS: `${abundanceAPI.V1.MEDIA}/pdf/documents`,
};
