import { withTranslation } from 'react-i18next';

import { Button, FormComposer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Password } from 'components/FormFields';

import { useCreateNewPasswordForm } from './useCreateNewPasswordForm';

const CreateNewPasswordForm: React.FC<WithTranslation> = ({ t }) => {
  const { control, handleSubmit, formState, isLoading, onSubmit } =
    useCreateNewPasswordForm();

  return (
    <FormComposer
      fluid
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'create-new-password-form-main',
          contents: [
            <Password
              fluid
              key="newPassword"
              name="newPassword"
              label={t('newPassword')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Password
              fluid
              key="newPasswordConfirm"
              name="newPasswordConfirm"
              label={t('newPasswordConfirm')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Button
              fluid
              key="submit"
              type="submit"
              variant="primary"
              isDisabled={!formState.isValid || isLoading}
            >
              {t('save')}
            </Button>,
          ],
        },
      ]}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'createNewPassword:createNewPasswordForm',
})(CreateNewPasswordForm);
