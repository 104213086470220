import { styled } from 'styled-components';

import {
  breakpointDown,
  FormComposer,
  getThemeColor,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(FormComposer)`
  padding: 24px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    padding: 16px
  `}
`;
