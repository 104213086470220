import { MutationOptions } from 'types/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  updateCarbonOffsetCalculation,
  UpdateCarbonOffsetCalculationArgs,
  UpdateCarbonOffsetCalculationResponse,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';
import { useUserId } from 'state/user';

import { carbonOffestQueryKeys } from './queryKeys';

export default function useUpdateCarbonOffsetCalculation(
  options?: MutationOptions<
    UpdateCarbonOffsetCalculationResponse,
    UpdateCarbonOffsetCalculationArgs
  >,
) {
  const { data: userId } = useUserId();
  const queryClient = useQueryClient();

  return useMutation<
    UpdateCarbonOffsetCalculationResponse,
    ErrorMessage,
    UpdateCarbonOffsetCalculationArgs,
    unknown
  >(updateCarbonOffsetCalculation, {
    ...options,
    onSuccess: (response) => {
      queryClient.setQueryData(
        carbonOffestQueryKeys.carbonOffsetCalculation(userId, response.id),
        response,
      );
      queryClient.setQueryData(
        carbonOffestQueryKeys.currentUserCarbonOffsetCalculation(userId),
        response,
      );
    },
  });
}
