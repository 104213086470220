import { CardsList } from '@abundance-brasil-wabi/sabi';

import { useImpact } from 'state/tree';

import AuroraVerdeInformationalCard from './AuroraVerdeInformationalCard';
import EnvironmentalRestorerInformationalCard from './EnvironmentalRestorerInformationalCard';
import MetamaskInformationalCard from './MetamaskInformationalCard';

const InformationalCardsList: React.FC = () => {
  const { isLoading: impactLoading } = useImpact();

  return (
    <CardsList isLoading={impactLoading}>
      <AuroraVerdeInformationalCard />

      <EnvironmentalRestorerInformationalCard />

      <MetamaskInformationalCard />
    </CardsList>
  );
};

export default InformationalCardsList;
