import { withTranslation } from 'react-i18next';

import { DocumentIcon } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useImpact } from 'state/tree';

import CarbonManagementCard from './CarbonManagementCard';

const CompensationCarbonManagementCard: React.FC<WithTranslation> = ({ t }) => {
  const { data: impact } = useImpact();

  return (
    <CarbonManagementCard
      title={t('title')}
      subtitle={t('subtitle')}
      icon={<DocumentIcon />}
      description={t('description')}
      availableTitle={t('availableTitle')}
      availableValue={impact?.balance}
      co2Title={t('co2Title')}
      co2Value="0 t"
      actionButtonTitle={t('actionButtonTitle')}
      isActionButtonDisabled
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'impact:carbonManagementCards:compensationCarbonManagementCard',
})(CompensationCarbonManagementCard);
