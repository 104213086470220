import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import { MetamaskLogo } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useNavbarActions } from 'state/navbar';
import { useImpact } from 'state/tree';

import InformationalCard from './InformationalCard';

const MetamaskInformationalCard: React.FC<WithTranslation> = ({ t }) => {
  const { data: impact } = useImpact();
  const { enqueueNavbar } = useNavbarActions();
  const dispatch = useDispatch();

  const { balance, until } = impact || {};
  const isDisabled = !balance && !until;

  const handleOpenMetamaskNavbar = useCallback((): void => {
    enqueueNavbar({
      name: 'MetaMaskDisclaimer',
    });
  }, [enqueueNavbar]);

  const openClaimTokenModal = useCallback((): void => {
    if (isDisabled) return;

    dispatch(pushModal({ name: ModalsList.CLAIM_TOKEN_MODAL }));
  }, [dispatch, isDisabled]);

  return (
    <InformationalCard
      icon={<MetamaskLogo />}
      tagContent={t('tagContent')}
      defaultTagColor="yellow"
      subtitle={t('subtitle')}
      title={t('title')}
      description={t('description')}
      primaryButtonTitle={t('primaryButton:disabledText')}
      onPrimaryButtonPress={openClaimTokenModal}
      isPrimaryButtonDisabled
      secondaryButtonTitle={t('secondaryButton:text')}
      onSecondaryButtonPress={handleOpenMetamaskNavbar}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:informationalCards:metamaskInformationalCard',
})(MetamaskInformationalCard);
