import {
  CarbonOffsetEnrollmentRestorationProfile,
  User,
  UserSubscriptionPlan,
} from 'api/services';

export const userSelector = (data: User): User => data;

export const userIdSelector = (data: User): string => data.id;

export const userShortNameSelector = (data: User): string => data.shortName;

export const userSubscriptionPlanSelector = (
  data: User,
): UserSubscriptionPlan | null | undefined => data.subscriptionPlan;

export const userHasSubscriptionPlanSelector = (data: User): boolean =>
  !!data.subscriptionPlan && data.subscriptionPlan?.status;

export const userValidPhysicalPersonSelector = (data: User): boolean =>
  data.isValidPhysicalPerson;

export const userPhoneNumberSelector = (data: User): string => data.phoneNumber;

export const userSyntropyLinkSelector = (data: User): string | undefined =>
  data.syntropyLink;

export const userHasSyntropyAccessSelector = (data: User): boolean =>
  !!userSyntropyLinkSelector(data);

export const userRestorationProfileSelector = (
  data: User,
): CarbonOffsetEnrollmentRestorationProfile =>
  userValidPhysicalPersonSelector(data) ? 0 : 1;
