import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import {
  useCarbonOffsetCalculationById,
  useCarbonOffsetEnrollmentById,
  useCreateCarbonOffsetEnrollment,
} from 'state/carbonOffset';
import { createSearchParams } from 'utils/formatting';
import { getDefaultAppLanguage } from 'utils/handlers';

import { registerYourProfileFormSchema } from './constants';
import { RegisterYourProfileFormSchema } from './types';

export type UseRegisterYourProfileForm =
  FormReturn<RegisterYourProfileFormSchema> & {
    isLoading: boolean;
    onSubmit: () => void;
  };

export function useRegisterYourProfileForm(): UseRegisterYourProfileForm {
  const [enrollmentId, calculationId] = useGetSearchParams([
    'enrollmentId',
    'calculationId',
  ]);

  const { mutateAsync: createCarbonOffsetEnrollment, isLoading } =
    useCreateCarbonOffsetEnrollment();
  const { data: carbonOffsetEnrollment } =
    useCarbonOffsetEnrollmentById(enrollmentId);
  const { data: carbonOffsetCalculation } =
    useCarbonOffsetCalculationById(calculationId);
  const carbonOffsetEnrollmentInfo = carbonOffsetCalculation?.enrollment;

  const { addToast } = useToast();
  const navigate = useNavigate();

  const form = useForm<RegisterYourProfileFormSchema>({
    resolver: zodResolver(registerYourProfileFormSchema),
    mode: 'all',
    defaultValues: {
      name: carbonOffsetEnrollment?.name ?? carbonOffsetEnrollmentInfo?.name,
      email: carbonOffsetEnrollment?.email ?? carbonOffsetEnrollmentInfo?.email,
      conditions: Boolean(carbonOffsetEnrollment || carbonOffsetEnrollmentInfo),
    },
  });

  const { handleSubmit } = form;

  const handleGoToCalculationPage = useCallback(
    (id?: string): void => {
      navigate({
        pathname: getPathnameFromKey('carbonOffsetCalculator'),
        search: createSearchParams(
          id || carbonOffsetEnrollment
            ? {
                enrollmentId: id ?? carbonOffsetEnrollment?.id ?? '',
              }
            : { calculationId: carbonOffsetCalculation?.id ?? '' },
        ),
      });
    },
    [carbonOffsetCalculation?.id, carbonOffsetEnrollment, navigate],
  );

  const submit = useCallback(
    (data: RegisterYourProfileFormSchema): void => {
      const { name, email } = data;
      const { name: defaultName, email: defaultEmail } =
        carbonOffsetEnrollmentInfo ?? carbonOffsetEnrollment ?? {};

      if (name === defaultName && email === defaultEmail)
        return handleGoToCalculationPage();

      createCarbonOffsetEnrollment(
        {
          body: {
            name,
            email,
            language: getDefaultAppLanguage(),
            restorationProfile: 1,
          },
        },
        {
          onSuccess: ({ id }) => {
            handleGoToCalculationPage(email === defaultEmail ? undefined : id);
          },
          onError: (error) => {
            addToast({
              message: getErrorMessage(error),
              state: 'error',
            });
          },
        },
      );
    },
    [
      addToast,
      carbonOffsetEnrollment,
      carbonOffsetEnrollmentInfo,
      createCarbonOffsetEnrollment,
      handleGoToCalculationPage,
    ],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
