import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { transactionMessages, transactionParams } from './constants';
import { TransactionType } from './types';
import { useToast } from './useToast';

type Param = {
  value: string;
  param: string;
};

type UseTransactionMessagesConfig = {
  transactionType: TransactionType;
};

/**
 * It takes a transaction type and a search parameter and displays
 * a toast message based on the transaction type and the search parameter.
 *
 * @param {UseTransactionMessagesConfig} transactionType - the type of transaction that is being
 * performed.
 */
export default function useTransactionMessages({
  transactionType,
}: UseTransactionMessagesConfig): void {
  const { addToast } = useToast();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = useCallback((): Param | void => {
    let parameter: Param = {} as Param;

    transactionParams.forEach((param: string) => {
      const result = searchParams.get(param);

      if (result) parameter = { value: result, param };
    });

    return parameter;
  }, [searchParams]);

  const getParamValueAndClearURI = useCallback((): Param | void => {
    const param = getParam();

    if (!param) return;

    searchParams.delete(param.param);

    setSearchParams(searchParams);

    return param;
  }, [getParam, searchParams, setSearchParams]);

  useEffect(() => {
    const param = getParamValueAndClearURI();

    if (!param?.param) return;

    const trasactionConfig =
      transactionMessages[transactionType]?.[param.value];

    const parameterType = trasactionConfig?.type;
    const message = trasactionConfig?.message;

    if (parameterType && message)
      addToast({
        message: t(`${message}`),
        state: parameterType,
      });
  }, [addToast, getParamValueAndClearURI, t, transactionType]);
}
