import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from 'hooks/useToast';
import {
  useCreateSubscriptionPlan,
  useManageSubscriptionPlan,
  useUpdateSubscriptionPlan,
} from 'state/subscriptionPlan';
import { useUserSubscriptionPlan } from 'state/user';

export type UseSubscription = {
  currentSelectedPlan: string | undefined;
  subscribeToPlan: (
    id: string,
    country: string,
    taxIdentificationNumber: string,
    returnUrl: string,
    callback?: () => void,
  ) => void;
  updatePlan: (id: string, units: number, callback?: () => void) => void;
  managePlan: (
    preturnUrl: string,
    subscriptionCancel?: boolean,
    subscriptionId?: string,
  ) => void;
};

export default function useSubscription(): UseSubscription {
  const { t } = useTranslation('shared', {
    keyPrefix: 'hooks:useSubscription',
  });

  const { mutate: createSubscriptionPlan } = useCreateSubscriptionPlan();
  const { mutate: updateUserSubscriptionPlan } = useUpdateSubscriptionPlan();
  const { mutate: manageSubscriptionPlan } = useManageSubscriptionPlan();
  const { data: userSubscriptionPlan } = useUserSubscriptionPlan();

  const { addToast } = useToast();
  const currentSelectedPlan = userSubscriptionPlan?.id;

  const subscribeToPlan = useCallback(
    (
      id: string,
      country: string,
      taxIdentificationNumber: string,
      returnUrl: string,
      callback?: () => void,
    ): void => {
      createSubscriptionPlan(
        {
          body: {
            id,
            country,
            taxIdentificationNumber,
            returnUrl,
          },
        },
        {
          onSuccess: ({ url }) => {
            window.location.href = url;
          },
          onError: () => {
            addToast({
              message: t('error'),
              state: 'error',
            });
          },
          onSettled: () => {
            if (callback) callback();
          },
        },
      );
    },
    [addToast, createSubscriptionPlan, t],
  );

  const updatePlan = useCallback(
    (id: string, units: number, callback?: () => void): void => {
      updateUserSubscriptionPlan(
        {
          body: {
            units,
            subscriptionId: userSubscriptionPlan?.subscriptionId || '',
          },
          params: {
            id,
          },
        },
        {
          onSuccess: () => {
            addToast({
              message: t('success'),
              state: 'success',
            });

            if (callback) callback();
          },
          onError: () => {
            addToast({
              message: t('error'),
              state: 'error',
            });
          },
        },
      );
    },
    [
      addToast,
      t,
      updateUserSubscriptionPlan,
      userSubscriptionPlan?.subscriptionId,
    ],
  );

  const managePlan = useCallback(
    (
      returnUrl: string,
      subscriptionCancel?: boolean,
      subscriptionId?: string,
    ): void => {
      manageSubscriptionPlan(
        {
          body: {
            returnUrl,
            subscriptionCancel: subscriptionCancel || false,
            subscriptionId: subscriptionId || '',
          },
        },
        {
          onSuccess: ({ url }) => {
            window.location.href = url;
          },
          onError: () => {
            addToast({
              message: t('manageError'),
              state: 'error',
            });
          },
        },
      );
    },
    [addToast, manageSubscriptionPlan, t],
  );

  return {
    currentSelectedPlan,
    subscribeToPlan,
    updatePlan,
    managePlan,
  };
}
