import { findKey } from 'lodash';

import paths from './paths';
import { PathKey } from './types';

/**
 * Takes a pathkey string as input and returns the pathname
 * associated with that pathkey in the routes's `paths` object.
 *
 * @param {string} pathkey - Represents the pathkey for which you want to find
 * the corresponding pathname in the routes's `paths` object.
 *
 * @returns If a matching pathname is found, it is returned as a string. If no
 * matching pathname is found, `undefined` is returned.
 */
export function getPathnameFromKey(pathKey: PathKey): string {
  return paths[pathKey]?.path;
}

/**
 * Takes a pathname string as input and returns the key
 * associated with that path in the routes's `paths` object.
 *
 * @param {string} pathName - Represents the path for which you want to find
 * the corresponding key in the routes's `paths` object.
 *
 * @returns If a matching key is found, it is returned as a string. If no
 * matching key is found, `undefined` is returned.
 */
export function getKeyFromPathname(pathName: string): PathKey | undefined {
  const pathKey = findKey(paths, (value) => value.path === pathName);

  if (!pathKey) return;

  return pathKey as PathKey;
}
