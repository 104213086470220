import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonProps } from '@abundance-brasil-wabi/sabi';

export type LinkButtonProps = ButtonProps & {
  to: string;
  search?: string;
  onPress?: () => void;
};

const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  search,
  onPress,
  children,
  ...props
}) => {
  const navigate = useNavigate();

  const handleOnPress = useCallback((): void => {
    onPress?.();

    return navigate({
      pathname: to,
      search,
    });
  }, [navigate, onPress, search, to]);

  return (
    <Button onPress={handleOnPress} {...props}>
      {children}
    </Button>
  );
};

export default LinkButton;
