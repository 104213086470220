import { Trans, withTranslation } from 'react-i18next';

import { Divider, Link, Navbar, Spacer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { IMAGE_URL } from 'constants/assets';
import { useNavbarActions, useNavbarIsOpen } from 'state/navbar';

import * as S from './styles';

const MetamaskDisclaimerNavbar: React.FC<WithTranslation> = ({ t }) => {
  const { dequeueNavbar } = useNavbarActions();
  const isOpen = useNavbarIsOpen('MetaMaskDisclaimer');

  return (
    <Navbar isOpen={isOpen} side="right" width={1120}>
      <S.Header>
        <S.BackButton onPress={dequeueNavbar}>
          <S.BackIcon />
        </S.BackButton>
      </S.Header>

      <S.Contents>
        <S.Title>{t('title')}</S.Title>

        <Divider margin="24px 0 48px 0" />

        <S.Main>
          <S.MMLogo />

          <S.Container>
            <S.MainTitle>{t('firstTitle')}</S.MainTitle>

            <S.Paragraph>{t('firstParagraph')}</S.Paragraph>
          </S.Container>

          <Link
            variant="primary"
            target="_blank"
            href="https://metamask.io/download/"
          >
            {t('startButton')}
          </Link>

          <Divider />

          <S.MainTitle>{t('howToCreate')}</S.MainTitle>

          <Spacer direction="column" align="flex-start" justify="center">
            {/* First step */}
            <S.Step>{t('step')} 1</S.Step>

            <S.Paragraph>
              {t('firstStep')} <b>Chrome</b>, <b>Firefox</b>
              {', '}
              <b>Edge</b> {t('and')} <b>Brave</b>.{' '}
              <S.ExternalLink href="https://metamask.io/download/">
                Link
              </S.ExternalLink>
              .
            </S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756804/Assets/Informational/Metamask/metamask-first-step_wb3l0c.png`}
              alt={t('imageAlt')}
            />

            {/* Second step */}
            <S.Step>{t('step')} 2</S.Step>

            <S.Paragraph>{t('secondStep')}</S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756849/Assets/Informational/Metamask/metamask-second-step_lanzzv.png`}
              alt={t('imageAlt')}
            />

            {/* Third step */}
            <S.Step>{t('step')} 3</S.Step>

            <S.Paragraph>{t('thirdStep')}</S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756889/Assets/Informational/Metamask/metamask-third-step_ewngzk.png`}
              alt={t('imageAlt')}
            />

            {/* Fourth step */}
            <S.Step>{t('step')} 4</S.Step>

            <S.Paragraph>{t('fourthStep')}</S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756750/Assets/Informational/Metamask/metamask-fourth-step_is4t8t.png`}
              alt={t('imageAlt')}
            />

            {/* Fifth step */}
            <S.Step>{t('step')} 5</S.Step>

            <S.Paragraph>
              <Trans i18nKey="components:navbars:metaMaskDisclaimerNavbar:fifthStep" />
            </S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756707/Assets/Informational/Metamask/metamask-fifth-step_ycbd4h.png`}
              alt={t('imageAlt')}
            />

            {/* Sixth step */}
            <S.Step>{t('step')} 6</S.Step>

            <S.Paragraph>
              <Trans i18nKey="components:navbars:metaMaskDisclaimerNavbar:sixthStep" />
            </S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756664/Assets/Informational/Metamask/metamask-sixth-step_hmnu33.png`}
              alt={t('imageAlt')}
            />

            {/* Seventh step */}
            <S.Step>{t('step')} 7</S.Step>

            <S.Paragraph>
              <Trans i18nKey="components:navbars:metaMaskDisclaimerNavbar:seventhStep" />
            </S.Paragraph>

            <S.StepImage
              src={`${IMAGE_URL}/v1702756926/Assets/Informational/Metamask/metamask-seventh-step_axrfen.png`}
              alt={t('imageAlt')}
            />
          </Spacer>

          <Divider />

          <Link
            variant="primary"
            target="_blank"
            href="https://metamask.io/download/"
          >
            {t('startButton')}
          </Link>
        </S.Main>
      </S.Contents>
    </Navbar>
  );
};

export default withTranslation('components', {
  keyPrefix: 'navbars:metaMaskDisclaimerNavbar',
})(MetamaskDisclaimerNavbar);
