import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Title = styled(Heading).attrs({
  level: 6,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
