import { useCallback } from 'react';
import { omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import { setTwoFactorAuthData } from 'state/auth/utils';
import { useRegisterUser } from 'state/user';
import { getDefaultAppLanguage, removeCookie } from 'utils/handlers';

import { signUpFormSchema } from './constants';
import { SignUpFormSchema } from './types';

export type UseSignUpFormConfig = {
  isPhysicalPerson?: boolean;
};

export type UseSignUpForm = FormReturn<SignUpFormSchema> & {
  isLoading: boolean;
  onSubmit: () => void;
};

export function useSignUpForm({
  isPhysicalPerson,
}: UseSignUpFormConfig): UseSignUpForm {
  const { mutate: registerUser, isLoading } = useRegisterUser();
  const [email, name] = useGetSearchParams(['email', 'name']);

  const { addToast } = useToast();
  const navigate = useNavigate();

  const form = useForm<SignUpFormSchema>({
    resolver: zodResolver(signUpFormSchema),
    mode: 'all',
    defaultValues: {
      email,
      name,
    },
  });

  const { handleSubmit } = form;

  const submit = useCallback(
    (data: SignUpFormSchema): void => {
      registerUser(
        {
          body: {
            ...omit(data, 'country'),
            language: getDefaultAppLanguage(),
            isValidPhysicalPerson: isPhysicalPerson,
          },
        },
        {
          onSuccess: () => {
            setTwoFactorAuthData({
              email: data.email,
            });

            navigate(getPathnameFromKey('emailConfirmation'));
            removeCookie('email');
          },
          onError: (error) => {
            addToast({
              message: getErrorMessage(error),
              state: 'error',
            });
          },
        },
      );
    },
    [addToast, isPhysicalPerson, navigate, registerUser],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
