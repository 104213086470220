import { styled } from 'styled-components';

import { breakpointDown, Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})`
  width: 100%;
  max-width: 400px;
  padding: 8px;

  ${breakpointDown('lg')`
    padding: 0;
  `}
`;
