import { flow } from 'lodash';
import mixpanel, { Dict } from 'mixpanel-browser';

import { transformObjectKeysToPascalCase } from '@abundance-brasil-wabi/utils';

import { SetUserTrackingInfoProps } from '../types';
import { AppNameProps, TrackEventProps } from './types';

function addAppNameToProperties<T extends Dict | undefined>(
  properties: T,
): T & AppNameProps {
  const appName = { appName: 'ESGPortal' };

  return { ...properties, ...appName };
}

export function trackEvent({
  eventName,
  properties,
  optionsOrCallback,
  callback,
}: TrackEventProps): void {
  const allProperties = flow(addAppNameToProperties)(properties);
  const transformedProperties = !allProperties
    ? undefined
    : transformObjectKeysToPascalCase(allProperties);

  mixpanel.track(eventName, transformedProperties, optionsOrCallback, callback);
}

export function setUserTrackingInfo({
  userId,
  email,
}: SetUserTrackingInfoProps): void {
  mixpanel.identify(email);

  mixpanel.people.set({
    user_id: userId,
    $email: email,
    last_login: new Date(),
  });

  mixpanel.people.set_once('firstLogin', new Date().toISOString());

  mixpanel.register({ email });
}

export function clearUserTrackingInfo(): void {
  mixpanel.reset();
}
