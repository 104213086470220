import { useCertificate } from 'state/tree';

import PDF from '../PDF';

const UserCertificate: React.FC = () => {
  const { data: certificate } = useCertificate();

  if (!certificate) return null;

  return <PDF pdfFile={certificate} />;
};

export default UserCertificate;
