import { withTranslation } from 'react-i18next';

import { Button, FormComposer } from '@abundance-brasil-wabi/sabi';
import { showOnlyLastFourLetters } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import { Password } from 'components/FormFields';

import * as S from './styles';
import { useOTPValidationForm } from './useOTPValidationForm';

const OTPValidationForm: React.FC<WithTranslation> = ({ t }) => {
  const {
    control,
    formState,
    requestData,
    resendSuccess,
    isLoading,
    onSubmit,
    resendVerificationCode,
  } = useOTPValidationForm();

  return (
    <FormComposer
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'otp-validation-form-main',
          contents: [
            <Password
              fluid
              key="twoFactorCode"
              name="twoFactorCode"
              label={t('phoneVerification')}
              isDisabled={resendSuccess || isLoading}
              {...{ control, formState }}
            />,

            <div key="content">
              <S.TypeCode>
                {t('typeCode')}{' '}
                {showOnlyLastFourLetters(requestData.phoneNumber)}.
              </S.TypeCode>

              <br />

              <S.ResendCodeButton onPress={resendVerificationCode}>
                {t('resendCode')}
              </S.ResendCodeButton>
            </div>,

            <Button
              fluid
              key="submit"
              type="submit"
              variant="primary"
              isDisabled={!formState.isValid || isLoading}
            >
              {t('send')}
            </Button>,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'modal:otpValidationModal:otpValidationForm',
})(OTPValidationForm);
