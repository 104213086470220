import { AxiosError } from 'axios';

import { removeAuthEntriesFromStorage } from 'state/auth/utils';
import { useNavbarStore } from 'state/navbar';

export default async function responseErrorInterceptor(
  error: AxiosError,
): Promise<void> {
  if (error?.response?.status !== 401) return Promise.reject(error);

  if (Object.hasOwn(error?.config?.headers || {}, 'X-OTP'))
    return Promise.reject(error);

  // Deauthenticating user if unauthorized.
  removeAuthEntriesFromStorage();

  // Closing navbars.
  // TODO: Should also close modals here.
  useNavbarStore.getState().actions.dequeueNavbar();
}
