import React, { useCallback, useState } from 'react';

import {
  Loader,
  RenderWhen,
  Spacer,
  useWindowSize,
} from '@abundance-brasil-wabi/sabi';
import {
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from 'constants/env';

import {
  defaultContainerStyle,
  defaultCoordinates,
  defaultMapOptions,
  defaultZoomLevel,
  markerCoordinates,
} from './constants';
import LayerSelector from './LayerSelector';
import * as S from './styles';
import { Coordinate, GoogleMap as GoogleMapsProps } from './types';

const GoogleMaps: React.FC<GoogleMapsProps> = ({
  containerStyle,
  coordinates = defaultCoordinates,
  zoomLevel,
}) => {
  const { width } = useWindowSize();

  const [map, setMap] = useState<google.maps.MapOptions | null>();
  const [currentMapType, setCurrentMapType] = useState('satellite');
  const [currentCoordinates, setCurrentCoordinates] =
    useState<Coordinate>(coordinates);

  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback((maps: google.maps.Map): void => {
    setMap(maps as google.maps.MapOptions);
  }, []);

  const onUnmount = useCallback((): void => {
    setMap(null);
  }, []);

  const handleCenterEnd = useCallback((): void => {
    if (map?.center) {
      const lat: () => number = map.center.lat as () => number;
      const lng: () => number = map.center.lng as () => number;

      setCurrentCoordinates({
        lat: lat(),
        lng: lng(),
      });
    }
  }, [map]);

  return (
    <S.Root>
      <RenderWhen condition={isLoaded}>
        <GoogleMap
          options={{ ...defaultMapOptions, mapTypeId: currentMapType }}
          mapContainerStyle={
            containerStyle || {
              ...defaultContainerStyle,
              height: width < 570 ? '400px' : '560px',
            }
          }
          center={coordinates || defaultCoordinates}
          zoom={zoomLevel || defaultZoomLevel}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onCenterChanged={handleCenterEnd}
        >
          <OverlayView
            position={markerCoordinates}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <Marker position={markerCoordinates} />
          </OverlayView>

          <S.LayerSelectorContainer>
            <LayerSelector
              coordinates={currentCoordinates}
              handleMapType={setCurrentMapType}
              mapType="satellite"
            />
            <LayerSelector
              coordinates={currentCoordinates}
              handleMapType={setCurrentMapType}
              mapType="roadmap"
            />
          </S.LayerSelectorContainer>
        </GoogleMap>
      </RenderWhen>

      <RenderWhen condition={!isLoaded}>
        <Spacer
          direction="row"
          align="center"
          justify="center"
          style={
            containerStyle
              ? { ...containerStyle }
              : { ...defaultContainerStyle }
          }
        >
          <Loader />
        </Spacer>
      </RenderWhen>
    </S.Root>
  );
};

export default GoogleMaps;
