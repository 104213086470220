import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { mediaQueryKeys } from './queryKeys';

export default function useResetMediaFiles() {
  const queryClient = useQueryClient();

  return useCallback((): void => {
    queryClient.removeQueries(mediaQueryKeys.mediaKit());
  }, [queryClient]);
}
