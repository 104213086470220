import { styled } from 'styled-components';

import { Button, getThemeColor } from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
`;

export const BackButton = styled(Button).attrs({
  variant: 'text',
})`
  position: fixed;
  top: 16px;
  left: 16px;
  color: ${getThemeColor('text', 'default', 'primary')};
`;
