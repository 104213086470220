import { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Alert,
  CardsList,
  RenderWhen,
  useWindowSize,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import {
  EnvironmentalRestorerInformationalCard,
  ImpactSummaryCard,
  TreeSummaryCard,
} from 'components/Cards';
import { useSetPageConfig } from 'components/Page';
import { IMAGE_URL } from 'constants/assets';
import Header from 'pages/Impact/Header';
import { getPathnameFromKey } from 'router/utils';
import { useHasImpactBalance } from 'state/tree';

import CarbonManagementCardsList from './CarbonManagementCards';
import * as S from './styles';

const impactUntil2052Desktop = `${IMAGE_URL}/v1702757099/Assets/Informational/Impact/impact-until-2052-desktop_qctjlt.svg`;
const impactUntil2052Mobile = `${IMAGE_URL}/v1702757040/Assets/Informational/Impact/impact-until-2052-mobile_w8vijf.svg`;

const Impact: React.FC<WithTranslation> = ({ t }) => {
  const [tipAlertState, setTipAlertState] = useState(true);
  const [chartImg, setChartImg] = useState(impactUntil2052Desktop);

  const { data: hasImpactBalance } = useHasImpactBalance();
  const { width } = useWindowSize();
  useSetPageConfig({ mode: 'fluid' });

  const handleCloseTipAlert = useCallback((): void => {
    setTipAlertState((oldState) => !oldState);
  }, []);

  useEffect(() => {
    if (width > 550) return setChartImg(impactUntil2052Desktop);

    setChartImg(impactUntil2052Mobile);
  }, [width]);

  return (
    <S.Root>
      <RenderWhen condition={tipAlertState && !hasImpactBalance}>
        <Alert
          title={t('title')}
          message={
            <>
              {t('notARestorer')}{' '}
              <S.KnowMoreLink to={getPathnameFromKey('howToGetMyTokens')}>
                {t('knowMore')}
              </S.KnowMoreLink>
            </>
          }
          variant="toast"
          state="warning"
          fluid
          onClose={handleCloseTipAlert}
        />
      </RenderWhen>

      <CardsList>
        <TreeSummaryCard />

        <ImpactSummaryCard />

        <RenderWhen condition={hasImpactBalance}>
          <EnvironmentalRestorerInformationalCard />
        </RenderWhen>
      </CardsList>

      <S.CarbonManagement>
        <Header />

        <CarbonManagementCardsList />
      </S.CarbonManagement>

      <S.ImpactTo2052 src={chartImg} alt={t('chartImageAlt')} />
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'impact',
})(Impact);
