import { QueryOptions } from 'types/react-query';

import { getInitialsFromTextWithMultipleWords } from '@abundance-brasil-wabi/utils';
import { useQuery } from '@tanstack/react-query';

import { getUser, User } from 'api/services';
import { getIsAuthenticated, getUserIdFromToken } from 'state/auth/utils';
import { userQueryKeys } from 'state/user/queryKeys';

import {
  userHasSubscriptionPlanSelector,
  userHasSyntropyAccessSelector,
  userIdSelector,
  userPhoneNumberSelector,
  userRestorationProfileSelector,
  userSelector,
  userShortNameSelector,
  userSubscriptionPlanSelector,
  userSyntropyLinkSelector,
  userValidPhysicalPersonSelector,
} from './selectors';
import { UserQueryKey } from './types';
import { getIsUserValidPhysicalPerson } from './utils';

export default function useGetUser<TData = User>(
  options?: QueryOptions<User, TData, UserQueryKey>,
) {
  const isAuthenticated = getIsAuthenticated();

  return useQuery<User, unknown, TData, UserQueryKey>(
    userQueryKeys.user(getUserIdFromToken()),
    async () => {
      const data = await getUser();

      const user: User = {
        ...data,
        shortName: getInitialsFromTextWithMultipleWords(data.name),
        isValidPhysicalPerson: Boolean(getIsUserValidPhysicalPerson()),
      };

      return user;
    },
    {
      ...options,
      enabled: isAuthenticated,
    },
  );
}

export const useUser = (enabled?: boolean) =>
  useGetUser({ select: userSelector, enabled });

export const useUserId = () => useGetUser({ select: userIdSelector });

export const useUserShortName = () =>
  useGetUser({ select: userShortNameSelector });

export const useUserSubscriptionPlan = () =>
  useGetUser({ select: userSubscriptionPlanSelector });

export const useUserHasSubscriptionPlan = () =>
  useGetUser({ select: userHasSubscriptionPlanSelector });

export const useUserIsValidPhysicalPerson = () =>
  useGetUser({ select: userValidPhysicalPersonSelector });

export const useUserPhoneNumber = () =>
  useGetUser({ select: userPhoneNumberSelector });

export const useUserSyntropyLink = () =>
  useGetUser({ select: userSyntropyLinkSelector });

export const useUserHasSyntropyAccess = () =>
  useGetUser({ select: userHasSyntropyAccessSelector });

export const useUserRestorationProfile = () =>
  useGetUser({ select: userRestorationProfileSelector });
