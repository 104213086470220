import { styled } from 'styled-components';

import { breakpointDown, getThemeColor } from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  padding: 40px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;

  ${breakpointDown('lg')`
    gap: 24px;
    padding: 32px;
  `}

  ${breakpointDown('sm')`
    gap: 16px;
    padding: 24px;
  `}
`;
