import { styled } from 'styled-components';

import {
  AbundanceBrasilTopLightLogo,
  getThemeColor,
  Link as BaseLink,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: '24px',
})`
  width: 400px;
  max-width: 100%;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: hidden;
`;

export const Logo = styled(AbundanceBrasilTopLightLogo)`
  width: 172px;
  max-width: 90%;
  height: 98px;
`;

export const Link = styled(BaseLink).attrs({
  target: '_blank',
})`
  font-size: 16px;
  text-decoration: underline;
  color: ${getThemeColor('white')};
`;
