import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  Loader as BaseLoader,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  ${createFlexDisplay({
    direction: 'column',
    align: 'flex-start',
    justify: 'center',
    gap: 6,
  })}

  width: 100%;

  ${breakpointDown('sm')`
    gap: 16px;
  `}
`;

export const Loader = styled(BaseLoader)`
  position: fixed;
  left: 50%;
  top: 50%;
  right: 50%;
  bottom: 50%;
`;
