import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import i18next from 'i18next';
import { useApplicationActions } from 'state/application';
import { getAuthToken } from 'state/auth/utils';
import { useUserId } from 'state/user';
import { getUserPDFPreview } from 'utils/handlers';

import { treeQueryKeys } from './queryKeys';
import { absorptionReportSelector } from './selectors';
import { AbsorptionReportQueryKey } from './types';

export default function useGetAbsorptionReport<TData = Blob>(
  options?: QueryOptions<Blob, TData, AbsorptionReportQueryKey>,
) {
  const { setFileLoading } = useApplicationActions();
  const { data: userId } = useUserId();
  const token = getAuthToken();

  return useQuery<Blob, unknown, TData, AbsorptionReportQueryKey>(
    treeQueryKeys.absorptionReport(userId, i18next.language),
    async () => {
      setFileLoading(true);

      const result = await getUserPDFPreview('ABSORPTION_REPORT', token);

      setFileLoading(false);

      return result;
    },
    {
      ...options,
    },
  );
}

export const useAbsorptionReport = () =>
  useGetAbsorptionReport({ select: absorptionReportSelector });
