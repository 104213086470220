import { css, styled } from 'styled-components';

import {
  Button,
  Card,
  getStampBackgroundColor,
  getThemeColor,
  getThemeFontWeight,
  Stamp,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledInformationalCardProps } from './types';

function getTagStyle({ $tag }: StyledInformationalCardProps) {
  switch ($tag) {
    case 'green': {
      return css`
        background-color: ${getThemeColor('success', 'dark')};
      `;
    }

    case 'yellow': {
      return css`
        background-color: ${getThemeColor('warning', 'dark')};
      `;
    }

    case 'grey': {
      return css`
        background-color: ${getThemeColor('border', 'disabled')};
        color: ${getThemeColor('text', 'disabled')};
      `;
    }

    case 'grey-white': {
      return css`
        background-color: ${getThemeColor('default')};
      `;
    }

    default: {
      return css`
        ${getStampBackgroundColor($tag as Stamp)};
      `;
    }
  }
}

export const Root = styled(Card)`
  background-color: ${getThemeColor('default')};
  border: none;
`;

export const Heading = styled(Card.Content)`
  display: flex;
  gap: 12px;
`;

export const IconContainer = styled.span`
  & > * {
    width: 40px;
    height: 40px;
  }
`;

export const HeadingContents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled(Text).attrs({
  level: 'overline',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Tag = styled.span<StyledInformationalCardProps>`
  width: fit-content;
  margin-top: 8px;
  padding: 4px 8px;
  border-radius: 1000px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('white')};

  ${getTagStyle}
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const SecondaryButton = styled(Button).attrs({
  variant: 'text',
})`
  text-align: start;
  font-size: 14px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
