import { useMemo } from 'react';

import { Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';

import CalculationResults from 'components/CalculationResults';
import {
  useCurrentUserCarbonOffsetCalculation,
  useCurrentUserHasCarbonOffsetCalculation,
} from 'state/carbonOffset';
import { useUserIsValidPhysicalPerson } from 'state/user';

import NoCalculationBanner from './NoCalculationBanner';

const Banners: React.FC = () => {
  const { data: hasCalculation, isLoading: isHasCalculationLoading } =
    useCurrentUserHasCarbonOffsetCalculation();
  const {
    data: currentUserCalculation,
    isLoading: isCurrentUserCalculationLoading,
  } = useCurrentUserCarbonOffsetCalculation();
  const { data: currentUserCarbonOffsetCalculation } =
    useCurrentUserCarbonOffsetCalculation();
  const {
    data: isValidPhysicalPerson,
    isLoading: isValidPhysicalPersonLoading,
  } = useUserIsValidPhysicalPerson();

  const hasFullyCompensated =
    Number(currentUserCalculation?.compensationInfo?.trees) <= 0;

  const isLoading = useMemo(
    (): boolean =>
      isHasCalculationLoading ||
      isCurrentUserCalculationLoading ||
      isValidPhysicalPersonLoading,
    [
      isHasCalculationLoading,
      isCurrentUserCalculationLoading,
      isValidPhysicalPersonLoading,
    ],
  );

  const showNoCalculationBanner = useMemo(
    (): boolean =>
      !isLoading &&
      !hasCalculation &&
      !hasFullyCompensated &&
      !isValidPhysicalPerson,
    [hasCalculation, hasFullyCompensated, isLoading, isValidPhysicalPerson],
  );

  const showCalculationResults = useMemo(
    (): boolean =>
      Boolean(
        !isLoading &&
          hasCalculation &&
          !hasFullyCompensated &&
          !isValidPhysicalPerson,
      ),
    [hasCalculation, hasFullyCompensated, isLoading, isValidPhysicalPerson],
  );

  return (
    <>
      <RenderWhen condition={isLoading}>
        <Loader />
      </RenderWhen>

      <RenderWhen condition={showNoCalculationBanner}>
        <NoCalculationBanner />
      </RenderWhen>

      <RenderWhen condition={showCalculationResults}>
        <CalculationResults calculation={currentUserCarbonOffsetCalculation} />
      </RenderWhen>
    </>
  );
};

export default Banners;
