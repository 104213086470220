import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import {
  ConfirmEmailArgs,
  ConfirmEmailBody,
  ResendEmailConfirmationArgs,
  ResendEmailConfirmationBody,
  ResendTwoFactorCodeArgs,
  ResendTwoFactorCodeBody,
  SendOTPArgs,
  SendOTPBody,
  SignIn2FAArgs,
  SignIn2FABody,
  SignInArgs,
  SignInBody,
  SignInResult,
} from './types';

export async function signIn(args: SignInArgs) {
  const { body } = args;

  const { data } = await request.post<
    SignInResult,
    AxiosResponse<SignInResult>,
    SignInBody
  >(`${abundanceAPI.V1.ACCOUNT}/login`, body);

  return data;
}

export async function signIn2FA(args: SignIn2FAArgs) {
  const { body } = args;

  const { data } = await request.post<
    SignInResult,
    AxiosResponse<SignInResult>,
    SignIn2FABody
  >(`${abundanceAPI.V1.ACCOUNT}/login-two-step`, body);

  return data;
}

export async function confirmEmail(args: ConfirmEmailArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ConfirmEmailBody
  >(`${abundanceAPI.V1.ACCOUNT}/confirm-email`, body);

  return data;
}

export async function resendEmailConfirmation(
  args: ResendEmailConfirmationArgs,
) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ResendEmailConfirmationBody
  >(`${abundanceAPI.V1.ACCOUNT}/resend-email-confirmation`, body);

  return data;
}

export async function resendTwoFactorCode(args: ResendTwoFactorCodeArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ResendTwoFactorCodeBody
  >(`${abundanceAPI.V1.ACCOUNT}/resend-two-factor-code`, body);

  return data;
}

export async function sendOTP(args: SendOTPArgs) {
  const { body } = args;

  const { data } = await request.post<void, AxiosResponse<void>, SendOTPBody>(
    `${abundanceAPI.V1.ACCOUNT}/otp/send`,
    body,
  );

  return data;
}
