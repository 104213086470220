import { VirtualForest } from 'api/services';

export const currentVirtualForestSelector = (
  data: VirtualForest[],
): VirtualForest[] => data;

export const hasVirtualForestsSelector = (data: VirtualForest[]): boolean =>
  !!currentVirtualForestSelector(data).length;

export const virtualForestAbsorptionReportSelector = (data: Blob): Blob => data;

export const virtualForestByIdSelector = (data: VirtualForest): VirtualForest =>
  data;

export const virtualForestCertificateSelector = (data: Blob): Blob => data;

export const virtualForestListSelector = (
  data: VirtualForest[],
): VirtualForest[] => data;
