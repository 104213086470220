import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'unset',
  justify: 'unset',
  gap: '48px',
})`
  width: 100%;
  margin: 48px 0;

  @media (max-width: 1145px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'space-between',
      gap: '24px',
    })}

    & > :not(:first-child) {
      margin-left: 0;
    }
  }

  ${breakpointDown('lg')`
    gap: 24px;
    margin: 24px 0;

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

export const Action = styled.div`
  width: 100%;
  padding: 32px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;

  ${breakpointDown('lg')`
    padding: 23px;

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;
