import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { Impact } from './types';

export async function getTreeAccountBalance() {
  const { data } = await request.get<number, AxiosResponse<number>, never>(
    `${abundanceAPI.V1.TREE}/users/current/account-balance`,
  );

  return data;
}

export async function getImpact() {
  const { data } = await request.get<Impact, AxiosResponse<Impact>, never>(
    `${abundanceAPI.V1.TREE}/users/current/environmental-impact`,
  );

  return data;
}

export async function downloadMediaKit() {
  const { data } = await request.get<AxiosResponse, AxiosResponse<Blob>, void>(
    `${abundanceAPI.V1.TREE}/users/current/media-kit`,
    {
      responseType: 'arraybuffer',
    },
  );

  return data;
}
