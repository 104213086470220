import { Button, Card, Divider, Text } from '@abundance-brasil-wabi/sabi';

import * as S from './styles';

export type SummaryCardProps = {
  title: React.ReactNode;
  icon: React.ReactNode;
  value: React.ReactNode;
  description: React.ReactNode;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  isPrimaryButtonDisabled?: boolean;
  onPrimaryButtonPress: () => void;
  onSecondaryButtonPress: () => void;
};

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  icon,
  value,
  description,
  primaryButtonTitle,
  secondaryButtonTitle,
  isPrimaryButtonDisabled = false,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
}) => {
  return (
    <Card>
      <Card.Content fluid align="center" justify="space-between">
        <Text level="subtitle">{title}</Text>

        <S.IconContainer>{icon}</S.IconContainer>
      </Card.Content>

      <Divider />

      <Card.Content direction="column" gap={0}>
        <S.Value>{value}</S.Value>

        <S.Description>{description}</S.Description>
      </Card.Content>

      <Card.Content fluid align="center" justify="space-between" gap={2}>
        <S.SecondaryButton onPress={onSecondaryButtonPress}>
          {secondaryButtonTitle}
        </S.SecondaryButton>

        <Button
          variant="secondary-outline"
          size={32}
          onPress={onPrimaryButtonPress}
          isDisabled={isPrimaryButtonDisabled}
        >
          {primaryButtonTitle}
        </Button>
      </Card.Content>
    </Card>
  );
};

export default SummaryCard;
