import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { PathKey } from 'router/types';
import { getKeyFromPathname } from 'router/utils';

export type UseCheckRoute = {
  checkIsRoute: (pathKey: PathKey) => boolean;
};

/**
 * Utility hook with helper methods for handling routes.
 */
export default function useCheckRoute(): UseCheckRoute {
  const { pathname } = useLocation();

  const checkIsRoute = useCallback(
    (pathKey: PathKey): boolean => {
      return getKeyFromPathname(pathname) === pathKey;
    },
    [pathname],
  );

  return {
    checkIsRoute,
  };
}
