import { CountryCode } from 'types/country';

import { DEFAULT_APPLICATION_LANGUAGE } from 'constants/application';
import supportedLanguages from 'constants/supportedLanguages';
import { getUserLanguage } from 'state/user/utils';

/**
 * It returns the language stored in the local storage, if it exists, otherwise
 * it returns the language of the browser, if it's supported, otherwise it
 * returns the default language.
 *
 * @returns The language of the browser or the language stored in the local storage.
 */
export function getDefaultAppLanguage(): string {
  const localStoredLanguage = getUserLanguage();

  if (localStoredLanguage && Object.keys(localStoredLanguage).length)
    return localStoredLanguage;

  if (Object.keys(supportedLanguages).includes(navigator.language))
    return navigator.language;

  return DEFAULT_APPLICATION_LANGUAGE;
}

export function getCountryCodeFromLanguage(language: string): CountryCode {
  return language.slice(3, 5) as CountryCode;
}

/**
 * It sets the default language of the HTML document.
 *
 * @param {string} language - The language to set the HTML element to.
 */
export function setDefaultHTMLLanguage(language: string): void {
  document.documentElement.setAttribute('lang', language);
}
