import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';

import * as S from './styles';

const NoPlanMessage: React.FC<WithTranslation> = ({ t }) => {
  return (
    <div>
      <S.Message>{t('message')}</S.Message>
    </div>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'subscriptionPlans:header:noPlanMessage',
})(NoPlanMessage);
