import { styled } from 'styled-components';

import {
  Button,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const TypeCode = styled(Text).attrs({
  level: 'bodySmall',
})`
  align-self: flex-start;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const ResendCodeButton = styled(Button).attrs({
  variant: 'text',
})`
  align-self: flex-start;
  text-decoration-line: underline;
  font-size: 14px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
