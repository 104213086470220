import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  margin-bottom: 10px;

  ${breakpointDown('lg')`
    width: 100%;
  `}
`;

export const Contents = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'space-between',
  gap: '24px',
})`
  width: 100%;
  flex: 1 0 auto;

  @media (max-width: 1566px) {
    padding: 0;
  }

  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '16px',
    })};

    width: 100%;
    padding: 24px;
    left: 0;
    top: 360px;
  `}

  ${breakpointDown('lg')`
    top: 0;
    padding: 0;

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

export const Left = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
  gap: '24px',
})`
  flex: 1;

  ${breakpointDown('lg')`
    width: 100%;
    gap: 16px;

    & > :not(:first-child) {
      margin-top: 0;
    }
  `}
`;

export const Right = styled.div`
  width: 35%;

  ${breakpointDown('lg')`
    width: 100%;
    gap: 16px;
  `}
`;
