import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import {
  AbundanceWalletBalances,
  getAbundanceWalletBalances,
} from 'api/services';

import { abundanceWalletQueryKeys } from './queryKeys';
import {
  abundanceWalletBalancesSelector,
  abundanceWalletTreeBalanceSelector,
} from './selectors';
import { AbundanceWalletBalancesQueryKey } from './types';

export default function useGetAbundanceWalletBalances<
  TData = AbundanceWalletBalances,
>(
  options?: QueryOptions<
    AbundanceWalletBalances,
    TData,
    AbundanceWalletBalancesQueryKey
  >,
) {
  return useQuery<
    AbundanceWalletBalances,
    unknown,
    TData,
    AbundanceWalletBalancesQueryKey
  >(
    abundanceWalletQueryKeys.abundanceWalletBalances(),
    getAbundanceWalletBalances,
    options,
  );
}

export const useAbundanceWalletBalances = () =>
  useGetAbundanceWalletBalances({
    select: abundanceWalletBalancesSelector,
  });

export const useAbundanceWalletTreeBalance = () =>
  useGetAbundanceWalletBalances({
    select: abundanceWalletTreeBalanceSelector,
  });
