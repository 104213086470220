import { css, styled } from 'styled-components';

import {
  AbundanceBrasilTopLightLogo,
  Button,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

import { StyledEmailConfirmationProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 400px;
  max-width: 100%;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: hidden;
`;

export const Logo = styled(AbundanceBrasilTopLightLogo)`
  width: 242px;
  max-width: 90%;
  height: 192px;
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('medium')};
`;

export const Body = styled(Text).attrs({
  level: 'bodySmall',
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('regular')};
`;

export const ResendButton = styled(Button).attrs({
  variant: 'tertiary',
})<StyledEmailConfirmationProps>`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'center',
  })};

  margin-bottom: 16px;

  span {
    margin: 0;
    font-size: 18px;
  }

  svg {
    margin-left: 8px;
    margin-top: 2px;
  }

  ${({ $isSending }) =>
    $isSending &&
    css`
      ${createFlexDisplay({
        direction: 'row',
        align: 'center',
        justify: 'space-between',
        gap: '0',
      })};
    `}
`;

export const LoginLink = styled(LinkButton).attrs({
  variant: 'text',
})`
  color: ${getThemeColor('white')};
`;
