import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledAuditDocumentsProps } from './types';

function getRootSizeStyle({ $size }: StyledAuditDocumentsProps) {
  switch ($size) {
    case 'small':
      return `
        gap: 8px;
        padding: 24px;
      `;

    default:
      return `
        gap: 24px;
        padding: 24px;
      `;
  }
}

function getDocumentWrapperSizeStyle({ $size }: StyledAuditDocumentsProps) {
  switch ($size) {
    case 'small': {
      return `
        gap: 8px;

        @media (max-width: 1590px) {
          width: max-content;
        }

        ${breakpointDown('lg')`
          width: 100%
        `}

        @media (max-width: 445px) {
          width: max-content;
        }
      `;
    }

    default: {
      return `
        gap: 24px;
      `;
    }
  }
}

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})<StyledAuditDocumentsProps>`
  width: 100%;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('white')};
  overflow: auto;

  ${getRootSizeStyle}
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  color: ${getThemeColor('text', 'default', 'primary')};
  font-weight: ${getThemeFontWeight('bold')};
`;

export const DocumentsWrapper = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})<StyledAuditDocumentsProps>`
  width: 100%;

  ${getDocumentWrapperSizeStyle}
`;

export const DocumentsContainer = styled.div`
  width: 100%;
`;

export const DocumentTitle = styled(Text).attrs({
  level: 'bodySmall',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const DocumentRow = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: '24px',
})`
  width: 100%;
  margin-top: 8px;
  padding: 8px 0;
  border-top: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-bottom: 1px solid ${getThemeColor('border', 'default', 'primary')};
`;

export const ColumnItem = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})``;

export const Left = styled.div<StyledAuditDocumentsProps>`
  ${createFlexDisplay({
    align: 'center',
    justify: 'space-between',
    gap: '24px',
  })};

  ${({ $size }) => {
    if ($size === 'normal') {
      return `
        @media (max-width: 445px) {
          ${createFlexDisplay({
            direction: 'column',
            align: 'flex-start',
            justify: 'center',
            gap: '12px',
          })}
        }
      `;
    }
  }}
`;

export const ItemTitle = styled(Text).attrs({
  level: 'overline',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'secondary', 'default')};
`;

export const ItemValue = styled(Text).attrs({
  level: 'caption',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const PreviewDocumentButton = styled(Button).attrs({
  variant: 'text-static',
})`
  ${createFlexDisplay({ align: 'center', justify: 'space-between' })}

  border: none;
  background-color: transparent;

  & > * {
    color: ${getThemeColor('text', 'secondary', 'default')};
  }
`;

export const ExportText = styled(Text).attrs({
  level: 'overline',
})`
  margin-right: 8px;
  font-weight: ${getThemeFontWeight('bold')};
  text-transform: uppercase;

  ${breakpointDown('xs')`
    display: none
  `}
`;
