import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';

import SignUpForm from '../SignUpForm';

const SignUpCompany: React.FC<WithTranslation> = ({ t }) => {
  return (
    <SignUpForm
      title={t('title')}
      subtitle={t('subtitle')}
      description={t('description')}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'signUp:signUpCompany',
})(SignUpCompany);
