import { useEffect } from 'react';

import { PageConfig, PageMode, useApplicationActions } from 'state/application';

import { pageSettingsByMode } from './constants';

export type UseSetPageConfigProps = {
  /**
   * The mode of the page, which determines the set of predefined settings to use.
   */
  mode?: PageMode;
  /**
   * Custom configuration settings for the page. If provided, these settings
   * override the default settings.
   */
  override?: PageConfig;
};

/**
 * This custom hook is used to set the page configuration based on the given properties.
 * `mode` determines the mode-specific settings to apply, while `overrides` allows for custom overrides.
 *
 * The hook listens to changes in the `mode` or `overrides`, and updates the page configuration accordingly.
 *
 *  Behavior:
 * - If neither `overrides` nor `mode` is provided, the hook does nothing.
 * - If only `mode` is provided, the hook sets the page configuration to the predefined settings for that mode.
 * - If only `overrides` is provided, the hook sets the page configuration to these custom settings.
 * - If both `mode` and `overrides` are provided, the hook combines the predefined settings for the mode with
 * the custom settings, with the latter taking precedence.
 */
export default function useSetPageConfig({
  mode,
  override,
}: UseSetPageConfigProps) {
  const { setPageConfig } = useApplicationActions();

  useEffect(() => {
    // If neither override nor mode is provided, exit early.
    if (!mode && !override) return;

    // Determine the base configuration based on the page mode or use an empty object as a fallback.
    const baseConfig = mode ? pageSettingsByMode[mode] : {};

    // Combine the base configuration with any custom configuration provided, prioritizing custom settings.
    const finalConfig = { ...baseConfig, ...override };

    // Update the page configuration.
    setPageConfig(finalConfig);
  }, [mode, override, setPageConfig]);
}
