import { useTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  Divider,
  FormComposer,
  RadioInput,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import {
  CounterInput,
  Dropdown,
  Input,
  PhoneNumber,
  RadioButtonGroup,
} from 'components/FormFields';
import LanguageSelector from 'components/LanguageSelector';
import { abundanceURLs } from 'constants/externalURLs';

import * as S from './styles';
import { useForestProducerRegistrationForm } from './useForestProducerRegistrationForm';

const ForestProducerRegistrationForm: React.FC<WithTranslation> = ({ t }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const {
    country,
    countriesList,
    formState,
    control,
    getValues,
    isLoading,
    onSubmit,
  } = useForestProducerRegistrationForm();

  return (
    <FormComposer
      fluid
      title={t('title')}
      subtitle={t('subtitle')}
      description={t('description')}
      sections={[
        {
          id: 'forest-producer-registration-form-main',
          contents: [
            <Input
              fluid
              key="name"
              name="name"
              label={t('nameLabel')}
              placeholder={t('namePlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="email"
              name="email"
              type="email"
              label={t('emailLabel')}
              placeholder={t('emailPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="country"
              name="country"
              label={t('country')}
              placeholder={t('country')}
              defaultValue={country}
              options={countriesList}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="taxIdentificationNumber"
              name="taxIdentificationNumber"
              label={t('tin')}
              placeholder={t('tin')}
              {...{ control, formState }}
            />,

            <PhoneNumber
              fluid
              key="phoneNumber"
              name="phoneNumber"
              label={t('phoneNumber')}
              value={getValues('phoneNumber')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <CounterInput
              fluid
              key="propertySize"
              name="propertySize"
              label={t('propertySizeLabel')}
              placeholder={t('propertySizePlaceholder')}
              defaultValue={getValues('propertySize')}
              isDisabled={isLoading}
              minValue={0}
              formatOptions={{
                style: 'unit',
                unit: 'hectare',
                unitDisplay: 'long',
              }}
              locale={language}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="city"
              name="city"
              label={t('cityLabel')}
              placeholder={t('cityPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="state"
              name="state"
              label={t('stateLabel')}
              placeholder={t('statePlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="car"
              name="car"
              label={t('carLabel')}
              placeholder={t('carPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="vegetation"
              name="vegetation"
              label={t('vegetationLabel')}
              placeholder={t('vegetationPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <RadioButtonGroup
              fluid
              key="isUnderLegalProtection"
              name="isUnderLegalProtection"
              label={t('legalProtectionLabel')}
              isDisabled={isLoading}
              {...{ control, formState }}
            >
              <RadioInput compact label={t('yes')} value="true" />
              <RadioInput compact label={t('no')} value="false" />
            </RadioButtonGroup>,

            <S.TextAreaInput
              fluid
              key="managementPractices"
              name="managementPractices"
              label={t('managementPracticesLabel')}
              placeholder={t('managementPracticesPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <S.TextAreaInput
              fluid
              key="socialIssues"
              name="socialIssues"
              label={t('socialIssuesLabel')}
              placeholder={t('socialIssuesPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Button
              fluid
              key="submit"
              type="submit"
              isDisabled={!formState.isValid || isLoading}
            >
              {t('send')}
            </Button>,

            <S.TalkToConsultant
              key="talkToConsultant"
              href={abundanceURLs.contact}
              target="_blank"
            >
              {t('talkToConsultant')}
            </S.TalkToConsultant>,

            <Divider key="finalDivider" margin="24px 0 0 0" />,

            <S.LanguageSelectorContainer key="languageSelector">
              <LanguageSelector />
            </S.LanguageSelectorContainer>,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'forestProducerRegistration:forestProducerRegistrationForm',
})(ForestProducerRegistrationForm);
