import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { MdOutlineGeneratingTokens } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import { WithTranslation } from 'i18n/types';
import { Modal } from 'components/Modal/types';
import { useAbundanceWalletBalances } from 'state/abundanceWallet';
import { useLastClaimDate } from 'state/claimHistory';
import { useNavbarActions } from 'state/navbar';
import { formatDate } from 'utils/formatting';

import SummaryCard from './SummaryCard';

const TokensSummaryCard: React.FC<WithTranslation> = ({ t }) => {
  const { data: balances } = useAbundanceWalletBalances();
  const { data: lastClaimDate } = useLastClaimDate();
  const { enqueueNavbar } = useNavbarActions();
  const dispatch = useDispatch();

  const { abundanceTokenBalance = 0 } = balances || {};

  const createOpenModal = useCallback(
    (modalToOpen: Modal) => (): void => {
      dispatch(pushModal(modalToOpen));
    },
    [dispatch],
  );

  const handleTokensSecondaryButtonPress = useCallback((): void => {
    enqueueNavbar({
      name: 'MetaMaskDisclaimer',
    });
  }, [enqueueNavbar]);

  const getLastClaimDate = useCallback((): string => {
    if (!lastClaimDate) return '';

    return t('message').replace('XXXX', formatDate(lastClaimDate, 'fullDate'));
  }, [lastClaimDate, t]);

  return (
    <SummaryCard
      title={t('title')}
      icon={<MdOutlineGeneratingTokens />}
      value={abundanceTokenBalance || 0}
      description={
        abundanceTokenBalance ? getLastClaimDate() : t('emptyMessage')
      }
      primaryButtonTitle={t('buttonTitle')}
      isPrimaryButtonDisabled
      onPrimaryButtonPress={createOpenModal({
        name: ModalsList.CLAIM_TOKEN_MODAL,
      })}
      secondaryButtonTitle={t('secondaryButtonTitle')}
      onSecondaryButtonPress={handleTokensSecondaryButtonPress}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:summaryCards:tokensSummaryCard',
})(TokensSummaryCard);
