import * as S from './styles';

export type ExtraInfoProps = {
  key: string;
  value: React.ReactNode;
  to: string;
  children: React.ReactNode;
};

const ExtraInfo: React.FC<ExtraInfoProps> = ({ key, value, to, children }) => {
  return (
    <S.ExtraInfo key={key}>
      <S.Value>{value}</S.Value>

      <S.ForestLink size={32} to={to}>
        {children}
      </S.ForestLink>
    </S.ExtraInfo>
  );
};

export default ExtraInfo;
