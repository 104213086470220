import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import i18next from 'i18next';
import { useApplicationActions } from 'state/application';
import { getAuthToken } from 'state/auth/utils';

import { virtualForestQueryKeys } from './queryKeys';
import { virtualForestCertificateSelector } from './selectors';
import { VirtualForestCertificateQueryKey } from './types';
import { getVirtualForestPDFPreview } from './utils';

export default function useGetVirtualForestCertificate<TData = Blob>(
  virtualForestId: string,
  options?: QueryOptions<Blob, TData, VirtualForestCertificateQueryKey>,
) {
  const token = getAuthToken();
  const { setFileLoading } = useApplicationActions();

  return useQuery<Blob, unknown, TData, VirtualForestCertificateQueryKey>(
    virtualForestQueryKeys.virtualForestCertificate(
      virtualForestId,
      i18next.language,
    ),
    async () => {
      setFileLoading(true);

      const result = await getVirtualForestPDFPreview(
        'certificate',
        virtualForestId,
        token,
      );

      setFileLoading(false);

      return result;
    },
    {
      ...options,
    },
  );
}

export const useVirtualForestCertificate = (virtualForestId: string) =>
  useGetVirtualForestCertificate(virtualForestId, {
    select: virtualForestCertificateSelector,
  });
