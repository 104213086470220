import { Syntropy, VirtualForest } from 'api/services';
import { useVirtualForestList } from 'state/virtualForest';

import { useGetSyntropyList } from '../state';

export type UseLoadSyntropyInformation = {
  syntropyInformationIsLoading: boolean;
  syntropyList: Syntropy[];
  virtualForestsList: VirtualForest[];
};

/**
 * Handles the loading process of the basic Syntropy information (Syntropy list and Virtual Forests list).
 *
 * @returns An object with the following properties:
 *  - syntropyInformationIsLoading: Indicates whether the syntropy information is loading. Is true if
 *  either syntropy list or virtual forests list are loading.
 *  - syntropyList: The Syntropy list.
 *  - virtualForestsList: The Virtual Forests list.
 */
export function useLoadSyntropyInformation(): UseLoadSyntropyInformation {
  const { data: virtualForestsList = [], isLoading: virtualForestsLoading } =
    useVirtualForestList();
  const { data: syntropyList = [], isLoading: syntropyLoading } =
    useGetSyntropyList();

  return {
    syntropyInformationIsLoading: syntropyLoading || virtualForestsLoading,
    syntropyList,
    virtualForestsList,
  };
}
