import { withTranslation } from 'react-i18next';

import {
  Button,
  FormComposer,
  Loader,
  RenderWhen,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Dropdown, Input, PhoneNumber } from 'components/FormFields';

import { useCompanySettingsForm } from './useCompanySettingsForm';
import { useGetFormOptions } from './useGetFormOptions';

const CompanySettingsForm: React.FC<WithTranslation> = ({ t }) => {
  const { sectors, industries, numberEmployees, annualIncome } =
    useGetFormOptions();

  const {
    formState,
    control,
    isUpdating,
    isCompanyLoading,
    handleSubmit,
    getValues,
    onSubmit,
  } = useCompanySettingsForm();

  const { isValid, isDirty } = formState;

  return (
    <>
      <RenderWhen condition={isCompanyLoading}>
        <Loader message={t('loading')} />
      </RenderWhen>

      <RenderWhen condition={!isCompanyLoading}>
        <FormComposer
          fluid
          onSubmit={handleSubmit(onSubmit)}
          sections={[
            {
              id: 'personal-info',
              title: t('accountOwner'),
              description: t('fillYourPersonalData'),
              contents: [
                <Input
                  fluid
                  key="name"
                  name="name"
                  label={t('yourName')}
                  placeholder={t('name')}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,

                <Input
                  fluid
                  key="email"
                  name="email"
                  type="email"
                  label={t('email')}
                  placeholder={t('emailPlaceholder')}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,

                <PhoneNumber
                  fluid
                  key="phoneNumber"
                  name="phoneNumber"
                  label={t('phoneNumber')}
                  value={getValues('phoneNumber')}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,
              ],
            },
            {
              id: 'extra-info',
              title: t('extraInfo'),
              description: t('extraInfoDescription'),
              contents: [
                <Input
                  fluid
                  key="address"
                  name="address"
                  label={t('address')}
                  placeholder={t('addressPlaceholder')}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,

                <Dropdown
                  fluid
                  key="sector"
                  name="sector"
                  valueType="number"
                  label={t('sector')}
                  placeholder={t('select')}
                  defaultValue={getValues('sector')}
                  options={sectors}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,

                <Dropdown
                  fluid
                  key="industry"
                  name="industry"
                  valueType="number"
                  label={t('industry')}
                  placeholder={t('select')}
                  defaultValue={getValues('industry')}
                  options={industries}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,

                <Dropdown
                  fluid
                  key="numberEmployees"
                  name="numberEmployees"
                  valueType="number"
                  label={t('numberEmployees')}
                  placeholder={t('select')}
                  defaultValue={getValues('numberEmployees')}
                  options={numberEmployees}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,

                <Dropdown
                  fluid
                  key="annualBilling"
                  name="annualBilling"
                  valueType="number"
                  label={t('annualBilling')}
                  placeholder={t('select')}
                  defaultValue={getValues('annualBilling')}
                  options={annualIncome}
                  isDisable={isUpdating}
                  {...{ control, formState }}
                />,
              ],
            },
            {
              id: 'submit',
              contents: [
                <Button
                  size={48}
                  key="submit"
                  type="submit"
                  variant="primary"
                  isDisabled={!isValid || isUpdating || !isDirty}
                >
                  {t('update')}
                </Button>,
              ],
            },
          ]}
        />
      </RenderWhen>
    </>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'companySettings:companySettingsForm',
})(CompanySettingsForm);
