import { withTranslation } from 'react-i18next';

import { Button, FormComposer } from '@abundance-brasil-wabi/sabi';
import { showOnlyLastFourLetters } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import { Password } from 'components/FormFields';

import * as S from './styles';
import { useSecurityVerificationForm } from './useSecurityVerificationForm';

export type SecurityVerificationFormProps = {
  shouldFormatNumber?: boolean;
  submitCallback?: (email: string, twoFactorCode: string) => void;
  onSuccess?: () => void;
};

const SecurityVerificationForm: React.FC<
  WithTranslation<SecurityVerificationFormProps>
> = ({ shouldFormatNumber, submitCallback, onSuccess, t }) => {
  const {
    control,
    handleSubmit,
    formState,
    storedUserInfo,
    isLoading,
    resendSuccess,
    onSubmit,
    resendVerificationCode,
  } = useSecurityVerificationForm({
    submitCallback,
    onSuccess,
  });

  return (
    <FormComposer
      fluid
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'security-verification-form-main',
          contents: [
            <Password
              fluid
              key="pass"
              name="pass"
              label={t('phoneVerification')}
              isDisabled={resendSuccess}
              {...{ control, formState }}
            />,

            <S.Message key="message">
              {t('typeCode')}{' '}
              {shouldFormatNumber
                ? showOnlyLastFourLetters(storedUserInfo?.phoneNumber || '')
                : storedUserInfo?.phoneNumber}
              .
              <S.ReSendButton onPress={resendVerificationCode}>
                {t('resendCode')}
              </S.ReSendButton>
            </S.Message>,

            <Button
              fluid
              key="submit"
              type="submit"
              isDisabled={!formState.isValid || isLoading}
            >
              {t('send')}
            </Button>,
          ],
        },
      ]}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'securityVerification:securityVerificationForm',
})(SecurityVerificationForm);
