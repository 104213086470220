import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { WithTranslation } from 'i18n/types';
import VirtualForestAbsorptionReport from 'components/PDF/VirtualForestAbsorptionReport';
import { useVirtualForestAbsorptionReport } from 'state/virtualForest';
import { downloadFile } from 'utils/handlers';

import { modalParamsSelector } from '../../../store/modules/modal/selectors';
import * as S from './styles';

export type VirtualForestAbsorptionReportModalProps = {
  virtualForestId: string;
};

const VirtualForestAbsorptionReportModal: React.FC<WithTranslation> =
  forwardRef(({ t }, ref) => {
    const { virtualForestId } = useSelector(modalParamsSelector);
    const { data: absorptionImpactFile } =
      useVirtualForestAbsorptionReport(virtualForestId);

    const handlePrint = useCallback((): void | null => {
      if (!absorptionImpactFile) return;

      downloadFile({
        file: absorptionImpactFile,
        fileName: t('fileName'),
      });
    }, [absorptionImpactFile, t]);

    useImperativeHandle(ref, () => ({
      handlePrint,
      content: () => null,
    }));

    return (
      <S.Root>
        <VirtualForestAbsorptionReport virtualForestId={virtualForestId} />
      </S.Root>
    );
  });

export default withTranslation('components', {
  keyPrefix: 'modal:virtualForestAbsorptionReportModal',
  withRef: true,
})(VirtualForestAbsorptionReportModal);
