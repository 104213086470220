import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import {
  ChangeLanguageArgs,
  ChangeLanguageBody,
  ChangePasswordArgs,
  ChangePasswordBody,
  ForgotPasswordArgs,
  ForgotPasswordBody,
  RegisterUserArgs,
  RegisterUserBody,
  ResetPasswordArgs,
  ResetPasswordBody,
  UpdateUserArgs,
  UpdateUserBody,
  User,
} from './types';

export async function registerUser(args: RegisterUserArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    RegisterUserBody
  >(`${abundanceAPI.V1.ACCOUNT}/register`, body);

  return data;
}

export async function getUser() {
  const { data } = await request.get<User, AxiosResponse<User>, never>(
    `${abundanceAPI.V1.CUSTOMER}/users/current`,
  );

  return data;
}

export async function updateUser(args: UpdateUserArgs) {
  const {
    body,
    params: { id },
  } = args;

  const { data } = await request.put<void, AxiosResponse<void>, UpdateUserBody>(
    `${abundanceAPI.V1.CUSTOMER}/${id}`,
    body,
  );

  return data;
}

export async function getAccountLanguage() {
  const { data } = await request.get<string, AxiosResponse<string>, never>(
    `${abundanceAPI.V1.ACCOUNT}/language`,
  );

  return data;
}

export async function changeLanguage(args: ChangeLanguageArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ChangeLanguageBody
  >(`${abundanceAPI.V1.ACCOUNT}/change-language`, body);

  return data;
}

export async function changePassword(args: ChangePasswordArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ChangePasswordBody
  >(`${abundanceAPI.V1.ACCOUNT}/change-password`, body);

  return data;
}

export async function forgotPassword(args: ForgotPasswordArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ForgotPasswordBody
  >(`${abundanceAPI.V1.ACCOUNT}/forgot-password`, body);

  return data;
}

export async function resetPassword(args: ResetPasswordArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    ResetPasswordBody
  >(`${abundanceAPI.V1.ACCOUNT}/reset-password`, body);

  return data;
}
