import { isEmpty } from 'lodash';

import { CarbonOffsetCalculation, CarbonOffsetEnrollment } from 'api/services';

export const carbonOffsetEnrollmentByIdSelector = (
  data: CarbonOffsetEnrollment,
): CarbonOffsetEnrollment => data;

export const carbonOffsetCalculationByIdSelector = (
  data: CarbonOffsetCalculation,
): CarbonOffsetCalculation => data;

export const currentUserCarbonOffsetCalculationSelector = (
  data: CarbonOffsetCalculation,
): CarbonOffsetCalculation => data;

export const currentUserHasCarbonOffsetCalculationSelector = (
  data: CarbonOffsetCalculation,
): boolean => !isEmpty(data);

export const currentUserCarbonOffsetCalculationUncompensatedPercentageSelector =
  (data: CarbonOffsetCalculation): number | undefined =>
    data.compensationInfo?.uncompensatedPercentage;

export const currentUserCarbonOffsetCalculationIsFullyCompensatedSelector = (
  data: CarbonOffsetCalculation,
): boolean =>
  currentUserCarbonOffsetCalculationUncompensatedPercentageSelector(data) === 0;
