import { AxiosResponse } from 'axios';

/**
 * It takes an Axios response, checks if it has a URL, and if it does, it
 * redirects the user to that URL.
 *
 * @param {AxiosResponse} response - AxiosResponse - the response from the API call.
 *
 * @returns A function that takes a response object as an argument.
 */
export function redirectToClaimPage(response: AxiosResponse): void {
  if (!response.data.url) return;

  window.location.href = response.data.url;
}
