import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, FormComposer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Input } from 'components/FormFields';
import ReCaptcha from 'components/ReCaptcha';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';
import { useForgotPasswordForm } from './useForgotPasswordForm';

export const ForgotPasswordForm: React.FC<WithTranslation> = ({ t }) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { formState, control, isLoading, onSubmit } = useForgotPasswordForm();

  return (
    <FormComposer
      fluid
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'forgot-password-form-main',
          contents: [
            <Input
              fluid
              key="email"
              name="email"
              type="email"
              label={t('email')}
              placeholder={t('email')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <div key="rememberedPassword">
              {t('rememberedPassword')}{' '}
              <S.LoginButton to={getPathnameFromKey('signIn')}>
                {t('login')}
              </S.LoginButton>
            </div>,

            <ReCaptcha key="recaptcha" verifiedAction={setIsCaptchaVerified} />,

            <Button
              fluid
              key="submit"
              type="submit"
              isDisabled={!formState.isValid || !isCaptchaVerified || isLoading}
            >
              {t('send')}
            </Button>,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'forgotPassword:forgotPasswordForm',
})(ForgotPasswordForm);
