import { FieldErrorMessage } from 'types/forms';

const locale = 'shared:constants:forms:fieldErrorMessages';

export const fieldErrorMessages: FieldErrorMessage = {
  field: {
    required: `${locale}:field:required`,
  },
  email: {
    required: `${locale}:email:required`,
    wrongPattern: `${locale}:email:wrongPattern`,
  },
  password: {
    required: `${locale}:password:required`,
    wrongPattern: `${locale}:password:wrongPattern`,
    blocked: `${locale}:password:blocked`,
    wrongPasswordPattern: `${locale}:password:wrongPasswordPattern`,
  },
  newPassword: {
    required: `${locale}:newPassword:required`,
    wrongPattern: `${locale}:newPassword:wrongPattern`,
  },
  newPasswordConfirm: {
    required: `${locale}:newPasswordConfirm:required`,
    wrongPattern: `${locale}:newPasswordConfirm:wrongPattern`,
    passwordsDontMatch: `${locale}:newPasswordConfirm:passwordsDontMatch`,
  },
  phoneNumber: {
    required: `${locale}:phoneNumber:required`,
    invalid: `${locale}:phoneNumber:invalid`,
  },
  tin: {
    required: `${locale}:tin:required`,
    noCountry: `${locale}:tin:noCountry`,
    invalid: `${locale}:tin:invalid`,
  },
  name: {
    required: `${locale}:name:required`,
  },
  twoFactorCode: {
    required: `${locale}:twoFactorCode:required`,
    tokenIsInvalid: `${locale}:twoFactorCode:tokenIsInvalid`,
  },
  address: {
    required: `${locale}:address:required`,
  },
  dropdown: {
    required: `${locale}:dropdown:required`,
  },
};
