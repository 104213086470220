import { styled } from 'styled-components';

import {
  Banner,
  breakpointDown,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Message = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};

  & > button {
    font-size: 16px;
    text-decoration: underline;
  }
`;

export const Content = styled(Banner.Content)`
  flex-direction: row;
  align-items: center;
  gap: 24px;

  & > :not(:first-child) {
    margin-top: 0;
  }

  ${breakpointDown('xxs')`
    flex-direction: column;
    gap: 16px;

    & > * {
      width: 100%
    }
  `}
`;
