import { AxiosResponse } from 'axios';
import { KeyStringValue } from 'types/common';

import { redirectToClaimPage } from './utils';

export const remoteOTPValidationCallbacks: KeyStringValue<
  (response: AxiosResponse) => void
> = {
  redirectToClaimPage,
};
