import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Message = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Plan = styled.b`
  font-weight: ${getThemeFontWeight('bold')};
`;
