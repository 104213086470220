import { TransactionMessage, TransactionType } from './types';

const locale = 'shared:hooks:transactionMessages';

export const transactionParams: string[] = ['checkoutsession', 'claimsession'];

export const transactionMessages: Record<TransactionType, TransactionMessage> =
  {
    purchaseTree: {
      success: {
        type: 'success',
        message: `${locale}:purchaseTree:success`,
      },
    },
    subscribeToPlan: {
      success: {
        type: 'success',
        message: `${locale}:subscribeToPlan:success`,
      },
    },
  };
