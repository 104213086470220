import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useToggle } from '@abundance-brasil-wabi/sabi';

import { useHasVisibleNavbar } from 'state/navbar';

import { modalIsModalOpenSelector } from '../store/modules/modal/selectors';

export type UseAppFocus = {
  isAppFocused: boolean;
  toggleAppFocus: (isAppFocused?: boolean) => void;
};

/**
 * Checks whether the app is in focus state or not.
 * The app is considered in focus state if a modal or a navbar is opened.
 *
 * @returns An object containing the focus state and a function to control it.
 */
export default function useAppFocus(): UseAppFocus {
  const hasVisibleNavbar = useHasVisibleNavbar();
  const isModalOpen = useSelector(modalIsModalOpenSelector);

  const isAppFocused = useMemo(
    (): boolean => ![hasVisibleNavbar, isModalOpen].some((state) => state),
    [hasVisibleNavbar, isModalOpen],
  );

  const { value, toggle } = useToggle(isAppFocused);

  useEffect(() => {
    toggle(isAppFocused);
  }, [isAppFocused, toggle]);

  return {
    isAppFocused: value,
    toggleAppFocus: toggle,
  };
}
