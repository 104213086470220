import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from '@abundance-brasil-wabi/sabi';

import useApplicationLoader from 'hooks/useApplicationLoader';
import { getIsAuthenticated } from 'state/auth/utils';

import { getPathnameFromKey } from '..';

/**
 * Public routes can only be accessed by users that are not authenticated.
 */
const Public: React.FC = () => {
  const { isLoading } = useApplicationLoader();
  const isAuthenticated = getIsAuthenticated();

  if (isLoading) return <Loader isFullScreen />;

  if (isAuthenticated)
    return <Navigate to={getPathnameFromKey('home')} replace />;

  return <Outlet />;
};

export default Public;
