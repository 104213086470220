import { useCallback } from 'react';

import { useResetMediaFiles } from 'state/media';
import { useResetTreeFiles } from 'state/tree';

/**
 * Actions to be executed when the user settings changes.
 */
export default function useOnUpdateUserSettings() {
  const resetTreeFiles = useResetTreeFiles();
  const resetMediaFiles = useResetMediaFiles();

  return useCallback(() => {
    resetTreeFiles();
    resetMediaFiles();
  }, [resetMediaFiles, resetTreeFiles]);
}
