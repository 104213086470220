import { Divider, getThemeColor } from '@abundance-brasil-wabi/sabi';

import * as S from './styles';

const SelectedDivider: React.FC = () => {
  return (
    <Divider color={getThemeColor('border', 'primary', 'default')}>
      <S.SelectedIcon />
    </Divider>
  );
};

export default SelectedDivider;
