import { styled } from 'styled-components';

import { breakpointDown, Loader } from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: calc(100% - 88px);
  height: calc(100% - 88px);
  position: fixed;
  top: 88px;
  left: 88px;

  ${breakpointDown('lg')`
    width: 100%;
    height: calc(100% - 72px);
    top: 72px;
    left: 0;
  `}

  ${breakpointDown('xxs')`
    height: calc(100% - 56px);
    top: 56px;
  `}
`;

export const CustomLoader = styled(Loader).attrs({})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpointDown('lg')`
    padding-right: 0;
  `}
`;

export const Tour = styled.iframe`
  width: 100%;
`;
