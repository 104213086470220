import { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MdCheckCircleOutline } from 'react-icons/md';

import { WithTranslation } from 'i18n/types';
import ColorfulBackground from 'components/ColorfulBackground';
import { useSetPageConfig } from 'components/Page';
import { getPathnameFromKey } from 'router/utils';
import { useResendEmailConfirmation } from 'state/auth';
import { getTwoFactorAuthData } from 'state/auth/utils';

import * as S from './styles';

const EmailConfirmation: React.FC<WithTranslation> = ({ t }) => {
  useSetPageConfig({ mode: 'cover' });

  const {
    mutate: resentEmailConfirmation,
    isLoading,
    isSuccess,
  } = useResendEmailConfirmation();

  const [resendEmailButtonText, setResendEmailButtonText] = useState<string>(
    t('resendEmail'),
  );

  const resendConfirmationEmail = useCallback(async (): Promise<
    void | null
  > => {
    const storedUserInfo = getTwoFactorAuthData();

    if (!storedUserInfo || isSuccess) return;

    setResendEmailButtonText(t('resending'));

    resentEmailConfirmation(
      { body: { email: storedUserInfo.email } },
      {
        onSuccess: () => {
          setResendEmailButtonText(t('resent'));
        },
        onError: () => {
          setResendEmailButtonText(t('resendEmail'));
        },
      },
    );
  }, [isSuccess, resentEmailConfirmation, t]);

  return (
    <ColorfulBackground>
      <S.Root>
        <S.Logo />

        <S.Title>{t('title')}</S.Title>

        <S.Body>{t('emailAccess')}</S.Body>

        <S.ResendButton
          $isSending={isLoading || isSuccess}
          onPress={resendConfirmationEmail}
          isDisabled={isSuccess}
          isLoading={isLoading}
          loadingMessage={t('loading')}
        >
          <S.Body>{resendEmailButtonText}</S.Body>

          {isSuccess && <MdCheckCircleOutline />}
        </S.ResendButton>

        <S.LoginLink to={getPathnameFromKey('signIn')}>
          {t('login')}
        </S.LoginLink>
      </S.Root>
    </ColorfulBackground>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'emailConfirmation',
})(EmailConfirmation);
