import { styled } from 'styled-components';

import {
  AuroraVerdeLogo,
  breakpointDown,
  Button,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
  VerdeAzulLogo,
} from '@abundance-brasil-wabi/sabi';

import { IMAGE_URL } from 'constants/assets';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-end',
  justify: 'space-between',
})`
  width: 1120px;
  height: 640px;
  padding: 72px;
  background-image: url(${`${IMAGE_URL}/v1702754593/Assets/Backgrounds/aurora-verde-header_ftoc6c.png`});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${getThemeColor('black')};

  &:after {
    content: '';
    position: absolute;
    height: 640px;
    inset: 0;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      ${getThemeColor('black')} 100%
    );
  }

  ${breakpointDown('lg')`
    width: 100%;
    padding: 24px;

    &:after {
      height: 695px;
    }
  `}
`;

export const Contents = styled.div`
  color: ${getThemeColor('white')};
  font-weight: ${getThemeFontWeight('bold')};
  z-index: 1;

  ${breakpointDown('lg')`
    width: 100%;
  `}
`;

export const TitleSection = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
})`
  margin-bottom: 48px;
  text-align: center;

  ${breakpointDown('xs')`
    margin-bottom: 24px;
  `}
`;

export const DescriptionContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: 3,
})``;

export const AuroraVerdeImage = styled(AuroraVerdeLogo)`
  width: 98px;
  height: 98px;
  margin-left: -10px;
  margin-right: 16px;

  ${breakpointDown('xs')`
    width: 56px;
    height: 56px;
    margin-left: -5px;
  `}

  @media (max-width: 380px) {
    width: 48px;
    height: 48px;
  }

  ${breakpointDown('xxxs')`
    width: 38px;
    height: 38px;
    margin-left: -3px;
  `}
`;

export const NumberOfTrees = styled.div`
  gap: 10px;
  padding: 4px 10px;
  border-radius: 1000px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: ${getThemeFontWeight('bold')};
  background-color: ${getThemeColor('success', 'dark')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  width: 492px;
  margin-top: 12px;
  font-weight: ${getThemeFontWeight('regular')};

  ${breakpointDown('lg')`
    width: 100%;
  `}
`;

export const GoToWalletButton = styled(Button).attrs({
  variant: 'primary',
})`
  margin: 24px 0;
`;

export const HeaderBottom = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
})`
  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'flex-start',
      gap: '8px',
    })};

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

export const DescriptionItem = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
})`
  margin-right: 12px;
  text-transform: none;
  font-size: 14px;
  font-weight: ${getThemeFontWeight('regular')};

  @media (max-width: 380px) {
    margin-right: 0;
    line-break: anywhere;
  }
`;

export const DescriptionItemCar = styled(DescriptionItem)`
  position: relative;
  right: 0.2px;
  margin-bottom: 8px;
`;

export const VerdeAzulImage = styled(VerdeAzulLogo)`
  width: 130px;
  height: 18px;
  margin-left: 8px;
  margin-top: 4px;

  ${breakpointDown('xs')`
    height: 3vw;
  `}
`;
