import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { Subject } from 'api/services';
import { useToast } from 'hooks/useToast';
import { useSendSupportRequest } from 'state/support';

import { supportFormSchema } from './constants';
import { SupportFormSchema } from './types';

export type UseSupportForm = FormReturn<SupportFormSchema> & {
  isLoading: boolean;
  hasSubmitted: boolean;
  onSubmit: () => void;
};

export function useSupportForm(): UseSupportForm {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation('pages', {
    keyPrefix: 'support:supportForm',
  });
  const { addToast } = useToast();

  const form = useForm<SupportFormSchema>({
    resolver: zodResolver(supportFormSchema),
    mode: 'all',
  });

  const { reset, handleSubmit } = form;

  const { mutate: sendSupportRequest } = useSendSupportRequest({
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setHasSubmitted(true);
      reset();
    },
    onError: () => {
      setIsLoading(false);

      addToast({
        message: t('error'),
        state: 'error',
      });
    },
    onSettled: () => {
      setTimeout(() => {
        setIsLoading(false);
        setHasSubmitted(false);
      }, 5000);
    },
  });

  const submit = useCallback(
    ({ subject, message }: SupportFormSchema): void => {
      sendSupportRequest({
        body: { subject: subject as Subject, message },
      });
    },
    [sendSupportRequest],
  );

  return {
    isLoading,
    hasSubmitted,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
