import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { sendOTP, SendOTPArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useSendOTP(
  options?: MutationOptions<void, SendOTPArgs>,
) {
  return useMutation<void, ErrorMessage, SendOTPArgs, unknown>(
    sendOTP,
    options,
  );
}
