import { MutationOptions } from 'types/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateCompany, UpdateCompanyArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

import { companyQueryKeys } from './queryKeys';

export default function useUpdateCompany(
  options?: MutationOptions<void, UpdateCompanyArgs>,
) {
  const queryClient = useQueryClient();

  return useMutation<void, ErrorMessage, UpdateCompanyArgs, unknown>(
    updateCompany,
    {
      onMutate: async () => {
        await queryClient.cancelQueries(companyQueryKeys.company());
      },
      onSettled: () => {
        queryClient.invalidateQueries(companyQueryKeys.company());
      },
      ...options,
    },
  );
}
