import { styled } from 'styled-components';

import {
  AbundanceBrasilTopLightLogo,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 327px;
  max-width: 100%;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: hidden;
`;

export const Logo = styled(AbundanceBrasilTopLightLogo)`
  width: 242px;
  max-width: 90%;
  height: 192px;
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('medium')};
`;

export const Description = styled(Text).attrs({
  level: 'body',
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('regular')};
`;
