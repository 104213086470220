import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: 2,
})`
  width: 100%;
`;

export const Contents = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
  gap: 2,
})`
  width: 100%;
`;

export const Title = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Value = styled(Text).attrs({
  level: 'bodySmall',
})`
  flex: none;
  text-align: right;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;
