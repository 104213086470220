import { useSetPageConfig } from 'components/Page';

import CreateNewPasswordForm from './CreateNewPasswordForm';

const CreateNewPassword: React.FC = () => {
  useSetPageConfig({ mode: 'split' });

  return <CreateNewPasswordForm />;
};

export default CreateNewPassword;
