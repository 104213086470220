import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import {
  resendEmailConfirmation,
  ResendEmailConfirmationArgs,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useResendEmailConfirmation(
  options?: MutationOptions<void, ResendEmailConfirmationArgs>,
) {
  return useMutation<void, ErrorMessage, ResendEmailConfirmationArgs, unknown>(
    resendEmailConfirmation,
    options,
  );
}
