import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledMediaKitProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
})`
  margin: 0;
`;

export const Content = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
})`
  width: 100%;
`;

export const Title = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Title)`
  margin-bottom: 42px;
  font-weight: ${getThemeFontWeight('medium')};

  ${breakpointDown('lg')`
    height: 80px;
    margin-bottom: 24px;
  `}

  @media (max-width: 1160px) {
    height: 100px;
    margin-bottom: 16px;
  }

  @media (max-width: 1118px) {
    height: 130px;
  }

  @media (max-width: 1035px) {
    height: 140px;
    margin-bottom: 16px;
  }

  @media (max-width: 860px) {
    height: auto;
  }
`;

export const Coin = styled.img`
  width: 193px;
  height: 200px;
  margin-bottom: 42px;

  ${breakpointDown('lg')`
    margin-bottom: 24px;
  `}

  ${breakpointDown('xxxs')`
    align-self: center;
  `}
`;

export const Loader = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'center',
})`
  width: 100%;
`;

export const ApplicationsContainer = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'center',
  gap: '24px',
})`
  width: 100%;

  @media (max-width: 860px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'center',
    })};

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const Application = styled(Spacer).attrs({
  direction: 'column',
})`
  margin-top: 48px;
  width: 100%;

  @media (max-width: 860px) {
    margin-top: 24px;
  }
`;

export const HorizontalStampContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})``;

export const StampCircular = styled.img`
  width: 72px;
  height: 72px;
`;

export const Stamp = styled.img<StyledMediaKitProps>`
  height: 72px;

  &:first-of-type {
    margin-bottom: 24px;
  }

  ${({ $isVertical }) =>
    $isVertical &&
    `
      width: 72px;
      height: auto;

      &:first-of-type {
        margin-right: 24px;
      }
    `}

  ${({ $isVertical }) =>
    !$isVertical &&
    `
      @media (max-width: 380px) {
        &:first-of-type {
          margin: 0;
          transform: scale(0.73) translate(-18.5%, 0%);
        }

        &:last-of-type {
          transform: scale(0.73) translate(-18.5%, 0%);
        }
      }
    `}
`;

export const DownloadButton = styled(Button).attrs({
  variant: 'primary',
})`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'space-between',
    gap: '8px',
  })};

  margin-bottom: 0;
`;
