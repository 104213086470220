import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getPurchaseHistory, PurchaseHistory } from 'api/services';

import { purchaseQueryKeys } from './queryKeys';
import { usePurchaseHistorySelector } from './selectors';
import { PurchaseHistoryQueryKey } from './types';

export default function useGetPurchaseHistory<TData = PurchaseHistory[]>(
  options?: QueryOptions<PurchaseHistory[], TData, PurchaseHistoryQueryKey>,
) {
  return useQuery<PurchaseHistory[], unknown, TData, PurchaseHistoryQueryKey>(
    purchaseQueryKeys.purchaseHistory(),
    getPurchaseHistory,
    options,
  );
}

export const usePurchaseHistory = () =>
  useGetPurchaseHistory({
    select: usePurchaseHistorySelector,
  });
