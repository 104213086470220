import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  CounterInput,
  createFlexDisplay,
  getThemeBoxShadow,
  getThemeColor,
  getThemeFontWeight,
  Link,
  MetaMaskLogo,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: 2,
})`
  width: 100%;

  & > :not(:first-child) {
    margin-top: 16px;
  }

  ${breakpointDown('lg')`
    & > :not(:first-child) {
      margin-top: 16px;
    }
  `}
`;

export const Header = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
  gap: 4,
})`
  width: 100%;

  ${breakpointDown('lg')`
    gap: 12px;
  `}

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '8px',
    })};

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const HeaderIcon = styled.img`
  width: 72px;
  height: 72px;
`;

export const TitleContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})`
  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '8px',
    })};

    text-align: center;

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const Name = styled(Text).attrs({
  level: 'caption',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Handlers = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: 2,
})`
  width: 100%;
  padding-top: 14px;

  & > :not(:first-child) {
    margin-top: 0px;
  }

  ${breakpointDown('lg')`
    &:first-child {
      margin-top: 16px;
    }
  `}

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '8px',
    })};
  }
`;

export const Handler = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: 2,
})`
  width: 100%;

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '8px',
    })};

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const TitleValue = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const TokenValue = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Counter = styled(CounterInput)`
  width: 64px;
  text-align: center;
`;

export const MetaMask = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: '12px',
})`
  width: 100%;
  padding: 48px 24px;
  border-radius: 8px;
  background-color: ${getThemeColor('default')};
  opacity: 0.25;

  ${breakpointDown('lg')`
    margin: 8px 0;
    margin-bottom: 0;
  `}

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
    })};
  }
`;

export const ImageCard = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'center',
  gap: '8px',
})`
  width: 48px;
  height: 48px;
  padding: 14px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('white')};
  box-shadow: ${getThemeBoxShadow('md')};
`;

export const MMLogo = styled(MetaMaskLogo)`
  width: 28px;
  height: 28px;
`;

export const MetaMaskTitle = styled(Text).attrs({
  level: 'bodySmall',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const UnavailableTag = styled(Text).attrs({
  level: 'overline',
})`
  text-align: center;
  gap: 10px;
  padding: 0px 8px;
  border-radius: 1000px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: ${getThemeFontWeight('bold')};
  background-color: ${getThemeColor('warning', 'dark')};
  color: ${getThemeColor('white')};

  @media (max-width: 440px) {
    margin-top: 16px;
  }
`;

export const Disclaimer = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})`
  width: 100%;

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '16px',
    })};

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const ConfirmDisclaimer = styled.div`
  @media (max-width: 440px) {
    align-self: flex-start;
  }
`;

export const DisclaimerText = styled(Text).attrs({
  level: 'bodySmall',
})`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'space-between',
    gap: '8px',
  })};

  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  @media (max-width: 440px) {
    width: 100%;
  }
`;

export const DisclaimerLink = styled(Link).attrs({
  variant: 'text',
  target: '_blank',
})`
  display: contents;
  font-weight: ${getThemeFontWeight('bold')};
  font-size: 13px;
`;

export const RedirectMessage = styled(Text).attrs({
  level: 'bodySmall',
})`
  align-self: flex-start;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const BuyButton = styled(Button).attrs({
  variant: 'primary',
})`
  @media (max-width: 440px) {
    width: 100%;
  }
`;
