import { styled } from 'styled-components';

import {
  AbundanceBrasilTopLightLogo,
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Link,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: hidden;
`;

export const Logo = styled(AbundanceBrasilTopLightLogo)`
  width: 242px;
  max-width: 90%;
  height: 192px;
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  margin-top: 24px;
  text-align: center;
  font-weight: ${getThemeFontWeight('bold')};
`;

export const Description = styled(Text).attrs({
  level: 'body',
})`
  margin-top: 8px;
  font-weight: ${getThemeFontWeight('medium')};
  text-align: center;
`;

export const Content = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'center',
  gap: 4,
})`
  max-width: 100%;
  position: relative;
  top: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const CreateAccountButton = styled(LinkButton).attrs({
  variant: 'secondary-outline-ghost',
})`
  position: relative;
  top: 16px;
  margin-bottom: 48px;
`;

export const ControlButtons = styled(LinkButton).attrs({
  variant: 'secondary-outline-ghost',
})`
  ${breakpointDown('xxxs')`
    font-size: 14px
  `}
`;

export const Footer = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  color: ${getThemeColor('white')};
`;

export const Doubts = styled(Text).attrs({
  level: 'caption',
})`
  margin-bottom: 8px;
  font-weight: ${getThemeFontWeight('regular')};
`;

export const BookPresentation = styled(Link).attrs({
  variant: 'text',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('white')};
`;
