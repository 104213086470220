import { withTranslation } from 'react-i18next';

import {
  AtSignIcon,
  Divider,
  Spacer,
  WhatsappOutlineIcon,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { IMAGE_URL } from 'constants/assets';

import * as S from './styles';
import { ResponsibleConsultantSize } from './types';

export type ResponsibleConsultantProps = {
  className?: string;
  size?: ResponsibleConsultantSize;
};

const ResponsibleConsultant: React.FC<
  WithTranslation<ResponsibleConsultantProps>
> = ({ className, size = 'normal', t }) => {
  return (
    <S.Root $size={size} className={className}>
      <S.Title $size={size}>{t('consultant')}</S.Title>

      <S.ConsultantInfo $size={size}>
        <S.Avatar
          $size={size}
          src={`${IMAGE_URL}/v1702757444/Assets/Avatars/matheus-haddad_cfeuct.png`}
          alt={t('avatarImageAlt')}
        />

        <Spacer direction="column" align="flex-start" justify="center">
          <S.ConsultantName $size={size}>Matheus Haddad</S.ConsultantName>
          <S.ConsultantRole $size={size}>{t('role')}</S.ConsultantRole>
        </Spacer>
      </S.ConsultantInfo>

      <Divider />

      <S.ConsultantContact>
        <S.Contact>
          <AtSignIcon />
          <span>matheus.haddad@abundancebrasil.com</span>
        </S.Contact>

        <S.Contact>
          <WhatsappOutlineIcon />
          <span>+55 31 9 9878-3585</span>
        </S.Contact>
      </S.ConsultantContact>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'responsibleConsultant',
})(ResponsibleConsultant);
