import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';
import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import { IMAGE_URL } from 'constants/assets';
import { useIsUpdatingSubscriptionPlan } from 'state/application';
import { useSubscription } from 'state/subscriptionPlan';

import * as S from './styles';

export type UpperSectionProps = {
  isSelected: boolean | undefined;
  name: string;
  title: string;
  bonusUnits: string | number;
  description: string;
  packagePrice: number;
};

const UpperSection: React.FC<WithTranslation<UpperSectionProps>> = ({
  isSelected,
  name,
  title,
  bonusUnits,
  description,
  packagePrice,
  t,
}) => {
  const { managePlan } = useSubscription();
  const isUpdatingSubscriptionPlan = useIsUpdatingSubscriptionPlan();

  const handleManagePlan = useCallback((): void => {
    managePlan('subscription-plans');
  }, [managePlan]);

  return (
    <S.Root>
      <RenderWhen condition={isSelected}>
        <S.SettingsButton onPress={handleManagePlan}>
          <RenderWhen condition={!isUpdatingSubscriptionPlan}>
            <S.GearIcon />
          </RenderWhen>

          <RenderWhen condition={isUpdatingSubscriptionPlan}>
            <S.CustomLoader />
          </RenderWhen>
        </S.SettingsButton>
      </RenderWhen>

      <S.CoinIcon
        src={`${IMAGE_URL}/v1702770570/Assets/Coins/coin_ptavvh.png`}
        alt={t('coinIconAlt')}
      />

      <S.Name>{name}</S.Name>

      <S.Title>{title}</S.Title>

      <S.Win>{bonusUnits.toString()}</S.Win>

      <S.Description>{description}</S.Description>

      <S.Value>R$ {formatToCurrency(packagePrice)}</S.Value>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix:
    'subscriptionPlans:subscriptionPlanCards:subscriptionPlanCard:upperSection',
})(UpperSection);
