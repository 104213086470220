import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';

import * as S from './styles';

export type PlanDescriptionMessageProps = {
  units: number | undefined;
};

const PlanDescriptionMessage: React.FC<
  WithTranslation<PlanDescriptionMessageProps>
> = ({ units = 0, t }) => {
  return (
    <div>
      <S.Message>
        <S.Plan>{t('currentPlan')}:</S.Plan> {units}{' '}
        {t(units === 1 ? 'singularPlan' : 'pluralPlan')}
      </S.Message>
    </div>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'subscriptionPlans:header:planDescriptionMessage',
})(PlanDescriptionMessage);
