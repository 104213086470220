import { useTransition } from 'react-spring';

import { ToastMessage } from 'hooks/types';

import * as S from './styles';
import ToastElement from './ToastElement';

export type ToastContainerProps = {
  messages: ToastMessage[];
};

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(messages, {
    keys: (message) => message.id,
    from: { opacity: 0, maxHeight: '0px' },
    enter: { opacity: 1, maxHeight: '300px' },
    leave: { opacity: 0, maxHeight: '0px' },
    config: {
      duration: 300,
    },
  });

  return (
    <S.Root>
      {messagesWithTransitions((style, item) => (
        <ToastElement key={item.id} message={item} />
      ))}
    </S.Root>
  );
};

export default ToastContainer;
