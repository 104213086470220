import { useSearchParams } from 'react-router-dom';

/**
 * Takes an array of parameter names and returns an array of corresponding search
 * parameter values from the URL.
 *
 * @param {string[]} params - An array of strings that represent the names of the
 * search parameters you want to retrieve from the URL search query string.
 *
 * @returns An array of strings, each one corresponds to the value of a search
 * parameter specified in the `params` array. If a search parameter is not found
 * in the search parameters, an empty string is returned for that parameter.
 */
export default function useGetSearchParams(params: string[]): string[] {
  const [searchParams] = useSearchParams();

  return params.map((param) => searchParams.get(param) || '');
}
