import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Link,
} from '@abundance-brasil-wabi/sabi';

import LinkButtonBase from 'components/LinkButton';

export const Conditions = styled.span`
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const LinkButton = styled(Link)`
  height: auto;
  display: inline-block;
  font-size: 16px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const SignUpButton = styled(LinkButtonBase).attrs({
  variant: 'text',
})`
  align-self: center;
  font-size: 17px;
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const LanguageSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
