import { Provider as ReduxProvider } from 'react-redux';

import QueryClientProvider from 'components/QueryClientProvider';
import RemoteOTPValidation from 'components/RemoteOTPValidation';
import { ToastProvider } from 'hooks/useToast';
import { RouterProvider } from 'router/index';
import { EventTrackingProvider } from 'state/eventTracking';

import store from '../../store';
import ThemeProvider from './ThemeProvider';

const App: React.FC = () => {
  return (
    <QueryClientProvider>
      <EventTrackingProvider>
        <ThemeProvider>
          <ToastProvider>
            <ReduxProvider store={store}>
              <RemoteOTPValidation>
                <RouterProvider />
              </RemoteOTPValidation>
            </ReduxProvider>
          </ToastProvider>
        </ThemeProvider>
      </EventTrackingProvider>
    </QueryClientProvider>
  );
};

export default App;
