import { withTranslation } from 'react-i18next';

import { ContentsHeader } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

export type CarbonOffsetResultsHeaderProps = {
  name?: string;
};

const CarbonOffsetResultsHeader: React.FC<
  WithTranslation<CarbonOffsetResultsHeaderProps>
> = ({ name, t }) => {
  if (!name) return null;

  return (
    <ContentsHeader
      title={`${name}, ${t('title')}`}
      subtitle={t('subtitle')}
      description={t('description')}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'carbonOffsetResults:carbonOffsetResultsHeader',
})(CarbonOffsetResultsHeader);
