import { Navigate, Outlet } from 'react-router-dom';

import { useUserHasSyntropyAccess } from 'state/user';

import { getPathnameFromKey } from '..';

const OnlyWithSyntropyAccess: React.FC = () => {
  const { data: hasSyntropyAccess, isLoading } = useUserHasSyntropyAccess();

  if (!hasSyntropyAccess && !isLoading)
    return <Navigate to={getPathnameFromKey('home')} replace />;

  return <Outlet />;
};

export default OnlyWithSyntropyAccess;
