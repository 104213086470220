import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { MdOutlinePark } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import { VirtualForest } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { Modal } from 'components/Modal/types';
import { getPathnameFromKey } from 'router/utils';
import { useAbundanceWalletBalances } from 'state/abundanceWallet';
import { useUserIsValidPhysicalPerson } from 'state/user';
import {
  useCurrentVirtualForest,
  useHasVirtualForests,
} from 'state/virtualForest';

import ExtraInfo from './ExtraInfo';
import * as S from './styles';
import SummaryCard from './SummaryCard';

const TreeSummaryCard: React.FC<WithTranslation> = ({ t }) => {
  const { data: currentVirtualForestsList } = useCurrentVirtualForest();
  const { data: isValidPhysicalPerson } = useUserIsValidPhysicalPerson();
  const { data: hasVirtualForests } = useHasVirtualForests();
  const { data: balances } = useAbundanceWalletBalances();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { treeBalance = 0 } = balances || {};
  const showVirtualForestsInfo = !isValidPhysicalPerson && hasVirtualForests;

  const handleTreeSecondaryButtonPress = useCallback((): void => {
    if (showVirtualForestsInfo)
      return navigate({ pathname: getPathnameFromKey('syntropyCommunities') });

    dispatch(
      pushModal({
        name: ModalsList.AURORA_VERDE_MODAL,
      }),
    );
  }, [dispatch, navigate, showVirtualForestsInfo]);

  const createOpenModal = useCallback(
    (modalToOpen: Modal) => (): void => {
      dispatch(pushModal(modalToOpen));
    },
    [dispatch],
  );

  const handleRenderDescription = useCallback((): React.ReactNode => {
    return (
      <>
        {treeBalance ? (
          <S.ActionButton
            onPress={createOpenModal({
              name: ModalsList.AURORA_VERDE_MODAL,
            })}
          >
            {t('message')}
          </S.ActionButton>
        ) : (
          t('emptyMessage')
        )}

        <RenderWhen condition={showVirtualForestsInfo}>
          <S.ContentWrapper>
            {currentVirtualForestsList?.map((forest: VirtualForest) => (
              <ExtraInfo
                key={forest.id}
                value={forest.balance}
                to={`${getPathnameFromKey('syntropyCommunities')}/${forest.id}`}
              >
                {forest.title}
              </ExtraInfo>
            ))}
          </S.ContentWrapper>
        </RenderWhen>
      </>
    );
  }, [
    createOpenModal,
    currentVirtualForestsList,
    showVirtualForestsInfo,
    t,
    treeBalance,
  ]);

  return (
    <SummaryCard
      title={t('title')}
      icon={<MdOutlinePark />}
      value={treeBalance || 0}
      description={handleRenderDescription()}
      primaryButtonTitle={t('buttonTitle')}
      onPrimaryButtonPress={createOpenModal({
        name: ModalsList.BUY_TREES_MODAL,
      })}
      secondaryButtonTitle={
        showVirtualForestsInfo
          ? `${t('virtualForests')}: ${currentVirtualForestsList?.length}`
          : t('secondaryButtonTitle')
      }
      onSecondaryButtonPress={handleTreeSecondaryButtonPress}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:summaryCards:treeSummaryCard',
})(TreeSummaryCard);
