import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnualIncome, Industry, NumberEmployees, Sector } from 'api/services';
import { DropdownOption } from 'components/FormFields/Dropdown/types';

export type UseGetFormOptions = {
  sectors: DropdownOption<Sector>[];
  industries: DropdownOption<Industry>[];
  numberEmployees: DropdownOption<NumberEmployees>[];
  annualIncome: DropdownOption<AnnualIncome>[];
};

export function useGetFormOptions(): UseGetFormOptions {
  const { t } = useTranslation('pages', {
    keyPrefix: 'companySettings:companySettingsForm',
  });

  const sectors = useMemo((): DropdownOption<Sector>[] => {
    return [
      {
        label: t('sectors:fashion'),
        value: 0,
      },
      {
        label: t('sectors:industry'),
        value: 1,
      },
      {
        label: t('sectors:foodRetail'),
        value: 2,
      },
      {
        label: t('sectors:foodAndDrinks'),
        value: 3,
      },
      {
        label: t('sectors:healthAndCosmetics'),
        value: 4,
      },
      {
        label: t('sectors:retailAndWholesale'),
        value: 5,
      },
    ];
  }, [t]);

  const industries = useMemo((): DropdownOption<Industry>[] => {
    return [
      {
        label: t('industries:production'),
        value: 0,
      },
      {
        label: t('industries:capital'),
        value: 1,
      },
      {
        label: t('industries:consumption'),
        value: 2,
      },
      {
        label: t('industries:extractive'),
        value: 3,
      },
      {
        label: t('industries:transformation'),
        value: 4,
      },
      {
        label: t('industries:energetic'),
        value: 5,
      },
      {
        label: t('industries:food'),
        value: 6,
      },
      {
        label: t('industries:civilConstruction'),
        value: 7,
      },
      {
        label: t('industries:informational'),
        value: 8,
      },
    ];
  }, [t]);

  const numberEmployees = useMemo((): DropdownOption<NumberEmployees>[] => {
    return [
      {
        label: t('employees:upTo10'),
        value: 0,
      },
      {
        label: t('employees:from10To50'),
        value: 1,
      },
      {
        label: t('employees:from50To100'),
        value: 2,
      },
      {
        label: t('employees:moreThan100'),
        value: 3,
      },
    ];
  }, [t]);

  const annualIncome = useMemo((): DropdownOption<AnnualIncome>[] => {
    return [
      {
        label: t('annualIncome:upTo100'),
        value: 0,
      },
      {
        label: t('annualIncome:from100To500'),
        value: 1,
      },
      {
        label: t('annualIncome:from500To1Mil'),
        value: 2,
      },
      {
        label: t('annualIncome:from1MilTo5Mil'),
        value: 3,
      },
      {
        label: t('annualIncome:moreThan5Mil'),
        value: 4,
      },
    ];
  }, [t]);

  return {
    sectors,
    industries,
    numberEmployees,
    annualIncome,
  };
}
