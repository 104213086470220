import { withTranslation } from 'react-i18next';

import { Button, FormComposer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Password } from 'components/FormFields';

import { useChangePasswordForm } from './useChangePasswordForm';

const ChangePasswordForm: React.FC<WithTranslation> = ({ t }) => {
  const { control, formState, isLoading, onSubmit } = useChangePasswordForm();

  return (
    <FormComposer
      fluid
      sections={[
        {
          id: 'change-password-form-main',
          contents: [
            <Password
              fluid
              key="oldPassword"
              name="oldPassword"
              label={t('typePassword')}
              isDisable={isLoading}
              {...{ control, formState }}
            />,

            <Password
              fluid
              key="newPassword"
              name="newPassword"
              label={t('typeNewPassword')}
              isDisable={isLoading}
              {...{ control, formState }}
            />,

            <Password
              fluid
              key="passwordConfirm"
              name="passwordConfirm"
              label={t('confirmNewPassword')}
              isDisable={isLoading}
              {...{ control, formState }}
            />,

            <Button
              key="submit"
              type="submit"
              variant="primary"
              isDisabled={!formState.isValid || isLoading}
            >
              {t(isLoading ? 'loading' : 'changePassword')}
            </Button>,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'changePassword:changePasswordForm',
})(ChangePasswordForm);
