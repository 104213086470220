import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import useOTPValidation from 'hooks/useOTPValidation';
import { useToast } from 'hooks/useToast';
import { useUserPhoneNumber } from 'state/user';

import { modalIsModalOpenSelector } from '../../../store/modules/modal/selectors';
import { otpValidationHasErrorSelector } from '../../../store/modules/otpValidation/selectors';
import { OTPPurpose } from '../../../store/modules/otpValidation/types';
import { changePasswordFormSchema } from './constants';
import { ChangePasswordFormSchema } from './types';

export type UseChangePasswordForm = FormReturn<ChangePasswordFormSchema> & {
  isLoading: boolean;
  onSubmit: () => void;
};

export function useChangePasswordForm(): UseChangePasswordForm {
  const [isLoading, setIsLoading] = useState(false);

  const isModalOpen = useSelector(modalIsModalOpenSelector);
  const hasError = useSelector(otpValidationHasErrorSelector);

  const { t } = useTranslation('pages', {
    keyPrefix: 'changePassword:changePasswordForm',
  });
  const { startOTPValidation } = useOTPValidation();
  const { addToast } = useToast();
  const { data: phoneNumber } = useUserPhoneNumber();

  const form = useForm<ChangePasswordFormSchema>({
    resolver: zodResolver(changePasswordFormSchema),
    mode: 'all',
  });

  const { handleSubmit, reset } = form;

  const onSubmit = useCallback(
    (data: ChangePasswordFormSchema): void => {
      setIsLoading(true);

      const requestData = {
        payload: data,
        endpoint: '/v1/account/change-password',
        phoneNumber: phoneNumber || '',
      };

      startOTPValidation({
        requestDataValues: requestData,
        purpose: OTPPurpose.CHANGE_PASSWORD,
        passwordBypass: data.oldPassword,
        method: 'post',
        callback: () => {
          setIsLoading(false);

          reset({ oldPassword: '', newPassword: '', passwordConfirm: '' });

          addToast({
            message: t('successChange'),
            state: 'success',
          });
        },
      });
    },
    [addToast, phoneNumber, reset, startOTPValidation, t],
  );

  useEffect(() => {
    if (!isModalOpen) setIsLoading(false);
  }, [isModalOpen]);

  useEffect(() => {
    if (hasError) {
      setIsLoading(false);

      addToast({
        message: t('errorChange'),
        state: 'error',
      });
    }
  }, [addToast, hasError, t]);

  return {
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
