import { styled } from 'styled-components';

import {
  Button,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const IconContainer = styled.span`
  font-size: 24px;
`;

export const Value = styled(Heading).attrs({
  level: 5,
})`
  font-weight: ${getThemeFontWeight('bold')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const SecondaryButton = styled(Button).attrs({
  variant: 'text',
})`
  text-align: start;
  font-size: 14px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const ActionButton = styled(Button).attrs({
  variant: 'text',
})`
  text-align: start;
  font-size: 14px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const ContentWrapper = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
  gap: 2,
})`
  width: 100%;
`;

export const ExtraInfo = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
})`
  color: ${getThemeColor('text', 'default', 'primary')};
  width: 100%;
`;

export const ForestLink = styled(LinkButton).attrs({
  variant: 'text',
  target: '_blank',
})`
  text-align: start;
  font-size: 13px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
