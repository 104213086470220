import { useTranslation } from 'react-i18next';

import { Divider } from '@abundance-brasil-wabi/sabi';
import { Key } from '@react-types/shared';

import * as S from './styles';
import { TabNavigatorColor, TabNavigatorItem } from './types';

export type TabNavigatorProps = {
  items: TabNavigatorItem<Key>[];
  activeTab: Key;
  className?: string;
  color?: TabNavigatorColor;
  setActive: (newActiveTab: Key) => void;
};

const TabNavigator = ({
  items,
  activeTab,
  className,
  color = 'dark',
  setActive,
}: TabNavigatorProps) => {
  const { t } = useTranslation();

  const createSetActiveTab = (newIndex: Key) => (): void => {
    setActive(newIndex);
  };

  return (
    <S.Root className={className}>
      <S.Container>
        {items.map((item) => (
          <S.TabItem
            key={t(item.label)}
            $isActive={item.id === activeTab}
            $color={color}
            onPress={createSetActiveTab(item.id)}
          >
            {t(item.label)}
          </S.TabItem>
        ))}
      </S.Container>

      <Divider />
    </S.Root>
  );
};

export default TabNavigator;
