import { CardsList } from '@abundance-brasil-wabi/sabi';

import { useAbundanceWalletBalances } from 'state/abundanceWallet';
import { useImpact } from 'state/tree';

import ImpactSummaryCard from './ImpactSummaryCard';
import TokensSummaryCard from './TokensSummaryCard';
import TreeSummaryCard from './TreeSummaryCard';

const SummaryCardsList: React.FC = () => {
  const { isLoading: abundanceWalletBalanceLoading } =
    useAbundanceWalletBalances();
  const { isLoading: impactLoading } = useImpact();

  return (
    <CardsList isLoading={impactLoading && abundanceWalletBalanceLoading}>
      <TreeSummaryCard />

      <ImpactSummaryCard />

      <TokensSummaryCard />
    </CardsList>
  );
};

export default SummaryCardsList;
