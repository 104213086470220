import { useCallback } from 'react';
import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { AuthArgs, SignInResult } from 'api/services';
import { ErrorMessage } from 'api/services/types';

import useAuth2FA from './useAuth2FA';
import useAuthDefault from './useAuthDefault';

export default function useAuth(
  options?: MutationOptions<SignInResult, AuthArgs>,
) {
  const auth = useAuthDefault(options);
  const auth2FA = useAuth2FA(options);

  const mutationFn = useCallback(
    async ({ body }: AuthArgs) => {
      const { email, pass, use2FA } = body;

      const user = await (use2FA ? auth2FA : auth).mutateAsync({
        body: {
          email,
          pass,
        },
      });

      return user;
    },
    [auth, auth2FA],
  );

  return useMutation<SignInResult, ErrorMessage, AuthArgs, unknown>(
    mutationFn,
    options,
  );
}
