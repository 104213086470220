import { ValueLabel } from 'types/common';

import { Country } from 'api/services';

export const countryListSelector = (data: Country[]): Country[] => data;

export const valueLabelCountryListSelector = (
  data: Country[],
): ValueLabel[] => {
  const countriesList = countryListSelector(data);

  return countriesList.map((countryFromList: Country) => ({
    value: countryFromList.cca2,
    label: countryFromList.name.common,
  }));
};
