import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  getThemePrimaryFontFamily,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  gap: 2,
})`
  width: 100%;
`;

export const TitlesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${breakpointDown('lg')`
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `}
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Subtitle = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  & > b {
    ${getThemePrimaryFontFamily};

    font-weight: ${getThemeFontWeight('bold')};
    color: ${getThemeColor('text', 'default', 'primary')};
  }
`;

export const CalculationDate = styled(Text).attrs({
  level: 'bodySmall',
})`
  text-align: end;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${breakpointDown('lg')`
    text-align: start;
  `}
`;
