import { merge } from 'lodash';
import Cookies, { CookieGetOptions, CookieSetOptions } from 'universal-cookie';

import { DEFAULT_STORAGE_NAMESPACE } from 'constants/storage';
import { CookieKey } from 'constants/types';

export const DEFAULT_EXPIRE_TIMEOUT_MILLIS = 3600 * 1000; // 1 hour.
export const DEFAULT_OPTIONS: CookieSetOptions = {
  secure: true,
  sameSite: true,
  expires: new Date(Date.now() + DEFAULT_EXPIRE_TIMEOUT_MILLIS),
  path: '/',
};

/**
 * Sets a cookie with the given key, value, and optional options.
 *
 * @param {CookieKey} key - The key of the cookie you want to set.
 *
 * @param {T} value - The `value` parameter is the value that you want to set for the cookie. It
 * can be of any type, but it will be converted to a string before being stored as a cookie value.
 *
 * @param {CookieSetOptions} [options] - The `options` parameter is an optional object that allows you
 * to customize the behavior of the cookie being set.
 */
export function setCookie<T>(
  key: CookieKey,
  value: T,
  options?: CookieSetOptions,
): T | undefined {
  if (!value) return undefined;

  const cookie = new Cookies();

  cookie.set(
    `${DEFAULT_STORAGE_NAMESPACE}${key}`,
    value,
    merge(DEFAULT_OPTIONS, options),
  );

  return value;
}

/**
 * Retrieves the value of a cookie with the specified key.
 *
 * @param {CookieKey} key - The key of the cookie you want to retrieve.
 *
 * @param {CookieGetOptions} [options] - The `options` parameter is an optional object that can be used
 * to specify additional options for retrieving the cookie.
 *
 * @returns The value of the cookie.
 */
export function getCookie<T = unknown>(
  key: CookieKey,
  options?: CookieGetOptions,
): T | undefined {
  const cookie = new Cookies();

  return cookie.get(`${DEFAULT_STORAGE_NAMESPACE}${key}`, options);
}

/**
 * Removes a cookie with the specified key and options.
 *
 * @param {CookieKey} key - The key of the cookie to be removed.
 *
 * @param {CookieSetOptions} [options] - The `options` parameter is an optional object that can be used
 * to specify additional settings for removing the cookie.
 */
export function removeCookie(
  key: CookieKey,
  options?: CookieSetOptions,
): CookieKey {
  const cookie = new Cookies();

  cookie.remove(
    `${DEFAULT_STORAGE_NAMESPACE}${key}`,
    merge(DEFAULT_OPTIONS, options),
  );

  return key;
}
