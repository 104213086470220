import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import {
  CarbonOffsetEnrollment,
  getCarbonOffsetEnrollment,
} from 'api/services';
import { useUserId } from 'state/user';

import { carbonOffestQueryKeys } from './queryKeys';
import { carbonOffsetEnrollmentByIdSelector } from './selectors';
import { CarbonOffsetEnrollmentQueryKey } from './types';

export default function useGetCarbonOffsetEnrollment<
  TData = CarbonOffsetEnrollment,
>(
  id?: string,
  options?: QueryOptions<
    CarbonOffsetEnrollment,
    TData,
    CarbonOffsetEnrollmentQueryKey
  >,
) {
  const { data: userId } = useUserId();

  return useQuery<
    CarbonOffsetEnrollment,
    unknown,
    TData,
    CarbonOffsetEnrollmentQueryKey
  >(
    carbonOffestQueryKeys.carbonOffsetEnrollment(userId, id),
    async () => {
      if (!id) return;

      return getCarbonOffsetEnrollment({ params: { id } });
    },
    {
      ...options,
      enabled: Boolean(id),
    },
  );
}

export const useCarbonOffsetEnrollmentById = (id?: string) =>
  useGetCarbonOffsetEnrollment(id, {
    select: carbonOffsetEnrollmentByIdSelector,
  });
