import { styled } from 'styled-components';

import {
  Alert,
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

import { StyledHowToGetMyTokenProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  margin: 0;
`;

export const AlertToast = styled(Alert).attrs({
  variant: 'toast',
  state: 'danger',
  fluid: true,
})`
  margin-bottom: 48px;
`;

export const ContentContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 2,
})<StyledHowToGetMyTokenProps>`
  ${({ $tipAlerIsOn }) =>
    $tipAlerIsOn &&
    `
      margin-top: 48px;

      ${breakpointDown('lg')`
        margin-top: 24px;
      `}
    `}
`;

export const Title = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Title)`
  margin-bottom: 42px;
  font-weight: ${getThemeFontWeight('medium')};

  ${breakpointDown('lg')`
    margin-bottom: 24px;
  `}
`;

export const KnowMoreLink = styled(LinkButton).attrs({
  variant: 'text',
})`
  font-size: 16px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const ExampleImage = styled.img`
  margin: 0;
  max-width: 65%;

  ${breakpointDown('lg')`
    max-width: 100%;
    margin-bottom: 24px;
  `}
`;

export const TokensLogo = styled.img`
  width: 201px;
  height: 201px;
  margin-bottom: 48px;

  ${breakpointDown('lg')`
    margin-bottom: 24px;
  `}
`;

export const DefaultImage = styled.img`
  max-width: 100%;
  position: relative;
  left: 0;
  margin-bottom: 48px;

  ${breakpointDown('lg')`
    margin-bottom: 24px;
  `}
`;

export const FluidImageContainer = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  margin-bottom: 42px;

  ${breakpointDown('lg')`
    margin-bottom: 24px;
  `}
`;

export const Wanna = styled(Title)`
  margin-bottom: 16px;
`;

export const RestoreFinalButton = styled(LinkButton).attrs({
  variant: 'primary',
})`
  margin-top: 16px;
  margin-bottom: 24px;
`;
