import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getVirtualForestList, VirtualForest } from 'api/services';

import { virtualForestQueryKeys } from './queryKeys';
import { virtualForestListSelector } from './selectors';
import { VirtualForestListQueryKey } from './types';

export default function useGetVirtualForestList<TData = VirtualForest[]>(
  options?: QueryOptions<VirtualForest[], TData, VirtualForestListQueryKey>,
) {
  return useQuery<VirtualForest[], unknown, TData, VirtualForestListQueryKey>(
    virtualForestQueryKeys.virtualForestList(),
    getVirtualForestList,
    {
      ...options,
    },
  );
}

export const useVirtualForestList = () =>
  useGetVirtualForestList({ select: virtualForestListSelector });
