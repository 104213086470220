import {
  FacebookOutlineIcon,
  InstagramOutlineIcon,
  LinkedInOutlineIcon,
  RenderWhen,
  SpotifyOutlineIcon,
  TwitterOutlineIcon,
  YoutubeOutlineIcon,
} from '@abundance-brasil-wabi/sabi';

import { VirtualForest } from 'api/services';

import * as S from './styles';

const SocialMediaLink: React.FC<VirtualForest> = ({
  customerFacebook,
  customerInstagram,
  customerLinkedIn,
  customerSpotify,
  customerYouTube,
  customerTwitter,
}) => {
  return (
    <S.Root>
      <RenderWhen condition={!!customerFacebook}>
        <S.SocialMediaLink href={customerFacebook}>
          <FacebookOutlineIcon />
        </S.SocialMediaLink>
      </RenderWhen>

      <RenderWhen condition={!!customerInstagram}>
        <S.SocialMediaLink href={customerInstagram}>
          <InstagramOutlineIcon />
        </S.SocialMediaLink>
      </RenderWhen>

      <RenderWhen condition={!!customerLinkedIn}>
        <S.SocialMediaLink href={customerLinkedIn}>
          <LinkedInOutlineIcon />
        </S.SocialMediaLink>
      </RenderWhen>

      <RenderWhen condition={!!customerSpotify}>
        <S.SocialMediaLink href={customerSpotify}>
          <SpotifyOutlineIcon />
        </S.SocialMediaLink>
      </RenderWhen>

      <RenderWhen condition={!!customerYouTube}>
        <S.SocialMediaLink href={customerYouTube}>
          <YoutubeOutlineIcon />
        </S.SocialMediaLink>
      </RenderWhen>

      <RenderWhen condition={!!customerTwitter}>
        <S.SocialMediaLink href={customerTwitter}>
          <TwitterOutlineIcon />
        </S.SocialMediaLink>
      </RenderWhen>
    </S.Root>
  );
};

export default SocialMediaLink;
