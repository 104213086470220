export type DownloadFileProps = {
  file: Blob;
  fileName: string;
};

/**
 * It creates a temporary link to the file, then clicks on it to download it.
 *
 * @param {DownloadFileProps} file - the file to download.
 */
export async function downloadFile({
  file,
  fileName,
}: DownloadFileProps): Promise<void> {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
  a.remove();
}
