import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'src/store/modules/modal/actions';

import useAppFocus from 'hooks/useAppFocus';
import { useNavbarActions } from 'state/navbar';

export type UsePageBackdrop = {
  onClick: () => void;
};

export default function usePageBackdrop(): UsePageBackdrop {
  const { toggleAppFocus } = useAppFocus();

  const { dequeueNavbar } = useNavbarActions();
  const dispatch = useDispatch();

  const handleIsAppUnfocused = useCallback((): void => {
    dequeueNavbar();
    dispatch(hideModal());
    toggleAppFocus();
  }, [dequeueNavbar, dispatch, toggleAppFocus]);

  return {
    onClick: handleIsAppUnfocused,
  };
}
