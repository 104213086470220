import { CardsList } from '@abundance-brasil-wabi/sabi';

import CompensationCarbonManagementCard from './CompensationCarbonManagementCard';
import SellCarbonManagementCard from './SellCarbonManagementCard';

const CarbonManagementCardsList: React.FC = () => {
  return (
    <CardsList>
      <CompensationCarbonManagementCard />

      <SellCarbonManagementCard />
    </CardsList>
  );
};

export default CarbonManagementCardsList;
