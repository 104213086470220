import { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import { SubscriptionPlan } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { useIsUpdatingSubscriptionPlan } from 'state/application';
import { useSubscription } from 'state/subscriptionPlan';
import { useUserHasSubscriptionPlan } from 'state/user';

import { pushModal } from '../../../store/modules/modal/actions';
import { ModalsList } from '../../../store/modules/modal/types';
import PlanBenefits from './PlanBenefits';
import SelectedIndicator from './SelectedIndicator';
import * as S from './styles';
import UpperSection from './UpperSection';

export type SubscriptionPlanCardProps = SubscriptionPlan & {
  title?: string;
};

const SubscriptionPlanCard: React.FC<
  WithTranslation<SubscriptionPlanCardProps>
> = ({
  id = '',
  name,
  title,
  bonusUnits,
  description,
  packagePrice = 0,
  tons,
  tonsUntil2052 = '',
  units = 0,
  t,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { updatePlan, currentSelectedPlan } = useSubscription();
  const { data: hasSubscriptionPlan } = useUserHasSubscriptionPlan();
  const isUpdatingSubscriptionPlan = useIsUpdatingSubscriptionPlan();
  const dispatch = useDispatch();
  const isSelected = currentSelectedPlan === id && hasSubscriptionPlan;

  const openSubscribeModal = useCallback((): void => {
    dispatch(
      pushModal({
        name: ModalsList.SUBSCRIPTION_PLAN_MODAL,
        params: { id, tonsUntil2052, packagePrice, units },
      }),
    );
  }, [dispatch, id, packagePrice, tonsUntil2052, units]);

  const handleChangePlan = useCallback((): void => {
    setIsLoading(true);

    updatePlan(id, units, () => {
      setTimeout(() => setIsLoading(false), 1000);
    });
  }, [id, units, updatePlan]);

  const handleSubscription = useCallback((): void => {
    if (!hasSubscriptionPlan) return openSubscribeModal();

    return handleChangePlan();
  }, [handleChangePlan, openSubscribeModal, hasSubscriptionPlan]);

  return (
    <S.SubscriptionPlanCard
      $isSelected={isSelected}
      $hasPlan={hasSubscriptionPlan}
    >
      <S.Contents>
        <UpperSection
          isSelected={isSelected}
          name={name}
          title={title || ''}
          bonusUnits={bonusUnits}
          description={t(description)}
          packagePrice={packagePrice || 0}
        />

        <SelectedIndicator isSelected={isSelected} />

        <PlanBenefits trees={units} tons={tons} tonsUntil2052={tonsUntil2052} />

        <S.SubscriptionButton
          onPress={handleSubscription}
          $hidden={!!isSelected}
          isDisabled={isLoading || isUpdatingSubscriptionPlan}
          isLoading={isLoading}
          loadingMessage={t('loading')}
        >
          <RenderWhen condition={!isLoading}>
            {t(hasSubscriptionPlan ? 'change' : 'subscribe')}
          </RenderWhen>
        </S.SubscriptionButton>
      </S.Contents>
    </S.SubscriptionPlanCard>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'subscriptionPlans:subscriptionPlanCards:subscriptionPlanCard',
})(SubscriptionPlanCard);
