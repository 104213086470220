import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { ClaimHistory } from './types';

export async function getClaimHistory() {
  const { data } = await request.get<
    ClaimHistory[],
    AxiosResponse<ClaimHistory[]>,
    never
  >(`${abundanceAPI.V1.CLAIMS}/users/current`);

  return data;
}

export async function getLastClaimDate() {
  const { data } = await request.get<string, AxiosResponse<string>, never>(
    `${abundanceAPI.V1.CLAIMS}/users/current/last-successful-claim`,
  );

  return data;
}
