import { Key } from 'react';
import { parseInt } from 'lodash';

import { ValueType } from './types';

export function getParseKey(key: Key, valueType: ValueType): Key | boolean {
  if (valueType === 'number') return parseInt(key as string);

  if (valueType === 'boolean') return key === 'true';

  return key;
}
