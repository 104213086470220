import { styled } from 'styled-components';

import { breakpointDown, getThemeColor } from '@abundance-brasil-wabi/sabi';

import TabNavigator from 'components/TabNavigator';

export const Root = styled(TabNavigator)`
  margin: 0;
  margin-bottom: 24px;

  ${breakpointDown('lg')`
    width: auto;
    margin: -64px;
    margin-bottom: 64px;
    padding: 0px 16px;
    background-color: ${getThemeColor('default')};
  `}

  ${breakpointDown('sm')`
    margin: -24px;
    margin-bottom: 24px;
  `}
`;
