import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  RadioGroup,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const OptionsContainer = styled(RadioGroup)`
  width: 100%;
  gap: 24px;

  ${breakpointDown('sm')`
    gap: 16px;
  `}
`;

export const IconContainer = styled.span`
  display: flex;
  font-size: 32px;

  ${breakpointDown('sm')`
    font-size: 30px;
  `}
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  ${breakpointDown('sm')`
    gap: 16px;
  `}
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignInLink = styled(LinkButton).attrs({
  variant: 'text',
  fluid: true,
})`
  font-size: 17px;
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const LanguageSelectorContainer = styled.div`
  align-self: center;
`;
