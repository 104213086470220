import { InternalAxiosRequestConfig } from 'axios';

import { getAuthToken } from 'state/auth/utils';

export default function requestInterceptor(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  const token = getAuthToken();

  if (!config.headers) return {} as InternalAxiosRequestConfig;

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
}
