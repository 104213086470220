import { css, styled } from 'styled-components';

import {
  Button,
  createFlexDisplay,
  createThemeTransition,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import { StyledTabItemProps } from './types';

function getThemeStyle({ $color }: StyledTabItemProps) {
  switch ($color) {
    case 'dark': {
      return css`
        color: ${getThemeColor('text', 'default', 'secondary')};
      `;
    }

    default: {
      return css`
        color: ${getThemeColor('text', 'white')};
      `;
    }
  }
}

export const Root = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const Container = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
  gap: '16px',
})`
  overflow: auto;

  @media (max-width: 330px) {
    gap: 12px;
  }
`;

export const TabItem = styled(Button).attrs({
  variant: 'text-static',
  size: 32,
})<StyledTabItemProps>`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'center',
  })};

  height: 48px;
  padding: 0 0.5px;
  border: none;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  font-weight: ${getThemeFontWeight('bold')};
  transition: ${createThemeTransition({ props: 'color' })};

  &[data-hovered] {
    color: ${getThemeColor('text', 'primary', 'hover')};
  }

  ${getThemeStyle}

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-bottom: 3px solid ${getThemeColor('text', 'primary', 'default')};
      color: ${getThemeColor('text', 'primary', 'default')};
    `};

  @media (max-width: 330px) {
    font-size: 10px;
  }
`;
