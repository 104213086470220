import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { resetPassword, ResetPasswordArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useResetPassword(
  options?: MutationOptions<void, ResetPasswordArgs>,
) {
  return useMutation<void, ErrorMessage, ResetPasswordArgs, unknown>(
    resetPassword,
    options,
  );
}
