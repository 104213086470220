import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getSyntropyList, Syntropy } from 'api/services';

import { syntropyQueryKeys } from './queryKeys';
import { SyntropyListQueryKey } from './types';

export default function useGetSyntropyList<TData = Syntropy[]>(
  options?: QueryOptions<Syntropy[], TData, SyntropyListQueryKey>,
) {
  return useQuery(syntropyQueryKeys.syntropyList(), getSyntropyList, options);
}
