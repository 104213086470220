import { KeyStringValue } from 'types/common';

const monthsByLocales: KeyStringValue<string[]> = {
  'pt-BR': [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  'en-US': [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

/**
 * It returns an array of months for a given locale.
 *
 * @param {string} locale - The locale to use.
 *
 * @returns An array of strings.
 */
export function getMonthsByLocale(locale: string): string[] {
  return monthsByLocales[locale];
}

export default getMonthsByLocale;
