import { withTranslation } from 'react-i18next';

import { Banner } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import useGetRandomSideBannerImage from './useGetRandomSideBannerImage';

const SideBanner: React.FC<WithTranslation> = ({ t }) => {
  const bannerImage = useGetRandomSideBannerImage();

  return (
    <Banner src={bannerImage}>
      <Banner.Header>
        <Banner.Title>{t('title')}</Banner.Title>
        <Banner.Subtitle>{t('subtitle')}</Banner.Subtitle>
      </Banner.Header>
    </Banner>
  );
};

export default withTranslation('components', {
  keyPrefix: 'page:sideBanner',
})(SideBanner);
