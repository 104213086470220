import { createSelector } from 'reselect';

import { AppState } from '../..';
import { ModalState } from './types';

const modalState = (state: AppState): ModalState => state.modal;

export const modalSelector = createSelector(
  modalState,
  (modal: ModalState) => modal,
);

export const modalIsModalOpenSelector = createSelector(
  modalState,
  (modal: ModalState) => modal.isModalOpen,
);

export const modalNameSelector = createSelector(
  modalState,
  (modal: ModalState) => modal.name,
);

export const modalParamsSelector = createSelector(
  modalState,
  (modal: ModalState) => modal.params,
);

export const modalOnHideValueSelector = createSelector(
  modalState,
  (modal: ModalState) => modal.onHideValue,
);
