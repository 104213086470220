import { externalEndpoints } from 'api/constants';

import { Country } from './types';

export async function getCountryList() {
  const response = fetch(externalEndpoints.COUNTRY)
    .then((resp) => resp.json())
    .then((data: Country[]) => {
      // Sorting list alphabetically.
      return data.sort((countryA: Country, countryB: Country) => {
        if (countryA.name.common < countryB.name.common) return -1;

        if (countryA.name.common > countryB.name.common) return 1;

        return 0;
      });
    });

  return response;
}
