import { css, styled } from 'styled-components';

import {
  Button,
  createThemeTransition,
  getThemeBoxShadow,
  getThemeColor,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import { StyledSubscriptionPlanCardProps } from './types';

export const Root = styled.div`
  width: 100%;
  min-height: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubscriptionPlanCard = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
})<StyledSubscriptionPlanCardProps>`
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('white')};
  transition: ${createThemeTransition({ props: 'all' })};

  &:hover {
    box-shadow: ${getThemeBoxShadow('md')};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 2px solid ${getThemeColor('border', 'primary', 'default')};
    `}

  ${({ $hasPlan }) =>
    $hasPlan &&
    css`
      cursor: pointer;
    `}
`;

export const Contents = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: 6,
})`
  width: 100%;
`;

export const SubscriptionButton = styled(Button).attrs({
  variant: 'primary',
  size: 32,
})<StyledSubscriptionPlanCardProps>`
  width: -webkit-fill-available;

  ${({ $hidden }) =>
    $hidden &&
    css`
      visibility: hidden;
    `}
`;
