import { useSetPageConfig } from 'components/Page';

import SecurityVerificationForm from './SecurityVerificationForm';

const SecurityVerification: React.FC = () => {
  useSetPageConfig({ mode: 'split' });

  return <SecurityVerificationForm shouldFormatNumber={false} />;
};

export default SecurityVerification;
