import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import {
  IndicateCustomerArgs,
  IndicateCustomerBody,
  SupportArgs,
  SupportBody,
} from './types';

export async function sendSupportRequest(args: SupportArgs) {
  const { body } = args;

  const { data } = await request.post<void, AxiosResponse<void>, SupportBody>(
    `${abundanceAPI.V1.SUPPORT}/users/current`,
    body,
  );

  return data;
}

export async function indicateCustomer(args: IndicateCustomerArgs) {
  const { body } = args;

  const { data } = await request.post<
    void,
    AxiosResponse<void>,
    IndicateCustomerBody
  >(`${abundanceAPI.V1.CUSTOMER}/users/current/indicate`, body);

  return data;
}
