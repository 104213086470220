import { styled } from 'styled-components';

import {
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const ContentText = styled(Text).attrs({
  level: 'body',
})`
  ${createFlexDisplay({
    align: 'center',
    gap: '12px',
  })};

  text-align: center;
`;

export const FooterText = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};

  & > a {
    font-size: 16px;
    color: ${getThemeColor('white')};
  }
`;
