import { useSetPageConfig } from 'components/Page';
import { DEFAULT_FLUID_PAGE_SETTINGS } from 'components/Page/constants';

import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword: React.FC = () => {
  useSetPageConfig({
    mode: 'split',
    override: {
      sideContent: DEFAULT_FLUID_PAGE_SETTINGS.sideContent,
    },
  });

  return <ChangePasswordForm />;
};

export default ChangePassword;
