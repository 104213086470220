import { useCallback } from 'react';
import { last } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { TRANSITION_DURATION_MILLIS } from '@abundance-brasil-wabi/sabi';

import {
  GetNavbarsParams,
  NavbarNames,
  Navbars as NavbarType,
} from './navbars';
import {
  actionsSelector,
  hasNavbarSelector,
  hasVisibleNavbarSelector,
  isNavbarOpenSelector,
  navbarParamsSelector,
} from './selectors';
import { NavbarState, NavbarStore } from './types';

const initialState: NavbarState = {
  navbarQueue: [],
};

const useNavbarStore = create(
  immer<NavbarStore>((set) => ({
    state: initialState,

    actions: {
      enqueueNavbar(navbar: NavbarType) {
        set(({ state }) => {
          state.navbarQueue.push({ ...navbar, show: true });
        });
      },

      switchNavbar(navbar: NavbarType) {
        set((store) => {
          if (!store.state.navbarQueue.length) return;

          const top = last(store.state.navbarQueue);

          if (!top) return;

          top.show = false;

          // Creates transition effect when switching between Navbars.
          setTimeout(() => {
            set(({ state }) => {
              state.navbarQueue.push({ ...navbar, show: true });
            });
          }, TRANSITION_DURATION_MILLIS);
        });
      },

      dequeueNavbar() {
        set(({ state }) => {
          if (state.navbarQueue.length) {
            const top = last(state.navbarQueue);

            if (!top) return;

            top.show = false;
          }
        });
      },
    },
  })),
);

export const useNavbarActions = () => useNavbarStore(actionsSelector);

export const useHasNavbar = () => useNavbarStore(hasNavbarSelector);

export const useHasVisibleNavbar = () =>
  useNavbarStore(useCallback((state) => hasVisibleNavbarSelector(state), []));

export const useNavbarIsOpen = (navbarName: NavbarNames) =>
  useNavbarStore(
    useCallback(
      (state) => isNavbarOpenSelector(state, navbarName),
      [navbarName],
    ),
  );

export const useNavbarParams = <TName extends NavbarNames>(
  navbarName: TName,
): GetNavbarsParams<TName> | undefined =>
  useNavbarStore(
    useCallback(
      (state) => navbarParamsSelector(state, navbarName),
      [navbarName],
    ),
  );

export default useNavbarStore;
