/* eslint-disable jsx-a11y/control-has-associated-label */
import { withTranslation } from 'react-i18next';

import {
  Link,
  Loader,
  PolygonLogo,
  RenderWhen,
} from '@abundance-brasil-wabi/sabi';
import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { ClaimHistory as Transaction } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { currencySymbols } from 'constants/currencySymbols';
import { useClaimHistory } from 'state/claimHistory';
import { formatDate } from 'utils/formatting';

import * as S from './styles';

const ClaimHistory: React.FC<WithTranslation> = ({ t }) => {
  const { data: claimHistory = [], isLoading: claimHistoryLoading } =
    useClaimHistory();

  return (
    <S.Root>
      <S.History $isLoading={claimHistoryLoading}>
        <RenderWhen condition={claimHistoryLoading}>
          <S.Loader>
            <Loader />
          </S.Loader>
        </RenderWhen>

        <RenderWhen condition={!claimHistoryLoading}>
          <S.Table>
            <S.THead>
              <tr>
                <th>{t('amount')}</th>
                <th>{t('value')}</th>
                <th>{t('status')}</th>
                <th>{t('date')}</th>
                <th> </th>
              </tr>
            </S.THead>
            <tbody>
              {claimHistory
                ? claimHistory?.map((transaction: Transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.quantity}</td>

                      <td>
                        {currencySymbols[transaction.currency]}{' '}
                        {formatToCurrency(transaction.amountTotal)}
                      </td>

                      <S.Status $status={transaction.status}>
                        {transaction.statusDescription}
                      </S.Status>

                      <td>
                        {transaction.timestamp
                          ? formatDate(
                              new Date(
                                parseFloat(transaction.timestamp) * 1000,
                              ),
                              'fullDate',
                            )
                          : null}
                      </td>

                      <td>
                        <RenderWhen condition={transaction.status === 2}>
                          <Link
                            variant="text"
                            href={transaction.receiptUrl}
                            target="_blank"
                          >
                            <PolygonLogo width={26} height={20} />
                          </Link>
                        </RenderWhen>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </S.Table>
        </RenderWhen>
      </S.History>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'claimHistory',
})(ClaimHistory);
