import { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  Divider,
  RenderWhen,
  useWindowSize,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useSetPageConfig } from 'components/Page';
import { IMAGE_URL } from 'constants/assets';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

const HowToGetMyCertificate: React.FC<WithTranslation> = ({ t }) => {
  const [tipAlertState, setTipAlertState] = useState(true);

  const { breakpoints } = useTheme();
  const { width } = useWindowSize();
  useSetPageConfig({ mode: 'fluid' });

  const handleCloseTipAlert = useCallback((): void => {
    setTipAlertState((oldState) => !oldState);
  }, []);

  return (
    <S.Root>
      <RenderWhen condition={tipAlertState}>
        <S.AlertToast
          title={t('title')}
          message={
            <>
              {t('notARestorer')}{' '}
              <S.KnowMoreLink to={getPathnameFromKey('howToGetMyTokens')}>
                {t('knowMore')}
              </S.KnowMoreLink>
            </>
          }
          onClose={handleCloseTipAlert}
        />
      </RenderWhen>

      <S.ContentContainer $tipAlerIsOn={tipAlertState}>
        <S.Title>{t('whatIsTheCertificate')}</S.Title>

        <S.Description>{t('certificateExplanation')}</S.Description>

        <S.Certificate
          src={`${IMAGE_URL}/v1702755466/Assets/Informational/Certificate/certificate_a3vn4g.png`}
          alt={t('certificateImageAlt')}
        />

        <S.Title>{t('howToGetTheCertificate')}</S.Title>

        <S.Description>{t('howToGetExplanation')}</S.Description>

        <Divider
          margin={width >= breakpoints.values.sm ? '48px 0' : '24px 0'}
        />

        <S.GetCertificateMessage>
          {t('wantToGetTheCertificate')}
        </S.GetCertificateMessage>

        <S.RestoreButton to={getPathnameFromKey('wallet')}>
          {t('restoreNow')}
        </S.RestoreButton>
      </S.ContentContainer>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'howToGetMyCertificate',
})(HowToGetMyCertificate);
