import { SubscriptionPlanCard } from './types';

export const subscriptionPlanCards: SubscriptionPlanCard[] = [
  {
    name: 'Friend',
    title: `oneTree:title`,
    description: `oneTree:description`,
  },
  {
    name: 'Hero',
    title: `tenTree:title`,
    description: `tenTree:description`,
  },
  {
    name: 'Super Hero',
    title: `fifthyTrees:title`,
    description: `fifthyTrees:description`,
  },
  {
    name: 'Family',
    title: `oneHundredTrees:title`,
    description: `oneHundredTrees:description`,
  },
];
