import { useMemo } from 'react';
import { sample } from 'lodash';

import { SIDE_BANNER_IMAGES } from './constants';

/**
 * Returns a random memoized side banner image.
 */
export default function useGetRandomSideBannerImage(): string {
  return (
    useMemo((): string | undefined => sample(SIDE_BANNER_IMAGES), []) ||
    SIDE_BANNER_IMAGES[0]
  );
}
