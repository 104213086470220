import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Navbar, Sidebar } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import useGetInstitutionalMenuContents from 'components/Page/useGetInstitutionalMenuContents';
import {
  useNavbarActions,
  useNavbarIsOpen,
  useNavbarParams,
} from 'state/navbar';

const LeftMenuInstitutionalNavbar: React.FC<WithTranslation> = ({ t }) => {
  const { switchNavbar, dequeueNavbar } = useNavbarActions();
  const isOpen = useNavbarIsOpen('LeftMenuInstitutional');
  const params = useNavbarParams('LeftMenuInstitutional');
  const { menuContents } = useGetInstitutionalMenuContents();

  const handleGoBack = useCallback((): void => {
    switchNavbar({
      name: 'LeftMenu',
    });
  }, [switchNavbar]);

  return (
    <Navbar isOpen={isOpen}>
      <Sidebar selectedKey="none">
        <Sidebar.Item
          title={t('menu')}
          icon={<MdArrowBack />}
          allowSelection={false}
          onPress={handleGoBack}
        />

        <Sidebar.Divider />

        {menuContents?.[params?.institutionalMenuItem || 'solution']?.items.map(
          ({ title, url }) => (
            <Sidebar.Item
              key={title}
              title={title}
              as={Link}
              href={url}
              target="_blank"
              allowSelection={false}
              weight="regular"
              onPress={dequeueNavbar}
            />
          ),
        )}
      </Sidebar>
    </Navbar>
  );
};

export default withTranslation('components', {
  keyPrefix: 'navbars:leftMenuInstitutionalNavbar',
})(LeftMenuInstitutionalNavbar);
