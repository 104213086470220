import { useCallback, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { MdClose, MdDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useApplicationActions, useIsFileLoading } from 'state/application';

import { hideModal } from '../../store/modules/modal/actions';
import { modalNameSelector } from '../../store/modules/modal/selectors';
import { MODALS_LIST } from './constants';
import * as S from './styles';
import { ModalElement, ModalName } from './types';

const ModalSelector: React.FC<WithTranslation> = ({ t }) => {
  const modalSelectorRef = useRef<{ handlePrint: () => void }>();

  const currentModal = useSelector(modalNameSelector) || ('' as ModalName);

  const { setFileLoading } = useApplicationActions();
  const isFileLoading = useIsFileLoading();
  const dispatch = useDispatch();

  const handleCloseModal = useCallback((): void => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleDownload = useCallback((): void | null => {
    if (!modalSelectorRef.current) return;

    modalSelectorRef.current.handlePrint();
  }, []);

  const ModalToRender =
    MODALS_LIST[currentModal as keyof ModalElement]?.element;

  useEffect(() => {
    if (!ModalToRender) setFileLoading(false);
  }, [ModalToRender, setFileLoading]);

  if (!ModalToRender) return null;

  return (
    <>
      <RenderWhen condition={isFileLoading}>
        <S.Loader>
          <Loader colorMode="white" message={t('loading')} />
        </S.Loader>
      </RenderWhen>

      <RenderWhen condition={!isFileLoading}>
        <div>
          {MODALS_LIST[currentModal as keyof ModalElement]?.isFileHandler && (
            <S.FileHandlerButtons>
              <S.HandlerButton onPress={handleCloseModal}>
                <MdClose />
              </S.HandlerButton>

              <S.HandlerButton onPress={handleDownload}>
                <MdDownload />
              </S.HandlerButton>
            </S.FileHandlerButtons>
          )}
          <S.Contents
            $isFullScreenOnMobile={
              MODALS_LIST[currentModal as keyof ModalElement]
                ?.isFullScreenOnMobile
            }
            style={{
              ...MODALS_LIST[currentModal as keyof ModalElement]?.style,
            }}
          >
            {MODALS_LIST[currentModal as keyof ModalElement]
              ?.shouldDisplayCloseButton && (
              <S.CloseButton onPress={handleCloseModal}>
                <S.CloseIcon />
              </S.CloseButton>
            )}

            <RenderWhen
              condition={
                MODALS_LIST[currentModal as keyof ModalElement]
                  ?.isFullScreenOnMobile
              }
            >
              <S.MobileHeader>
                <S.BackButton onPress={handleCloseModal}>
                  <S.BackIcon />
                </S.BackButton>

                <S.Title>
                  {t(
                    MODALS_LIST[currentModal as keyof ModalElement]
                      ?.mobileTitle || '',
                  )}
                </S.Title>

                <S.Holder />
              </S.MobileHeader>
            </RenderWhen>

            <ModalToRender ref={modalSelectorRef} />
          </S.Contents>
        </div>
      </RenderWhen>
    </>
  );
};

export default withTranslation('components', {
  keyPrefix: 'modal:modalList',
})(ModalSelector);
