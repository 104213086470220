import { withTranslation } from 'react-i18next';

import { SectionHeader } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import ArticlesCardsList from 'components/Cards/ArticlesCards';
import { getPathnameFromKey } from 'router/utils';
import { useHasArticlesList } from 'state/article';

import * as S from './styles';

const ArticlesList: React.FC<WithTranslation> = ({ t }) => {
  const { data: hasArticles, isLoading } = useHasArticlesList();

  if (!hasArticles || isLoading) return;

  return (
    <S.Root>
      <SectionHeader title={t('title')}>
        <S.SeeAllLink to={getPathnameFromKey('articles')}>
          {t('seeAll')}
        </S.SeeAllLink>
      </SectionHeader>

      <ArticlesCardsList />
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'home:articlesList',
})(ArticlesList);
