import { useCallback } from 'react';
import { omit } from 'lodash';
import { withTranslation } from 'react-i18next';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { useToggle } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';

import Input from '../Input';
import { DefaultFieldProps } from '../types';
import * as S from './styles';

export type PasswordProps = WithTranslation<DefaultFieldProps> & {
  showVisibilityButton?: boolean;
  showForgotPassword?: boolean;
};

const Password: React.FC<PasswordProps> = ({
  showVisibilityButton = true,
  showForgotPassword = false,
  t,
  ...props
}) => {
  const { value: isPasswordVisible, toggle } = useToggle(false);

  const handleToggle = useCallback((): void => {
    toggle();
  }, [toggle]);

  return (
    <Input
      {...omit(props, 'i18n', 'tReady')}
      placeholder="●●●●●●●●"
      type={isPasswordVisible ? 'text' : 'password'}
      endContent={
        showVisibilityButton && (
          <S.ToggleButton variant="text" onPress={handleToggle}>
            <S.ToggleIcon>
              {isPasswordVisible ? <MdVisibility /> : <MdVisibilityOff />}
            </S.ToggleIcon>
          </S.ToggleButton>
        )
      }
      secondaryLabel={
        showForgotPassword && (
          <S.ForgotButton to={getPathnameFromKey('forgotPassword')}>
            {t('forgotPassword')}
          </S.ForgotButton>
        )
      }
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'formFields:password',
})(Password);
