import { CardsList } from '@abundance-brasil-wabi/sabi';

import { useGetSyntropyList } from 'pages/Syntropy/state';

import SyntropyCard from './SyntropyCard';

const SyntroptCardsList: React.FC = () => {
  const { data: syntropyList = [], isLoading } = useGetSyntropyList();

  return (
    <CardsList mode="full" isLoading={isLoading}>
      {syntropyList.map((syntropy) => (
        <SyntropyCard key={syntropy.link} {...syntropy} />
      ))}
    </CardsList>
  );
};

export default SyntroptCardsList;
