import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import { getDefaultAppLanguage, setDefaultHTMLLanguage } from 'utils/handlers';

import translations from './locales';

const defaultLanguage = getDefaultAppLanguage();

setDefaultHTMLLanguage(defaultLanguage);

i18n.use(initReactI18next).init({
  resources: translations,
  ns: ['pages', 'components', 'shared'],
  defaultNS: 'pages',
  fallbackLng: defaultLanguage,
  keySeparator: ':',
});

export default i18n;
