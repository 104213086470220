import { styled } from 'styled-components';

import { breakpointDown, Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  gap: '8px',
})`
  width: min-content;
  min-width: 500px;
  max-width: 90%;
  position: fixed;
  top: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  z-index: 1000;

  ${breakpointDown('lg')`
    min-width: 90%;
  `}
`;
