import { useCallback } from 'react';

import { SignInResult, User } from 'api/services';
import { useUser } from 'state/user';
import { setIsUserValidPhysicalPerson } from 'state/user/utils';
import { setCookie } from 'utils/handlers';

import { checkIsValidPhysicalPerson, removeTwoFactorAuthData } from './utils';

export default function useOnAuthSuccess() {
  const { refetch: getUser } = useUser(false);

  return useCallback(
    async (signInResult: SignInResult): Promise<User | undefined> => {
      removeTwoFactorAuthData();

      setCookie('token', signInResult.authResponse.accessToken, {
        path: '/',
      });

      setIsUserValidPhysicalPerson(checkIsValidPhysicalPerson(signInResult));

      const { data } = await getUser();

      return data;
    },
    [getUser],
  );
}
