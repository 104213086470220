import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledAuroraVerdeModalProps } from './types';

function getParagraphAlignmentStyle({
  $alignToCenter,
}: StyledAuroraVerdeModalProps) {
  switch ($alignToCenter) {
    case true: {
      return `
        text-align: center;

        ${breakpointDown('lg')`
          text-align: start;
        `}
      `;
    }

    default: {
      return `
        margin-bottom: 12px;
        text-align: start;

        &:last-of-type {
          margin-bottom: 0;
        }

        ${breakpointDown('lg')`
          margin-bottom: 0;
        `}
      `;
    }
  }
}

export const Root = styled.div`
  width: 100%;
  max-width: 1120px;
  height: 100%;
  overflow: hidden;

  ${breakpointDown('lg')`
    width: 100%;
    max-width: 100%;
  `}
`;

export const CloseButton = styled(Button).attrs({
  variant: 'text-static',
})`
  position: absolute;
  right: 0;
  padding: 8px;
  font-size: 20px;
  color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    display: none;
  `}
`;

export const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
`;

export const VideoIframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Contents = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: '32px',
})`
  width: 100%;
  padding: 72px;

  ${breakpointDown('lg')`
    padding: 24px;
  `}
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const SectionTitle = styled(Heading).attrs({
  level: 5,
})`
  margin: 12px 0;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Paragraph = styled(Text).attrs({
  level: 'body',
})<StyledAuroraVerdeModalProps>`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${getParagraphAlignmentStyle}
`;

export const ImpactImage = styled.img`
  width: 100%;
`;
