/* eslint-disable jsx-a11y/control-has-associated-label */
import { withTranslation } from 'react-i18next';

import { Link, Loader, PDFIcon, RenderWhen } from '@abundance-brasil-wabi/sabi';
import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { PurchaseHistory as PurchaseHistoryType } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { currencySymbols } from 'constants/currencySymbols';
import { usePurchaseHistory } from 'state/purchase';
import { formatDate } from 'utils/formatting';

import * as S from './styles';

const PurchaseHistory: React.FC<WithTranslation> = ({ t }) => {
  const { data: purchaseHistory, isLoading: purchaseHistoryLoading } =
    usePurchaseHistory();

  return (
    <S.Root>
      <S.History $isLoading={purchaseHistoryLoading}>
        <RenderWhen condition={purchaseHistoryLoading}>
          <S.Loader>
            <Loader />
          </S.Loader>
        </RenderWhen>

        <RenderWhen condition={!purchaseHistoryLoading}>
          <S.Table>
            <S.THead>
              <tr>
                <th>{t('credits')}</th>
                <th>{t('value')}</th>
                <th>{t('status')}</th>
                <th>{t('date')}</th>
                <th> </th>
              </tr>
            </S.THead>
            <tbody>
              {purchaseHistory
                ? purchaseHistory.map((transaction: PurchaseHistoryType) => (
                    <tr key={transaction.id}>
                      <td>{transaction.quantity}</td>

                      <td>
                        {currencySymbols[transaction.currency]}{' '}
                        {formatToCurrency(transaction.amountTotal)}
                      </td>

                      <S.Status $status={transaction.status}>
                        {transaction.statusDescription}
                      </S.Status>

                      <td>{formatDate(transaction.created, 'fullDate')}</td>

                      <td>
                        <Link
                          variant="text"
                          href={transaction.receiptUrl}
                          target="_blank"
                        >
                          <PDFIcon />
                        </Link>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </S.Table>
        </RenderWhen>
      </S.History>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'wallet:purchaseHistory',
})(PurchaseHistory);
