import { Key, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ValueLabel } from 'types/common';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import i18n from 'i18n/i18n';
import useLanguage from 'hooks/useLanguage';
import useOnLanguageChange from 'hooks/useOnLanguageChange';
import { useToast } from 'hooks/useToast';
import { useValueLabelCountryList } from 'state/country';
import { useUpdateUser, useUser } from 'state/user';

import { legalPersonFormSchema } from './constants';
import { LegalPersonFormSchema } from './types';

export type useLegalPersonForm = FormReturn<LegalPersonFormSchema> & {
  country: string | undefined;
  countriesList: ValueLabel<string>[];
  isLoading: boolean;
  isUpdating: boolean;
  onCountryChange: (newCountry: string) => void;
  onTinChange: (newTin: string) => void;
  onSubmit: () => void;
};

export function useLegalPersonForm(): useLegalPersonForm {
  const { t } = useTranslation('pages', {
    keyPrefix: 'userSettings:legalPerson:legalPersonForm',
  });
  const { data: countriesList = [], isLoading: isCountriesLoading } =
    useValueLabelCountryList();
  const { changeAppLanguage } = useLanguage();
  const { addToast } = useToast();

  const { data: user, isLoading: isUserLoading } = useUser();
  const { mutateAsync: updateUser, isLoading: isUpdating } = useUpdateUser({
    onSuccess: () => {
      addToast({
        message: t('success'),
        state: 'success',
      });
    },
  });

  const form = useForm<LegalPersonFormSchema>({
    resolver: zodResolver(legalPersonFormSchema),
    defaultValues: {
      ...user,
      taxIdentificationNumber: user?.taxIdentificationNumber || '',
      country: user?.country || '',
    } as unknown as LegalPersonFormSchema,
    mode: 'all',
  });

  const { formState, handleSubmit, setValue, reset, trigger } = form;
  const { country } = user || {};
  const isLoading = isCountriesLoading || isUserLoading;

  const handleSetLanguage = useCallback((): void => {
    setValue('language', i18n.language);
  }, [setValue]);

  const submit = useCallback(
    async (data: LegalPersonFormSchema): Promise<void> => {
      await updateUser({
        body: data,
        params: {
          id: user?.id,
        },
      });

      changeAppLanguage(data.language || '', false);

      reset(data);
    },
    [changeAppLanguage, reset, updateUser, user?.id],
  );

  const handleCountryChange = useCallback(
    (newCountry: Key): void => {
      const countryAsString = newCountry as string;

      setValue('country', countryAsString);
      trigger();
    },
    [setValue, trigger],
  );

  const handleTinChange = useCallback(
    (newTin: string): void => {
      setValue('taxIdentificationNumber', newTin);
      trigger();
    },
    [setValue, trigger],
  );

  useOnLanguageChange(handleSetLanguage);

  useEffect(() => {
    if (!formState?.defaultValues?.name) {
      reset(user as unknown as LegalPersonFormSchema);
    }
  }, [formState?.defaultValues?.name, reset, user]);

  return {
    country,
    countriesList,
    isLoading,
    isUpdating,
    onCountryChange: handleCountryChange,
    onTinChange: handleTinChange,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
