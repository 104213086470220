export default {
  auditDocuments: {
    documents: 'Documentos',
    date: 'Data',
    responsible: 'Responsável',
    export: 'Exportar',

    constants: {
      auditDocuments: {
        projectExecution: {
          title: 'Relatório de execução de projeto de restauração',
          responsible: 'Fernando Pereira Guimarães',
          date: '08-03-22',
          fileToExport: 'Relatório de execução de projeto de restauração.pdf',
          fileName: 'restoringProject',
        },
        carbonEstimated: {
          title: 'Relatório de estimativa de sequestro de carbono',
          responsible: 'Thiago Magalhães Meireles',
          date: '08-03-22',
          fileToExport: 'Relatório de estimativa de sequestro de carbono.pdf',
          fileName: 'carbonSequestration',
        },
      },
    },
  },

  auroraVerdeHeader: {
    iconAlt:
      'Logo da floresta Aurora Verde. Seta apontando para cima colorida com um degrade, em cima de um quadrado branco e ao fundo a floresta.',
    name: 'Floresta',
    title: 'Aurora Verde',
  },

  backLink: {
    back: 'Voltar',
  },

  buyTree: {
    trees: 'Árvores',
    unity: 'Unidade',
    total: 'Total',
    contract: 'contrato',
    buy: 'Comprar',
    redirect: 'Você será redirecionado para a plataforma de pagamento.',
  },

  carbonCompensationCalculator: {
    label: 'Seletor de quantidade de árvores.',
    recalculate: 'Recalcular',
    buy: 'Comprar árvores',

    results: {
      of: 'de',
      carbon: 'Carbono emitido',
      trees: 'Árvores',
      percentage: 'Porcentagem',
      value: 'Valor',
    },
  },

  carbonOffsetSummary: {
    carbonOffsetSummaryHeader: {
      title: 'Pegada de Carbono',
      description: 'Calculado em',
      compensated: 'Você compensou',
      emissions: 'das suas emissões',
    },

    scopesResults: {
      of: 'de',
      scope: 'Escopo',
      scope1: 'Emissões diretamente geradas pela sua empresa.',
      scope2: 'Emissões associadas ao seu consumo de energia.',
      scope3: 'Emissões indiretas ligadas à sua cadeia de suprimentos.',
    },
  },

  cards: {
    informationalCards: {
      informationalCard: {
        until: 'até',
      },

      auroraVerdeInformationalCard: {
        subtitle: 'Aurora Verde',
        title: '1 milhão de árvores',
        iconAlt:
          'Logo da floresta Aurora Verde. Seta apontando para cima colorida com um degrade, em cima de um quadrado branco e ao fundo a floresta.',
        tagContent: '300 mil árvores em 2024',
        description: 'Acompanhe o desenvolvimento da floresta Aurora Verde.',
        secondaryButton: {
          text: 'Saiba mais',
          props: {
            fileToExport: 'Relatório de execução de projeto de restauração.pdf',
            fileName: 'restoringProject',
          },
        },
        primaryButton: {
          disabledText: 'Comprar árvores',
          enabledText: 'Comprar árvores',
        },
      },

      environmentalRestorerInformationalCard: {
        subtitle: 'Certificado',
        title: 'Restaurador Ambiental',
        iconAlt:
          'Três árvores alinhadas. Uma ao centro e as outras duas ao fundo.',
        tagContent: 'Indisponível',
        description:
          'Comprove para o mundo que você é um investidor de florestas.',
        secondaryButton: {
          text: 'Saiba mais',
        },
        primaryButton: {
          disabledText: 'Certificado indisponível',
          enabledText: 'Visualizar',
        },
      },

      metamaskInformationalCard: {
        subtitle: 'Tokenização',
        title: 'Abundance Tokens (ATKs)',
        iconAlt:
          'Logo da Metamask. Face de uma raposa laranja ao centro, a frente de um quadrado branco e, ao fundo, um degrade que vai do roxo ao azul.',
        tagContent: 'Novo!',
        description:
          'Resgate seus ATKs com a sua carteira de criptomoedas Metamask',
        secondaryButton: {
          text: 'Saiba mais',
        },
        primaryButton: {
          disabledText: 'Em breve',
          enabledText: 'Resgatar ATKs',
        },
      },
    },

    summaryCards: {
      impactSummaryCard: {
        title: 'Impacto',
        emptyMessage: 'Você não absorveu seu próprio impacto ambiental',
        start: 'Desde',
        from: 'De',
        until: 'até',
        since: 'desde',
        secondaryButtonTitle: 'Saiba mais',
        emptyButtonTitle: 'Relatório indisponível',
        buttonTitle: 'Relatório',
      },

      tokensSummaryCard: {
        title: 'ATKs resgatados',
        emptyMessage: 'Sem resgate',
        message: 'Último resgate: XXXX',
        secondaryButtonTitle: 'Saiba mais',
        emptyButtonTitle: 'Resgate indisponível',
        buttonTitle: 'Em breve',
      },

      treeSummaryCard: {
        title: 'Árvores',
        emptyMessage: 'Você não possui árvores próprias',
        message: 'Aurora Verde',
        secondaryButtonTitle: 'Saiba mais',
        buttonTitle: 'Comprar árvores',
        virtualForests: 'Florestas virtuais',
      },
    },
  },

  claimHistory: {
    amount: 'Quantidade',
    value: 'Valor',
    status: 'Status',
    date: 'Data',
  },

  claimToken: {
    iconAlt: 'Moeda verde com três árvores no centro.',
    name: 'Resgate',
    title: 'Abundance Tokens (ATKs)',
    availableTrees: 'Árvores disponíveis',
    tokensClaim: 'Resgate de ATKs',
    agree: 'Confirmo o resgate conforme o',
    contract: 'contrato',
    unavailable: 'indisponível',
    claim: 'Em breve',
    redirect: 'Você será redirecionado para a plataforma de resgate de tokens',
  },

  esgActing: {
    environment: 'Meio Ambiente',
    social: 'Social',
    governance: 'Governança',
    esgActing: 'Atuação ESG',
    environmentItem: {
      firstli: 'Absorção de C02',
      secondli: 'Contribuição na contenção climática (evitar aumento de 1,5ºC)',
      thirdli: 'Novas florestas com maximização da biodiversidade',
      fourthli: 'Geração de biodiversidade',
      fifthli: 'Energia limpa',
      sixthli: 'Economia de papéis na operação',
      seventhli: 'Tratamento hídrico',
    },
    socialItem: {
      firstli: 'Geração de empregos e renda na comunidade local',
      secondli: 'Repasse de parte dos lucros à projetos sociais',
      thirdli:
        'Motivação aos colaboradores atuais pelo incentivo à práticas ambientais',
    },
    governanceItem: {
      firstli: 'Selo de Restaurador Ambiental',
      secondli:
        'Contribuição na obtenção de certificados ambientais, como a B Corporation',
      thirdli: 'Maturidade econômica com a adoção de Wallet Digital',
      fourthli: 'Transparência das operações em ESG',
    },
  },

  forms: {
    taxInformationForm: {
      title: 'Identificação fiscal',
      description: 'Preencha os dados abaixo',
      tin: 'Número de identificação fiscal',
      country: 'País',
    },
  },

  formFields: {
    password: {
      forgotPassword: 'Esqueci minha senha',
    },

    phoneNumber: {
      phoneNumber: 'Número',
    },
  },

  modal: {
    modalList: {
      loading: 'Carregando...',

      auroraVerdeModal: {
        mobileTitle: 'Aurora Verde',
      },
    },

    auroraVerdeModal: {
      auroraVerdeHeader: {
        auroraVerdeImageAlt:
          'Seta apontando para cima, colorida com um gradiente de cores que vão do azul ao verde.',
        forest: 'Floresta de restauração ambiental',
        treesAmount: '100 mil árvores plantadas em 2022',
        firstParagraph:
          'O projeto Floresta Aurora Verde tem o propósito de ajudar a restaurar o meio ambiente, gerar créditos de carbono e promover a economia sustentável.',
        secondParagraph:
          'Milhares de mudas de espécies nativas já foram plantadas na região. Vamos juntos bater a meta de 1 milhão de árvores?',
        beARestorer: 'Seja um restaurador agora',
        plantingArea: 'Área do plantio: 44 hectares',
        location: 'Localização',
        forestProducer: 'Produtor florestal',
      },
      descriptionTitle:
        'O projeto Aurora Verde é um case de sucesso no propósito de esverdear o Brasil por meio da restauração ambiental.',
      firstParagraph:
        'Contribua com essa iniciativa que ajuda o meio ambiente: invista em tokens que correspondem a uma árvore, receba o crédito de carbono atrelado à ela e acompanhe o desenvolvimento da sua parte da floresta.',
      oneMillionTrees: '1 milhão de árvores!',
      secondParagraph:
        'Nossa meta é elevar e acelerar o que o Brasil pode ter de melhor: sustentabilidade e abundância ambiental.',
      thirdParagraph:
        'Os Abundance Tokens são lastreados em árvores e emitidos para preservar cada uma delas, garantindo que cumpram seu ciclo de vida. Uma árvore sequestra, em média, de 0,2 a 0,3 tonelada de CO2 em um prazo de 20 a 30 anos. Assim, podemos alcançar um valor significativo ao formar uma floresta a partir da emissão de um milhão de tokens.',
      fourthParagraph:
        'Invista na restauração ambiental e na preservação do meio ambiente ao adquirir o seu token e tenha a sua parte da Aurora Verde!',
      impactImageAlt:
        'Gráfico indicando a previsão de absorção de uma árvore até 2052.',
    },

    buyTokensModal: {
      abundanceTokenImageAlt: 'Moeda verde com três árvores no centro.',
      buyTokens: 'Comprar Abundance Tokens',
      quantity: 'Quantidade',
      buy: 'Comprar',
      redirect: 'Você será redirecionado para o sistema de pagamentos',
    },

    otpValidationModal: {
      otpValidationForm: {
        title: 'Verificação de segurança',
        description: 'Para proteger sua conta, conclua a seguinte verificação.',
        invalidCode: 'Código inválido',
        codeError: 'Erro ao reenviar código',
        phoneVerification: 'Verificação por telefone',
        codeResent: 'Código reenviado',
        typeCode: 'Digite o código de 6 dígitos enviado para',
        resendCode: 'Reenviar código',
        send: 'Enviar',
      },
    },

    passwordVerificationModal: {
      passwordVerificationForm: {
        title: 'Digite sua senha',
        description:
          'Para proteger os dados da sua conta, favor digitar sua senha de acesso ao Portal ESG',
        label: 'Senha',
        send: 'Enviar',
        error: 'Erro ao validar senha',
      },
    },

    securityVerificationModal: {
      securityVerification: 'Verificação de segurança',
      accountProtection:
        'Para proteger sua conta, conclua a seguinte verificação.',
    },

    subscriptionModal: {
      subscriptionModal: {
        title: 'Plano de assinatura',
        tree: 'Árvore',
        trees: 'Árvores',
        tinTitle: 'Identificação fiscal',
        tinDescription: 'Preencha os dados abaixo',
        country: 'País',
        tin: 'Número de identificação fiscal',
        agree: 'Confirmo a compra conforme os',
        terms: 'termos e condições de uso',
        subscribe: 'Adquirir plano',
        footerText:
          'Ao clicar em comprar, você será redirecionado para a plataforma de pagamento.',
      },

      subscriptionSummary: {
        carbon2052: 'Sequestro de carbono até 2052',
        unity: 'Unidade',
      },
    },

    userAbsorptionReportModal: {
      fileName: 'Relatório de Impacto Ambiental',
    },

    userCertificateModal: {
      fileName: 'Certificado de Restaurador Ambiental',
    },

    virtualForestAbsorptionReportModal: {
      fileName: 'Relatório de Impacto Ambiental',
    },

    virtualForestCertificateModal: {
      fileName: 'Certificado de Restaurador Ambiental',
    },
  },

  navbars: {
    leftMenuInstitutionalNavbar: {
      menu: 'Menu',
    },

    metaMaskDisclaimerNavbar: {
      title: 'Tokenização',
      firstTitle: 'O que é a MetaMask?',
      firstParagraph:
        'Metamask é uma carteira criptográfica e gateway para aplicativos blockchain. Disponível como extensão do navegador e como aplicativo móvel, o MetaMask oferece um cofre de chaves, login seguro, carteira de tokens e troca de tokens - tudo o que você precisa para gerenciar seus ativos digitais.',
      startButton: 'Iniciar',
      howToCreate: 'Como criar uma carteira MetaMask',
      step: 'Passo',
      and: 'e',
      imageAlt: 'Screenshot da página da MetaMask.',
      firstStep:
        'Primeiro você precisa ir ao site da Metamask para instalar a extensão do navegador. MetaMask está disponível para',
      secondStep:
        'Você será redirecionado para o guia MetaMask sobre como criar uma conta.',
      thirdStep:
        'O MetaMask perguntará se você deseja importar ou criar uma carteira, clique em Criar uma Carteira.',
      fourthStep: 'Você precisa criar uma senha para o MetaMask.',
      fifthStep:
        'Uma <strong>Frase de Recuperação Secreta</strong> é uma frase de 12 palavras que é a "chave mestra" da sua carteira. Ela precisa ser salva em um local seguro caso você precise recuperar sua conta. Clique em Avançar.',
      sixthStep:
        'Nesta página você pode ver sua <strong>Frase de Recuperação Secreta</strong> ou pressionar Lembre-me mais tarde.',
      seventhStep:
        'Depois de configurar a carteira, você será redirecionado para a página da sua carteira, onde poderá ver todas as informações sobre ela. <strong>Agora você pode conectar sua carteira a qualquer site.</strong>',
    },

    rightMenuNavbar: {
      hi: 'Olá',
      out: 'Sair',
      userSettings: 'Usuário',
      companySettings: 'Empresa',
      mediaKit: 'Restaurador ambiental',
      howToGetMyTokens: 'Restaurador ambiental',
      myCertificate: 'Certificado',
      howToGetMyCertificate: 'Certificado',
      esgReport: 'Relatório ESG',
      changePassword: 'Segurança',
    },
  },

  page: {
    footer: {
      privacyPolicy: 'Política de privacidade',
      termsAndConditions: 'Termos e condições',
      faq: 'Perguntas frequentes',
      cookies: 'Cookies',
      copyright: 'Copyright © Abundance Brasil. Todos os direitos reservados.',
    },

    sideBanner: {
      title: 'Restaurando o futuro do planeta agora',
      subtitle:
        'Chegou a hora de fazer a diferença! Abrace a missão de restaurar a natureza e ajude a formar novas florestas no Brasil por um futuro melhor para todos.',
    },

    sideMenu: {
      home: 'Home',
      wallet: 'Carteira',
      impact: 'Impacto',
      syntropyReports: 'Syntropy',
      articles: 'Artigos',
      support: 'Suporte',
      subscriptionPlans: 'Planos',
    },

    titleBar: {
      endContent: {
        signIn: 'Login',
        createAccount: 'Crie sua conta',
        mobileMenuAriaLabel: 'Opções de criação de conta',
      },
    },

    useGetInstitutionalMenuContents: {
      solution: 'Solução',
      forests: 'Florestas',
      community: 'Comunidade',
      ecosystem: 'Ecossistema',
      about: 'Sobre',
      howItWorks: 'Como funciona',
      companies: 'Empresas',
      people: 'Pessoas',
      forestProducer: 'Produtor florestal',
      partners: 'Parceiros',
      auroraVerdePlanting: 'Plantio Aurora Verde',
      createYourForest: 'Crie sua floresta',
      governance: 'Governança florestal',
      tribe: 'Abundance Tribe',
      festivalsAndEvents: 'Festivais e eventos',
      syntropy: 'Syntropy',
      restorers: 'Restauradores ambientais',
      abundanceBrasil: 'Abundance Brasil',
      blog: 'Blog',
      press: 'Imprensa',
      carrers: 'Carreira',
      contact: 'Contato',
    },
  },

  plantingStages: {
    stages: 'Etapas',
    plantedTrees: 'Árvores plantadas em',
    thousandTrees: 'mil árvores',
  },

  purchaseButtonWithDisclaimer: {
    loading: 'Carregando',
    agree: 'Confirmo a compra conforme os',
    terms: 'termos e condições de uso',
  },

  responsibleConsultant: {
    consultant: 'Consultor',
    avatarImageAlt: 'Avatar de um funcionário da Abundance.',
    role: 'Biólogo',
  },

  statusIndicator: {
    noPlan: {
      title: 'Você não possuí um plano de assinatura',
      buttonText: 'Conheça os planos',
      iconAlt: 'Placa de aviso triangular com sinal de exclamação no centro.',
    },
    withPlan: {
      title: 'Plano atual: ',
      buttonText: 'Alterar plano',
      iconAlt: 'Silhueta de uma árvore.',
    },
    monthlyTree: 'árvore mensal',
    monthlyTrees: 'árvores mensais',
  },

  tokenOverview: {
    treesTip: 'Árvores',
    claimedTokensTip: 'ATKs',
    tonsTip: 'CO2 absorvidos',
    moneyTip: 'Reais',
  },
};
