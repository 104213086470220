import { Navbar } from '@abundance-brasil-wabi/sabi';

import { SideMenu } from 'components/Page';
import { useNavbarIsOpen } from 'state/navbar';

const LeftMenuNavbar: React.FC = () => {
  const isOpen = useNavbarIsOpen('LeftMenu');

  return (
    <Navbar isOpen={isOpen}>
      <SideMenu mode="expanded" />
    </Navbar>
  );
};

export default LeftMenuNavbar;
