import { styled } from 'styled-components';

import { breakpointDown, getThemeColor } from '@abundance-brasil-wabi/sabi';

import CarbonCompensationCalculator from 'components/CarbonCompensationCalculator';
import CarbonOffsetSummary from 'components/CarbonOffsetSummary';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > :first-child {
    flex: 1;
  }

  & > :not(:first-child) {
    border-left: none;
  }

  ${breakpointDown('xl')`
    flex-direction: column;

    & > :not(:first-child) {
      border: 1px solid ${getThemeColor('border', 'default', 'primary')};
      border-top: none;
    }
  `}
`;

export const OffsetSummary = styled(CarbonOffsetSummary)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${breakpointDown('xl')`
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

export const CompensationCalculator = styled(CarbonCompensationCalculator)`
  width: 544px;
  max-width: 50%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ${breakpointDown('xl')`
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
`;
