import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import useGetSearchParams from 'hooks/useGetSearchParams';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import { useResetPassword } from 'state/user';

import { createNewPasswordFormSchema } from './constants';
import { CreateNewPasswordFormSchema } from './types';

export type UseCreateNewPasswordForm =
  FormReturn<CreateNewPasswordFormSchema> & {
    isLoading: boolean;
    onSubmit: () => void;
  };

export function useCreateNewPasswordForm(): UseCreateNewPasswordForm {
  const { t } = useTranslation('pages', {
    keyPrefix: 'createNewPassword:createNewPasswordForm',
  });
  const { mutate: resetPassword, isLoading } = useResetPassword();
  const { addToast } = useToast();
  const [email, token] = useGetSearchParams(['email', 'token']);
  const navigate = useNavigate();

  const form = useForm<CreateNewPasswordFormSchema>({
    resolver: zodResolver(createNewPasswordFormSchema),
    mode: 'all',
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    ({
      newPassword,
      newPasswordConfirm,
    }: CreateNewPasswordFormSchema): void => {
      resetPassword(
        {
          body: {
            email,
            token,
            password: newPassword,
            passwordConfirm: newPasswordConfirm,
          },
        },
        {
          onSuccess: () => {
            navigate(getPathnameFromKey('createNewPasswordSuccess'));
          },
          onError: () => {
            addToast({
              message: t('error'),
              state: 'error',
            });
          },
        },
      );
    },
    [addToast, email, navigate, resetPassword, t, token],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
