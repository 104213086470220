import { useMemo } from 'react';
import { cloneDeep, forEach } from 'lodash';
import { useTranslation } from 'react-i18next';

import { InstitutionalMenuContentsItems, MenuItemTitle } from './types';

export type UseGetInstitutionalMenuContents = {
  menuContents: InstitutionalMenuContentsItems | null;
  menuItems: MenuItemTitle[];
};

export const INSTITUTIONAL_MENU_CONTENTS: InstitutionalMenuContentsItems | null =
  null;

// Content example to be used as value for INSTITUTIONAL_MENU_CONTENTS:
// {
// community: {
//   key: 'community',
//   items: [
//     {
//       id: 'tribe',
//       title: 'tribe',
//       url: '',
//       previewImageSrc: 'https://i.ibb.co/HPjZKJG/1.png',
//       previewImageAlt: 'tribe',
//     },
//     {
//       id: 'festivalsAndEvents',
//       title: 'festivalsAndEvents',
//       url: '',
//       previewImageSrc: 'https://i.ibb.co/HPjZKJG/1.png',
//       previewImageAlt: 'festivalsAndEvents',
//     },
//   ],
// },
// }

export default function useGetInstitutionalMenuContents(): UseGetInstitutionalMenuContents {
  const { t } = useTranslation('components', {
    keyPrefix: 'page:useGetInstitutionalMenuContents',
  });

  const translatedMenuContents =
    useMemo((): InstitutionalMenuContentsItems | null => {
      if (!INSTITUTIONAL_MENU_CONTENTS) return null;

      const translated = cloneDeep(INSTITUTIONAL_MENU_CONTENTS);

      forEach(translated, (menu) => {
        forEach(menu.items, (item) => {
          item.title = t(item.title);
        });
      });

      return translated;
    }, [t]);

  const allTranslatedMenuItemTitles = useMemo((): MenuItemTitle[] => {
    const titles: MenuItemTitle[] = [];

    forEach(translatedMenuContents, (menu) => {
      titles.push({
        key: menu.key,
        title: t(menu.key),
      });
    });

    return titles;
  }, [t, translatedMenuContents]);

  return {
    menuContents: translatedMenuContents,
    menuItems: allTranslatedMenuItemTitles,
  };
}
