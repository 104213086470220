export default {
  auditDocuments: {
    documents: 'Documents',
    date: 'Date',
    responsible: 'Responsible',
    export: 'Export',

    constants: {
      auditDocuments: {
        projectExecution: {
          title: 'Restoration Project Execution Report',
          responsible: 'Fernando Pereira Guimarães',
          date: '03-08-22',
          fileToExport: 'Restoration Project Execution Report.pdf',
          fileName: 'restoringProject',
        },
        carbonEstimated: {
          title: 'Carbon Sequestration Estimate Report',
          responsible: 'Thiago Magalhães Meireles',
          date: '03-08-22',
          fileToExport: 'Carbon Sequestration Estimate Report.pdf',
          fileName: 'carbonSequestration',
        },
      },
    },
  },

  auroraVerdeHeader: {
    iconAlt:
      'Logo da floresta Aurora Verde. Setagrade orientação para cima colorida com um quadrado branco e ao fundo a floresta.',
    name: 'Forest',
    title: 'Aurora Verde',
  },

  backLink: {
    back: 'Back',
  },

  buyTree: {
    trees: 'Trees',
    unity: 'Unity',
    total: 'Total',
    contract: 'contract',
    buy: 'Buy',
    redirect: 'You will be redirected to the payment platform.',
  },

  carbonCompensationCalculator: {
    label: 'Tree quantity selector.',
    recalculate: 'Recalculate',
    buy: 'Buy trees',

    results: {
      of: 'of',
      carbon: 'Carbon emitted',
      trees: 'Trees',
      percentage: 'Percentage',
      value: 'Value',
    },
  },

  carbonOffsetSummary: {
    carbonOffsetSummaryHeader: {
      title: 'Carbon Offset',
      description: 'Calculated on',
      compensated: 'You have offset',
      emissions: 'of your emissions',
    },

    scopesResults: {
      of: 'of',
      scope: 'Scope',
      scope1: 'Emissions directly generated by your company.',
      scope2: 'Emissions associated with your energy consumption.',
      scope3: 'Indirect emissions linked to your supply chain.',
    },
  },

  cards: {
    informationalCards: {
      informationalCard: {
        until: 'until',
      },

      auroraVerdeInformationalCard: {
        subtitle: 'Aurora Verde',
        title: '1 million trees',
        iconAlt:
          'Aurora Verde forest logo. Upward pointing arrow colored with a gradient, on top of a white square and the forest in the background.',
        tagContent: '300k trees in 2024',
        description: 'Follow the development of the Aurora Verde forest.',
        secondaryButton: {
          text: 'Buy trees',
          props: {
            fileToExport: 'Restoration Project Execution Report.pdf',
            fileName: 'restoringProject',
          },
        },
        primaryButton: {
          disabledText: 'Buy trees',
          enabledText: 'Buy trees',
        },
      },

      environmentalRestorerInformationalCard: {
        subtitle: 'Certificate',
        title: 'Environmental Restorer',
        tagContent: 'Unavailable',
        description: 'Prove to the world that you are a forest investor.',
        secondaryButton: {
          text: 'See more',
        },
        primaryButton: {
          disabledText: 'Certificate unavailable',
          enabledText: 'View',
        },
      },

      metamaskInformationalCard: {
        subtitle: 'Tokenization',
        title: 'Abundance Tokens (ATKs)',
        tagContent: 'New!',
        description: 'Claim your ATKs with your Metamask cryptocurrency wallet',
        secondaryButton: {
          text: 'See more',
        },
        primaryButton: {
          disabledText: 'Soon',
          enabledText: 'Claim ATKs',
        },
      },
    },

    summaryCards: {
      impactSummaryCard: {
        title: 'Impact',
        emptyMessage: "You haven't absorbed your own environmental impact",
        start: 'From',
        from: 'From',
        until: 'to',
        since: 'since',
        secondaryButtonTitle: 'See more',
        emptyButtonTitle: 'Report unavailable',
        buttonTitle: 'Report',
      },

      tokensSummaryCard: {
        title: 'Claimed ATKs',
        emptyMessage: 'No claimed token',
        message: 'Last rescue: XXXX',
        secondaryButtonTitle: 'See more',
        emptyButtonTitle: 'Unavailable claim',
        buttonTitle: 'Soon',
      },

      treeSummaryCard: {
        title: 'Trees',
        emptyMessage: 'You have no trees of your own',
        message: 'Aurora Verde',
        secondaryButtonTitle: 'See more',
        buttonTitle: 'Buy trees',
        virtualForests: 'Virtual forests',
      },
    },
  },

  claimHistory: {
    amount: 'Amount',
    value: 'Value',
    status: 'Status',
    date: 'Date',
  },

  claimToken: {
    iconAlt: 'Green coin with three trees in the center.',
    name: 'Claim',
    title: 'Abundance Tokens (ATKs)',
    availableTrees: 'Available trees',
    tokensClaim: 'ATKs claim',
    agree: 'I confirm the claim as per the',
    contract: 'contract',
    unavailable: 'unavailable',
    claim: 'Soon',
    redirect: 'You will be redirected to the claim tokens platform',
  },

  esgActing: {
    environment: 'Environment',
    social: 'Social',
    governance: 'Governance',
    esgActing: 'ESG Acting',
    environmentItem: {
      firstli: 'C02 absorption',
      secondli: 'Contribution to climate containment (avoid 1.5ºC increase)',
      thirdli: 'New forests with biodiversity maximization',
      fourthli: 'Biodiversity generation',
      fifthli: 'Clean energy',
      sixthli: 'Paper saving in the operation',
      seventhli: 'Water treatment',
    },
    socialItem: {
      firstli: 'Generating jobs and income in the local community',
      secondli: 'Transfer of part of the profits to social projects',
      thirdli:
        'Motivation to current employees by encouraging environmental practices',
    },
    governanceItem: {
      firstli: 'Environmental Restorer stamp',
      secondli:
        'Contribution in obtaining environmental certificates, such as B Corporation',
      thirdli: 'Economic maturity with the adoption of Digital Wallet',
      fourthli: 'Transparency of ESG operations',
    },
  },

  forms: {
    taxInformationForm: {
      title: 'Tax identification number',
      description: 'Fill the info below',
      tin: 'Taxpayer identification number',
      country: 'Country',
    },
  },

  formFields: {
    password: {
      forgotPassword: 'I forgot my password',
    },

    phoneNumber: {
      phoneNumber: 'Number',
    },
  },

  modal: {
    modalList: {
      loading: 'Loading...',

      auroraVerdeModal: {
        mobileTitle: 'Aurora Verde',
      },
    },

    auroraVerdeModal: {
      auroraVerdeHeader: {
        auroraVerdeImageAlt:
          'Arrow pointing up, colored with a gradient of colors ranging from blue to green.',
        forest: 'Environmental restoration forest',
        treesAmount: '100 thousand trees planted in 2022',
        firstParagraph:
          'The Aurora Verde Forest project aims to help restore the environment, generate carbon credits and promote a sustainable economy.',
        secondParagraph:
          "Thousands of seedlings of native species have already been planted in the region. Let's hit the goal of 1 million trees together?",
        beARestorer: 'Be a restorer now',
        plantingArea: 'Planting area: 44 hectares',
        location: 'Location',
        forestProducer: 'Forest producer',
      },
      descriptionTitle:
        'The Aurora Verde project is a successful case in the purpose of greening Brazil through environmental restoration.',
      firstParagraph:
        'Contribute to this initiative that helps the environment: invest in tokens that correspond to a tree, receive the carbon credit linked to it and follow the development of your part of the forest.',
      oneMillionTrees: '1 million trees!',
      secondParagraph:
        'Our goal is to elevate and accelerate the best that Brazil can have: sustainability and environmental abundance.',
      thirdParagraph:
        'Abundance Tokens are backed by trees and issued to preserve each of them, ensuring they fulfill their life cycle. A tree sequesters, on average, 0.2 to 0.3 ton of CO2 in a period of 20 to 30 years. Thus, we can achieve significant value by forming a forest from the issuance of one million tokens.',
      fourthParagraph:
        'Invest in environmental restoration and the preservation of the environment by purchasing your token and have your share of Aurora Verde!',
      impactImageAlt:
        'Graph indicating the predicted absorption of a tree until 2052.',
    },

    buyTokensModal: {
      abundanceTokenImageAlt: 'Green coin with three trees in the center.',
      buyTokens: 'Buy Abundance Tokens',
      quantity: 'Quantity',
      buy: 'Buy',
      redirect: 'You will be redirected to the payment system',
    },

    otpValidationModal: {
      otpValidationForm: {
        title: 'Security verification',
        description:
          'To secure your account, complete the following verification.',
        invalidCode: 'Invalid code',
        codeError: 'Error while resending code',
        phoneVerification: 'Phone verification',
        codeResent: 'Code resent',
        typeCode: 'Enter the 6-digit code sent to',
        resendCode: 'Resend code',
        send: 'Send',
      },
    },

    passwordVerificationModal: {
      passwordVerificationForm: {
        title: 'Enter your password',
        description:
          'To protect your account data, please enter your password to access the ESG Portal',
        label: 'Password',
        send: 'Send',
        error: 'Error while validating password',
      },
    },

    subscriptionModal: {
      subscriptionModal: {
        title: 'Subscription plan',
        tree: 'Tree',
        trees: 'Trees',
        tinTitle: 'Tax identification',
        tinDescription: 'Fill the data below',
        country: 'Country',
        tin: 'Tax identification number',
        agree: 'I confirm the purchase as per the',
        terms: 'terms and conditions of use',
        subscribe: 'Purchase plan',
        footerText:
          'By clicking buy, you will be redirected to the payment platform.',
      },

      subscriptionSummary: {
        carbon2052: 'Carbon sequestration by 2032',
        unity: 'Unity',
      },
    },

    userAbsorptionReportModal: {
      fileName: 'Environmental Impact Report',
    },

    userCertificateModal: {
      fileName: 'Environmental Restorer Certificate',
    },

    virtualForestAbsorptionReportModal: {
      fileName: 'Environmental Impact Report',
    },

    virtualForestCertificateModal: {
      fileName: 'Environmental Restorer Certificate',
    },
  },

  navbars: {
    leftMenuInstitutionalNavbar: {
      menu: 'Menu',
    },

    metaMaskDisclaimerNavbar: {
      title: 'Tokenization',
      firstTitle: 'What is MetaMask?',
      firstParagraph:
        'Metamask is a crypto wallet & gateway to blockchain apps. Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets.',
      startButton: 'Get started',
      howToCreate: 'How to create a MetaMask wallet',
      step: 'Step',
      and: 'and',
      imageAlt: "MetaMask's page screnshot.",
      firstStep:
        'First you need to go to the Metamask website to install the browser extension. MetaMask is available for',
      secondStep:
        'You will be redirected to the MetaMask guide on how to create an account.',
      thirdStep:
        'MetaMask will ask if you want to import or create a wallet, click on Create a Wallet.',
      fourthStep: 'You need to create a password for MetaMask.',
      fifthStep:
        'A <strong>Secret Recovery Phrase</strong> is a 12-word phrase that is the "master key" of your wallet. It needs to be saved to a safe place in case you need to recover your account. Click Next.',
      sixthStep:
        'On this page you can see your <strong>Secret Recovery Phrase</strong> or press Remind me later.',
      seventhStep:
        'After setting up the wallet, you will be redirected to your wallet page where you can see all the information about it. <strong>Now you can connect your wallet to any website.</strong>',
    },

    rightMenuNavbar: {
      hi: 'Hi',
      out: 'Logout',
      userSettings: 'User',
      companySettings: 'Company',
      mediaKit: 'Environmental restorer',
      howToGetMyTokens: 'Environmental restorer',
      myCertificate: 'Certificate',
      howToGetMyCertificate: 'Certificate',
      esgReport: 'ESG Report',
      changePassword: 'Security',
    },
  },

  page: {
    footer: {
      privacyPolicy: 'Privacy policy',
      termsAndConditions: 'Terms and conditions',
      faq: 'FAQ',
      cookies: 'Cookies',
      copyright: 'Copyright © Abundance Brasil. All rights reserved.',
    },

    sideBanner: {
      title: "Restoring the planet's future now",
      subtitle:
        'The time has come to make a difference! Embrace the mission of restoring nature and help form new forests in Brazil for a better future for everyone.',
    },

    sideMenu: {
      home: 'Home',
      wallet: 'Wallet',
      impact: 'Impact',
      syntropyReports: 'Syntropy',
      articles: 'Articles',
      support: 'Support',
      subscriptionPlans: 'Plans',
    },

    titleBar: {
      endContent: {
        signIn: 'Sign in',
        createAccount: 'Create your account',
        mobileMenuAriaLabel: 'Account creation options',
      },
    },

    useGetInstitutionalMenuContents: {
      solution: 'Solution',
      forests: 'Forests',
      community: 'Community',
      ecosystem: 'Ecosystem',
      about: 'About',
      howItWorks: 'How it works',
      companies: 'Companies',
      people: 'People',
      forestProducer: 'Forest producer',
      partners: 'Partners',
      auroraVerdePlanting: 'Aurora Verde Planting',
      createYourForest: 'Create your forest',
      governance: 'Forest governance',
      tribe: 'Abundance Tribe',
      festivalsAndEvents: 'Festivals and events',
      syntropy: 'Syntropy',
      restorers: 'Environmental restorers',
      abundanceBrasil: 'Abundance Brasil',
      blog: 'Blog',
      press: 'Press',
      carrers: 'Careers',
      contact: 'Contact',
    },
  },

  plantingStages: {
    stages: 'Stages',
    plantedTrees: 'Trees planted in',
    thousandTrees: 'thousand trees',
  },

  purchaseButtonWithDisclaimer: {
    loading: 'Loading',
    agree: 'I confirm the purchase as per the',
    terms: 'terms and conditions of use',
  },

  responsibleConsultant: {
    consultant: 'Consultant',
    avatarImageAlt: 'Avatar of an Abundance employee.',
    role: 'Biologist',
  },

  statusIndicator: {
    noPlan: {
      title: 'You do not have a subscription plan',
      buttonText: 'Check out the plans',
    },
    withPlan: {
      title: 'Current plan: ',
      buttonText: 'Change',
    },
    monthlyTree: 'monthly tree',
    monthlyTrees: 'monthly trees',
  },

  tokenOverview: {
    treesTip: 'Trees',
    claimedTokensTip: 'ATKs',
    tonsTip: 'Absorbed CO2',
    moneyTip: 'Reais',
  },
};
