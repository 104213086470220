import { ErrorMessage } from 'api/services/types';

export function getErrorMessage(error: ErrorMessage): string {
  const errorData = error?.response?.data;

  if (!errorData) return '';

  const { Messages, errors } = errorData;

  if (Messages) return errorData.Messages[0];

  if (errors) {
    const firstErrorKey = Object.keys(errors)[0];

    return errors[firstErrorKey][0];
  }

  return '';
}
