import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import {
  CarbonOffsetCalculation,
  getCarbonOffsetCalculation,
} from 'api/services';
import { useUserId } from 'state/user';

import { carbonOffestQueryKeys } from './queryKeys';
import { carbonOffsetCalculationByIdSelector } from './selectors';
import { CarbonOffsetCalculationQueryKey } from './types';

export default function useGetCarbonOffsetCalculation<
  TData = CarbonOffsetCalculation,
>(
  id?: string,
  options?: QueryOptions<
    CarbonOffsetCalculation,
    TData,
    CarbonOffsetCalculationQueryKey
  >,
) {
  const { data: userId } = useUserId();

  return useQuery<
    CarbonOffsetCalculation,
    unknown,
    TData,
    CarbonOffsetCalculationQueryKey
  >(
    carbonOffestQueryKeys.carbonOffsetCalculation(userId, id),
    async () => {
      if (!id) return;

      return getCarbonOffsetCalculation({ params: { id } });
    },
    {
      ...options,
      enabled: Boolean(id),
    },
  );
}

export const useCarbonOffsetCalculationById = (id?: string) =>
  useGetCarbonOffsetCalculation(id, {
    select: carbonOffsetCalculationByIdSelector,
  });
