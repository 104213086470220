import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import useChangePassword from 'state/user/useForgotPassword';
import { createSearchParams } from 'utils/formatting';

import { forgotPasswordFormSchema } from './constants';
import { ForgotPasswordFormSchema } from './types';

export type UseForgotPasswordForm = FormReturn<ForgotPasswordFormSchema> & {
  isLoading: boolean;
  onSubmit: () => void;
};

export function useForgotPasswordForm(): UseForgotPasswordForm {
  const { mutate: forgotPassword, isLoading } = useChangePassword();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const form = useForm<ForgotPasswordFormSchema>({
    resolver: zodResolver(forgotPasswordFormSchema),
    mode: 'all',
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    ({ email }: ForgotPasswordFormSchema): void => {
      forgotPassword(
        {
          body: { email },
        },
        {
          onSuccess: () => {
            navigate({
              pathname: getPathnameFromKey('createNewPasswordConfirm'),
              search: createSearchParams({
                email,
              }),
            });
          },
          onError: (error) => {
            addToast({
              message: getErrorMessage(error),
              state: 'error',
            });
          },
        },
      );
    },
    [addToast, forgotPassword, navigate],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
