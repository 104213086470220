import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  getThemeColor,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const ToggleButton = styled(Button).attrs({
  variant: 'text',
})`
  width: 24px;
  height: 24px;
`;

export const ToggleIcon = styled.span`
  font-size: 24px;
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const ForgotButton = styled(LinkButton).attrs({
  variant: 'text',
})`
  font-size: 13px;

  ${breakpointDown('xxxs')`
    font-size: 10px;
  `}
`;
