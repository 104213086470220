import { withTranslation } from 'react-i18next';
import {
  MdOutlineAssignmentInd,
  MdOutlineDirectionsCar,
  MdOutlineMapsHomeWork,
} from 'react-icons/md';

import { InfoTile } from '@abundance-brasil-wabi/sabi';

import { CarbonOffsetCalculationScope } from 'api/services';
import { WithTranslation } from 'i18n/types';

import * as S from './styles';

export type ScopesResultsProps = {
  scope1?: CarbonOffsetCalculationScope;
  scope2?: CarbonOffsetCalculationScope;
  scope3?: CarbonOffsetCalculationScope;
};

const ScopesResults: React.FC<WithTranslation<ScopesResultsProps>> = ({
  scope1,
  scope2,
  scope3,
  t,
}) => {
  return (
    <S.Root>
      <S.ScopeInfo colorTheme="tertiary">
        <InfoTile.IconContainer>
          <MdOutlineAssignmentInd />
        </InfoTile.IconContainer>

        <InfoTile.Title>{t('scope')} 1</InfoTile.Title>

        <InfoTile.MainContent>{t('scope1')}</InfoTile.MainContent>

        <S.EndContent>
          <S.Amount>{scope1?.percentageOfTotal}%</S.Amount> {scope1?.emissions}t{' '}
          {t('of')} CO₂e
        </S.EndContent>
      </S.ScopeInfo>

      <S.ScopeInfo colorTheme="secondary">
        <InfoTile.IconContainer>
          <MdOutlineDirectionsCar />
        </InfoTile.IconContainer>

        <InfoTile.Title>{t('scope')} 2</InfoTile.Title>

        <InfoTile.MainContent>{t('scope2')}</InfoTile.MainContent>

        <S.EndContent>
          <S.Amount>{scope2?.percentageOfTotal}%</S.Amount> {scope2?.emissions}t{' '}
          {t('of')} CO₂e
        </S.EndContent>
      </S.ScopeInfo>

      <S.ScopeInfo colorTheme="warning">
        <InfoTile.IconContainer>
          <MdOutlineMapsHomeWork />
        </InfoTile.IconContainer>

        <InfoTile.Title>{t('scope')} 3</InfoTile.Title>

        <InfoTile.MainContent>{t('scope3')}</InfoTile.MainContent>

        <S.EndContent>
          <S.Amount>{scope3?.percentageOfTotal}%</S.Amount> {scope3?.emissions}t{' '}
          {t('of')} CO₂e
        </S.EndContent>
      </S.ScopeInfo>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'carbonOffsetSummary:scopesResults',
})(ScopesResults);
