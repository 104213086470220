import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { ClaimHistory } from 'api/services';
import { getClaimHistory } from 'api/services/claimHistory/claimHistory';

import { claimQueryKeys } from './queryKeys';
import { claimHistorySelector } from './selectors';
import { ClaimHistoryQueryKey } from './types';

export default function useGetClaimHistory<TData = ClaimHistory[]>(
  options?: QueryOptions<ClaimHistory[], TData, ClaimHistoryQueryKey>,
) {
  return useQuery<ClaimHistory[], unknown, TData, ClaimHistoryQueryKey>(
    claimQueryKeys.claimHistory(),
    getClaimHistory,
    options,
  );
}

export const useClaimHistory = () =>
  useGetClaimHistory({
    select: claimHistorySelector,
  });
