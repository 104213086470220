import { Key, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ValueLabel } from 'types/common';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { useValueLabelCountryList } from 'state/country';

import { taxInformationFormSchema } from './constants';
import { TaxInformationFormSchema } from './types';

export type UseTaxInformationFormConfig = {
  onCountryChange: (newCountry: string) => void;
  onTinChange: (newTin: string) => void;
  onValidChange: (isValid: boolean) => void;
};

export type UseTaxInformationForm = FormReturn<TaxInformationFormSchema> & {
  countriesList: ValueLabel<string>[];
  onCountryChange: (newCountry: Key) => void;
  onTinChange: (newTin: string) => void;
};

export function useTaxInformationForm({
  onCountryChange,
  onTinChange,
  onValidChange,
}: UseTaxInformationFormConfig): UseTaxInformationForm {
  const { data: countriesList = [] } = useValueLabelCountryList();

  const form = useForm<TaxInformationFormSchema>({
    resolver: zodResolver(taxInformationFormSchema),
    mode: 'all',
  });

  const { formState, trigger, setValue } = form;

  const handleCountryChange = useCallback(
    (newCountry: Key): void => {
      const countryAsString = newCountry as string;

      setValue('country', countryAsString);
      onCountryChange(countryAsString);
      trigger();
    },
    [onCountryChange, setValue, trigger],
  );

  const handleTinChange = useCallback(
    (newTin: string): void => {
      onTinChange(newTin);
      setValue('taxIdentificationNumber', newTin);
      trigger();
    },
    [onTinChange, setValue, trigger],
  );

  useEffect(() => {
    onValidChange(formState.isValid);
  }, [formState.isValid, onValidChange]);

  return {
    countriesList,
    onCountryChange: handleCountryChange,
    onTinChange: handleTinChange,
    ...form,
  };
}
