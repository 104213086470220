import { css, styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getStampColor,
  getThemeColor,
  getThemeFontWeight,
  Link,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledBackgroundImageProps, StyledSyntropyCardProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})`
  flex-grow: 1;
`;

export const SyntropyLink = styled(Link).attrs({
  variant: 'text',
  target: '_blank',
})`
  width: 100%;
  height: 244px;
  border-radius: 8px;
  color: transparent;
`;

export const Contents = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'space-between',
})`
  width: 100%;
  height: 100%;

  & > :not(:first-child) {
    margin-top: 0;
  }
`;

export const Header = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: 2,
})`
  width: 100%;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  background-color: ${getThemeColor('white')};

  @media (max-width: 360px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'flex-start',
    })};

    & > :not(:first-child) {
      margin-top: 8px;
      margin-left: 0;
    }
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 50%;
  padding: 4px;
  background-color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    width: 42px;
    height: 42px;
  `}
`;

export const Title = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Sector = styled(Text).attrs({
  level: 'caption',
})`
  text-align: left;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const StampText = styled(Text).attrs({
  level: 'caption',
})<StyledSyntropyCardProps>`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};

  ${({ $stamp }) => getStampColor($stamp)}
`;

export const BackgroundImage = styled.div<StyledBackgroundImageProps>`
  width: 100%;
  height: -webkit-fill-available;
  border-left: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-right: 1px solid ${getThemeColor('border', 'default', 'primary')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  ${({ $backgroundImage }) => css`
    background-image: url(${$backgroundImage});
  `}
`;

export const Footer = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})`
  width: 100%;
  padding: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  background-color: ${getThemeColor('white')};

  @media (max-width: 360px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'center',
    })};

    height: 52px;

    & > :not(:first-child) {
      margin-left: 0;
      margin-top: 4px;
    }
  }
`;

export const LastPurchase = styled(Text).attrs({
  level: 'caption',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const LastPurchaseDate = styled(Text).attrs({
  level: 'caption',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const TreesAmount = styled(Text).attrs({
  level: 'caption',
})<StyledSyntropyCardProps>`
  font-weight: ${getThemeFontWeight('bold')};

  ${({ $stamp }) => getStampColor($stamp)}
`;
