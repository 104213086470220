/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, AnyAction, Dispatch } from 'redux';

import { ModalName, PushModalAction } from 'components/Modal/types';

export enum ModalAction {
  PUSH_MODAL = 'PUSH_MODAL',
  SET_ON_HIDE_MODAL_VALUE = 'SET_ON_HIDE_MODAL_VALUE',
  HIDE_MODAL = 'HIDE_MODAL',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export enum ModalsList {
  AURORA_VERDE_MODAL = 'AURORA_VERDE_MODAL',
  BUY_TREES_MODAL = 'BUY_TREES_MODAL',
  CLAIM_TOKEN_MODAL = 'CLAIM_TOKEN_MODAL',
  OTP_VALIDATION_MODAL = 'OTP_VALIDATION_MODAL',
  PASSWORD_VERIFICATION_MODAL = 'PASSWORD_VERIFICATION_MODAL',
  SUBSCRIPTION_PLAN_MODAL = 'SUBSCRIPTION_PLAN_MODAL',
  USER_ABSORPTION_REPORT_MODAL = 'USER_ABSORPTION_REPORT_MODAL',
  USER_CERTIFICATE_MODAL = 'USER_CERTIFICATE_MODAL',
  VIRTUAL_FOREST_ABSORPTION_REPORT_MODAL = 'VIRTUAL_FOREST_ABSORPTION_REPORT_MODAL',
  VIRTUAL_FOREST_CERTIFICATE_MODAL = 'VIRTUAL_FOREST_CERTIFICATE_MODAL',
}

export type ModalState = {
  isModalOpen: boolean;
  name: ModalName | null;
  // Execeptions to the rule. There's no way to know the exact
  // type for these props. In order to make this more consistent
  // simply pass the correct type when selecting this value from
  // the Redux store.
  params?: any;
  onHideValue?: any;
};

export type ModalActionDispatch = {
  (action: PushModalAction): Promise<any>;
};

export type ModalReduxDispatch<A extends Action = AnyAction> =
  ModalActionDispatch & Dispatch<A>;
