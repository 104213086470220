import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { Key } from '@react-types/shared';

import { WithTranslation } from 'i18n/types';
import ClaimHistory from 'components/ClaimHistory';
import TabNavigator from 'components/TabNavigator';
import { TabNavigatorItem } from 'components/TabNavigator/types';
import useTabNavigation from 'hooks/useTabNavigation';
import { useClaimHistory } from 'state/claimHistory';
import { usePurchaseHistory } from 'state/purchase';

import PurchaseHistory from '../PurchaseHistory';
import * as S from './styles';

type TabItem = 'purchased' | 'claimed';

const WalletTransactionsHistory: React.FC<WithTranslation> = ({ t }) => {
  const { activeTab, setActiveTab } = useTabNavigation<Key>({
    defaultTabId: 'purchased',
  });

  const { data: purchaseHistory } = usePurchaseHistory();
  const { data: claimHistory } = useClaimHistory();

  const tabItems = useMemo((): TabNavigatorItem<TabItem>[] => {
    const tabs: TabNavigatorItem<TabItem>[] = [
      {
        id: 'purchased',
        label: t('purchased'),
      },
    ];

    if (claimHistory?.length)
      tabs.push({
        id: 'claimed',
        label: t('claimed'),
      });

    return tabs;
  }, [claimHistory?.length, t]);

  if (!purchaseHistory?.length) return null;

  return (
    <S.Root>
      <TabNavigator
        items={Object.values(tabItems)}
        activeTab={activeTab}
        setActive={setActiveTab}
      />
      <S.Table>
        <S.Item $show={activeTab === 'purchased'}>
          <PurchaseHistory />
        </S.Item>

        <S.Item $show={activeTab === 'claimed'}>
          <ClaimHistory />
        </S.Item>
      </S.Table>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'wallet:walletTransactionsHistory',
})(WalletTransactionsHistory);
