import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';
import { Key } from '@react-types/shared';

import { getErrorMessage } from 'api/http';
import { ErrorMessage } from 'api/services/types';
import { useSetPageConfig } from 'components/Page';
import useOnLanguageChange from 'hooks/useOnLanguageChange';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import {
  useGetVirtualForestById,
  useResetVirtualForestFiles,
} from 'state/virtualForest';

import { pushModal } from '../../../store/modules/modal/actions';
import { ModalsList } from '../../../store/modules/modal/types';
import { HeaderTabItem } from '../types';
import Header from './Header';
import Planting from './Planting';
import Start from './Start';
import * as S from './styles';

const ForestDetails: React.FC = () => {
  const { addToast } = useToast();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetVirtualForestFiles = useResetVirtualForestFiles();
  useSetPageConfig({ mode: 'fluid' });

  const { data: virtualForestData, isLoading } = useGetVirtualForestById(id, {
    onError: (error) => {
      addToast({
        message: getErrorMessage(error as ErrorMessage),
        state: 'error',
      });

      return navigate({
        pathname: getPathnameFromKey('syntropyCommunities'),
      });
    },
  });

  const [activeTab, setActiveTab] = useState<HeaderTabItem>('start');

  const handleTabChange = useCallback(
    (newTab: Key): void => {
      // 'start' and 'planting' are the two visible tabs.
      if (newTab === 'start' || newTab === 'planting') setActiveTab(newTab);

      if (newTab === 'report')
        dispatch(
          pushModal({
            name: ModalsList.VIRTUAL_FOREST_ABSORPTION_REPORT_MODAL,
            params: { virtualForestId: id },
          }),
        );

      if (newTab === 'certificate')
        dispatch(
          pushModal({
            name: ModalsList.VIRTUAL_FOREST_CERTIFICATE_MODAL,
            params: { virtualForestId: id },
          }),
        );
    },
    [dispatch, id],
  );

  useOnLanguageChange(() => {
    resetVirtualForestFiles(id);
  });

  return (
    <>
      <RenderWhen condition={isLoading}>
        <S.Loader>
          <Loader />
        </S.Loader>
      </RenderWhen>
      <RenderWhen condition={!isLoading}>
        <S.Root>
          {!!virtualForestData && (
            <>
              <Header
                virtualForestData={virtualForestData}
                changeTab={handleTabChange}
              />

              <S.ForestDetails>
                <RenderWhen condition={activeTab === 'start'}>
                  <Start {...virtualForestData} />
                </RenderWhen>

                <RenderWhen condition={activeTab === 'planting'}>
                  <Planting />
                </RenderWhen>
              </S.ForestDetails>
            </>
          )}
        </S.Root>
      </RenderWhen>
    </>
  );
};

export default ForestDetails;
