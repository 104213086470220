import { MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import {
  AbundanceDefaultMixedLogo,
  breakpointDown,
  Button,
  getThemeColor,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${getThemeColor('white')};
  z-index: 100;
`;

export const MenuButton = styled(Button)`
  height: 24px;
`;

export const MenuIcon = styled(MdMenu)`
  font-size: 24px;
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const AbundanceLinkIcon = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileLogo = styled(AbundanceDefaultMixedLogo)`
  ${breakpointDown('xxxs')`
    width: 132px;
  `}
`;

export const EndContentDesktop = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  ${breakpointDown('lg')`
    display: none;
  `}
`;

export const EndContentMobile = styled.div`
  display: none;

  ${breakpointDown('lg')`
    display: block;
  `}
`;

export const MenuItem = styled(LinkButton)`
  height: 40px;

  ${breakpointDown('sm')`
    width: 100%;
    text-align: start;
  `}
`;
