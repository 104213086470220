import { Dispatch, SetStateAction, useCallback } from 'react';

import { GOOGLE_RECAPTCHA_KEY_ID } from 'constants/env';

import * as S from './styles';

export type ReCaptchaProps = {
  verifiedAction: Dispatch<SetStateAction<boolean>> | (() => boolean);
};

const ReCaptcha: React.FC<ReCaptchaProps> = ({ verifiedAction }) => {
  const createHandleVerifiedAction = (isExpired: boolean) => (): void => {
    verifiedAction(isExpired);
  };

  const verifyRecaptcha = useCallback(
    (token: string | null): void => {
      if (token) verifiedAction(true);
    },
    [verifiedAction],
  );

  return (
    <S.Root>
      <S.Contents
        sitekey={GOOGLE_RECAPTCHA_KEY_ID}
        onExpired={createHandleVerifiedAction(false)}
        onChange={verifyRecaptcha}
      />
    </S.Root>
  );
};

export default ReCaptcha;
