import { AnyAction, Dispatch, Middleware } from 'redux';

import { ModalAction } from '../modules/modal/types';
import { ReduxAction } from '../types';
import store from '..';

type Resolve = (value: unknown) => void;

const HANDLED_ACTIONS: ModalAction[] = [
  ModalAction.PUSH_MODAL,
  ModalAction.HIDE_MODAL,
];

function checkCanHandleAction(actionType: ModalAction): boolean {
  return HANDLED_ACTIONS.includes(actionType);
}

/**
 * It returns a middleware that adds a promise resolver to an array when a modal is
 * pushed, and resolves the most recent promise when a modal is hidden.
 *
 * @returns A middleware function that returns another function.
 */
export const createModalMiddleware = (): Middleware => {
  const resolvers: Resolve[] = [];

  /**
   * It resolves the promise that was created when the modal was opened
   *
   * @returns The function resolveWithValue is being returned.
   */
  const resolveWithValue = (): void => {
    const { onHideValue } = store.getState().modal || {};
    const resolver = resolvers.pop();

    if (resolver) return resolver(onHideValue);

    return undefined;
  };

  return () =>
    (next: Dispatch<AnyAction>) =>
    (action: ReduxAction<ModalAction>) => {
      const result = next(action);

      if (!checkCanHandleAction(action?.type)) return result;

      // If a modal is pushed, add a new resolver to be
      // resolved when the modal is closed.
      if (action.type === ModalAction.PUSH_MODAL)
        return new Promise((resolve) => {
          resolvers.push(resolve);
        });

      // If a modal is hidden, resolve the most recent promise.
      if (action.type === ModalAction.HIDE_MODAL) return resolveWithValue();

      return result;
    };
};
