import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: '24px',
})`
  width: 100%;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};

  ${breakpointDown('lg')`
    padding: 24px;
    gap: 0;
  `}
`;

export const Title = styled(Heading).attrs({
  level: 6,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const TextSection = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: '8px',
})`
  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'flex-start',
      gap: '8px',
    })}
  `}
`;

export const Subtitle = styled(Heading).attrs({
  level: 6,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
