import { forwardRef } from 'react';

import PasswordVerificationForm from './PasswordVerificationForm';
import * as S from './styles';

const PasswordVerificationModal: React.FC = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_, _ref) => {
    return (
      <S.Root>
        <PasswordVerificationForm />
      </S.Root>
    );
  },
);

export default PasswordVerificationModal;
