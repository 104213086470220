import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { MdOutlineCloud } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import { VirtualForest } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { Modal } from 'components/Modal/types';
import { getPathnameFromKey } from 'router/utils';
import { useImpact } from 'state/tree';
import { useUserIsValidPhysicalPerson } from 'state/user';
import {
  useCurrentVirtualForest,
  useHasVirtualForests,
} from 'state/virtualForest';
import { formatDate } from 'utils/formatting';

import ExtraInfo from './ExtraInfo';
import * as S from './styles';
import SummaryCard from './SummaryCard';

const ImpactSummaryCard: React.FC<WithTranslation> = ({ t }) => {
  const { data: impact } = useImpact();
  const { data: currentVirtualForestsList } = useCurrentVirtualForest();
  const { data: isValidPhysicalPerson } = useUserIsValidPhysicalPerson();
  const { data: hasVirtualForests } = useHasVirtualForests();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tons = 0, until, since } = impact || {};

  const showVirtualForestsInfo = !isValidPhysicalPerson && hasVirtualForests;

  const handleImpactSecondaryButtonPress = useCallback((): void => {
    return navigate({ pathname: getPathnameFromKey('impact') });
  }, [navigate]);

  const getStartFinishDate = useCallback((): string => {
    if (!since) return '';

    if (!until) return `${t('start')} ${formatDate(since, 'fullDate')}`;

    return `${t('from')} ${formatDate(since, 'fullDate')} ${t(
      'until',
    )} ${formatDate(until, 'fullDate')}`;
  }, [since, t, until]);

  const createOpenModal = useCallback(
    (modalToOpen: Modal) => (): void => {
      dispatch(pushModal(modalToOpen));
    },
    [dispatch],
  );

  const handleRenderDescription = useCallback((): React.ReactNode => {
    return (
      <>
        {tons ? getStartFinishDate() : t('emptyMessage')}

        <RenderWhen condition={showVirtualForestsInfo}>
          <S.ContentWrapper>
            {currentVirtualForestsList?.map((forest: VirtualForest) => (
              <ExtraInfo
                key={forest.id}
                value={`${forest.tons} t`}
                to={`${getPathnameFromKey('syntropyCommunities')}/${forest.id}`}
              >
                {forest.title} {t('since')}
                {formatDate(forest.since, 'fullDate')}
              </ExtraInfo>
            ))}
          </S.ContentWrapper>
        </RenderWhen>
      </>
    );
  }, [
    currentVirtualForestsList,
    getStartFinishDate,
    showVirtualForestsInfo,
    t,
    tons,
  ]);

  return (
    <SummaryCard
      title={t('title')}
      icon={<MdOutlineCloud />}
      value={tons ? `${tons} t` : 0}
      description={handleRenderDescription()}
      primaryButtonTitle={tons ? t('buttonTitle') : t('emptyButtonTitle')}
      isPrimaryButtonDisabled={!tons && !until}
      onPrimaryButtonPress={createOpenModal({
        name: ModalsList.USER_ABSORPTION_REPORT_MODAL,
      })}
      secondaryButtonTitle={t('secondaryButtonTitle')}
      onSecondaryButtonPress={handleImpactSecondaryButtonPress}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:summaryCards:impactSummaryCard',
})(ImpactSummaryCard);
