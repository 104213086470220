import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import {
  useUserHasSubscriptionPlan,
  useUserSubscriptionPlan,
} from 'state/user';

import NoPlanMessage from './NoPlanMessage';
import PlanDescriptionMessage from './PlanDescriptionMessage';

const Header: React.FC = () => {
  const { data: hasSubscriptionPlan } = useUserHasSubscriptionPlan();
  const { data: subscriptionPlan } = useUserSubscriptionPlan();

  return (
    <div>
      <RenderWhen condition={!hasSubscriptionPlan}>
        <NoPlanMessage />
      </RenderWhen>

      <RenderWhen condition={hasSubscriptionPlan}>
        <PlanDescriptionMessage units={subscriptionPlan?.units} />
      </RenderWhen>
    </div>
  );
};

export default Header;
