import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import { abundanceURLs } from 'constants/externalURLs';

import * as S from './styles';

export type PurchaseButtonWithDisclaimerProps = {
  disclaimerAgreed: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  buttonText: string;
  onDisclaimerAgreed: (isAgreed: boolean) => void;
  onSubmit: () => void;
};

const PurchaseButtonWithDisclaimer: React.FC<
  WithTranslation<PurchaseButtonWithDisclaimerProps>
> = ({
  disclaimerAgreed,
  isDisabled,
  isLoading,
  buttonText,
  onDisclaimerAgreed,
  onSubmit,
  t,
}) => {
  const handleOnSubmit = useCallback((): void => {
    onSubmit();
  }, [onSubmit]);

  return (
    <S.Root>
      <S.DisclaimerSection>
        <S.CheckboxContainer
          isChecked={disclaimerAgreed}
          onChange={onDisclaimerAgreed}
          label={
            <S.Disclaimer>
              {t('agree')}{' '}
              <S.TermsButton href={abundanceURLs.termsAndConditions}>
                {t('terms')}
              </S.TermsButton>
              .
            </S.Disclaimer>
          }
        />
      </S.DisclaimerSection>

      <S.BuyButton
        isDisabled={isDisabled}
        onPress={handleOnSubmit}
        isLoading={isLoading}
        loadingMessage={t('loading')}
      >
        {buttonText}
      </S.BuyButton>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'purchaseButtonWithDisclaimer',
})(PurchaseButtonWithDisclaimer);
