import { AuditDocument } from '../../constants/types';

const locale = 'constants:auditDocuments';

export const auditDocuments: AuditDocument[] = [
  {
    title: `${locale}:projectExecution:title`,
    responsible: `${locale}:projectExecution:responsible`,
    date: `${locale}:projectExecution:date`,
    fileToExport: `${locale}:projectExecution:fileToExport`,
    fileName: `${locale}:projectExecution:fileName`,
  },
  {
    title: `${locale}:carbonEstimated:title`,
    responsible: `${locale}:carbonEstimated:responsible`,
    date: `${locale}:carbonEstimated:date`,
    fileToExport: `${locale}:carbonEstimated:fileToExport`,
    fileName: `${locale}:carbonEstimated:fileName`,
  },
];
