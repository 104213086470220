import { defaultTheme, WabiSabiProvider } from '@abundance-brasil-wabi/sabi';

export type ThemeProviderProps = {
  children?: React.ReactNode;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return <WabiSabiProvider theme={defaultTheme}>{children}</WabiSabiProvider>;
};

export default ThemeProvider;
