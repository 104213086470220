import { omit } from 'lodash';
import { Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { FlexDirection, RadioGroup } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import { DefaultFieldProps } from '../types';

export type RadioButtonGroupProps = WithTranslation<DefaultFieldProps> & {
  direction?: FlexDirection;
  fluid?: boolean;
  children: React.ReactNode;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  name = 'radio-group',
  label,
  control,
  formState,
  direction = 'row',
  fluid = true,
  children,
  t,
  ...props
}) => {
  const errors = formState?.errors?.[name];

  return (
    <Controller
      {...{ name, control }}
      render={({ field }) => {
        const { onChange } = field;

        return (
          <RadioGroup
            {...{ name, label, direction, fluid, onChange }}
            {...omit(props, 'i18n', 'tReady')}
            aria-labelledby={label}
            state={errors ? 'danger' : 'none'}
            feedbackMessage={t(errors?.message)}
          >
            {children}
          </RadioGroup>
        );
      }}
    />
  );
};

export default withTranslation()(RadioButtonGroup);
