import { AxiosResponse } from 'axios';

import { OTPValidationAction, RequestData, StartValidation } from './types';

export type OTPPurpose = {
  type: string;
  payload: {
    purpose: string;
  };
};

export type APIMethod = {
  type: string;
  payload: {
    method: string;
  };
};

export type ValidatePasswordAndPurpose = {
  type: string;
  payload: {
    purpose: string;
    password: string;
  };
};

export type RequestDataValues = {
  type: string;
  payload: {
    requestData: RequestData;
  };
};

export type PasswordSuccess = {
  type: string;
  payload: {
    password: string;
    passwordSuccess: boolean;
  };
};

export type TokenSuccess = {
  type: string;
  payload: {
    tokenSuccess: boolean;
  };
};

export type HasError = {
  type: string;
  payload: {
    hasError: boolean;
  };
};

export type OTPValidationSuccess = {
  type: string;
  payload: {
    otpValidatedSuccessfully: boolean;
  };
};

export type RemoteOTPValidation = {
  type: string;
  payload: {
    validationData: StartValidation;
  };
};

export type ValidationResponse = {
  type: string;
  payload: {
    response: AxiosResponse;
  };
};

export type Request = {
  type: string;
};

export function otpPurpose(purpose: string): OTPPurpose {
  return {
    type: OTPValidationAction.OTP_PURPOSE,
    payload: {
      purpose,
    },
  };
}

export function apiMethod(method: string): APIMethod {
  return {
    type: OTPValidationAction.API_METHOD,
    payload: {
      method,
    },
  };
}

export function validatePasswordAndPurposeRequest(
  purpose: string,
  password: string,
): ValidatePasswordAndPurpose {
  return {
    type: OTPValidationAction.VALIDATE_PASSWORD_AND_PURPOSE_VALIDATION,
    payload: {
      purpose,
      password,
    },
  };
}

export function requestData(requestDataValues: RequestData): RequestDataValues {
  return {
    type: OTPValidationAction.REQUEST_DATA,
    payload: {
      requestData: requestDataValues,
    },
  };
}

export function passwordSuccess(
  password: string,
  success: boolean,
): PasswordSuccess {
  return {
    type: OTPValidationAction.PASSWORD_SUCCESS,
    payload: {
      password,
      passwordSuccess: success,
    },
  };
}

export function tokenSuccess(success: boolean): TokenSuccess {
  return {
    type: OTPValidationAction.TOKEN_SUCCESS,
    payload: {
      tokenSuccess: success,
    },
  };
}

export function hasErrorHandler(hasError: boolean): HasError {
  return {
    type: OTPValidationAction.HAS_ERROR,
    payload: {
      hasError,
    },
  };
}

export function otpValidationSuccess(success: boolean): OTPValidationSuccess {
  return {
    type: OTPValidationAction.OTP_VALIDATION_SUCCESS,
    payload: {
      otpValidatedSuccessfully: success,
    },
  };
}

export function remoteOTPValidation(
  validationData: StartValidation,
): RemoteOTPValidation {
  return {
    type: OTPValidationAction.REMOTE_OTP_VALIDATION,
    payload: {
      validationData,
    },
  };
}

export function validationResponse(
  response: AxiosResponse,
): ValidationResponse {
  return {
    type: OTPValidationAction.VALIDATION_RESPONSE,
    payload: {
      response,
    },
  };
}

export function otpValidationReset(): Request {
  return {
    type: OTPValidationAction.OTP_VALIDATION_RESET,
  };
}
