import { Control, Controller } from 'react-hook-form';

import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '@abundance-brasil-wabi/sabi';

export type CheckboxProps = BaseCheckboxProps & {
  defaultValue?: boolean;
  // Control can have any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, unknown>;
};

const Checkbox: React.FC<CheckboxProps> = ({
  name = 'checkbox',
  label,
  defaultValue = false,
  isDisabled,
  control,
  onChange,
}) => {
  return (
    <Controller
      {...{ name, defaultValue, control }}
      render={({ field }) => {
        const { onChange: onValueChange, value } = field;

        return (
          <BaseCheckbox
            {...field}
            {...{ label, isDisabled }}
            isChecked={!!value}
            onChange={(isChecked) => {
              onChange?.(isChecked);
              onValueChange(isChecked);
            }}
          />
        );
      }}
    />
  );
};

export default Checkbox;
