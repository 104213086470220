import { CarbonOffsetCalculation } from 'api/services';

import CarbonOffsetSummaryHeader from './CarbonOffsetSummaryHeader';
import ScopesResults from './ScopesResults';
import * as S from './styles';

export type CarbonOffsetSummaryProps = {
  calculation?: CarbonOffsetCalculation;
  className?: string;
};

const CarbonOffsetSummary: React.FC<CarbonOffsetSummaryProps> = ({
  calculation,
  className,
}) => {
  return (
    <S.Root className={className}>
      <CarbonOffsetSummaryHeader
        calculationDate={calculation?.calculationDate}
        compensationPercentage={
          calculation?.compensationInfo?.compensationPercentage
        }
      />

      <ScopesResults
        scope1={calculation?.scope1}
        scope2={calculation?.scope2}
        scope3={calculation?.scope3}
      />
    </S.Root>
  );
};

export default CarbonOffsetSummary;
