import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

export type PageBannerProps = {
  title: string;
  description: string;
};

const PageBanner: React.FC<WithTranslation<PageBannerProps>> = ({
  title,
  description,
  t,
}) => {
  return (
    <S.Root>
      <S.Content>
        <S.Icon />

        <S.Elements>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>

          <S.GoToSupportButton to={getPathnameFromKey('support')} fluidHeight>
            {t('talkWithConsultant')}
          </S.GoToSupportButton>
        </S.Elements>
      </S.Content>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:pageBanner',
})(PageBanner);
