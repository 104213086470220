import { useVirtualForestCertificate } from 'state/virtualForest';

import PDF from '../PDF';

export type VirtualForestCertificateProps = {
  virtualForestId: string;
};

const VirtualForestCertificate: React.FC<VirtualForestCertificateProps> = ({
  virtualForestId,
}) => {
  const { data: certificateFile } =
    useVirtualForestCertificate(virtualForestId);

  if (!certificateFile) return null;

  return <PDF pdfFile={certificateFile} />;
};

export default VirtualForestCertificate;
