import { Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import {
  TextArea as TextAreaBase,
  TextAreaProps,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

const TextArea: React.FC<WithTranslation<TextAreaProps>> = ({
  name = 'text',
  label,
  placeholder,
  defaultValue = '',
  control,
  formState,
  fluid,
  startContent,
  endContent,
  isDisabled,
  className,
  onChange,
  t,
}) => {
  const errors = formState?.errors?.[name];

  return (
    <Controller
      {...{ name, defaultValue, control }}
      render={({ field }) => {
        const { onChange: onValueChange } = field;

        return (
          <TextAreaBase
            {...field}
            {...{
              label,
              placeholder,
              fluid,
              startContent,
              endContent,
              isDisabled,
              className,
            }}
            onChange={(value: unknown) => {
              onChange?.(value);
              onValueChange(value);
            }}
            aria-labelledby={name}
            state={errors ? 'danger' : 'none'}
            feedbackMessage={t(errors?.message)}
          />
        );
      }}
    />
  );
};

export default withTranslation()(TextArea);
