import { useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';

import { Key } from '@react-types/shared';

import { VirtualForest } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { TabNavigatorItem } from 'components/TabNavigator/types';
import useTabNavigation from 'hooks/useTabNavigation';
import { HeaderTabItem } from 'pages/Syntropy';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';
import Summary from './Summary';

export type HeaderProps = {
  virtualForestData: VirtualForest;
  changeTab: (newActiveTab: Key) => void;
};

const Header: React.FC<WithTranslation<HeaderProps>> = ({
  virtualForestData,
  changeTab,
  t,
}) => {
  const { icon, backgroundImage, title } = virtualForestData;

  const { activeTab, setActiveTab } = useTabNavigation<HeaderTabItem>({
    defaultTabId: 'start',
  });

  const tabItems = useMemo((): TabNavigatorItem<HeaderTabItem>[] => {
    return [
      {
        id: 'start',
        label: t('start'),
      },
      {
        id: 'planting',
        label: t('planting'),
      },
      {
        id: 'report',
        label: t('report'),
      },
      {
        id: 'certificate',
        label: t('certificate'),
      },
    ];
  }, [t]);

  const handleNewActiveTab = useCallback(
    (newActiveTab: Key): void => {
      // 'start' and 'planting' are the two visible tabs.
      if (newActiveTab === 'start' || newActiveTab === 'planting')
        setActiveTab(newActiveTab);

      changeTab(newActiveTab);
    },
    [changeTab, setActiveTab],
  );

  return (
    <S.Root>
      <S.BackgroundImage $backgroundImage={backgroundImage} />

      <S.BackButton to={getPathnameFromKey('syntropyCommunities')}>
        <S.BackButtonContent>
          <MdArrowBack />
          <span>{t('back')}</span>
        </S.BackButtonContent>
      </S.BackButton>

      <S.HeaderInfo>
        <S.HeaderIcon src={icon} alt={t('logoImageAlt')} />

        <S.InfoSection>
          <S.HeaderInfoContainer>
            <S.HeaderDetails>
              <S.HeaderTitle>{title}</S.HeaderTitle>

              <Summary {...virtualForestData} />
            </S.HeaderDetails>
          </S.HeaderInfoContainer>

          <S.Tab
            items={Object.values(tabItems)}
            activeTab={activeTab}
            setActive={handleNewActiveTab}
          />
        </S.InfoSection>
      </S.HeaderInfo>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:forestDetails:header:header',
})(Header);
