import { CardsList } from '@abundance-brasil-wabi/sabi';

import { ArticleCard } from 'components/Cards';
import { useSetPageConfig } from 'components/Page';
import { useArticlesList } from 'state/article';

const Articles: React.FC = () => {
  const { data: articlesList, isLoading } = useArticlesList();
  useSetPageConfig({ mode: 'fluid' });

  return (
    <CardsList mode="full" isLoading={isLoading}>
      {articlesList?.map((article) => (
        <ArticleCard key={article.link} {...article} />
      ))}
    </CardsList>
  );
};

export default Articles;
