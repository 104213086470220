import { useSetPageConfig } from 'components/Page';
import { useGetCurrentUserSubscriptionPlan } from 'state/subscriptionPlan';

import Footer from './Footer';
import Header from './Header';
import * as S from './styles';
import SubscriptionPlanCardsList from './SubscriptionPlanCards';

const SubscriptionPlans: React.FC = () => {
  const { isLoading } = useGetCurrentUserSubscriptionPlan();
  useSetPageConfig({
    mode: 'fluid',
    override: {
      isLoading,
    },
  });

  return (
    <S.Root>
      <Header />

      <SubscriptionPlanCardsList />

      <Footer />
    </S.Root>
  );
};

export default SubscriptionPlans;
