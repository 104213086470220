import { useEffect, useState } from 'react';

import { useDelay } from '@abundance-brasil-wabi/sabi';

import { useImpact } from 'state/tree';
import { useUser } from 'state/user';

export type UseUserInfoLoader = {
  isUserInfoLoaded: boolean;
};

export default function useUserInfoLoader(): UseUserInfoLoader {
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);

  const { isFetched: isUserFetched } = useUser();
  const { isFetched: isImpactFetched } = useImpact();

  const [delayedOnLoadingComplete] = useDelay(
    () => setIsUserInfoLoaded(true),
    200,
  );

  useEffect(() => {
    if (isUserFetched && isImpactFetched && !isUserInfoLoaded)
      delayedOnLoadingComplete();
  }, [
    delayedOnLoadingComplete,
    isImpactFetched,
    isUserFetched,
    isUserInfoLoaded,
  ]);

  return {
    isUserInfoLoaded,
  };
}
