import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > svg {
    font-size: 24px;
  }
`;

export const QuestionContainer = styled(Spacer).attrs({
  direction: 'column',
})`
  width: 100%;
`;

export const QuestionTitle = styled(Text).attrs({
  level: 'subtitle',
})`
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const LegalText = styled(Text).attrs({
  level: 'body',
})`
  color: ${getThemeColor('text', 'danger')};
  font-weight: ${getThemeFontWeight('regular')};
`;
