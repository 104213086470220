import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import AuditDocuments from 'components/AuditDocuments';
import ESGActing from 'components/ESGActing';
import ResponsibleConsultant from 'components/ResponsibleConsultant';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
`;

export const Audit = styled(AuditDocuments).attrs({
  size: 'small',
})`
  padding: 0;
  border: none;
  border-radius: 0;
`;

export const Plating = styled.div`
  ${createFlexDisplay({
    align: 'flex-start',
    justify: 'space-between',
    gap: '24px',
  })};

  width: 100%;
  flex: 1 0 auto;

  @media (max-width: 1566px) {
    padding: 0;
  }

  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '16px',
    })};

    width: 100%;
    left: 0;
    top: 0;
  `}
`;

export const Left = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
  gap: '24px',
})`
  width: 65%;

  ${breakpointDown('lg')`
    width: 100%;
    gap: 16px;

    & > :not(:first-child) {
      margin-top: 0;
    }
  `}
`;

export const Right = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
  gap: '24px',
})`
  width: 35%;
  padding: 31px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;

  ${breakpointDown('lg')`
    width: 100%;
    gap: 16px;
    padding: 24px;
  `}
`;

export const ESGActingItem = styled(ESGActing).attrs({
  size: 'small',
})`
  padding: 0;
  border: none;
  border-radius: 0;
`;

export const ResponsibleConsultantItem = styled(ResponsibleConsultant).attrs({
  size: 'small',
})`
  padding: 0;
  border: none;
  border-radius: 0;
`;
