import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Link,
} from '@abundance-brasil-wabi/sabi';

export const LinkButton = styled(Link)`
  height: auto;
  display: inline-block;
  text-decoration: underline;
  font-weight: ${getThemeFontWeight('bold')};
  font-size: 14px;
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const LanguageSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
