import { withTranslation } from 'react-i18next';
import { SVG } from 'types/common';

import { WithTranslation } from 'i18n/types';

import * as S from './styles';

export type BenefitProps = {
  title: string;
  value: string | number;
  icon: SVG;
};

const Benefit: React.FC<WithTranslation<BenefitProps>> = ({
  title,
  value,
  icon: Icon,
  t,
}) => {
  return (
    <S.Root>
      <S.Contents>
        <Icon width={24} height={24} />

        <S.Title>{t(title)}</S.Title>
      </S.Contents>

      <S.Value>{value}</S.Value>
    </S.Root>
  );
};

export default withTranslation()(Benefit);
