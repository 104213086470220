import { forwardRef } from 'react';

import ClaimToken from 'components/ClaimToken';

import * as S from './styles';

const ClaimTokensModal: React.FC = forwardRef(() => {
  return (
    <S.Root>
      <ClaimToken returnURL="home" />
    </S.Root>
  );
});

export default ClaimTokensModal;
