import { withTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';

import { WithTranslation } from 'i18n/types';
import { PathKey } from 'router/types';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

export type BackLinkProps = {
  to: PathKey;
  search?: string;
};

const BackLink: React.FC<WithTranslation<BackLinkProps>> = ({
  to,
  search,
  t,
}): React.ReactNode => {
  return (
    <S.Root to={getPathnameFromKey(to)} search={search}>
      <MdArrowBack />

      {t('back')}
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'backLink',
})(BackLink);
