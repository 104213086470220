import { useEffect } from 'react';

import i18n from 'i18n/i18n';

/**
 * Actions to trigger when the application language changes.
 */
export default function useOnLanguageChange(callback: (lng: string) => void) {
  useEffect(() => {
    i18n.on('languageChanged', callback);

    return () => {
      i18n.off('languageChanged', callback);
    };
  }, [callback]);
}
