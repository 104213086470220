import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import UserCertificate from 'components/PDF/UserCertificate';
import { useCertificate } from 'state/tree';
import { downloadFile } from 'utils/handlers';

import * as S from './styles';

const UserCertificateModal: React.FC<WithTranslation> = forwardRef(
  ({ t }, ref) => {
    const { data: certificateFile } = useCertificate();

    const handlePrint = useCallback((): void | null => {
      if (!certificateFile) return;

      downloadFile({
        file: certificateFile,
        fileName: t('fileName'),
      });
    }, [certificateFile, t]);

    useImperativeHandle(ref, () => ({
      handlePrint,
      content: () => null,
    }));

    return (
      <S.Root>
        <UserCertificate />
      </S.Root>
    );
  },
);

export default withTranslation('components', {
  keyPrefix: 'modal:userCertificateModal',
  withRef: true,
})(UserCertificateModal);
