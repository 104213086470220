import { withTranslation } from 'react-i18next';

import { Gallery } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import Description from 'components/Description';
import GoogleMaps from 'components/GoogleMaps';
import PlantingStages from 'components/PlantingStages';
import { AURORA_VERDE_MODAL_GALLERY_IMAGES } from 'constants/galleryImages';

import Banner from '../Banner';
import * as S from './styles';

const Planting: React.FC<WithTranslation> = ({ t }: WithTranslation) => {
  return (
    <S.Root>
      <S.Plating>
        <S.Left>
          <Description.Root>
            <Description.Section
              title={t('title')}
              subtitle={t('firstSubtitle')}
              text={t('firstText')}
            />

            <Description.Section
              subtitle={t('secondSubtitle')}
              text={t('secondText')}
            />
          </Description.Root>

          <Gallery images={AURORA_VERDE_MODAL_GALLERY_IMAGES} />

          <GoogleMaps
            containerStyle={{
              width: '100%',
              height: '398px',
              borderRadius: '8px',
            }}
          />

          <PlantingStages />

          <Banner size="small" />
        </S.Left>

        <S.Right>
          <S.ESGActingItem />

          <S.Audit />

          <S.ResponsibleConsultantItem />
        </S.Right>
      </S.Plating>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:forestDetails:planting:planting',
})(Planting);
