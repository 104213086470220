import {
  AnnualFlightsFrequency,
  AverageMonthlyElectricityBillCost,
  CarbonCalculationQuestions,
  CreateCarbonOffsetCalculationBody,
  ElectricityFromRenewableSources,
  FuelTypesAndQuantitiesPerMonth,
  PercentageOfWasteRecycled,
  WorkModelAdopted,
} from 'api/services';
import { omitEmptyValues } from 'utils/formatting';

import { CarbonOffsetCalculatorFormSchema } from './types';

export function getCarbonOffsetCalculationPayload(
  enrollmentId: string,
  data: CarbonOffsetCalculatorFormSchema,
): CreateCarbonOffsetCalculationBody {
  const {
    numberOfEmployees,
    workModelAdopted,
    gasoline,
    gasolineLiters,
    diesel,
    dieselLiters,
    ethanol,
    ethanolLiters,
    gnv,
    gnvLiters,
    electricalVehicles,
    electricalVehiclesKwH,
    averageMonthlySpendingOnTaxiAndTransportApps,
    annualFrequencyOfDomesticFlights,
    annualFrequencyOfInternationalFlights,
    averageMonthlyElectricityBillCost,
    electricityFromRenewableSources,
    descriptionOfGreenAreasMaintained,
    treePlantingParticipation,
    averageMonthlyWasteSentToLandfillInTons,
    percentageOfWasteRecycled,
  } = data;

  return {
    enrollmentId,
    numberOfEmployees,
    workModelAdopted: workModelAdopted as WorkModelAdopted,
    fuelTypesAndQuantitiesPerMonth: omitEmptyValues({
      gasolineLiters: gasoline ? gasolineLiters : undefined,
      dieselLiters: diesel ? dieselLiters : undefined,
      ethanolLiters: ethanol ? ethanolLiters : undefined,
      gnvLiters: gnv ? gnvLiters : undefined,
      electricalVehiclesKwH: electricalVehicles
        ? electricalVehiclesKwH
        : undefined,
    }) as FuelTypesAndQuantitiesPerMonth,
    averageMonthlySpendingOnTaxiAndTransportApps,
    annualFrequencyOfDomesticFlights:
      annualFrequencyOfDomesticFlights as AnnualFlightsFrequency,
    annualFrequencyOfInternationalFlights:
      annualFrequencyOfInternationalFlights as AnnualFlightsFrequency,
    averageMonthlyElectricityBillCost:
      averageMonthlyElectricityBillCost as AverageMonthlyElectricityBillCost,
    electricityFromRenewableSources:
      electricityFromRenewableSources as ElectricityFromRenewableSources,
    descriptionOfGreenAreasMaintained,
    treePlantingParticipation,
    averageMonthlyWasteSentToLandfillInTons,
    percentageOfWasteRecycled:
      percentageOfWasteRecycled as PercentageOfWasteRecycled,
  };
}

export function getDefaultCarbonOffsetCalculationValues(
  defaultValues?: CarbonCalculationQuestions,
): CarbonOffsetCalculatorFormSchema | undefined {
  if (!defaultValues) return undefined;

  const {
    numberOfEmployees,
    workModelAdopted,
    fuelTypesAndQuantitiesPerMonth,
    averageMonthlySpendingOnTaxiAndTransportApps,
    annualFrequencyOfDomesticFlights,
    annualFrequencyOfInternationalFlights,
    averageMonthlyElectricityBillCost,
    electricityFromRenewableSources,
    descriptionOfGreenAreasMaintained,
    treePlantingParticipation,
    averageMonthlyWasteSentToLandfillInTons,
    percentageOfWasteRecycled,
  } = defaultValues;

  const {
    gasolineLiters,
    dieselLiters,
    ethanolLiters,
    gnvLiters,
    electricalVehiclesKwH,
  } = fuelTypesAndQuantitiesPerMonth || {};

  return {
    numberOfEmployees,
    workModelAdopted,
    gasoline: Boolean(gasolineLiters),
    gasolineLiters,
    diesel: Boolean(dieselLiters),
    dieselLiters,
    ethanol: Boolean(ethanolLiters),
    ethanolLiters,
    gnv: Boolean(gnvLiters),
    gnvLiters,
    electricalVehicles: Boolean(electricalVehiclesKwH),
    electricalVehiclesKwH,
    averageMonthlySpendingOnTaxiAndTransportApps,
    annualFrequencyOfDomesticFlights,
    annualFrequencyOfInternationalFlights,
    averageMonthlyElectricityBillCost,
    electricityFromRenewableSources,
    descriptionOfGreenAreasMaintained,
    treePlantingParticipation,
    averageMonthlyWasteSentToLandfillInTons,
    percentageOfWasteRecycled,
  };
}
