import { CardsList } from '@abundance-brasil-wabi/sabi';

import { useArticlesList } from 'state/article';

import ArticleCard from './ArticleCard';

const ArticlesCardsList: React.FC = () => {
  const { data: articlesList, isLoading } = useArticlesList();

  return (
    <CardsList isLoading={isLoading}>
      {articlesList
        ?.slice(0, 4)
        ?.map((article) => <ArticleCard key={article.link} {...article} />)}
    </CardsList>
  );
};

export default ArticlesCardsList;
