import { DEFAULT_STORAGE_NAMESPACE } from 'constants/storage';
import { SessionStorageKey } from 'constants/types';

/**
 * If the itemToStore is truthy, then set it in sessionStorage and return it.
 *
 * @param {SessionStorageKey} key - SessionStorageKey - The key to store the item under.
 * @param {T} itemToStore - The item you want to store in sessionStorage.
 *
 * @returns The itemToStore is being returned.
 */
export function setItemInSessionStorage<T>(
  key: SessionStorageKey,
  itemToStore: T,
): T | undefined {
  if (!itemToStore) return undefined;

  sessionStorage.setItem(
    `${DEFAULT_STORAGE_NAMESPACE}${key}`,
    JSON.stringify(itemToStore),
  );

  return itemToStore;
}

/**
 * If there's an item in session storage with the given key, return it, otherwise return undefined.
 *
 * @param {SessionStorageKey} key - SessionStorageKey - The key of the item you want to retrieve from session storage.
 *
 * @returns The value of the key in sessionStorage.
 */
export function getItemFromSessionStorage<T = unknown>(
  key: SessionStorageKey,
): T | undefined {
  const storedItem = sessionStorage.getItem(
    `${DEFAULT_STORAGE_NAMESPACE}${key}`,
  );

  return storedItem ? JSON.parse(storedItem) : undefined;
}

/**
 * Remove an item from session storage.
 *
 * @param {SessionStorageKey} key - SessionStorageKey - The key of the item you want to remove from session storage.
 *
 * @returns The key that was removed from sessionStorage.
 */
export function removeItemFromSessionStorage(
  key: SessionStorageKey,
): SessionStorageKey {
  sessionStorage.removeItem(`${DEFAULT_STORAGE_NAMESPACE}${key}`);

  return key;
}
