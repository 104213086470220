import { MdArrowBack, MdClose } from 'react-icons/md';
import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  createFlexDisplay,
  createThemeTransition,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import { StyledModalProps } from './types';

export const Loader = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 54px;
  height: 54px;
  position: fixed;
  inset: 0;
  margin: auto;
  z-index: 101;
`;

export const FileHandlerButtons = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})`
  width: 100%;
  position: fixed;
  top: 10px;
  padding: 0 8px;
  z-index: 101;
`;

export const HandlerButton = styled(Button).attrs({
  variant: 'text',
})`
  font-size: 24px;
  color: ${getThemeColor('white')};
`;

export const Contents = styled.div<StyledModalProps>`
  width: max-content;
  max-width: 90%;
  max-height: 95%;
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 16px;
  border-radius: 8px;
  background-color: ${getThemeColor('white')};
  opacity: 1;
  z-index: 101;
  pointer-events: auto;
  transform: translate(-50%, -50%);
  transition: ${createThemeTransition({ props: 'all' })};

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpointDown('lg')`
      ${({ $isFullScreenOnMobile }) =>
        $isFullScreenOnMobile &&
        `
          width: 100vw;
          max-width: 100%;
          max-height: 100%;
          border-radius: 0;
        `}
    `}
`;

export const CloseButton = styled(Button).attrs({
  variant: 'text-static',
})`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border: none;
`;

export const CloseIcon = styled(MdClose)`
  font-size: 24px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const MobileHeader = styled.div`
  display: none;

  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'row',
      align: 'center',
      justify: 'space-between',
    })}

    background: ${getThemeColor('gradient', 'default')};
    padding: 16px;
  `}
`;

export const BackButton = styled(Button).attrs({
  variant: 'text',
})`
  ${breakpointDown('lg')`
    width: 20px;
    border: none;
    background-color: transparent;
    z-index: 101;
  `}
`;

export const BackIcon = styled(MdArrowBack)`
  ${breakpointDown('lg')`
    font-size: 20px;
    font-weight: ${getThemeFontWeight('bold')};
    color: ${getThemeColor('white')};
  `}
`;

export const Title = styled(Heading).attrs({
  level: 6,
})`
  ${breakpointDown('lg')`
    display: block;
    font-weight: ${getThemeFontWeight('regular')};
    color: ${getThemeColor('white')};
  `}
`;

export const Holder = styled.div`
  ${breakpointDown('lg')`
    width: 20px;
  `}
`;
