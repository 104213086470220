import { Divider, RenderWhen } from '@abundance-brasil-wabi/sabi';

import SelectedDivider from './SelectedDivider';
import * as S from './styles';

export type SelectedIndicatorProps = {
  isSelected: boolean | undefined;
};

const SelectedIndicator: React.FC<SelectedIndicatorProps> = ({
  isSelected,
}) => {
  return (
    <S.Root>
      <RenderWhen condition={!isSelected}>
        <Divider />
      </RenderWhen>

      <RenderWhen condition={isSelected}>
        <SelectedDivider />
      </RenderWhen>
    </S.Root>
  );
};

export default SelectedIndicator;
