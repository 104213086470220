import { withTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useWindowSize } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import networkError from 'assets/network-error.png';
import notFound from 'assets/not-found.png';
import LinkButton from 'components/LinkButton';
import { useSetPageConfig } from 'components/Page';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

const NotFound: React.FC<WithTranslation> = ({ t }) => {
  const { width } = useWindowSize();
  const { breakpoints } = useTheme();

  useSetPageConfig({ mode: 'cover' });

  return (
    <S.Root>
      <S.Content>
        {width && width > breakpoints.values.sm ? (
          <S.Logo src={notFound} alt={t('notFoundImageAlt')} />
        ) : (
          <S.Logo src={networkError} alt={t('treeErrorImageAlt')} />
        )}

        <S.Title>{t('notFound')}</S.Title>

        <S.Body>{t('sorry')}</S.Body>

        <LinkButton variant="primary" to={getPathnameFromKey('home')}>
          {t('return')}
        </LinkButton>
      </S.Content>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'notFound',
})(NotFound);
