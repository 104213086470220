import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  Divider,
  FormComposer,
  useToggle,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Checkbox, Input } from 'components/FormFields';
import LanguageSelector from 'components/LanguageSelector';
import { abundanceURLs } from 'constants/externalURLs';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';
import { useRegisterYourProfileForm } from './useRegisterYourProfileForm';

export type RegisterYourProfileFormProps = {
  hasDefaultInfo: boolean;
};

const RegisterYourProfileForm: React.FC<
  WithTranslation<RegisterYourProfileFormProps>
> = ({ hasDefaultInfo, t }) => {
  const { value: agreedWithConditions, toggle: toggleAgreedWithConditions } =
    useToggle(hasDefaultInfo);

  const { formState, control, getValues, isLoading, onSubmit } =
    useRegisterYourProfileForm();

  const getSearchParams = useCallback((): string => {
    const searchParams = new URLSearchParams();

    if (getValues('email')) {
      searchParams.append('email', getValues('email'));
    }

    if (getValues('name')) {
      searchParams.append('name', getValues('name'));
    }

    return searchParams.toString();
  }, [getValues]);

  return (
    <FormComposer
      fluid
      title={t('title')}
      subtitle={t('subtitle')}
      description={t('description')}
      sections={[
        {
          id: 'register-your-profile-form-main',
          contents: [
            <Input
              fluid
              key="name"
              name="name"
              label={t('nameLabel')}
              placeholder={t('namePlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="email"
              name="email"
              type="email"
              label={t('emailLabel')}
              placeholder={t('emailPlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Checkbox
              key="conditions"
              name="conditions"
              label={
                <S.Conditions>
                  {t('continue')}{' '}
                  <S.LinkButton
                    href={abundanceURLs.termsAndConditions}
                    target="_blank"
                  >
                    {t('terms')}
                  </S.LinkButton>{' '}
                  {t('and')}{' '}
                  <S.LinkButton
                    href={abundanceURLs.privacyPolicy}
                    target="_blank"
                  >
                    {t('policy')}
                  </S.LinkButton>
                  .
                </S.Conditions>
              }
              control={control}
              onChange={toggleAgreedWithConditions}
            />,

            <Button
              fluid
              key="submit"
              type="submit"
              isDisabled={
                !formState.isValid || !agreedWithConditions || isLoading
              }
            >
              {t('initiate')}
            </Button>,

            <S.SignUpButton
              key="signUpCompany"
              to={getPathnameFromKey('signUpCompany')}
              search={getSearchParams()}
            >
              {t('dontCalculate')}
            </S.SignUpButton>,

            <Divider key="divider" />,

            <S.LanguageSelectorContainer key="languageSelector">
              <LanguageSelector />
            </S.LanguageSelectorContainer>,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'registerYourProfile:registerYourProfileForm',
})(RegisterYourProfileForm);
