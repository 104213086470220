import { Dispatch, memo, SetStateAction, useCallback } from 'react';

import { GoogleMap } from '@react-google-maps/api';

import {
  defaultContainerStyle,
  defaultMapOptions,
  defaultZoomLevel,
} from '../constants';
import { Coordinate } from '../types';
import * as S from './styles';

export type LayerSelector = {
  coordinates: Coordinate;
  handleMapType: Dispatch<SetStateAction<string>>;
  mapType: string;
};

const LayerSelector = memo(
  ({ coordinates, handleMapType, mapType }: LayerSelector) => {
    const handleClick = useCallback((): void => {
      handleMapType(mapType);
    }, [handleMapType, mapType]);

    return (
      <S.Root onPress={handleClick}>
        <GoogleMap
          options={{ ...defaultMapOptions, mapTypeId: mapType }}
          mapContainerStyle={defaultContainerStyle}
          center={coordinates}
          zoom={defaultZoomLevel}
        />
      </S.Root>
    );
  },
);

export default LayerSelector;
