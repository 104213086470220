import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

import { StyledVirtualForestCardProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})`
  position: relative;
  flex-grow: 1;
  grid-auto-rows: 1fr;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
`;

export const Header = styled.div<StyledVirtualForestCardProps>`
  width: 100%;
  height: 108px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-bottom: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ $backgroundImage }) => `
    background-image: url(${$backgroundImage});
  `}
`;

export const IconContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 88px;
  height: 88px;
  border-radius: 8px;
  position: absolute;
  top: 32px;
  left: 32px;
  background-color: ${getThemeColor('white')};
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};

  ${breakpointDown('lg')`
    left: 24px;
  `}

  @media (max-width: 680px) {
    top: 57px;
  }
`;

export const Icon = styled.img`
  height: inherit;
`;

export const Content = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})`
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-top: 68px;
`;

export const Title = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${breakpointDown('lg')`
    margin-top: 4px;
  `}
`;

export const Summary = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'space-between',
  gap: '16px',
})`
  height: 100%;
  margin-top: 16px;

  ${breakpointDown('lg')`
    width: 100%;
  `}
`;

export const SummaryItems = styled.ul`
  ${createFlexDisplay({
    direction: 'column',
    align: 'flex-start',
    justify: 'center',
    gap: '8px',
  })};

  list-style: none;
`;

export const SummaryItem = styled.li`
  ${createFlexDisplay({
    direction: 'ROW',
    align: 'center',
    justify: 'flex-start',
    gap: '4px',
  })};

  line-height: 16px;
  font-size: 13px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const SummaryItemDescription = styled.div`
  ${createFlexDisplay({
    direction: 'ROW',
    align: 'center',
    justify: 'flex-start',
    gap: '4px',
  })};

  line-height: 16px;
  font-size: 13px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const AccessButton = styled(LinkButton).attrs({
  variant: 'secondary-outline',
})`
  width: fit-content;

  ${breakpointDown('lg')`
    width: -webkit-fill-available;
  `}
`;
