import {
  MdAccountBalanceWallet,
  MdArticle,
  MdAssignmentTurnedIn,
  MdCategory,
  MdCloud,
  MdContactSupport,
  MdHome,
  MdOutlineAccountBalanceWallet,
  MdOutlineArticle,
  MdOutlineAssignmentTurnedIn,
  MdOutlineCategory,
  MdOutlineCloud,
  MdOutlineContactSupport,
  MdOutlineHome,
} from 'react-icons/md';
import { SideMenuItem } from 'types/common';

export const SIDE_MENU_ITEMS: SideMenuItem[] = [
  {
    key: 'home',
    icon: <MdOutlineHome />,
    activeIcon: <MdHome />,
  },
  {
    key: 'wallet',
    icon: <MdOutlineAccountBalanceWallet />,
    activeIcon: <MdAccountBalanceWallet />,
  },
  {
    key: 'impact',
    icon: <MdOutlineCloud />,
    activeIcon: <MdCloud />,
  },
  {
    key: 'syntropyReports',
    icon: <MdOutlineCategory />,
    activeIcon: <MdCategory />,
  },
  {
    key: 'articles',
    icon: <MdOutlineArticle />,
    activeIcon: <MdArticle />,
  },
  {
    key: 'support',
    icon: <MdOutlineContactSupport />,
    activeIcon: <MdContactSupport />,
  },
  {
    key: 'subscriptionPlans',
    icon: <MdOutlineAssignmentTurnedIn />,
    activeIcon: <MdAssignmentTurnedIn />,
  },
];
