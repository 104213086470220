import { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Divider,
  RenderWhen,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import LinkButton from 'components/LinkButton';
import { useSetPageConfig } from 'components/Page';
import { IMAGE_URL } from 'constants/assets';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

const HowToGetMyTokens: React.FC<WithTranslation> = ({ t }) => {
  const [tipAlertState, setTipAlertState] = useState(true);

  const isMobile = useIsMobileViewport();
  useSetPageConfig({ mode: 'fluid' });

  const handleCloseTipAlert = useCallback((): void => {
    setTipAlertState((oldState) => !oldState);
  }, []);

  return (
    <S.Root>
      <RenderWhen condition={tipAlertState}>
        <S.AlertToast
          title={t('title')}
          message={t('youAreNotARestorer')}
          onClose={handleCloseTipAlert}
        />
      </RenderWhen>

      <S.ContentContainer $tipAlerIsOn={tipAlertState}>
        <S.TokensLogo
          src={`${IMAGE_URL}/v1702770570/Assets/Coins/coin_ptavvh.png`}
          alt={t('abundanceTokenImageAlt')}
        />

        <S.Title>{t('firstSpan')}</S.Title>

        <S.Description>{t('firstParagraph')}</S.Description>

        <LinkButton variant="primary" to={getPathnameFromKey('wallet')}>
          {t('restoreNow')}
        </LinkButton>

        <RenderWhen condition={!isMobile}>
          <Divider margin="42px 0" />
        </RenderWhen>

        <S.Title>{t('secondSpan')}</S.Title>

        <S.Description>{t('secondParagraph')}</S.Description>

        <S.Title>{t('thirdSpan')}</S.Title>

        <S.Description>{t('thirdParagraph')}</S.Description>

        <S.DefaultImage
          src={`${IMAGE_URL}/v1702756607/Assets/Informational/Stamp/stamps-info_uucyqt.png`}
          alt={t('stampsInfoImageAlt')}
        />

        <S.Title>{t('fourthSpan')}</S.Title>

        <S.Description>
          {t('fourthParagraph')}{' '}
          <S.KnowMoreLink to={getPathnameFromKey('howToGetMyCertificate')}>
            {t('knowMore')}.
          </S.KnowMoreLink>
        </S.Description>

        <S.FluidImageContainer>
          <S.ExampleImage
            src={`${IMAGE_URL}/v1702755524/Assets/Informational/Certificate/certificates_oi5red.png`}
            alt={t('certificateImageAlt')}
          />
        </S.FluidImageContainer>

        <S.Title>{t('fifthSpan')}</S.Title>

        <S.Description>{t('fifthParagraph')}</S.Description>

        <S.FluidImageContainer>
          <S.ExampleImage
            src={`${IMAGE_URL}/v1702756543/Assets/Informational/Report/reports_v8xaja.png`}
            alt={t('reportsImageAlt')}
          />
        </S.FluidImageContainer>

        <S.Title>{t('sixthSpan')}</S.Title>

        <S.Description>{t('sixthParagraph')}</S.Description>

        <S.DefaultImage
          src={`${IMAGE_URL}/v1702755692/Assets/Informational/Emtel/emtel_zgaffw.png`}
          alt={t('emtelImageAlt')}
        />

        <RenderWhen condition={isMobile}>
          <Divider margin="0 0 48px 0" />
        </RenderWhen>

        <S.Wanna>{t('seventhSpan')}</S.Wanna>

        <LinkButton variant="primary" to={getPathnameFromKey('wallet')}>
          {t('restoreNow')}
        </LinkButton>
      </S.ContentContainer>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'howToGetMyTokens',
})(HowToGetMyTokens);
