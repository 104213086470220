import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import { Account2FAInfo } from 'api/services';
import { DEFAULT_APPLICATION_LANGUAGE } from 'constants/application';
import { fieldErrorMessages } from 'constants/forms';
import supportedLanguages from 'constants/supportedLanguages';
import { useToast } from 'hooks/useToast';
import { useAuth, useOnAuthSuccess, useResendTwoFactorCode } from 'state/auth';
import {
  getTwoFactorAuthData,
  getUserInfo,
  removeTwoFactorAuthData,
} from 'state/auth/utils';
import { setUserLanguage } from 'state/user/utils';

import { securityVerificationFormSchema } from './constants';
import { SecurityVerificationFormSchema } from './types';

export type UseSecurityVerificationFormConfig = {
  submitCallback?: (email: string, twoFactorCode: string) => void;
  onSuccess?: () => void;
};

export type UseSecurityVerificationForm =
  FormReturn<SecurityVerificationFormSchema> & {
    storedUserInfo: Account2FAInfo | undefined;
    isLoading: boolean;
    resendSuccess: boolean;
    resendVerificationCode: () => void;
    onSubmit: () => void;
  };

export function useSecurityVerificationForm({
  submitCallback,
  onSuccess,
}: UseSecurityVerificationFormConfig): UseSecurityVerificationForm {
  const [resendSuccess, setResendSuccess] = useState(false);

  const { t } = useTranslation('pages', {
    keyPrefix: 'securityVerification:securityVerificationForm',
  });
  const { mutate: signIn, isLoading } = useAuth();
  const { mutate: resendTwoFactorCode } = useResendTwoFactorCode();
  const { addToast } = useToast();
  const storedUserInfo = getTwoFactorAuthData();
  const onAuthSuccess = useOnAuthSuccess();

  const form = useForm<SecurityVerificationFormSchema>({
    resolver: zodResolver(securityVerificationFormSchema),
    mode: 'all',
  });

  const { handleSubmit, setError, reset } = form;

  const resendVerificationCode = useCallback((): void | null => {
    if (!storedUserInfo) return;

    reset();

    resendTwoFactorCode(
      {
        body: {
          email: storedUserInfo.email,
        },
      },
      {
        onSuccess: () => {
          addToast({
            message: t('codeResent'),
            state: 'success',
          });

          setResendSuccess(true);

          setTimeout(() => {
            setResendSuccess(false);
          }, 3000);
        },
        onError: (error) => {
          addToast({
            message: getErrorMessage(error),
            state: 'error',
          });
        },
      },
    );
  }, [addToast, resendTwoFactorCode, reset, storedUserInfo, t]);

  const onSubmit = useCallback(
    ({ pass }: SecurityVerificationFormSchema): void => {
      if (!pass || !storedUserInfo) return;

      if (submitCallback) return submitCallback(storedUserInfo.email, pass);

      signIn(
        {
          body: {
            email: storedUserInfo.email,
            pass,
            use2FA: true,
          },
        },
        {
          onSuccess: async (data) => {
            await onAuthSuccess(data);

            const userInfo = getUserInfo(data);

            setUserLanguage(
              userInfo.user.language ||
                supportedLanguages[DEFAULT_APPLICATION_LANGUAGE].language,
            );

            removeTwoFactorAuthData();
            onSuccess?.();
          },
          onError: () => {
            setError('pass', {
              message: fieldErrorMessages.twoFactorCode.tokenIsInvalid,
            });
          },
        },
      );
    },
    [
      onAuthSuccess,
      onSuccess,
      setError,
      signIn,
      storedUserInfo,
      submitCallback,
    ],
  );

  return {
    storedUserInfo,
    isLoading,
    resendSuccess,
    resendVerificationCode,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
