import { validateEntity, validatePerson } from 'stdnum';
import { z } from 'zod';

import { fieldErrorMessages } from 'constants/forms';

function validateTinNumber(
  validator: typeof validatePerson,
  country: string,
  tinNumber = '',
): boolean {
  const { isValid } = validator(country, tinNumber);

  return isValid ?? false;
}

export const taxInformationFormSchema = z
  .object({
    country: z.string().optional(),
    taxIdentificationNumber: z.string().optional(),
  })
  .refine(({ country }) => country, {
    message: fieldErrorMessages.tin.noCountry,
    path: ['country'],
  })
  .refine(({ taxIdentificationNumber }) => taxIdentificationNumber, {
    message: fieldErrorMessages.tin.required,
    path: ['taxIdentificationNumber'],
  })
  .refine(
    ({ country, taxIdentificationNumber }) => {
      if (!country) return false;

      const personValidator = validateTinNumber(
        validatePerson,
        country,
        taxIdentificationNumber,
      );

      const entityValidator = validateTinNumber(
        validateEntity,
        country,
        taxIdentificationNumber,
      );

      if (!personValidator && !entityValidator) return false;

      return true;
    },
    {
      message: fieldErrorMessages.tin.invalid,
      path: ['taxIdentificationNumber'],
    },
  );
