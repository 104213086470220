import { Coordinate } from './types';

export const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: 'satellite',
};

export const defaultContainerStyle: React.CSSProperties = {
  width: '100%',
  height: '560px',
  borderRadius: '8px',
};

export const defaultCoordinates: Coordinate = {
  lat: -21.0514374,
  lng: -45.2692805,
};

export const markerCoordinates: Coordinate = {
  lat: -21.0473671,
  lng: -45.2716227,
};

export const defaultZoomLevel = 15;
