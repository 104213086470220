import { userQueryKey } from 'state/utils';

export const treeQueryKeys = {
  all: [{ scope: 'tree' }] as const,
  treeAccountBalance: () =>
    [{ ...treeQueryKeys.all[0], entity: 'treeAccountBalance' }] as const,
  impact: () => [{ ...treeQueryKeys.all[0], entity: 'impact' }] as const,
  certificate: (userId?: string, language?: string) =>
    [
      {
        ...treeQueryKeys.all[0],
        ...userQueryKey(userId),
        entity: 'certificate',
        language,
      },
    ] as const,
  absorptionReport: (userId?: string, language?: string) =>
    [
      {
        ...treeQueryKeys.all[0],
        ...userQueryKey(userId),
        entity: 'absorptionReport',
        language,
      },
    ] as const,
};
