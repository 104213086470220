import { cloneDeepWith, Dictionary, isObject, omitBy } from 'lodash';

/**
 * Removes properties with empty values (empty string, undefined, or null)
 * from an object.
 *
 * @param object - The function takes this object as input and removes any
 * key-value pairs where the value is an empty string, `undefined`, or `null`.
 *
 * @returns A new object that is a copy of the input object, but with any properties
 * that have empty values (empty string, `undefined`, or `null`.) removed.
 */
export function omitEmptyValues<T>(object: Dictionary<T>): Dictionary<T> {
  return omitBy(
    object,
    (value) => value === '' || value === undefined || value === null,
  );
}

/**
 * Converts all properties of an object to strings.
 *
 * @param object - Contains key-value pairs to convert to strings.
 *
 * @returns A deep clone of the input object `object`, where all non-object
 * values are converted to strings.
 */
export function convertPropsToStrings<T>(object: Dictionary<T>): Dictionary<T> {
  return cloneDeepWith(object, (value) => {
    if (isObject(value)) return undefined;

    return value.toString();
  });
}
