import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
  gap: '16px',
})`
  width: 100%;

  ${breakpointDown('lg')`
    gap: 12px;
  `}
`;

export const Icon = styled.img`
  width: 72px;
  height: 72px;
`;

export const Name = styled(Text).attrs({
  level: 'caption',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'primary', 'default')};
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'secondary', 'default')};
`;
