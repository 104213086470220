import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import ColorfulBackground from 'components/ColorfulBackground';
import { useSetPageConfig } from 'components/Page';
import { abundanceURLs } from 'constants/externalURLs';
import useGetSearchParams from 'hooks/useGetSearchParams';

import * as S from './styles';

const PurchaseWithoutAccountConfirmation: React.FC<WithTranslation> = ({
  t,
}) => {
  const [email] = useGetSearchParams(['email']);

  useSetPageConfig({ mode: 'cover' });

  return (
    <ColorfulBackground>
      <S.Root>
        <S.Logo />

        <S.Title>{t('title')}</S.Title>

        <S.Content>{t('emailSent', { email })}</S.Content>

        <S.Content>
          {t('forMoreInfo')}{' '}
          <S.LinkButton href={abundanceURLs.faq} target="_blank">
            {t('faq')}
          </S.LinkButton>{' '}
          {t('contactSupport')}{' '}
          <S.LinkButton href={abundanceURLs.contact} target="_blank">
            {t('here')}
          </S.LinkButton>
          .
        </S.Content>
      </S.Root>
    </ColorfulBackground>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'purchaseWithoutAccountConfirmation',
})(PurchaseWithoutAccountConfirmation);
