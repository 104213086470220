import { styled } from 'styled-components';

import { StyledWalletTransactionsHistoryProps } from './types';

export const Root = styled.div`
  width: 100%;
`;

export const Table = styled.div`
  width: 100%;
  min-height: 284px;
  position: relative;
`;

export const Item = styled.div<StyledWalletTransactionsHistoryProps>`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;

  ${({ $show }) =>
    $show &&
    `
      display: block;
    `}
`;
