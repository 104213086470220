import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage, request } from 'api/http';
import { ErrorMessage } from 'api/services/types';
import { useToast } from 'hooks/useToast';
import { useSendOTP } from 'state/auth';

import { hideModal } from '../../../../store/modules/modal/actions';
import {
  otpValidationSuccess,
  tokenSuccess,
  validationResponse,
} from '../../../../store/modules/otpValidation/actions';
import { otpValidationSelector } from '../../../../store/modules/otpValidation/selectors';
import { RequestData } from '../../../../store/modules/otpValidation/types';
import { otpValidationForm } from './constants';
import { OTPValidationFormSchema } from './types';

export type UseOTPValidationForm = FormReturn<OTPValidationFormSchema> & {
  requestData: RequestData;
  resendSuccess: boolean;
  isLoading: boolean;
  resendVerificationCode: () => void;
  onSubmit: () => void;
};

export function useOTPValidationForm(): UseOTPValidationForm {
  const [resendSuccess, setResendSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('components', {
    keyPrefix: 'modal:otpValidationModal:otpValidationForm',
  });
  const { addToast } = useToast();
  const { mutate: sendOTP } = useSendOTP();
  const dispatch = useDispatch();

  const { requestData, purpose, password, apiMethod } = useSelector(
    otpValidationSelector,
  );

  const form = useForm<OTPValidationFormSchema>({
    reValidateMode: 'onChange',
    resolver: zodResolver(otpValidationForm),
    mode: 'all',
  });

  const { handleSubmit, reset } = form;

  const resendVerificationCode = useCallback((): void => {
    reset();

    sendOTP(
      {
        body: { purpose, password },
      },
      {
        onSuccess: () => {
          addToast({
            message: t('codeResent'),
            state: 'success',
          });

          setResendSuccess(true);

          setTimeout(() => {
            setResendSuccess(false);
          }, 3000);
        },
        onError: () => {
          addToast({
            message: t('codeError'),
            state: 'error',
          });
        },
      },
    );
  }, [addToast, password, purpose, reset, sendOTP, t]);

  const submit = useCallback(
    ({ twoFactorCode }: OTPValidationFormSchema): void => {
      setIsLoading(true);

      request[apiMethod](requestData.endpoint, requestData.payload, {
        headers: {
          'X-OTP': twoFactorCode,
        },
      })
        .then((response: AxiosResponse) => {
          dispatch(validationResponse(response));
          dispatch(tokenSuccess(true));
          dispatch(otpValidationSuccess(true));
        })
        .catch((error: ErrorMessage) => {
          if (error?.response?.data.status === 401)
            return addToast({
              message: t('invalidCode'),
              state: 'error',
            });

          const validationKey = Object.keys(error?.response?.data.errors || {});

          addToast({
            message:
              error?.response?.data.errors[validationKey[0]][0] ||
              getErrorMessage(error),
            state: 'error',
          });
        })
        .finally(() => {
          dispatch(hideModal());
          setIsLoading(false);
        });
    },
    [
      addToast,
      apiMethod,
      dispatch,
      requestData.endpoint,
      requestData.payload,
      t,
    ],
  );

  return {
    requestData,
    resendSuccess,
    isLoading,
    resendVerificationCode,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
