import { useCallback } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useSetPageConfig } from 'components/Page';

import PurchaseWithoutAccountForm from './PurchaseWithoutAccountForm';
import * as S from './styles';

const PurchaseWithoutAccount: React.FC = () => {
  const navigate = useNavigate();
  useSetPageConfig({ mode: 'split' });

  const navigateBack = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  return (
    <S.Root>
      <S.BackButton onPress={navigateBack} variant="text">
        <MdArrowBack />
      </S.BackButton>

      <PurchaseWithoutAccountForm />
    </S.Root>
  );
};

export default PurchaseWithoutAccount;
