import * as S from './styles';

export type PageDescriptionProps = {
  title: string;
  description: string;
};

const PageDescription: React.FC<PageDescriptionProps> = ({
  title,
  description,
}) => {
  return (
    <div>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </div>
  );
};

export default PageDescription;
