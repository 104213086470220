import { withTranslation } from 'react-i18next';

import { Divider } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import ColorfulBackground from 'components/ColorfulBackground';
import { useSetPageConfig } from 'components/Page';
import { abundanceURLs } from 'constants/externalURLs';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

const MobileIntroduction: React.FC<WithTranslation> = ({ t }) => {
  useSetPageConfig({ mode: 'cover' });

  return (
    <ColorfulBackground>
      <S.Root>
        <S.Logo />

        <S.Title>{t('restoring')}</S.Title>

        <S.Description>{t('performance')}</S.Description>

        <S.CreateAccountButton to={getPathnameFromKey('signIn')}>
          {t('accessAccount')}
        </S.CreateAccountButton>

        <S.Description>{t('createAccount')}</S.Description>

        <S.Content>
          <S.ControlButtons to={getPathnameFromKey('signUpPersonal')}>
            {t('forYou')}
          </S.ControlButtons>

          <S.ControlButtons to={getPathnameFromKey('signUpCompany')}>
            {t('forCompany')}
          </S.ControlButtons>
        </S.Content>

        <Divider margin="24px 0" />

        <S.Footer>
          <S.Doubts>{t('doubts')}</S.Doubts>
          <S.BookPresentation href={abundanceURLs.contact} target="_blank">
            {t('book')}
          </S.BookPresentation>
        </S.Footer>
      </S.Root>
    </ColorfulBackground>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'mobileIntroduction',
})(MobileIntroduction);
