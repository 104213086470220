import { styled } from 'styled-components';

import {
  breakpointDown,
  Card,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Card)`
  background-color: ${getThemeColor('default')};
  border: none;
`;

export const Content = styled(Card.Content)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const ContentsHeading = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.span`
  & > * {
    width: 24px;
    height: 24px;
  }
`;

export const Subtitle = styled(Text).attrs({
  level: 'overline',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  width: 100%;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const ButtonContainer = styled(Card.Content).attrs({
  justify: 'flex-end',
})`
  width: 100%;

  ${breakpointDown('sm')`
    & > * {
      width: 100%;
    }
  `}
`;
