import { useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import {
  CertificateStampsList,
  Divider,
  DownloadIcon,
  Loader,
  RenderWhen,
  Spacer,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useSetPageConfig } from 'components/Page';
import { coinsList } from 'constants/coinsList';
import { useToast } from 'hooks/useToast';
import { useIsFileLoading } from 'state/application';
import { useMediaKit } from 'state/media';
import { useDownloadMediaKit, useImpact } from 'state/tree';
import { downloadFile } from 'utils/handlers';

import * as S from './styles';

const MediaKit: React.FC<WithTranslation> = ({ t }) => {
  const { addToast } = useToast();
  const isMobile = useIsMobileViewport();
  useSetPageConfig({ mode: 'fluid' });

  const {
    data: mediaKit,
    refetch: getMediaKit,
    isFetching: isFetchingMediaKit,
  } = useMediaKit(false);
  const { data: impact, isFetched: isImpactFetched } = useImpact();
  const { mutate: downloadMediaKit, isLoading } = useDownloadMediaKit({
    onSuccess: (data) => {
      const blobZipFile = new Blob([data], {
        type: 'application/octet-stream',
      });

      downloadFile({
        file: blobZipFile,
        fileName: 'Abundance Media Kit.zip',
      });
    },
    onError: () => {
      addToast({
        message: t('downloadError'),
        state: 'error',
      });
    },
  });

  const { stamp } = impact || {};

  const fileLoading = useIsFileLoading();

  const handleDownload = useCallback((): void => {
    downloadMediaKit();
  }, [downloadMediaKit]);

  useEffect(() => {
    if (isImpactFetched && !mediaKit && !isFetchingMediaKit) {
      getMediaKit();
    }
  }, [getMediaKit, isFetchingMediaKit, isImpactFetched, mediaKit]);

  return (
    <S.Root>
      <S.Content>
        <S.Coin
          src={coinsList[stamp as keyof CertificateStampsList]}
          alt={t('coinImageAlt')}
        />

        <S.Title>{t('firstSpan')}</S.Title>

        <S.Description>{t('firstParagraph')}</S.Description>

        <RenderWhen condition={fileLoading}>
          <S.Loader>
            <Loader message={t('loading')} />
          </S.Loader>
        </RenderWhen>

        <RenderWhen condition={!fileLoading}>
          <S.Title>{t('firstHeader')}</S.Title>

          <S.ApplicationsContainer>
            <S.Application>
              <S.Title>{t('secondSpan')}</S.Title>
              <S.Description>{t('secondParagraph')}</S.Description>

              <S.HorizontalStampContainer>
                <S.StampCircular
                  src={mediaKit?.circularSmall}
                  alt={t('stampImageAlt')}
                />
              </S.HorizontalStampContainer>
            </S.Application>

            <S.Application>
              <S.Title>{t('thirdSpan')}</S.Title>
              <S.Description>{t('thirdParagraph')}</S.Description>

              <S.HorizontalStampContainer>
                <S.Stamp
                  src={mediaKit?.horizontalNormal}
                  alt={t('secondParagraph')}
                />
                <S.Stamp
                  src={mediaKit?.horizontalSmall}
                  alt={t('secondParagraph')}
                />
              </S.HorizontalStampContainer>
            </S.Application>

            <S.Application>
              <S.Title>{t('fourthSpan')}</S.Title>
              <S.Description>{t('fourthParagraph')}</S.Description>

              <Spacer direction="row" align="flex-start" justify="flex-start">
                <S.Stamp
                  src={mediaKit?.verticalNormal}
                  alt={t('secondParagraph')}
                  $isVertical
                />
                <S.Stamp
                  src={mediaKit?.verticalSmall}
                  alt={t('secondParagraph')}
                  $isVertical
                />
              </Spacer>
            </S.Application>
          </S.ApplicationsContainer>

          <Divider margin={!isMobile ? '38px 0' : '14px 0'} />

          <S.DownloadButton onPress={handleDownload} isDisabled={isLoading}>
            {isLoading ? t('preparingDownload') : t('downloadButton')}

            <RenderWhen condition={!isLoading}>
              <DownloadIcon />
            </RenderWhen>
          </S.DownloadButton>
        </RenderWhen>
      </S.Content>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'mediaKit',
})(MediaKit);
