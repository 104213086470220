import { useTranslation, withTranslation } from 'react-i18next';

import { Divider } from '@abundance-brasil-wabi/sabi';
import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { CarbonOffsetCalculationCompensationInfo } from 'api/services';
import { WithTranslation } from 'i18n/types';

import * as S from './styles';

export type ResultsProps = {
  compensationInfo?: CarbonOffsetCalculationCompensationInfo;
  quantity?: number;
};

const Results: React.FC<WithTranslation<ResultsProps>> = ({
  compensationInfo,
  quantity,
  t,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <S.Root>
      <S.ResultContainer>
        <S.Title>{t('carbon')}</S.Title>

        <S.Result>
          {new Intl.NumberFormat(language).format(
            compensationInfo?.carbonEmitted || 0,
          )}
          t {t('of')} CO₂e
        </S.Result>
      </S.ResultContainer>

      <S.ResultContainer>
        <S.Title>{t('trees')}</S.Title>

        <S.Result>
          {new Intl.NumberFormat(language).format(quantity || 0)}
        </S.Result>
      </S.ResultContainer>

      <S.ResultContainer>
        <S.Title>{t('percentage')}</S.Title>

        <S.Result>{compensationInfo?.uncompensatedPercentage}%</S.Result>
      </S.ResultContainer>

      <Divider />

      <S.ResultContainer>
        <S.Title>{t('value')}</S.Title>

        <S.Value>
          R$ {formatToCurrency(compensationInfo?.uncompensatedValue || 0)}
        </S.Value>
      </S.ResultContainer>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'carbonCompensationCalculator:results',
})(Results);
