import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ValueLabel } from 'types/common';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getPathnameFromKey } from 'router/utils';
import { useValueLabelCountryList } from 'state/country';
import { useUser } from 'state/user';

import { forestProducerRegistrationFormSchema } from './constants';
import { ForestProducerRegistrationFormSchema } from './types';

export type UseForestProducerRegistrationForm =
  FormReturn<ForestProducerRegistrationFormSchema> & {
    isLoading: boolean;
    country: string | undefined;
    countriesList: ValueLabel<string>[];
    onSubmit: () => void;
  };

export function useForestProducerRegistrationForm(): UseForestProducerRegistrationForm {
  const { data: countriesList = [] } = useValueLabelCountryList();
  const { data: user } = useUser();
  const navigate = useNavigate();

  const { country } = user || {};

  const form = useForm<ForestProducerRegistrationFormSchema>({
    resolver: zodResolver(forestProducerRegistrationFormSchema),
    mode: 'all',
  });

  const { handleSubmit } = form;

  const submit = useCallback(
    (data: ForestProducerRegistrationFormSchema): void => {
      // finish the implementation.
      // eslint-disable-next-line no-console
      console.log(data);

      navigate({
        pathname: getPathnameFromKey('forestProducerRegistrationSuccess'),
      });
    },
    [navigate],
  );

  return {
    isLoading: false,
    country,
    countriesList,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
