import { AxiosResponse } from 'axios';
import { KeyStringValue } from 'types/common';

export type DefaultOTPPayload = KeyStringValue<
  string | number | boolean | Date | null | undefined
>;

export enum OTPValidationAction {
  OTP_PURPOSE = 'OTP_PURPOSE',
  API_METHOD = 'API_METHOD',
  VALIDATE_PASSWORD_AND_PURPOSE_VALIDATION = 'VALIDATE_PASSWORD_AND_PURPOSE_VALIDATION',
  REQUEST_DATA = 'REQUEST_DATA',
  PASSWORD_SUCCESS = 'PASSWORD_SUCCESS',
  TOKEN_SUCCESS = 'TOKEN_SUCCESS',
  HAS_ERROR = 'HAS_ERROR',
  OTP_VALIDATION_SUCCESS = 'OTP_VALIDATION_SUCCESS',
  OTP_VALIDATION_RESET = 'OTP_VALIDATION_RESET',
  REMOTE_OTP_VALIDATION = 'REMOTE_OTP_VALIDATION',
  VALIDATION_RESPONSE = 'VALIDATION_RESPONSE',
}

export enum OTPPurpose {
  UPDATE_CUSTOMER = 'UpdateCustomer',
  UPDATE_COMPANY = 'UpdateCompany',
  CREATE_CLAIM_SESSION = 'CreateClaimSession',
  CHANGE_PASSWORD = 'ChangePassword',
}

export type StartValidation = {
  requestDataValues: RequestData;
  purpose: OTPPurpose;
  passwordBypass?: string;
  method?: 'get' | 'put' | 'post';
  callback?: (() => void) | string;
};

export type RequestData = {
  payload: DefaultOTPPayload;
  endpoint: string;
  phoneNumber: string;
};

export type OTPValidationState = {
  purpose: string;
  apiMethod: 'get' | 'put' | 'post';
  password: string;
  requestData: RequestData;
  passwordSuccess: boolean;
  tokenSuccess: boolean;
  hasError: boolean;
  otpValidatedSuccessfully: boolean;
  remoteOTPValidationData: StartValidation | null;
  otpValidationResponse: AxiosResponse | null;
};
