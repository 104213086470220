import { MutationOptions } from 'types/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  createCarbonOffsetEnrollment,
  CreateCarbonOffsetEnrollmentArgs,
  CreateCarbonOffsetEnrollmentResponse,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';
import { useUserId } from 'state/user';

import { carbonOffestQueryKeys } from './queryKeys';

export default function useCreateCarbonOffsetEnrollment(
  options?: MutationOptions<
    CreateCarbonOffsetEnrollmentResponse,
    CreateCarbonOffsetEnrollmentArgs
  >,
) {
  const { data: userId } = useUserId();
  const queryClient = useQueryClient();

  return useMutation<
    CreateCarbonOffsetEnrollmentResponse,
    ErrorMessage,
    CreateCarbonOffsetEnrollmentArgs,
    unknown
  >(createCarbonOffsetEnrollment, {
    ...options,
    onSuccess: () => {
      queryClient.removeQueries(
        carbonOffestQueryKeys.carbonOffsetEnrollment(userId),
      );
    },
  });
}
