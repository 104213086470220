import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import {
  CheckoutPaymentArgs,
  CheckoutPaymentBody,
  CheckoutPaymentResponse,
  PurchaseHistory,
  PurchaseWithoutAccountArgs,
  PurchaseWithoutAccountBody,
} from './types';

export async function getPurchaseHistory() {
  const { data } = await request.get<
    PurchaseHistory[],
    AxiosResponse<PurchaseHistory[]>,
    never
  >(`${abundanceAPI.V1.PURCHASE}/users/current`);

  return data;
}

export async function checkoutPayment(args: CheckoutPaymentArgs) {
  const { body } = args;

  const { data } = await request.post<
    AxiosResponse,
    AxiosResponse<CheckoutPaymentResponse>,
    CheckoutPaymentBody
  >(`${abundanceAPI.V1.PURCHASE}/users/current/checkout-session`, body);

  return data;
}

export async function purchaseWithoutAccount(args: PurchaseWithoutAccountArgs) {
  const { body } = args;

  const { data } = await request.post<
    AxiosResponse,
    AxiosResponse,
    PurchaseWithoutAccountBody
  >(`${abundanceAPI.V1.ACCOUNT}/purchase-without-account`, body);

  return data;
}
