import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { Country, getCountryList } from 'api/services';

import { countryQueryKeys } from './queryKeys';
import {
  countryListSelector,
  valueLabelCountryListSelector,
} from './selectors';
import { CountryListQueryKey } from './types';

export default function useGetCountryList<TData = Country[]>(
  options?: QueryOptions<Country[], TData, CountryListQueryKey>,
) {
  return useQuery<Country[], unknown, TData, CountryListQueryKey>(
    countryQueryKeys.countryList(),
    getCountryList,
    {
      ...options,
      enabled: true,
    },
  );
}

export const useCountryList = () =>
  useGetCountryList({
    select: countryListSelector,
  });

export const useValueLabelCountryList = () =>
  useGetCountryList({
    select: valueLabelCountryListSelector,
  });
