import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import { formatDate } from 'utils/formatting';

import * as S from './styles';

export type CarbonOffsetSummaryHeaderProps = {
  calculationDate?: string;
  compensationPercentage?: number;
};

const CarbonOffsetSummaryHeader: React.FC<
  WithTranslation<CarbonOffsetSummaryHeaderProps>
> = ({ calculationDate, compensationPercentage, t }) => {
  return (
    <S.Root>
      <S.TitlesContainer>
        <S.Title>{t('title')}</S.Title>

        <S.CalculationDate>
          {t('description')} {formatDate(calculationDate, 'shortDateWithTime')}
        </S.CalculationDate>
      </S.TitlesContainer>

      <S.Subtitle>
        {t('compensated')} <b>{compensationPercentage}%</b> {t('emissions')}
      </S.Subtitle>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'carbonOffsetSummary:carbonOffsetSummaryHeader',
})(CarbonOffsetSummaryHeader);
