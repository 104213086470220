import { getMonthsByLocale } from 'utils/general';

import { SupportedLanguage, SupportedLanguages } from './types';

const supportedLanguages: Record<SupportedLanguages, SupportedLanguage> = {
  'pt-BR': {
    language: 'pt-BR',
    title: 'Português (Brasil)',
    icon: '',
    months: getMonthsByLocale('pt-BR'),
    phoneNumberFormat: 'BR',
    dateFormats: {
      fullDate: 'DD [de] MMMM [de] YYYY',
      shortDate: 'DD/MM/YY',
      shortDateWithTime: 'DD/MM/YY [às] hh:mm A',
    },
  },
  'en-US': {
    language: 'en-US',
    title: 'English (US)',
    icon: '',
    months: getMonthsByLocale('en-US'),
    phoneNumberFormat: 'US',
    dateFormats: {
      fullDate: 'MMMM DD, YYYY',
      shortDate: 'MM/DD/YY',
      shortDateWithTime: 'MM/DD/YY [at] hh:mm A',
    },
  },
};

export default supportedLanguages;
