import { Navigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useWindowSize } from '@abundance-brasil-wabi/sabi';

import { getIsAuthenticated } from 'state/auth/utils';

import { getPathnameFromKey } from '..';

const Inital: React.FC = () => {
  const { width } = useWindowSize();
  const { breakpoints } = useTheme();
  const isAuthenticated = getIsAuthenticated();

  if (isAuthenticated)
    return <Navigate to={getPathnameFromKey('home')} replace />;

  if (!isAuthenticated && width < breakpoints.values.md)
    return <Navigate to={getPathnameFromKey('mobileIntroduction')} replace />;

  return <Navigate to={getPathnameFromKey('signIn')} replace />;
};

export default Inital;
