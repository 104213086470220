import { styled } from 'styled-components';

import {
  Button,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Contents = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  gap: 2,
})`
  width: 100%;
  padding: 8px 24px 0;
`;

export const PrimaryInfo = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const SecondaryInfo = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const SignOutButton = styled(Button).attrs({
  variant: 'text-static',
})`
  display: flex;
  align-items: center;
  gap: 24px;
  color: ${getThemeColor('danger', 'dark')};
  align-self: flex-start;
  margin-left: 16px;

  &[data-hovered] {
    color: ${getThemeColor('text', 'danger')};
  }
`;

export const SignOut = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
`;

export const LanguageSelectorContainer = styled(Contents)`
  & > * {
    position: absolute;
    bottom: 24px;
    left: 0;
  }
`;
