import { useCallback } from 'react';

import { Article } from 'api/services';

import * as S from './styles';

export type ArticleCardProps = Article;

const ArticleCard: React.FC<ArticleCardProps> = ({
  title,
  description,
  image,
  link,
}) => {
  const openArticles = useCallback((): void => {
    window.open(link, '_blank', 'noopener, noreferrer');
  }, [link]);

  return (
    <S.Root onPress={openArticles}>
      <S.Image $image={image} />

      <S.Title>
        {/* Dummy replacement to fix decimal unicode issue for dashes. */}
        {title.replaceAll('&#8211;', '-')}
      </S.Title>

      <S.Description>{description}</S.Description>
    </S.Root>
  );
};

export default ArticleCard;
