import { styled } from 'styled-components';

import {
  Button,
  GearIcon as Gear,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Loader,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
`;

export const SettingsButton = styled(Button).attrs({
  variant: 'text',
})`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const GearIcon = styled(Gear)`
  width: 16px;
  height: 16px;
`;

export const CustomLoader = styled(Loader)`
  position: relative;
  left: 24px;
  bottom: 24px;
  transform: scale(0.3);
`;

export const CoinIcon = styled.img`
  width: 72px;
  height: 72px;
  margin-bottom: 16px;
`;

export const Name = styled(Text).attrs({
  level: 'bodySmall',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  text-align: center;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Win = styled(Text).attrs({
  level: 'bodySmall',
})`
  margin-top: 4px;
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'primary', 'default')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  margin-top: 4px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Value = styled(Heading).attrs({
  level: 6,
})`
  text-align: center;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;
