/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import { Toast } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { ToastMessage } from 'hooks/types';
import { useToast } from 'hooks/useToast';

export type ToastElementProps = {
  message: ToastMessage;
};

const ToastElement: React.FC<WithTranslation<ToastElementProps>> = ({
  message,
  t,
}) => {
  const { removeToast } = useToast();

  const createRemoveToastCaller = (messageId: string) => (): void => {
    removeToast(messageId);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 10000);

    return () => clearTimeout(timer);
  }, [message.id, removeToast]);

  return (
    <Toast
      message={t(message.message)}
      state={message.state}
      onDismiss={createRemoveToastCaller(message.id)}
    />
  );
};

export default withTranslation()(ToastElement);
