import { useSetPageConfig } from 'components/Page';

import SignUpCompany from './SignUpCompany';
import SignUpPersonal from './SignUpPersonal';

export type SignUpProps = {
  isPhysicalPerson?: boolean;
};

const SignUp: React.FC<SignUpProps> = ({ isPhysicalPerson }) => {
  useSetPageConfig({ mode: 'split' });

  return isPhysicalPerson ? <SignUpPersonal /> : <SignUpCompany />;
};

export default SignUp;
