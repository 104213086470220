import { useSetPageConfig } from 'components/Page';

import SupportForm from './SupportForm';

const Support: React.FC = () => {
  useSetPageConfig({ mode: 'fluid' });

  return <SupportForm />;
};

export default Support;
