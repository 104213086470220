import { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Divider,
  RenderWhen,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';
import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import AuroraVerdeHeader from 'components/AuroraVerdeHeader';
import { TaxInformationForm } from 'components/Forms';
import PurchaseButtonWithDisclaimer from 'components/PurchaseButtonWithDisclaimer';
import { currencySymbols } from 'constants/currencySymbols';
import { MAX_PURCHASE_TREE_AMOUNT } from 'constants/env';
import { useCheckoutPayment } from 'state/purchase';
import { useUser } from 'state/user';

import * as S from './styles';

export type BuyTreeProps = {
  returnURL: string;
};

const BuyTree: React.FC<WithTranslation<BuyTreeProps>> = ({ returnURL, t }) => {
  const { mutate: checkoutPayment } = useCheckoutPayment();
  const { data: user } = useUser();
  const isMobile = useIsMobileViewport();

  const { country, taxIdentificationNumber } = user || {};

  const [disclaimerAgreed, setDisclaimerAgreed] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userCountry, setUserCountry] = useState(country);
  const [userTaxIdentificationNumber, setUserTaxIdentificationNumber] =
    useState(taxIdentificationNumber);
  const [isValid, setIsValid] = useState(!!taxIdentificationNumber);

  const handleBuyTree = useCallback(async (): Promise<void | null> => {
    setLoading(true);

    checkoutPayment({
      body: {
        quantity: totalSelected,
        taxIdentificationNumber:
          taxIdentificationNumber || userTaxIdentificationNumber,
        country: country || userCountry || '',
        returnURL,
      },
    });
  }, [
    checkoutPayment,
    country,
    returnURL,
    taxIdentificationNumber,
    totalSelected,
    userCountry,
    userTaxIdentificationNumber,
  ]);

  return (
    <S.Root>
      <S.HigherSection>
        <AuroraVerdeHeader />

        <S.Handlers>
          <S.Handler>
            <S.TreesAmount>{t('trees')}</S.TreesAmount>

            <S.Counter
              defaultValue={0}
              minValue={0}
              maxValue={MAX_PURCHASE_TREE_AMOUNT}
              aria-label={t('trees')}
              onChange={setTotalSelected}
            />
          </S.Handler>

          <S.Values>
            <S.UnityElement>
              {t('unity')}: <S.Unity>{currencySymbols.brl} 100,00</S.Unity>
            </S.UnityElement>

            <Divider margin={!isMobile ? '8px 0' : '4px 0'} />

            <S.UnityElement>
              {t('total')}:
              <S.TotalValue>
                {currencySymbols.brl} {formatToCurrency(totalSelected * 100)}
              </S.TotalValue>
            </S.UnityElement>
          </S.Values>
        </S.Handlers>
      </S.HigherSection>

      <RenderWhen condition={!taxIdentificationNumber}>
        <TaxInformationForm
          country={userCountry || ''}
          onCountryChange={setUserCountry}
          onTinChange={setUserTaxIdentificationNumber}
          onValidChange={setIsValid}
        />
      </RenderWhen>

      <S.LowerSection>
        <PurchaseButtonWithDisclaimer
          isDisabled={
            totalSelected === 0 || !disclaimerAgreed || !isValid || loading
          }
          disclaimerAgreed={disclaimerAgreed}
          isLoading={loading}
          buttonText={t('buy')}
          onDisclaimerAgreed={setDisclaimerAgreed}
          onSubmit={handleBuyTree}
        />

        <Divider margin="16px 0" />

        <S.RedirectMessage>{t('redirect')}</S.RedirectMessage>
      </S.LowerSection>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'buyTree',
})(BuyTree);
