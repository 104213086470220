import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getCurrentUserSubscriptionPlan, SubscriptionPlan } from 'api/services';

import { subscriptionPlanQueryKeys } from './queryKeys';
import { currentUserSubscriptionPlanSelector } from './selectors';
import { CurrentUserSubscriptionPlanQueryKey } from './types';

export default function useGetCurrentUserSubscriptionPlan<
  TData = SubscriptionPlan[],
>(
  options?: QueryOptions<
    SubscriptionPlan[],
    TData,
    CurrentUserSubscriptionPlanQueryKey
  >,
) {
  return useQuery<
    SubscriptionPlan[],
    unknown,
    TData,
    CurrentUserSubscriptionPlanQueryKey
  >(
    subscriptionPlanQueryKeys.currentUserSubscriptionPlan(),
    async () => {
      const data = await getCurrentUserSubscriptionPlan();

      return data;
    },
    options,
  );
}

export const useCurrentUserSubscriptionPlan = () =>
  useGetCurrentUserSubscriptionPlan({
    select: currentUserSubscriptionPlanSelector,
  });
