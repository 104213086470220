import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getImpact, Impact } from 'api/services';
import { getIsAuthenticated } from 'state/auth/utils';

import { treeQueryKeys } from './queryKeys';
import {
  hasImpactBalanceSelector,
  impactSelector,
  impactTonsSelector,
} from './selectors';
import { ImpactQueryKey } from './types';

export default function useGetImpact<TData = Impact>(
  options?: QueryOptions<Impact, TData, ImpactQueryKey>,
) {
  const isAuthenticated = getIsAuthenticated();

  return useQuery<Impact, unknown, TData, ImpactQueryKey>(
    treeQueryKeys.impact(),
    getImpact,
    {
      ...options,
      enabled: isAuthenticated,
    },
  );
}

export const useImpact = () => useGetImpact({ select: impactSelector });

export const useImpactTons = () => useGetImpact({ select: impactTonsSelector });

export const useHasImpactBalance = () =>
  useGetImpact({ select: hasImpactBalanceSelector });
