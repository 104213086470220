import { pdfsEndpoints } from 'api/constants';

import { downloadFile } from './fileDownload';
import { getFile } from './files';

/**
 * It takes a file name and a file name to export, and then downloads the file.
 *
 * @param {string} fileToExport - The name of the file you want to export.
 * @param {string} fileName - The name of the file you want to download.
 */
export async function handleExportFile(
  fileToExport: string,
  fileName: string,
): Promise<void> {
  const document: Blob = await getFile({
    fileEndpoint: `${pdfsEndpoints.DOCUMENTS}`,
    fileName,
  });

  downloadFile({ file: document, fileName: fileToExport });
}
