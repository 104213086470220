import { useState } from 'react';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import { useSetPageConfig } from 'components/Page';

import { useLoadSyntropyInformation } from './hooks';
import Reports from './Reports';
import * as S from './styles';
import SyntropyPageTabs from './SyntropyPageTabs';
import { SyntropyPageTabItem } from './types';
import VirtualForests from './VirtualForests';

const Syntropy: React.FC = () => {
  const [currentTabId, setCurrentTabId] =
    useState<SyntropyPageTabItem>('reports');

  const { syntropyInformationIsLoading, syntropyList, virtualForestsList } =
    useLoadSyntropyInformation();
  useSetPageConfig({
    mode: 'fluid',
    override: {
      isLoading: syntropyInformationIsLoading,
    },
  });

  return (
    <>
      <SyntropyPageTabs
        syntropyListSize={syntropyList.length}
        virtualForestsListSize={virtualForestsList.length}
        onTabSelected={setCurrentTabId}
      />
      <S.Root>
        <RenderWhen condition={currentTabId === 'reports'}>
          <Reports />
        </RenderWhen>

        <RenderWhen condition={currentTabId === 'communities'}>
          <VirtualForests />
        </RenderWhen>
      </S.Root>
    </>
  );
};

export default Syntropy;
