export const virtualForestQueryKeys = {
  all: [{ scope: 'virtualForest' }] as const,
  virtualForestList: () =>
    [
      { ...virtualForestQueryKeys.all[0], entity: 'virtualForestList' },
    ] as const,
  currentVirtualForests: () =>
    [
      { ...virtualForestQueryKeys.all[0], entity: 'currentVirtualForests' },
    ] as const,
  virtualForestAbsorptionReport: (
    virtualForestId?: string,
    language?: string,
  ) =>
    [
      {
        ...virtualForestQueryKeys.all[0],
        entity: 'virtualForestAbsorptionReport',
        virtualForestId,
        language,
      },
    ] as const,
  virtualForestCertificate: (virtualForestId?: string, language?: string) =>
    [
      {
        ...virtualForestQueryKeys.all[0],
        entity: 'virtualForestCertificate',
        virtualForestId,
        language,
      },
    ] as const,
  virtualForestById: (id?: string) =>
    [
      { ...virtualForestQueryKeys.all[0], entity: 'virtualForestById', id },
    ] as const,
};
