import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'components/App';

import { GlobalStyle } from './GlobalStyle';

import './setup';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
);
