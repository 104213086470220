import { useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { SVG } from 'types/common';

import {
  GearIcon,
  RenderWhen,
  SingleTreeIcon,
  WarningIcon,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';
import { useIsUpdatingSubscriptionPlan } from 'state/application';
import { useSubscription } from 'state/subscriptionPlan';
import { useUser } from 'state/user';

import * as S from './styles';

type Message = 'no-plan' | 'with-plan';

export type MessageConfig = {
  title: string;
  buttonText: string;
  icon: SVG;
};

const StatusIndicator: React.FC<WithTranslation> = ({ t }) => {
  const { managePlan } = useSubscription();

  const { data: user } = useUser();
  const { subscriptionPlan, isValidPhysicalPerson } = user || {};
  const isUpdatingSubscriptionPlan = useIsUpdatingSubscriptionPlan();

  const messagesConfig = useMemo(
    (): Record<Message, MessageConfig> => ({
      'no-plan': {
        title: t('noPlan:title'),
        buttonText: t('noPlan:buttonText'),
        icon: WarningIcon,
      },
      'with-plan': {
        title: t('withPlan:title'),
        buttonText: t('withPlan:buttonText'),
        icon: SingleTreeIcon,
      },
    }),
    [t],
  );

  const hasPlan = !!subscriptionPlan && subscriptionPlan?.status;
  const planName = `${subscriptionPlan?.units || 0} ${t(
    `${subscriptionPlan?.units === 1 ? 'monthlyTree' : 'monthlyTrees'}`,
  )}`;

  const config: MessageConfig =
    messagesConfig[hasPlan ? 'with-plan' : 'no-plan'];

  const Icon = config.icon;

  const handleManagePlan = useCallback((): void => {
    managePlan(
      isValidPhysicalPerson
        ? 'user-settings/physical-person'
        : 'user-settings/legal-person',
    );
  }, [isValidPhysicalPerson, managePlan]);

  return (
    <S.Root $hasPlan={hasPlan}>
      <S.IconContainer $hasPlan={hasPlan}>
        <Icon width={18} height={20} />
      </S.IconContainer>

      <S.Contents>
        <RenderWhen condition={hasPlan}>
          <S.PlanName>{subscriptionPlan?.name}</S.PlanName>
        </RenderWhen>
        <S.Title $hasPlan={hasPlan}>
          {`${hasPlan ? '' : config.title}`}
          {`${hasPlan ? planName : ''}`}
        </S.Title>

        <S.MainButton to={getPathnameFromKey('subscriptionPlans')}>
          {config.buttonText}
        </S.MainButton>
      </S.Contents>

      <RenderWhen condition={hasPlan}>
        <S.ManagePlanButton onPress={handleManagePlan}>
          <RenderWhen condition={!isUpdatingSubscriptionPlan}>
            <GearIcon />
          </RenderWhen>
          <RenderWhen condition={isUpdatingSubscriptionPlan}>
            <S.CustomLoader />
          </RenderWhen>
        </S.ManagePlanButton>
      </RenderWhen>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'statusIndicator',
})(StatusIndicator);
