import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '../../../api';
import {
  hasErrorHandler,
  passwordSuccess,
  validatePasswordAndPurposeRequest,
} from './actions';
import { OTPValidationAction } from './types';

type ValidatePasswordAndPurpose = ReturnType<
  typeof validatePasswordAndPurposeRequest
>;

const PREFIX = '/v1/account';

function* validatePasswordAndPurpose({ payload }: ValidatePasswordAndPurpose) {
  try {
    yield put(hasErrorHandler(false));

    yield call(request.post, `${PREFIX}/otp/send`, {
      purpose: payload.purpose,
      password: payload.password,
    });

    yield put(passwordSuccess(payload.password, true));
  } catch (error) {
    yield put(passwordSuccess('', false));
    yield put(hasErrorHandler(true));
  }
}

export default all([
  takeLatest(
    OTPValidationAction.VALIDATE_PASSWORD_AND_PURPOSE_VALIDATION,
    validatePasswordAndPurpose,
  ),
]);
