import { styled } from 'styled-components';

import { Link, Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
  gap: 2,
})`
  width: 100%;
  flex-wrap: wrap;
`;

export const SocialMediaLink = styled(Link).attrs({
  variant: 'text',
  size: 24,
  target: '_blank',
})``;
