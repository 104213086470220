import { isValidPhoneNumber } from 'react-phone-number-input';
import { validateEntity, validatePerson } from 'stdnum';
import { CountryCode } from 'types/country';
import { z } from 'zod';

import { VALID_EMAIL_FORMAT } from '@abundance-brasil-wabi/utils';

import { fieldErrorMessages } from 'constants/forms';

function validateTinNumber(
  validator: typeof validatePerson,
  country: string,
  tinNumber = '',
): boolean {
  const { isValid } = validator(country, tinNumber);

  return isValid ?? false;
}

export const physicalPersonFormSchema = z
  .object({
    name: z.string().min(1, fieldErrorMessages.name.required),
    email: z
      .string()
      .min(1, fieldErrorMessages.email.required)
      .regex(
        new RegExp(VALID_EMAIL_FORMAT),
        fieldErrorMessages.email.wrongPattern,
      ),
    phoneNumber: z.string().optional(),
    language: z.string().optional(),
    country: z.string().optional(),
    taxIdentificationNumber: z.string().optional(),
  })
  .refine(
    ({ phoneNumber = '', country }) =>
      isValidPhoneNumber(phoneNumber, country as CountryCode),
    {
      message: fieldErrorMessages.phoneNumber.invalid,
      path: ['phoneNumber'],
    },
  )
  .refine(({ country }) => country, {
    message: fieldErrorMessages.tin.noCountry,
    path: ['country'],
  })
  .refine(({ taxIdentificationNumber }) => taxIdentificationNumber, {
    message: fieldErrorMessages.tin.required,
    path: ['taxIdentificationNumber'],
  })
  .refine(
    ({ country, taxIdentificationNumber }) => {
      if (!country) return false;

      const personValidator = validateTinNumber(
        validatePerson,
        country,
        taxIdentificationNumber,
      );

      const entityValidator = validateTinNumber(
        validateEntity,
        country,
        taxIdentificationNumber,
      );

      if (!personValidator && !entityValidator) return false;

      return true;
    },
    {
      message: fieldErrorMessages.tin.invalid,
      path: ['taxIdentificationNumber'],
    },
  );
