import { isValidPhoneNumber } from 'react-phone-number-input';
import { CountryCode } from 'types/country';
import { z } from 'zod';

import {
  STRONG_PASSWORD,
  VALID_EMAIL_FORMAT,
} from '@abundance-brasil-wabi/utils';

import { fieldErrorMessages } from 'constants/forms';

export const signUpFormSchema = z
  .object({
    name: z.string().min(1, fieldErrorMessages.name.required),
    email: z
      .string()
      .min(1, fieldErrorMessages.email.required)
      .regex(
        new RegExp(VALID_EMAIL_FORMAT),
        fieldErrorMessages.email.wrongPattern,
      ),
    phoneNumber: z.string().optional(),
    country: z.string().optional(),
    password: z
      .string()
      .min(1, fieldErrorMessages.password.required)
      .regex(
        new RegExp(STRONG_PASSWORD),
        fieldErrorMessages.password.wrongPasswordPattern,
      ),
    passwordConfirm: z
      .string()
      .min(1, fieldErrorMessages.newPasswordConfirm.required)
      .regex(
        new RegExp(STRONG_PASSWORD),
        fieldErrorMessages.newPasswordConfirm.wrongPattern,
      ),
    howDidYouGetHere: z.string().optional(),
    subscribeToNewsletter: z.boolean().optional(),
  })
  .refine(
    ({ phoneNumber = '', country }) =>
      isValidPhoneNumber(phoneNumber, country as CountryCode),
    {
      message: fieldErrorMessages.phoneNumber.invalid,
      path: ['phoneNumber'],
    },
  )
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    message: fieldErrorMessages.newPasswordConfirm.passwordsDontMatch,
    path: ['passwordConfirm'],
  })
  .refine(({ howDidYouGetHere }) => howDidYouGetHere, {
    message: fieldErrorMessages.dropdown.required,
    path: ['howDidYouGetHere'],
  });
