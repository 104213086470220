import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  position: relative;

  // !important is used here to override Google Map's default styles.

  // Removing copyright notices.
  .gm-style-cc {
    display: none;
  }

  // Removing Google logo off of the map.
  a[title='Open this area in Google Maps (opens a new window)'] {
    display: none !important;
  }

  .gm-svpc {
    top: 113px !important;
    border-radius: 50% !important;
  }

  .gm-bundled-control-on-bottom:last-child {
    right: 56px !important;
    bottom: 174px !important;

    .gmnoprint {
      display: block !important;
      top: 8px !important;

      div {
        height: 100px;
        display: flex !important;
        flex-direction: column-reverse !important;
        box-shadow: none !important;
        background-color: transparent !important;

        button {
          border-radius: 50%;
          background-color: ${getThemeColor('white')} !important;
          color: $black !important;

          &:first-of-type {
            top: 4px !important;
          }
        }

        div {
          height: 0 !important;
        }
      }
    }

    ${breakpointDown('xs')`
      right: 46px !important;
      bottom: 159px !important;
    `}
  }
`;

export const LayerSelectorContainer = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-end',
  justify: 'flex-start',
  gap: '8px',
})`
  position: absolute;
  bottom: 32px;
  left: 32px;

  ${breakpointDown('xs')`
    bottom: 16px;
    left: 16px;
  `}
`;
