import { useSetPageConfig } from 'components/Page';
import { DEFAULT_FLUID_PAGE_SETTINGS } from 'components/Page/constants';
import { checkIsValidPhysicalPerson } from 'state/auth/utils';

import LegalPerson from './LegalPerson';
import PhysicalPerson from './PhysicalPerson';

const UserSettings: React.FC = () => {
  const isValidPhysicalPerson = checkIsValidPhysicalPerson();
  useSetPageConfig({
    mode: 'split',
    override: {
      sideContent: DEFAULT_FLUID_PAGE_SETTINGS.sideContent,
    },
  });

  return isValidPhysicalPerson ? <PhysicalPerson /> : <LegalPerson />;
};

export default UserSettings;
