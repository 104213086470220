import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  height: 100vh;
`;

export const Container = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: 100%;
  background-color: ${getThemeColor('black')};
  overflow: hidden;
`;

export const Content = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100vw;
  padding: 24px;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: hidden auto;
`;

export const Logo = styled.img`
  margin-bottom: 24px;
`;

export const Title = styled(Heading).attrs({
  level: 4,
})`
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('white')};
`;

export const Body = styled(Text).attrs({
  level: 'body',
})`
  margin-top: 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('white')};
`;
