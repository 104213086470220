import { produce } from 'immer';
import { Reducer } from 'redux';

import { ModalAction, ModalState } from './types';

const INITIAL_STATE: ModalState = {
  isModalOpen: false,
  name: null,
  params: null,
  onHideValue: null,
};

const modal: Reducer<ModalState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ModalAction.PUSH_MODAL: {
        const { name, params } = action.payload || {};

        draft.name = name;
        draft.params = params;
        draft.isModalOpen = true;

        break;
      }

      case ModalAction.SET_ON_HIDE_MODAL_VALUE: {
        const { onHideValue } = action.payload || {};

        draft.onHideValue = onHideValue;

        break;
      }

      case ModalAction.HIDE_MODAL: {
        draft.onHideValue = action.payload?.onHideValue || null;
        draft.name = null;
        draft.isModalOpen = false;

        break;
      }

      case ModalAction.TOGGLE_MODAL: {
        draft.isModalOpen = !state.isModalOpen;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default modal;
