import { MutationOptions } from 'types/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  createCarbonOffsetCalculation,
  CreateCarbonOffsetCalculationArgs,
  CreateCarbonOffsetCalculationResponse,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';
import { useUserId } from 'state/user';

import { carbonOffestQueryKeys } from './queryKeys';

export default function useCreateCarbonOffsetCalculation(
  options?: MutationOptions<
    CreateCarbonOffsetCalculationResponse,
    CreateCarbonOffsetCalculationArgs
  >,
) {
  const { data: userId } = useUserId();
  const queryClient = useQueryClient();

  return useMutation<
    CreateCarbonOffsetCalculationResponse,
    ErrorMessage,
    CreateCarbonOffsetCalculationArgs,
    unknown
  >(createCarbonOffsetCalculation, {
    ...options,
    onSuccess: (response) => {
      if (userId) {
        queryClient.setQueryData(
          carbonOffestQueryKeys.currentUserCarbonOffsetCalculation(userId),
          response,
        );
      }

      queryClient.setQueryData(
        carbonOffestQueryKeys.carbonOffsetCalculation(userId, response.id),
        response,
      );
    },
  });
}
