import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { IMAGE_URL } from 'constants/assets';

import { StyledBannerProps } from './types';

function getRootSizeStyle({ $size }: StyledBannerProps) {
  switch ($size) {
    case 'small': {
      return `
        height: 100%;
        padding: 32px;

        ${breakpointDown('sm')`
          padding: 24px;
        `}
      `;
    }

    default: {
      return `
        height: 259px;
        padding: 65px 48px;

        ${breakpointDown('lg')`
          width: 100%;
          border-radius: 0;
          padding: 24px;
        `}
      `;
    }
  }
}

function getTitlesSizeStyle({ $size }: StyledBannerProps) {
  switch ($size) {
    case 'small': {
      return `
        ${createFlexDisplay({
          direction: 'column',
          align: 'flex-start',
          justify: 'center',
          gap: '4px',
        })};
    `;
    }

    default: {
      return `
        ${createFlexDisplay({
          direction: 'column',
          align: 'flex-start',
          justify: 'center',
          gap: '10px',
        })};

        ${breakpointDown('sm')`
          ${createFlexDisplay({
            direction: 'column',
            align: 'flex-start',
            justify: 'center',
          })};
        `}
      `;
    }
  }
}

function getMiddleTitlesSizeStyle({ $size }: StyledBannerProps) {
  switch ($size) {
    case 'small': {
      return `
        font-size: 24px;

        ${breakpointDown('sm')`
          font-size: 16px;
        `}

        ${breakpointDown('xxxs')`
          font-size: 12px;
        `}
      `;
    }

    default: {
      return `
        font-size: 34px;

        ${breakpointDown('sm')`
          font-size: 22px;
        `}
      `;
    }
  }
}

function getLowerTitlesSizeStyle({ $size }: StyledBannerProps) {
  switch ($size) {
    case 'small': {
      return `
        font-size: 13px;
      `;
    }

    default: {
      return `
        font-size: 16px;

        ${breakpointDown('sm')`
          font-size: 14px;
        `}
      `;
    }
  }
}

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})<StyledBannerProps>`
  width: 100%;
  border-radius: 8px;
  background-image: url(${`${IMAGE_URL}/v1702754414/Assets/Backgrounds/leaves-3_sr4cjk.png`});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: inherit;
  background-color: $black;

  ${getRootSizeStyle};

  ${breakpointDown('sm')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'center',
      gap: '18px',
    })};
  `}
`;

export const Titles = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})<StyledBannerProps>`
  margin-right: 12px;
  color: ${getThemeColor('white')};

  ${getTitlesSizeStyle};
`;

export const UpperTitle = styled(Text).attrs({
  level: 'caption',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'primary', 'default')};
`;

export const MiddleTitle = styled(Heading).attrs({
  level: 5,
})<StyledBannerProps>`
  ${getMiddleTitlesSizeStyle};
`;

export const LowerTitle = styled(Text).attrs({
  level: 'bodySmall',
})<StyledBannerProps>`
  font-weight: ${getThemeFontWeight('regular')};

  ${getLowerTitlesSizeStyle};
`;
