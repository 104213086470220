import { useCallback, useState } from 'react';
import { isArray, isNil } from 'lodash';
import { withTranslation } from 'react-i18next';

import { Button, Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';

import { CarbonOffsetCalculation } from 'api/services';
import { WithTranslation } from 'i18n/types';
import LinkButton from 'components/LinkButton';
import { getPathnameFromKey } from 'router/utils';
import { getIsAuthenticated } from 'state/auth/utils';
import { useUpdateCarbonOffsetCalculationWithSlider } from 'state/carbonOffset';
import { createSearchParams } from 'utils/formatting';

import Results from './Results';
import * as S from './styles';

export type CarbonCompensationCalculatorProps = {
  calculation?: CarbonOffsetCalculation;
  className?: string;
};

const CarbonCompensationCalculator: React.FC<
  WithTranslation<CarbonCompensationCalculatorProps>
> = ({ calculation, className, t }) => {
  const {
    mutateAsync: createCarbonOffsetCalculation,
    isLoading: isCreatingCarbonOffsetCalculation,
  } = useUpdateCarbonOffsetCalculationWithSlider();
  const isAuthenticated = getIsAuthenticated();

  const [isPurchasing, setIsPurchasing] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number | undefined>();
  const [quantityDisplay, setQuantityDisplay] = useState<number | undefined>(
    quantity,
  );
  const [allowPurchase, setAllowPurchase] = useState(true);

  const { id, compensationInfo, checkoutLink } = calculation || {};

  const handleChangeTressAmout = useCallback(
    (newTreesAmout: number | number[]): void => {
      setQuantity(isArray(newTreesAmout) ? newTreesAmout[0] : newTreesAmout);
    },
    [],
  );

  const handleCreateNewCalculation = useCallback((): void => {
    if (!quantity) {
      setAllowPurchase(false);

      return;
    }

    setAllowPurchase(true);
    setQuantityDisplay(quantity);

    createCarbonOffsetCalculation({
      body: {
        quantity,
        returnUrl: `${getPathnameFromKey(
          isAuthenticated ? 'home' : 'signUpCompany',
        )}?${`email=${encodeURIComponent(
          calculation?.enrollment?.email || '',
        )}&name=${encodeURIComponent(calculation?.enrollment?.name || '')}`}`,
      },
      params: {
        id,
      },
    });
  }, [
    calculation?.enrollment?.email,
    calculation?.enrollment?.name,
    createCarbonOffsetCalculation,
    id,
    isAuthenticated,
    quantity,
  ]);

  const handleBuyTrees = useCallback((): void => {
    if (!checkoutLink) return;

    setIsPurchasing(true);

    window.location.href = checkoutLink;
  }, [checkoutLink]);

  if (isNil(quantity) && !isNil(compensationInfo)) {
    setQuantity(compensationInfo.trees);
    setQuantityDisplay(compensationInfo?.trees);
  }

  return (
    <S.Root className={className}>
      <RenderWhen condition={isCreatingCarbonOffsetCalculation}>
        <Loader />
      </RenderWhen>

      <RenderWhen condition={!isCreatingCarbonOffsetCalculation}>
        <S.Slider
          fluid
          aria-labelledby={t('label')}
          value={quantity}
          minValue={0}
          maxValue={compensationInfo?.trees}
          isDisabled={compensationInfo?.trees === 0}
          onChange={handleChangeTressAmout}
          onChangeEnd={handleCreateNewCalculation}
        />

        <Results
          compensationInfo={compensationInfo}
          quantity={quantityDisplay}
        />

        <S.ButtonsContainer>
          <LinkButton
            variant="secondary-outline"
            to={getPathnameFromKey('carbonOffsetCalculator')}
            search={createSearchParams({
              calculationId: id || '',
            })}
          >
            {t('recalculate')}
          </LinkButton>

          <Button
            onPress={handleBuyTrees}
            isDisabled={
              !allowPurchase ||
              isPurchasing ||
              Number(compensationInfo?.trees) <= 0
            }
          >
            {t('buy')}
          </Button>
        </S.ButtonsContainer>
      </RenderWhen>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'carbonCompensationCalculator',
})(CarbonCompensationCalculator);
