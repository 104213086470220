import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import AppLayout from 'components/App/AppLayout';
import Page from 'components/Page';
import Articles from 'pages/Articles';
import CarbonOffsetCalculator from 'pages/CarbonOffsetCalculator';
import CarbonOffsetResults from 'pages/CarbonOffsetResults';
import ChangePassword from 'pages/ChangePassword';
import CompanySettings from 'pages/CompanySettings';
import CreateNewPassword from 'pages/CreateNewPassword';
import CreateNewPasswordConfirm from 'pages/CreateNewPasswordConfirm';
import CreateNewPasswordSuccess from 'pages/CreateNewPasswordSuccess';
import EmailConfirmation from 'pages/EmailConfirmation';
import EmailConfirmationSuccess from 'pages/EmailConfirmationSuccess';
import ESGReport from 'pages/ESGReport';
import ForestProducerRegistration from 'pages/ForestProducerRegistration';
import ForestProducerRegistrationSuccess from 'pages/ForestProducerRegistrationSuccess';
import ForgotPassword from 'pages/ForgotPassword';
import Home from 'pages/Home';
import HowToGetMyCertificate from 'pages/HowToGetMyCertificate';
import HowToGetMyTokens from 'pages/HowToGetMyTokens';
import Impact from 'pages/Impact';
import Locked from 'pages/Locked';
import MediaKit from 'pages/MediaKit';
import MobileIntroduction from 'pages/MobileIntroduction';
import MyCertificate from 'pages/MyCertificate';
import NotFound from 'pages/NotFound';
import PurchaseWithoutAccount from 'pages/PurchaseWithoutAccount';
import PurchaseWithoutAccountConfirmation from 'pages/PurchaseWithoutAccountConfirmation';
import RegisterYourProfile from 'pages/RegisterYourProfile';
import SecurityVerification from 'pages/SecurityVerification';
import SelectAccountType from 'pages/SelectAccountType';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import SubscriptionPlans from 'pages/SubscriptionPlans';
import Support from 'pages/Support';
import Syntropy from 'pages/Syntropy';
import ForestDetails from 'pages/Syntropy/ForestDetails';
import UserSettings from 'pages/UserSettings';
import VirtualTour from 'pages/VirtualTour';
import Wallet from 'pages/Wallet';

import {
  Free,
  Inital,
  OnlyLegalPerson,
  OnlyWithBalance,
  OnlyWithNoBalance,
  OnlyWithSecurityCodeRequested,
  OnlyWithSyntropyAccess,
  Private,
  Public,
} from './guards';
import { getPathnameFromKey } from './utils';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route element={<Page />}>
        {/* Free routes */}
        <Route element={<Free />}>
          {/* Main route */}
          <Route path="/" element={<Inital />} />

          {/* Not found route */}
          <Route path="*" element={<NotFound />} />

          {/* Other free wroutes */}
          <Route
            path={getPathnameFromKey('carbonOffsetCalculator')}
            element={<CarbonOffsetCalculator />}
          />

          <Route
            path={getPathnameFromKey('carbonOffsetResults')}
            element={<CarbonOffsetResults />}
          />
        </Route>

        {/** Public routes */}
        <Route element={<Public />}>
          <Route
            path={getPathnameFromKey('mobileIntroduction')}
            element={<MobileIntroduction />}
          />
          <Route path={getPathnameFromKey('signIn')} element={<SignIn />} />
          <Route path={getPathnameFromKey('locked')} element={<Locked />} />
          <Route
            path={getPathnameFromKey('purchaseWithoutAccount')}
            element={<PurchaseWithoutAccount />}
          />
          <Route
            path={getPathnameFromKey('purchaseWithoutAccountConfirmation')}
            element={<PurchaseWithoutAccountConfirmation />}
          />
          <Route
            path={getPathnameFromKey('emailConfirmation')}
            element={<EmailConfirmation />}
          />
          <Route
            path={getPathnameFromKey('emailConfirmationSuccess')}
            element={<EmailConfirmationSuccess />}
          />
          <Route
            path={getPathnameFromKey('signUpCompany')}
            element={<SignUp />}
          />
          <Route
            path={getPathnameFromKey('signUpPersonal')}
            element={<SignUp isPhysicalPerson />}
          />
          <Route
            path={getPathnameFromKey('createNewPassword')}
            element={<CreateNewPassword />}
          />
          <Route
            path={getPathnameFromKey('createNewPasswordConfirm')}
            element={<CreateNewPasswordConfirm />}
          />
          <Route
            path={getPathnameFromKey('createNewPasswordSuccess')}
            element={<CreateNewPasswordSuccess />}
          />
          <Route
            path={getPathnameFromKey('forgotPassword')}
            element={<ForgotPassword />}
          />
          <Route
            path={getPathnameFromKey('selectAccountType')}
            element={<SelectAccountType />}
          />
          <Route
            path={getPathnameFromKey('registerYourProfile')}
            element={<RegisterYourProfile />}
          />
          <Route
            path={getPathnameFromKey('forestProducerRegistration')}
            element={<ForestProducerRegistration />}
          />
          <Route
            path={getPathnameFromKey('forestProducerRegistrationSuccess')}
            element={<ForestProducerRegistrationSuccess />}
          />

          {/** Only with security code requested routes */}
          <Route element={<OnlyWithSecurityCodeRequested />}>
            <Route
              path={getPathnameFromKey('securityVerification')}
              element={<SecurityVerification />}
            />
          </Route>
        </Route>

        {/* Private routes */}
        <Route element={<Private />}>
          <Route path={getPathnameFromKey('articles')} element={<Articles />} />
          <Route path={getPathnameFromKey('impact')} element={<Impact />} />
          <Route path={getPathnameFromKey('home')} element={<Home />} />
          <Route
            path={getPathnameFromKey('changePassword')}
            element={<ChangePassword />}
          />
          <Route
            path={getPathnameFromKey('userSettings')}
            element={<UserSettings />}
          />
          <Route
            path={getPathnameFromKey('subscriptionPlans')}
            element={<SubscriptionPlans />}
          />
          <Route path={getPathnameFromKey('support')} element={<Support />} />
          <Route
            path={getPathnameFromKey('syntropyReports')}
            element={<Syntropy />}
          />
          <Route
            path={getPathnameFromKey('syntropyCommunities')}
            element={<Syntropy />}
          />
          <Route
            path={getPathnameFromKey('syntropyCommunitiesDetails')}
            element={<ForestDetails />}
          />
          <Route path={getPathnameFromKey('wallet')} element={<Wallet />} />
          <Route
            path={getPathnameFromKey('virtualTour')}
            element={<VirtualTour />}
          />

          {/* Only with no balance routes */}
          <Route element={<OnlyWithNoBalance />}>
            <Route
              path={getPathnameFromKey('howToGetMyCertificate')}
              element={<HowToGetMyCertificate />}
            />
            <Route
              path={getPathnameFromKey('howToGetMyTokens')}
              element={<HowToGetMyTokens />}
            />
          </Route>

          {/* Only with balance routes */}
          <Route element={<OnlyWithBalance />}>
            <Route
              path={getPathnameFromKey('myCertificate')}
              element={<MyCertificate />}
            />
            <Route
              path={getPathnameFromKey('mediaKit')}
              element={<MediaKit />}
            />
          </Route>

          {/* Only legal person routes */}
          <Route element={<OnlyLegalPerson />}>
            <Route
              path={getPathnameFromKey('companySettings')}
              element={<CompanySettings />}
            />
          </Route>

          {/* Only with Syntropy access routes */}
          <Route element={<OnlyWithSyntropyAccess />}>
            <Route
              path={getPathnameFromKey('esgReport')}
              element={<ESGReport />}
            />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

export default router;
