import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { Syntropy } from './types';

export async function getSyntropyList() {
  const { data } = await request.get<
    Syntropy[],
    AxiosResponse<Syntropy[]>,
    never
  >(abundanceAPI.V1.SYNTROPY);

  return data;
}
