import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AnnualFlightsFrequency,
  AverageMonthlyElectricityBillCost,
  ElectricityFromRenewableSources,
  PercentageOfWasteRecycled,
  WorkModelAdopted,
} from 'api/services';
import { DropdownOption } from 'components/FormFields/Dropdown/types';

export type UseGetFormOptions = {
  workModelAdopted: DropdownOption<WorkModelAdopted>[];
  flightsFrequency: DropdownOption<AnnualFlightsFrequency>[];
  averageMonthlyElectricityBillCost: DropdownOption<AverageMonthlyElectricityBillCost>[];
  electricityFromRenewableSources: DropdownOption<ElectricityFromRenewableSources>[];
  booleanQuestion: DropdownOption<boolean>[];
  percentageOfWasteRecycled: DropdownOption<PercentageOfWasteRecycled>[];
};

export function useGetFormOptions(): UseGetFormOptions {
  const { t } = useTranslation('pages', {
    keyPrefix: 'carbonOffsetCalculator:carbonOffsetCalculatorForm',
  });

  const workModelAdopted = useMemo((): DropdownOption<WorkModelAdopted>[] => {
    return [
      {
        label: t('menuItems:workModelAdopted:full'),
        value: 0,
      },
      {
        label: t('menuItems:workModelAdopted:partial'),
        value: 1,
      },
      {
        label: t('menuItems:workModelAdopted:remote'),
        value: 2,
      },
    ];
  }, [t]);

  const flightsFrequency =
    useMemo((): DropdownOption<AnnualFlightsFrequency>[] => {
      return [
        {
          label: t('menuItems:flightsFrequency:itemOne'),
          value: 0,
        },
        {
          label: t('menuItems:flightsFrequency:itemTwo'),
          value: 1,
        },
        {
          label: t('menuItems:flightsFrequency:itemThree'),
          value: 2,
        },
        {
          label: t('menuItems:flightsFrequency:itemFour'),
          value: 3,
        },
        {
          label: t('menuItems:flightsFrequency:itemFive'),
          value: 4,
        },
      ];
    }, [t]);

  const averageMonthlyElectricityBillCost =
    useMemo((): DropdownOption<AverageMonthlyElectricityBillCost>[] => {
      return [
        {
          label: t('menuItems:averageMonthlyElectricityBillCost:low'),
          value: 0,
        },
        {
          label: t('menuItems:averageMonthlyElectricityBillCost:moderate'),
          value: 1,
        },
        {
          label: t('menuItems:averageMonthlyElectricityBillCost:high'),
          value: 2,
        },
      ];
    }, [t]);

  const electricityFromRenewableSources =
    useMemo((): DropdownOption<ElectricityFromRenewableSources>[] => {
      return [
        {
          label: t('menuItems:electricityFromRenewableSources:high'),
          value: 0,
        },
        {
          label: t('menuItems:electricityFromRenewableSources:average'),
          value: 1,
        },
        {
          label: t('menuItems:electricityFromRenewableSources:none'),
          value: 2,
        },
      ];
    }, [t]);

  const booleanQuestion = useMemo((): DropdownOption<boolean>[] => {
    return [
      { label: t('menuItems:booleanQuestion:yes'), value: true },
      { label: t('menuItems:booleanQuestion:no'), value: false },
    ];
  }, [t]);

  const percentageOfWasteRecycled =
    useMemo((): DropdownOption<PercentageOfWasteRecycled>[] => {
      return [
        {
          label: t('menuItems:percentageOfWasteRecycled:itemOne'),
          value: 0,
        },
        {
          label: t('menuItems:percentageOfWasteRecycled:itemTwo'),
          value: 1,
        },
        {
          label: t('menuItems:percentageOfWasteRecycled:itemThree'),
          value: 2,
        },
        {
          label: t('menuItems:percentageOfWasteRecycled:itemFour'),
          value: 3,
        },
      ];
    }, [t]);

  return {
    workModelAdopted,
    flightsFrequency,
    averageMonthlyElectricityBillCost,
    electricityFromRenewableSources,
    booleanQuestion,
    percentageOfWasteRecycled,
  };
}
