import { Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';

import BackLink from 'components/BackLink';
import { PathKey } from 'router/types';

import * as S from './styles';

export type FormPageContainerProps = {
  /**
   * Whether the form info is loading.
   */
  isLoading?: boolean;
  /**
   * Where to redirect when clicking on the back button.
   */
  to?: PathKey;
  /**
   * Search parameters to be used when redirecting the user after clicking on the back button.
   */
  search?: string;
  /**
   * The forms content to be rendered.
   */
  children?: React.ReactNode;
};

const FormPageContainer: React.FC<FormPageContainerProps> = ({
  isLoading,
  to,
  search,
  children,
}) => {
  return (
    <S.Root>
      <RenderWhen condition={isLoading}>
        <Loader isFullScreen />
      </RenderWhen>

      <RenderWhen condition={!isLoading}>
        {to && <BackLink to={to} search={search} />}

        {children}
      </RenderWhen>
    </S.Root>
  );
};
export default FormPageContainer;
