import { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import { RenderWhen, useDelay } from '@abundance-brasil-wabi/sabi';

import { useSetPageConfig } from 'components/Page';
import { DEFAULT_APPLICATION_LOADING_TIME_MS } from 'constants/application';
import { VIDEO_URL } from 'constants/assets';

import * as S from './styles';

const VirtualTour: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [delayedOnLoadingComplete] = useDelay(
    () => setIsLoading(false),
    DEFAULT_APPLICATION_LOADING_TIME_MS,
  );

  useEffect(() => {
    if (isLoading) delayedOnLoadingComplete();
  }, [delayedOnLoadingComplete, isLoading]);

  useSetPageConfig({
    mode: 'fluid',
    override: {
      hideFooter: true,
      bybassInitialLoading: true,
    },
  });

  return (
    <S.Root>
      <RenderWhen condition={isLoading}>
        <S.CustomLoader />
      </RenderWhen>

      <RenderWhen condition={!isLoading}>
        <ReactAudioPlayer
          src={`${VIDEO_URL}/v1702757361/Assets/Audios/forest-sounds_xn4jpp.wav`}
          preload="auto"
          autoPlay
          loop
        />
      </RenderWhen>

      <S.Tour
        title="Virtual Tour"
        src="https://www.banibconecta.com/site/tour/150822/abundance-brasil/novo-plantio-2023-vr/autostart"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="gyroscope; accelerometer; xr-spatial-tracking; clipboard-write"
        allowFullScreen
      />
    </S.Root>
  );
};

export default VirtualTour;
