import { withTranslation } from 'react-i18next';

import { Heading, Text } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import ColorfulBackground from 'components/ColorfulBackground';
import { useSetPageConfig } from 'components/Page';
import { abundanceURLs } from 'constants/externalURLs';

import * as S from './styles';

const ForestProducerRegistrationSuccess: React.FC<WithTranslation> = ({
  t,
}) => {
  useSetPageConfig({ mode: 'cover' });

  return (
    <ColorfulBackground>
      <S.Root>
        <S.Logo />

        <Heading level={4}>{t('title')}</Heading>

        <Text level="body">{t('firstParagraph')}</Text>

        <Text level="body">
          {t('forMore')} <S.Link href={abundanceURLs.faq}>{t('faq')}</S.Link>{' '}
          {t('contact')}{' '}
          <S.Link href={abundanceURLs.contact}>{t('here')}</S.Link>.
        </Text>
      </S.Root>
    </ColorfulBackground>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'forestProducerRegistrationSuccess',
})(ForestProducerRegistrationSuccess);
