import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  position: relative;
  top: 8px;
`;

export const Items = styled.ul`
  ${createFlexDisplay({
    direction: 'row',
    align: 'flex-start',
    justify: 'flex-start',
    gap: '16px',
  })};

  list-style: none;
`;

export const Item = styled.li`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'center',
    gap: '4px',
  })};
`;

export const Icon = styled.img`
  width: 13px;
  height: 13px;
`;

export const SummaryText = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('default')};

  b {
    color: ${getThemeColor('white')};
  }

  ${breakpointDown('lg')`
    span {
      display: none;
    }
  `};
`;
