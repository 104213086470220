import { useMemo, useRef } from 'react';
import { isNil } from 'lodash';
import { Outlet, useOutletContext } from 'react-router-dom';

import { Backdrop, Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';

import Modal from 'components/Modal';
import Navbars from 'components/Navbars';
import useAppFocus from 'hooks/useAppFocus';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { PageOutletContextType } from 'router/guards/types';
import { usePageConfig } from 'state/application';
import { getIsAuthenticated } from 'state/auth/utils';

import Footer from './Footer';
import * as S from './styles';
import TitleBar from './TitleBar';
import usePageBackdrop from './usePageBackdrop';

const Page: React.FC = (): React.ReactNode => {
  const {
    mode,
    topContent,
    sideContent,
    hideTitleBar,
    hideSideContent,
    hideFooter,
    isLoading,
    bybassInitialLoading,
  } = usePageConfig();

  const pageRef = useRef(null);
  const sideContentRef = useRef<HTMLElement>(null);

  const { onClick: onPageBackdropClick } = usePageBackdrop();
  const { isAppFocused } = useAppFocus();
  const { isAppLoaded } = useOutletContext<PageOutletContextType>() || {};
  const isAuthenticated = getIsAuthenticated();

  useScrollIntoView();

  const isLoaded = useMemo((): boolean => {
    if (bybassInitialLoading || !isAuthenticated || isNil(isAppLoaded))
      return true;

    return isAppLoaded && !isLoading;
  }, [bybassInitialLoading, isAppLoaded, isAuthenticated, isLoading]);

  return (
    <>
      <Backdrop isOpen={!isAppFocused} onClick={onPageBackdropClick} />

      <Modal />

      <Navbars />

      <S.Root ref={pageRef}>
        {!hideTitleBar && <TitleBar />}

        <S.Main>
          <S.Container>
            {!hideSideContent && (
              <S.Side ref={sideContentRef}>{sideContent}</S.Side>
            )}

            <S.Contents
              $mode={mode}
              $sideContentWidth={sideContentRef.current?.offsetWidth}
            >
              <RenderWhen condition={isLoaded}>
                {topContent && topContent}
              </RenderWhen>

              <S.Children $mode={mode} $isLoaded={isLoaded}>
                <RenderWhen condition={!isLoaded}>
                  <S.LoaderContainer>
                    <Loader />
                  </S.LoaderContainer>
                </RenderWhen>

                <RenderWhen condition={isLoaded}>
                  <RenderWhen condition={mode === 'cover'}>
                    <S.Cover>
                      <S.FluidContents>
                        <Outlet />
                      </S.FluidContents>
                    </S.Cover>
                  </RenderWhen>

                  <RenderWhen condition={mode === 'fluid'}>
                    <S.Fluid>
                      <S.FluidContents>
                        <Outlet />
                      </S.FluidContents>
                    </S.Fluid>
                  </RenderWhen>

                  <RenderWhen condition={mode === 'expanded'}>
                    <S.Expanded>
                      <Outlet />
                    </S.Expanded>
                  </RenderWhen>

                  <RenderWhen condition={mode === 'split'}>
                    <S.Split>
                      <Outlet />
                    </S.Split>
                  </RenderWhen>
                </RenderWhen>
              </S.Children>
            </S.Contents>
          </S.Container>
        </S.Main>

        {!hideFooter && <Footer />}
      </S.Root>
    </>
  );
};

export default Page;
