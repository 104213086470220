import { styled } from 'styled-components';

import { Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 464px;
  position: relative;
  padding: 10px 16px;

  @media (max-width: 630px) {
    width: 100%;
    padding: 8px;
  }
`;
