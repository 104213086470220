import ReCAPTCHA from 'react-google-recaptcha';
import { styled } from 'styled-components';

import { breakpointDown, createFlexDisplay } from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  position: relative;

  & > :first-child {
    position: relative;
    top: 1px;
  }

  ${createFlexDisplay({
    direction: 'column',
    align: 'flex-start',
    justify: 'center',
  })}

  ${breakpointDown('md')`
    align-items: flex-start;
  `};

  @media (max-width: 360px) {
    & > :first-child {
      height: 53px;
      top: -12px;
    }
  }
`;

export const Contents = styled(ReCAPTCHA)`
  @media (max-width: 360px) {
    iframe {
      transform: scale(0.7);
      transform-origin: 0;
    }
  }
`;
