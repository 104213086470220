import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Checkbox,
  Divider,
  Spacer,
  useIsMobileViewport,
  useToggle,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { IMAGE_URL } from 'constants/assets';
import { stripeURLs } from 'constants/externalURLs';
import { useAbundanceWalletTreeBalance } from 'state/abundanceWallet';
import { useUserPhoneNumber } from 'state/user';

import { remoteOTPValidation } from '../../store/modules/otpValidation/actions';
import { OTPPurpose } from '../../store/modules/otpValidation/types';
import * as S from './styles';

export type ClaimTokenProps = {
  returnURL: string;
};

const ClaimToken: React.FC<WithTranslation<ClaimTokenProps>> = ({
  returnURL,
  t,
}) => {
  const { value: agreedWithConditions, toggle: toggleAgreedWithConditions } =
    useToggle(false);
  const [totalSelected, setTotalSelected] = useState(0);

  const { data: treeBalance } = useAbundanceWalletTreeBalance();
  const { data: phoneNumber, isSuccess } = useUserPhoneNumber();
  const isMobile = useIsMobileViewport();
  const dispatch = useDispatch();

  const createStartClaimProcess = () => (): void => {
    if (!isSuccess) return;

    const requestData = {
      payload: {
        quantity: totalSelected,
        returnURL,
      },
      endpoint: '/v1/claims/users/current/claim-session',
      phoneNumber,
    };

    dispatch(
      remoteOTPValidation({
        requestDataValues: requestData,
        purpose: OTPPurpose.CREATE_CLAIM_SESSION,
        method: 'post',
        callback: 'redirectToClaimPage',
      }),
    );
  };

  return (
    <S.Root>
      <S.Header>
        <S.HeaderIcon
          src={`${IMAGE_URL}/v1702770569/Assets/Coins/coin-without-shadow_mwxsk0.png`}
          alt={t('iconAlt')}
        />

        <S.TitleContainer>
          <S.Name>{t('name')}</S.Name>
          <S.Title>{t('title')}</S.Title>
        </S.TitleContainer>
      </S.Header>

      <S.Handlers>
        <S.Handler>
          <S.TitleValue>{t('availableTrees')}</S.TitleValue>

          <S.TitleValue>
            <S.TokenValue>{treeBalance}</S.TokenValue>
          </S.TitleValue>
        </S.Handler>

        <Divider margin={!isMobile ? '16px 0' : '8px 0'} />

        <S.Handler>
          <S.TokenValue>{t('tokensClaim')}</S.TokenValue>

          <S.Counter
            value={totalSelected}
            minValue={0}
            maxValue={treeBalance}
            aria-label={t('trees')}
            isDisabled
            onChange={setTotalSelected}
          />
        </S.Handler>
      </S.Handlers>

      <S.MetaMask>
        <Spacer direction="row" align="center" justify="space-between" gap={3}>
          <S.ImageCard>
            <S.MMLogo />
          </S.ImageCard>

          <S.MetaMaskTitle>Metamask</S.MetaMaskTitle>
        </Spacer>

        <S.UnavailableTag>{t('unavailable')}</S.UnavailableTag>
      </S.MetaMask>

      <Divider />

      <S.Disclaimer>
        <S.ConfirmDisclaimer>
          <S.DisclaimerText>
            <Checkbox
              isChecked={agreedWithConditions}
              onChange={toggleAgreedWithConditions}
              isDisabled
              label={
                <>
                  {t('agree')}{' '}
                  <S.DisclaimerLink href={stripeURLs.termsAndConditions}>
                    {t('contract')}
                  </S.DisclaimerLink>
                  .
                </>
              }
            />
          </S.DisclaimerText>
        </S.ConfirmDisclaimer>

        <S.BuyButton
          // Should uncomment once claim tokens features are implemented.
          // isDisabled={totalSelected === 0 || !agreedWithConditions}
          isDisabled
          onPress={createStartClaimProcess()}
        >
          {t('claim')}
        </S.BuyButton>
      </S.Disclaimer>

      <Divider />

      <S.RedirectMessage>{t('redirect')}</S.RedirectMessage>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'claimToken',
})(ClaimToken);
