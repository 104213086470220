import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  actionsSelector,
  fileLoadingSelector,
  pageConfigSelector,
  updatingSubscriptionPlanSelector,
} from './selectors';
import { ApplicationState, ApplicationStore, PageConfig } from './types';

const initialState: ApplicationState = {
  pageConfig: {
    mode: 'fluid',
  },
  isFileLoading: true,
  isUpdatingSubscriptionPlan: false,
};

const useApplicationStore = create(
  immer<ApplicationStore>((set) => ({
    state: initialState,

    actions: {
      setPageConfig(newPageConfig: PageConfig) {
        set(({ state }) => {
          state.pageConfig = newPageConfig;
        });
      },

      setFileLoading(isFileLoading: boolean) {
        set(({ state }) => {
          state.isFileLoading = isFileLoading;
        });
      },

      setUpdatingSubscriptionPlan(isUpdatingSubscriptionPlan: boolean) {
        set(({ state }) => {
          state.isUpdatingSubscriptionPlan = isUpdatingSubscriptionPlan;
        });
      },

      resetApplicationState() {
        set((store) => {
          store.state = initialState;
        });
      },
    },
  })),
);

export const useApplicationActions = () => useApplicationStore(actionsSelector);

export const usePageConfig = () => useApplicationStore(pageConfigSelector);

export const useIsFileLoading = () => useApplicationStore(fileLoadingSelector);

export const useIsUpdatingSubscriptionPlan = () =>
  useApplicationStore(updatingSubscriptionPlanSelector);

export default useApplicationStore;
