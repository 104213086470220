import { EventTrackingContext } from './EventTrackingContext';
import { EventTrackingProviderProps } from './types';
import useMixpanelEventTracking from './useMixpanelEventTracking';

const EventTrackingProvider: React.FC<EventTrackingProviderProps> = ({
  children,
}) => (
  <EventTrackingContext.Provider value={useMixpanelEventTracking()}>
    {children}
  </EventTrackingContext.Provider>
);

export default EventTrackingProvider;
