import { styled } from 'styled-components';

import { Spacer } from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: 8,
})`
  width: 100%;
`;

export const AccessButton = styled(LinkButton)`
  width: 100%;
  justify-content: center;
`;
