import { useAbsorptionReport } from 'state/tree';

import PDF from '../PDF';

const UserAbsorptionReport: React.FC = () => {
  const { data: absorptionImpact } = useAbsorptionReport();

  if (!absorptionImpact) return null;

  return <PDF pdfFile={absorptionImpact} />;
};

export default UserAbsorptionReport;
