import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import {
  Banner,
  Button,
  RenderWhen,
  useToggle,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import LinkButton from 'components/LinkButton';
import { IMAGE_URL } from 'constants/assets';
import { getPathnameFromKey } from 'router/utils';
import { useUserHasSubscriptionPlan } from 'state/user';

import * as S from './styles';

const HomeTopBanner: React.FC<WithTranslation> = ({ t }) => {
  const { value: showMessage, toggleOff: hideMessage } = useToggle(true);
  const { data: userHasSubscriptionPlan } = useUserHasSubscriptionPlan();
  const dispatch = useDispatch();

  const handleOpenBuyTreesModal = useCallback((): void => {
    dispatch(pushModal({ name: ModalsList.BUY_TREES_MODAL }));
  }, [dispatch]);

  return (
    <Banner
      orientation="horizontal"
      message={
        <Banner.Message showMessage={showMessage} onClose={hideMessage}>
          <S.Message>
            {t('message')}{' '}
            <LinkButton to={getPathnameFromKey('virtualTour')} variant="text">
              {t('access')}
            </LinkButton>
          </S.Message>
        </Banner.Message>
      }
      src={`${IMAGE_URL}/v1707002103/Assets/Backgrounds/Overview-banner_yp7gxy.png`}
    >
      <Banner.Header>
        <Banner.Title>{t('title')}</Banner.Title>

        <Banner.Subtitle>{t('subtitle')}</Banner.Subtitle>
      </Banner.Header>

      <S.Content>
        <RenderWhen condition={!userHasSubscriptionPlan}>
          <LinkButton
            variant="secondary-outline-ghost"
            to={getPathnameFromKey('subscriptionPlans')}
          >
            {t('subscribe')}
          </LinkButton>
        </RenderWhen>

        <Button variant="primary" onPress={handleOpenBuyTreesModal}>
          {t('buy')}
        </Button>
      </S.Content>
    </Banner>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'home:homeTopBanner',
})(HomeTopBanner);
