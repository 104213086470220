import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useOTPValidation from 'hooks/useOTPValidation';

import { otpValidationSelector } from '../../store/modules/otpValidation/selectors';
import { remoteOTPValidationCallbacks } from './constants';

export type RemoteOTPValidationProps = {
  children: React.ReactNode;
};

/**
 * This HOC is used when there is the need to start
 * the OTP validation process from a component that is going to be unmounted
 * during the validation. By using this component, the whole process
 * will always be alive and working, even if the component that triggered
 * it is no mounted anymore.
 */
const RemoteOTPValidation: React.FC<RemoteOTPValidationProps> = ({
  children,
}) => {
  const { remoteOTPValidationData, otpValidationResponse } = useSelector(
    otpValidationSelector,
  );

  const { startOTPValidation } = useOTPValidation();

  useEffect(() => {
    if (
      otpValidationResponse &&
      typeof remoteOTPValidationData?.callback === 'string'
    )
      remoteOTPValidationCallbacks[remoteOTPValidationData?.callback](
        otpValidationResponse,
      );
  }, [otpValidationResponse, remoteOTPValidationData?.callback]);

  useEffect(() => {
    if (remoteOTPValidationData)
      return startOTPValidation(remoteOTPValidationData);
  }, [remoteOTPValidationData, startOTPValidation]);

  return children;
};

export default RemoteOTPValidation;
