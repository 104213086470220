import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  CartIcon,
  Divider,
  FormComposer,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Input, Password } from 'components/FormFields';
import LanguageSelector from 'components/LanguageSelector';
import LinkButton from 'components/LinkButton';
import ReCaptcha from 'components/ReCaptcha';
import { getPathnameFromKey } from 'router/utils';

import { useSignInForm } from './useSignInForm';

const SignInForm: React.FC<WithTranslation> = ({ t }) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { formState, control, isLoading, onSubmit } = useSignInForm();

  return (
    <FormComposer
      fluid
      title={t('title')}
      description={t('description')}
      sections={[
        {
          id: 'sign-in-form-main',
          contents: [
            <Input
              fluid
              key="email"
              name="email"
              type="email"
              label={t('email')}
              placeholder={t('email')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Password
              fluid
              showForgotPassword
              key="password"
              name="password"
              label={t('password')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <ReCaptcha key="recaptcha" verifiedAction={setIsCaptchaVerified} />,

            <Button
              fluid
              key="submit"
              type="submit"
              variant="primary"
              isDisabled={!formState.isValid || !isCaptchaVerified || isLoading}
            >
              {t('login')}
            </Button>,

            <Divider key="orContinueWith">{t('orContinueWith')}</Divider>,

            <LinkButton
              fluid
              key="purchsageWithoutAccount"
              to={getPathnameFromKey('purchaseWithoutAccount')}
              variant="secondary-outline"
            >
              <CartIcon />
              {t('purchaseWithoutAccount')}
            </LinkButton>,

            <Divider key="finalDivider" />,

            <LanguageSelector key="languageSelector" />,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'signIn:signInForm',
})(SignInForm);
