import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { sendSupportRequest, SupportArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useSendSupportRequest(
  options?: MutationOptions<void, SupportArgs>,
) {
  return useMutation<void, ErrorMessage, SupportArgs, unknown>(
    sendSupportRequest,
    options,
  );
}
