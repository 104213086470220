import { MdArrowBack } from 'react-icons/md';
import styled from 'styled-components';

import {
  breakpointDown,
  Button,
  getThemeBoxShadow,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Link,
  MetaMaskLogo,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Header = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'flex-start',
})`
  width: 100%;
`;

export const BackButton = styled(Button).attrs({
  variant: 'text',
})`
  position: fixed;
  top: 24px;
  left: 24px;
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${breakpointDown('lg')`
    width: 100%;
    height: 70px;
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 0;
    background-color: ${getThemeColor('white')};
  `}

  ${breakpointDown('xs')`
    height: 48px;
  `}
`;

export const BackIcon = styled(MdArrowBack)`
  ${breakpointDown('lg')`
    position: fixed;
    left: 24px;
    top: 24px;
  `}

  ${breakpointDown('xs')`
    left: 16px;
    top: 12px;
  `}
`;

export const Contents = styled.div`
  width: 100%;
  padding: 70px 72px;
  overflow: auto;

  ${breakpointDown('lg')`
    margin-top: 70px;
    padding: 0 24px;
    padding-bottom: 24px;
  `}

  ${breakpointDown('xs')`
    padding: 16px;
    padding-top: 0;
    margin-top: 48px;
  `}
`;

export const Title = styled(Text).attrs({
  level: 'bodySmall',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const MMLogo = styled(MetaMaskLogo)`
  width: 153px;
  height: 144px;
  margin-bottom: 16px;
`;

export const Main = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: '16px',
})`
  ${breakpointDown('lg')`
    & > :not(:first-child) {
      margin-top: 16px;
    }
  `}
`;

export const Container = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: 2,
})``;

export const MainTitle = styled(Heading).attrs({
  level: 5,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Paragraph = styled(Text).attrs({
  level: 'body',
})`
  margin-top: 8px;
  margin-bottom: 24px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Step = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const ExternalLink = styled(Link).attrs({
  variant: 'text',
  target: '_blank',
  size: 40,
})`
  display: initial;
  left: 2px;
  font-weight: ${getThemeFontWeight('bold')};
  text-decoration: underline;
`;

export const StepImage = styled.img`
  width: 100%;
  margin-bottom: 40px;
  box-shadow: ${getThemeBoxShadow('lg')};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${breakpointDown('lg')`
    margin-top: 16px;
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;
