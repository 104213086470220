import { Outlet } from 'react-router-dom';

import { Loader } from '@abundance-brasil-wabi/sabi';

import useApplicationLoader from 'hooks/useApplicationLoader';
import useScrollIntoView from 'hooks/useScrollIntoView';

/**
 * Free routes can be accessed by any user at any time, whether they are authenticated or not.
 */
const Free: React.FC = () => {
  const { isLoading } = useApplicationLoader();

  useScrollIntoView();

  if (isLoading) return <Loader isFullScreen />;

  return <Outlet />;
};

export default Free;
