import { styled } from 'styled-components';

import { breakpointDown } from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: -webkit-fill-available;
  padding: 24px;

  ${breakpointDown('xs')`
    padding: 16px;
  `}
`;
