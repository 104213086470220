import { styled } from 'styled-components';

import {
  Button,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { StyledImageProps } from './types';

export const Root = styled(Button).attrs({
  variant: 'text',
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: self-start;
  gap: 0;
  text-align: start;
`;

export const Image = styled.img<StyledImageProps>`
  width: 100%;
  height: 195px;
  border-radius: 6px;

  ${({ $image }) => `
    background: url(${$image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${getThemeColor('black')};
  `}
`;

export const Title = styled(Text).attrs({
  level: 'body',
})`
  margin-top: 8px;
  margin-bottom: 4px;
  max-height: 1.4em;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  font-weight: ${getThemeFontWeight('bold')};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'bodySmall',
})`
  max-height: 2.4em;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  font-weight: ${getThemeFontWeight('regular')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
