export default {
  constants: {
    carouselImages: {
      auroraVerdeWorker1Alt: 'Homem capinando mato com uma enxada.',
      auroraVerdeWorker2Alt:
        'Homem com um grande chapéu agachado lidando com a terra.',
      auroraVerdeWorker3Alt:
        'Homem andando em direção ao por do sol em um campo.',
    },

    forms: {
      fieldErrorMessages: {
        field: {
          required: 'Campo obrigatório',
        },
        email: {
          required: 'E-mail é obrigatório',
          wrongPattern: 'E-mail é inválido',
        },
        password: {
          required: 'Senha é obrigatória',
          wrongPattern: 'Senha é inválida',
          blocked: 'Após 5 tentativas sua conta será bloqueada',
          wrongPasswordPattern:
            'Sua senha deve contar com ao menos 8 caracteres, sendo ao menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
        },
        newPassword: {
          required: 'Nova senha é obrigatória',
          wrongPattern: 'Nova senha é inválida',
        },
        newPasswordConfirm: {
          required: 'Confirmação da senha é obrigatória',
          wrongPattern: 'Confirmação da senha é inválida',
          passwordsDontMatch: 'As senhas não são iguais',
        },
        phoneNumber: {
          required: 'Número de telefone é obrigatório',
          invalid: 'Número de telefone inválido',
        },
        tin: {
          required: 'Número de identificação fiscal é obrigatório',
          noCountry: 'Selecione um país',
          invalid: 'Número de identificação fiscal inválido',
        },
        name: {
          required: 'Nome é obrigatório',
        },
        twoFactorCode: {
          required: 'Código é obrigatório',
          tokenIsInvalid: 'Código inválido ou expirado',
        },
        address: {
          required: 'Endereço é obrigatório',
        },
        dropdown: {
          required: 'Por favor, selecione uma das opções',
        },
      },
    },
  },

  hooks: {
    transactionMessages: {
      purchaseTree: {
        success: 'Compra realizada com sucesso',
      },
      subscribeToPlan: {
        success: 'Plano assinado com sucesso',
      },
    },

    useLanguage: {
      error: 'Erro ao alterar o idioma',
    },

    useSubscription: {
      success: 'Plano assinado com sucesso',
      error: 'Erro ao assinar o plano',
      manageError: 'Erro ao gerenciar o plano',
    },
  },
};
