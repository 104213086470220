import { Fragment, useCallback, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  ButtonVariant,
  Item,
  ListBox,
  PopoverTrigger,
  RenderWhen,
  Spacer,
} from '@abundance-brasil-wabi/sabi';
import { Key, Selection } from '@react-types/shared';

import { WithTranslation } from 'i18n/types';
import useCheckRoute from 'hooks/useCheckRoute';
import { PathKey } from 'router/types';
import { getPathnameFromKey } from 'router/utils';
import { getIsAuthenticated } from 'state/auth/utils';
import { useCompanyShortName } from 'state/company';
import { useNavbarActions } from 'state/navbar';
import { useUserIsValidPhysicalPerson, useUserShortName } from 'state/user';

import * as S from './styles';
import { MenuButtons } from './types';

const EndContent: React.FC<WithTranslation> = ({ t }): React.ReactNode => {
  const [selection, setSelection] = useState<Key | undefined>();

  const { data: isValidPhysicalPerson } = useUserIsValidPhysicalPerson();
  const { data: userShortName } = useUserShortName();
  const { data: companyShortName } = useCompanyShortName();

  const { enqueueNavbar } = useNavbarActions();
  const { checkIsRoute } = useCheckRoute();
  const isAuthenticated = getIsAuthenticated();
  const navigate = useNavigate();

  const isSignInPage = useMemo(
    (): boolean => checkIsRoute('signIn'),
    [checkIsRoute],
  );
  const isSignUpPage = useMemo(
    (): boolean =>
      checkIsRoute('signUpCompany') ||
      checkIsRoute('signUpPersonal') ||
      checkIsRoute('selectAccountType'),
    [checkIsRoute],
  );

  const handleOpenRightMenuNavbar = useCallback((): void => {
    enqueueNavbar({
      name: 'RightMenu',
    });
  }, [enqueueNavbar]);

  const getMenuButtons = useCallback(
    (createAccountVariant: ButtonVariant): MenuButtons[] => {
      return [
        {
          key: 'signIn',
          textValue: t('signIn'),
          button: (!isSignInPage && (
            <S.MenuItem
              variant="text"
              to={getPathnameFromKey('signIn')}
              onPress={() => setSelection('signIn')}
            >
              {t('signIn')}
            </S.MenuItem>
          )) as JSX.Element,
        },
        {
          key: 'selectAccountType',
          textValue: t('createAccount'),
          button:
            !isSignUpPage &&
            ((
              <S.MenuItem
                variant={createAccountVariant}
                to={getPathnameFromKey('selectAccountType')}
                onPress={() => setSelection('selectAccountType')}
              >
                {t('createAccount')}
              </S.MenuItem>
            ) as JSX.Element),
        },
      ].filter(({ button }) => Boolean(button));
    },
    [isSignInPage, isSignUpPage, t],
  );

  const handleMobileMenuSelection = useCallback(
    (keys: Selection): void => {
      const currentKey = Array.from(keys)[0];

      setSelection(currentKey);
      navigate({ pathname: getPathnameFromKey(currentKey as PathKey) });
    },
    [navigate],
  );

  return (
    <Spacer align="center" justify="center" gap="24px">
      <RenderWhen condition={isAuthenticated}>
        <Avatar onPress={handleOpenRightMenuNavbar}>
          {isValidPhysicalPerson ? userShortName : companyShortName}
        </Avatar>
      </RenderWhen>

      <RenderWhen condition={!isAuthenticated}>
        <S.EndContentDesktop key={`desktop-${selection}`}>
          {getMenuButtons('secondary-outline').map(({ key, button }) => (
            <Fragment key={key}>{button}</Fragment>
          ))}
        </S.EndContentDesktop>

        <S.EndContentMobile key={`mobile-${selection}`}>
          <PopoverTrigger variant="avatar" isNonModal={false}>
            <ListBox
              aria-label={t('mobileMenuAriaLabel')}
              selectionMode="multiple"
              onSelectionChange={handleMobileMenuSelection}
            >
              {getMenuButtons('text').map(({ key, textValue }) => (
                <Item key={key} textValue={textValue}>
                  {textValue}
                </Item>
              ))}
            </ListBox>
          </PopoverTrigger>
        </S.EndContentMobile>
      </RenderWhen>
    </Spacer>
  );
};

export default withTranslation('components', {
  keyPrefix: 'page:titleBar:endContent',
})(EndContent);
