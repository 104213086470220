import styled from 'styled-components';

import { getThemeColor, Link } from '@abundance-brasil-wabi/sabi';

import { TextArea } from 'components/FormFields';

export const TextAreaInput = styled(TextArea).attrs({ fluid: true })`
  height: 202px;
  min-height: 202px;
`;

export const TalkToConsultant = styled(Link).attrs({ variant: 'text' })`
  padding-top: 8px;
  align-self: center;
  font-size: 17px;
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const LanguageSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
