import { Key, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Item } from '@abundance-brasil-wabi/sabi';

import supportedLanguages from 'constants/supportedLanguages';
import { SupportedLanguages } from 'constants/types';
import useLanguage from 'hooks/useLanguage';

import * as S from './styles';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const { changeAppLanguage, isLoading } = useLanguage();

  const handleLanguageChange = useCallback(
    (key: Key): void => {
      changeAppLanguage(key as string);
    },
    [changeAppLanguage],
  );

  return (
    <S.Root>
      <S.Dropdown
        variant="text"
        aria-labelledby={
          supportedLanguages[i18n.language as SupportedLanguages].title
        }
        isDisabled={isLoading}
        size={40}
        selectedKey={i18n.language}
        onSelectionChange={handleLanguageChange}
      >
        {Object.keys(supportedLanguages).map((language: string) => (
          <Item key={language}>
            {supportedLanguages[language as SupportedLanguages].title}
          </Item>
        ))}
      </S.Dropdown>
    </S.Root>
  );
};

export default LanguageSelector;
