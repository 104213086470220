import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { Company, UpdateCompanyArgs, UpdateCompanyBody } from './types';

export async function getCompany() {
  const { data } = await request.get<Company, AxiosResponse<Company>, never>(
    `${abundanceAPI.V1.COMPANY}/users/current`,
  );

  return data;
}

export async function updateCompany(args: UpdateCompanyArgs) {
  const {
    body,
    params: { id },
  } = args;

  const { data } = await request.put<
    void,
    AxiosResponse<void>,
    UpdateCompanyBody
  >(`${abundanceAPI.V1.COMPANY}/${id}`, body);

  return data;
}
