import { styled } from 'styled-components';

import {
  getThemeColor,
  SelectedIcon as Selected,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
`;

export const SelectedIcon = styled(Selected)`
  color: ${getThemeColor('border', 'primary', 'default')};
`;
