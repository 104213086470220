import { styled } from 'styled-components';

import {
  breakpointDown,
  CounterInput,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'space-between',
  gap: '8px',
})`
  width: 100%;
  height: 100%;
`;

export const HigherSection = styled.div`
  width: 100%;
`;

export const Handlers = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: '8px',
})`
  width: 100%;
`;

export const Handler = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: '8px',
})`
  width: 100%;
  min-height: 80px;

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '8px',
    })};
  }
`;

export const TreesAmount = styled.span`
  line-height: 20px;
  font-size: 18px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};

  @media (max-width: 630px) {
    font-size: 14px;
  }

  ${breakpointDown('xxxs')`
    font-size: 12px;
  `}
`;

export const Counter = styled(CounterInput)`
  width: 64px;
  text-align: center;
`;

export const Values = styled(Spacer).attrs({
  direction: 'column',
  align: 'space-between',
  justify: 'center',
  gap: '8px',
})`
  width: 100%;
  margin-bottom: 16px;
`;

export const UnityElement = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})`
  width: 100%;
  letter-spacing: -0.004em;
  line-height: 20px;
  font-weight: ${getThemeFontWeight('regular')};
  font-size: 16px;
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Unity = styled.strong`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const TotalValue = styled.strong`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const LowerSection = styled.div`
  width: 100%;
`;

export const RedirectMessage = styled.span`
  width: -webkit-fill-available;
  align-self: flex-start;
  line-height: 24px;
  font-size: 14px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
