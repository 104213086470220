import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getLastClaimDate } from 'api/services';

import { claimQueryKeys } from './queryKeys';
import { lastClaimDateSelector } from './selectors';
import { LastClaimDateQueryKey } from './types';

export default function useGetLastClaimDate<TData = string>(
  options?: QueryOptions<string, TData, LastClaimDateQueryKey>,
) {
  return useQuery<string, unknown, TData, LastClaimDateQueryKey>(
    claimQueryKeys.lastClaimDate(),
    getLastClaimDate,
    options,
  );
}

export const useLastClaimDate = () =>
  useGetLastClaimDate({
    select: lastClaimDateSelector,
  });
