import { pdfjs } from 'react-pdf';

import { pdfsEndpoints } from 'api/constants';
import { PDFsEndpointURL } from 'api/types';

export type GetFileProps = {
  fileEndpoint: string;
  fileName: string;
  language?: string;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * It takes a string and a token, and returns a blob.
 *
 * @param {string} pdf - string - this is the name of the pdf you want to get.
 * @param {string} token - The user's token.
 *
 * @returns A blob.
 */
export async function getUserPDFPreview(
  pdf: PDFsEndpointURL,
  token: string,
): Promise<Blob> {
  return fetch(pdfsEndpoints[pdf], {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      return blob;
    });
}

/**
 * It takes in a file name and language, and returns a blob.
 *
 * @param {GetFileProps} fileEndpoint - the endpoint to fetch the file from.
 *
 * @returns A blob.
 */
export async function getFile({
  fileEndpoint,
  fileName,
  language,
}: GetFileProps): Promise<Blob> {
  return fetch(fileEndpoint, {
    method: 'POST',
    body: JSON.stringify({
      fileName,
      language,
    }),
  })
    .then((res) => res.blob())
    .then((blob) => {
      return blob;
    });
}
