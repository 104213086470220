import { css, styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';
import TabNavigator from 'components/TabNavigator';

import { StyledHeaderProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: calc(100% + 128px);
  position: relative;
  top: -64px;
  left: 0;
  background-color: ${getThemeColor('secondary', 'selected')};

  ${breakpointDown('lg')`
    width: 100vw;
    top: -64px;
    left: 0;
  `}

  ${breakpointDown('sm')`
    top: -24px;
    padding: 0 6px;
  `}
`;

export const BackgroundImage = styled.div<StyledHeaderProps>`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ $backgroundImage }) => css`
    background-image: linear-gradient(
        180deg,
        ${getThemeColor('black')} -13.03%,
        rgba(0, 0, 0, 0) 100%
      ),
      url(${$backgroundImage});
  `}

  ${breakpointDown('lg')`
    height: 100px;
  `}
`;

export const BackButton = styled(LinkButton).attrs({
  variant: 'text-static',
})`
  position: absolute;
  top: 19px;
  left: 24px;
  line-height: 16px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    display: none;
  `}
`;

export const BackButtonContent = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
})`
  position: relative;
  top: 1px;
  font-size: 13px;
`;

export const HeaderInfo = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'flex-start',
})`
  width: 100%;
  flex: 1 0 auto;
  padding: 0 64px;

  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'flex-start',
    })};

    width: 100%;
    position: relative;
    padding: 0;

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

export const HeaderIcon = styled.img`
  ${createFlexDisplay({
    direction: 'column',
    align: 'center',
    justify: 'center',
  })};

  width: 124px;
  height: 124px;
  position: relative;
  bottom: 32px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  background-color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    left: 24px;
    bottom: 60px;
    margin-bottom: -37px;
  `}

  ${breakpointDown('xs')`
    width: 88px;
    height: 88px;
    bottom: 58px;
    padding: 12px;
    margin-bottom: -44px;
  `}
`;

export const InfoSection = styled.section`
  position: relative;
  left: 32px;

  ${breakpointDown('lg')`
    width: inherit;
    left: 0;
    padding: 0 24px;
    overflow: auto hidden;
  `}
`;

export const HeaderInfoContainer = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 8,
})`
  width: 100%;
  height: 107px;

  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'flex-start',
      gap: '0',
    })};

    height: auto;
    position: relative;
    bottom: 7px;
  `}
`;

export const HeaderDetails = styled.div`
  margin-top: 8px;

  ${breakpointDown('lg')`
    position: relative;
    margin-top: 0;
  `}
`;

export const HeaderTitle = styled(Heading).attrs({
  level: 3,
})`
  min-inline-size: max-content;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    margin-bottom: 6px;
  `}
`;

export const Tab = styled(TabNavigator).attrs({
  color: 'light',
})`
  width: max-content;
  margin-top: 0;
  position: relative;
  top: 1px;
  left: -156px;

  ${breakpointDown('lg')`
    left: 0;
  `}
`;
