export default {
  constants: {
    carouselImages: {
      auroraVerdeWorker1Alt: 'Man weeding weeds with a hoe.',
      auroraVerdeWorker2Alt:
        'Man in a big hat crouching dealing with the soil.',
      auroraVerdeWorker3Alt: 'Man walking towards sunset in a field.',
    },

    forms: {
      fieldErrorMessages: {
        field: {
          required: 'Required field',
        },
        email: {
          required: 'Email is mandatory',
          wrongPattern: 'Email is invalid',
        },
        password: {
          required: 'Password is mandatory',
          wrongPattern: 'Password is invalid',
          blocked: 'After 5 attempts your account will be blocked',
          wrongPasswordPattern:
            'Your password must contain at least 8 characters, with at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
        },
        newPassword: {
          required: 'New password is required',
          wrongPattern: 'New password is invalid',
        },
        newPasswordConfirm: {
          required: 'Password confirmation is required',
          wrongPattern: 'Password confirmation is invalid',
          passwordsDontMatch: 'Passwords do not match',
        },
        phoneNumber: {
          required: 'Phone number is required',
          invalid: 'Invalid phone number',
        },
        tin: {
          required: 'Taxpayer identification number is required',
          noCountry: 'Select a country',
          invalid: 'Invalid taxpayer identification number',
        },
        name: {
          required: 'Name is required',
        },
        twoFactorCode: {
          required: 'Code is mandatory',
          tokenIsInvalid: 'Invalid or expired code',
        },
        address: {
          required: 'Address is required',
        },
        dropdown: {
          required: 'Please select one of the options',
        },
      },
    },
  },

  hooks: {
    transactionMessages: {
      purchaseTree: {
        success: 'Successful purchase',
      },
      subscribeToPlan: {
        success: 'Plan successfully subscribed',
      },
    },

    useLanguage: {
      error: 'Error while changing language',
    },

    useSubscription: {
      success: 'Plan successfully subscribed',
      error: 'Error when subscribing to the plan',
      manageError: 'Error managing the plan',
    },
  },
};
