import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import { EnvironmentalRestorerCertificateDarkIcon } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';
import { useImpact } from 'state/tree';

import InformationalCard from './InformationalCard';

const EnvironmentalRestorerInformationalCard: React.FC<WithTranslation> = ({
  t,
}) => {
  const { data: impact } = useImpact();
  const dispatch = useDispatch();

  const { balance, until } = impact || {};
  const isDisabled = !balance && !until;
  const navigate = useNavigate();

  const handleGoToMediaKitPage = useCallback((): void => {
    navigate({ pathname: getPathnameFromKey('mediaKit') });
  }, [navigate]);

  const handleGoToHowToGetTokens = useCallback((): void => {
    navigate({ pathname: getPathnameFromKey('howToGetMyTokens') });
  }, [navigate]);

  const openCertificateModal = useCallback((): void => {
    if (isDisabled) return;

    dispatch(pushModal({ name: ModalsList.USER_CERTIFICATE_MODAL }));
  }, [dispatch, isDisabled]);

  return (
    <InformationalCard
      icon={<EnvironmentalRestorerCertificateDarkIcon />}
      isStamp
      tagContent={t('tagContent')}
      subtitle={t('subtitle')}
      title={t('title')}
      description={t('description')}
      primaryButtonTitle={
        isDisabled
          ? t('primaryButton:disabledText')
          : t('primaryButton:enabledText')
      }
      onPrimaryButtonPress={openCertificateModal}
      isPrimaryButtonDisabled={isDisabled}
      secondaryButtonTitle={t('secondaryButton:text')}
      onSecondaryButtonPress={
        balance || until ? handleGoToMediaKitPage : handleGoToHowToGetTokens
      }
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:informationalCards:environmentalRestorerInformationalCard',
})(EnvironmentalRestorerInformationalCard);
