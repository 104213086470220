import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getCurrentVirtualForests, VirtualForest } from 'api/services';

import { virtualForestQueryKeys } from './queryKeys';
import {
  currentVirtualForestSelector,
  hasVirtualForestsSelector,
} from './selectors';
import { CurrentVirtualForestsQueryKey } from './types';

export default function useGetCurrentVirtualForest<TData = VirtualForest[]>(
  options?: QueryOptions<VirtualForest[], TData, CurrentVirtualForestsQueryKey>,
) {
  return useQuery<
    VirtualForest[],
    unknown,
    TData,
    CurrentVirtualForestsQueryKey
  >(
    virtualForestQueryKeys.currentVirtualForests(),
    getCurrentVirtualForests,
    options,
  );
}

export const useCurrentVirtualForest = () =>
  useGetCurrentVirtualForest({
    select: currentVirtualForestSelector,
  });

export const useHasVirtualForests = () =>
  useGetCurrentVirtualForest({
    select: hasVirtualForestsSelector,
  });
