import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  Slider as BaseSlider,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('default')};

  ${breakpointDown('xl')`
    gap: 24px;
    padding: 32px;
  `}

  ${breakpointDown('sm')`
    gap: 16px;
    padding: 24px;
  `}
`;

export const Slider = styled(BaseSlider)`
  height: auto;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${breakpointDown('lg')`
    flex-direction: column-reverse;
    gap: 16px;
  `}
`;
