import { css, styled } from 'styled-components';

import {
  Button,
  getThemeColor,
  getThemeFontWeight,
  Loader,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

import { StyledStatusIndicatorProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 2,
})<StyledStatusIndicatorProps>`
  width: 100%;
  position: relative;
  padding: 16px;
  border-radius: 4px;

  ${({ $hasPlan }) => {
    if ($hasPlan) {
      return css`
        border: 2px solid ${getThemeColor('border', 'selected')};
        background-color: ${getThemeColor('white')};
      `;
    }

    return css`
      border: 2px solid ${getThemeColor('warning', 'dark')};
      background-color: ${getThemeColor('warning', 'light')};
    `;
  }}
`;

export const Contents = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
})`
  position: relative;
`;

export const PlanName = styled(Text).attrs({
  variant: 'body',
})`
  margin-bottom: 4px;
  font-weight: ${getThemeFontWeight('bold')};
  text-transform: uppercase;
  color: ${getThemeColor('black')};
`;

export const IconContainer = styled.div<StyledStatusIndicatorProps>`
  ${({ $hasPlan }) => {
    if ($hasPlan) {
      return css`
        color: ${getThemeColor('black')};
      `;
    }

    return css`
      color: ${getThemeColor('warning', 'dark')};
    `;
  }}
`;

export const Title = styled(Text).attrs({
  variant: 'body',
})<StyledStatusIndicatorProps>`
  margin-bottom: 8px;
  font-weight: ${getThemeFontWeight('medium')};

  ${({ $hasPlan }) => {
    if ($hasPlan) {
      return css`
        color: ${getThemeColor('black')};
      `;
    }

    return css`
      color: ${getThemeColor('warning', 'dark')};
    `;
  }}
`;

export const MainButton = styled(LinkButton).attrs({
  variant: 'text',
})`
  font-size: 13px;
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const ManagePlanButton = styled(Button).attrs({
  variant: 'text',
})`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const CustomLoader = styled(Loader)`
  position: relative;
  bottom: 24px;
  left: 24px;
  transform: scale(0.3);
`;
