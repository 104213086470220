import { useCallback } from 'react';
import { omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { useToast } from 'hooks/useToast';
import { useCompany, useUpdateCompany } from 'state/company';

import { companySettingsFormSchema } from './constants';
import { CompanySettingsFormSchema } from './types';

export type UseCompanySettingsForm = FormReturn<CompanySettingsFormSchema> & {
  isCompanyLoading: boolean;
  isUpdating: boolean;
  onSubmit: () => void;
};

export function useCompanySettingsForm(): UseCompanySettingsForm {
  const { t } = useTranslation('pages', {
    keyPrefix: 'companySettings:companySettingsForm',
  });
  const { addToast } = useToast();
  const { data: company, isLoading: isCompanyLoading } = useCompany();
  const { mutateAsync: updateCompany, isLoading: isUpdating } =
    useUpdateCompany({
      onSuccess: () => {
        addToast({
          message: t('dataUpdated'),
          state: 'success',
        });
      },
    });

  const form = useForm<CompanySettingsFormSchema>({
    resolver: zodResolver(companySettingsFormSchema),
    defaultValues: {
      ...company,
      address: company?.address ?? '',
      sector: company?.sector ?? -1,
      industry: company?.industry ?? -1,
      numberEmployees: company?.numberEmployees ?? -1,
      annualBilling: company?.annualBilling ?? -1,
    },
    mode: 'all',
  });

  const { handleSubmit, reset } = form;

  const onSubmit = useCallback(
    async (data: CompanySettingsFormSchema): Promise<void> => {
      await updateCompany({
        body: omit(data, 'country'),
        params: {
          id: company?.id,
        },
      });

      reset(data);
    },
    [company?.id, reset, updateCompany],
  );

  return {
    isCompanyLoading,
    isUpdating,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
