import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { AbundanceWalletBalances } from './types';

export async function getAbundanceWalletBalances() {
  const { data } = await request.get<
    AbundanceWalletBalances,
    AxiosResponse<AbundanceWalletBalances>,
    never
  >(`${abundanceAPI.V1.ABUDANCE_WALLET}/users/current/account-balance`);

  return data;
}
