import { withTranslation } from 'react-i18next';

import {
  DonateIcon,
  PersonaIcon,
  TreeRoundedIcon,
} from '@abundance-brasil-wabi/sabi';

import { VirtualForest } from 'api/services';
import { WithTranslation } from 'i18n/types';

import * as S from './styles';

const Summary: React.FC<WithTranslation<VirtualForest>> = ({
  type,
  balance,
  participants,
  t,
}) => {
  return (
    <S.Root>
      <S.Items>
        {/* Plating */}
        <S.Item>
          <TreeRoundedIcon color="white" />

          <S.SummaryText>
            <span>{t('planting')}:</span>
            <b>
              {' '}
              {balance} <span>{t(balance === 1 ? 'tree' : 'trees')}</span>
            </b>
          </S.SummaryText>
        </S.Item>

        {/* Participants */}
        <S.Item>
          <PersonaIcon color="white" />

          <S.SummaryText>
            <span>{t('participants')}:</span>
            <b> {participants}</b>
          </S.SummaryText>
        </S.Item>

        {/* Type */}
        <S.Item>
          <DonateIcon color="white" />

          <S.SummaryText>
            <span>{t('type')}:</span>
            <b> {type}</b>
          </S.SummaryText>
        </S.Item>
      </S.Items>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:forestDetails:header:summary',
})(Summary);
