import { useCallback, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Key } from '@react-types/shared';

import { WithTranslation } from 'i18n/types';
import useTabNavigation from 'hooks/useTabNavigation';
import { SyntropyPageTabItem } from 'pages/Syntropy';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';
import { TabItemsProps } from './types';

export type SyntropyPageTabsProps = {
  syntropyListSize: number;
  virtualForestsListSize: number;
  onTabSelected: (selectedTabId: SyntropyPageTabItem) => void;
};

const SyntropyPageTabs: React.FC<WithTranslation<SyntropyPageTabsProps>> = ({
  syntropyListSize,
  virtualForestsListSize,
  onTabSelected,
  t,
}) => {
  const { pathname } = useLocation();
  const { activeTab, setActiveTab } = useTabNavigation<SyntropyPageTabItem>({
    defaultTabId: 'reports',
  });
  const navigate = useNavigate();

  const tabItems = useMemo((): TabItemsProps[] => {
    return [
      {
        id: 'reports',
        label: `${t('reports')} (${syntropyListSize})`,
        link: getPathnameFromKey('syntropyReports'),
      },
      {
        id: 'communities',
        label: `${t('communities')} (${virtualForestsListSize})`,
        link: getPathnameFromKey('syntropyCommunities'),
      },
    ];
  }, [syntropyListSize, t, virtualForestsListSize]);

  const handleTabClick = useCallback(
    (newTabIndex: Key): void => {
      const nextTab = tabItems.find((item) => item.id === newTabIndex);

      if (!nextTab) return;

      onTabSelected(nextTab.id);

      return navigate({
        pathname: nextTab.link,
      });
    },
    [navigate, onTabSelected, tabItems],
  );

  const getCurrentTab = useCallback((): void => {
    const currentTab = tabItems.find((item) => item.link === pathname);

    if (!currentTab) return;

    setActiveTab(currentTab?.id);
    onTabSelected(currentTab?.id);
  }, [onTabSelected, pathname, setActiveTab, tabItems]);

  useEffect(() => {
    getCurrentTab();
  }, [getCurrentTab]);

  return (
    <S.Root<SyntropyPageTabItem>
      items={tabItems}
      activeTab={activeTab}
      setActive={handleTabClick}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:syntropyPageTabs',
})(SyntropyPageTabs);
