import { withTranslation } from 'react-i18next';

import { Banner } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useGetRandomSideBannerImage } from 'components/Page';

const CarbonOffsetCalculatorSideBanner: React.FC<WithTranslation> = ({
  t,
}): React.ReactNode => {
  const bannerImage = useGetRandomSideBannerImage();

  return (
    <Banner src={bannerImage}>
      <Banner.Header>
        <Banner.Title>{t('title')}</Banner.Title>

        <Banner.Subtitle>{t('subtitle')}</Banner.Subtitle>

        <br />

        <Banner.Subtitle>
          {t('step')} 1
          <br />
          {t('step1')}
        </Banner.Subtitle>

        <br />

        <Banner.Subtitle>
          {t('step')} 2
          <br />
          {t('step3')}
        </Banner.Subtitle>

        <br />

        <Banner.Subtitle>
          {t('step')} 3
          <br />
          {t('step3')}
        </Banner.Subtitle>
      </Banner.Header>
    </Banner>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'carbonOffsetCalculator:carbonOffsetCalculatorSideBanner',
})(CarbonOffsetCalculatorSideBanner);
