import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'flex-start',
  gap: 12,
})`
  margin: 0;

  ${breakpointDown('lg')`
    gap: 48px;

    & > :not(:first-child) {
      margin-top: 0
    }
  `}

  ${breakpointDown('sm')`
    gap: 16px;
  `}
`;

export const KnowMoreLink = styled(LinkButton).attrs({
  variant: 'text',
})`
  font-size: 16px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Cards = styled(Spacer).attrs({
  direction: 'row',
  align: 'unset',
  justify: 'center',
  gap: 6,
})`
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'space-between',
      gap: '16px',
    })}

    & > :not(:first-child) {
      margin-left: 0
    }
  `}
`;

export const CarbonManagement = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 6,
})`
  width: 100%;
`;

export const ImpactTo2052 = styled.img`
  width: 100%;
`;
