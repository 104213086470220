import { styled } from 'styled-components';

import {
  Alert,
  breakpointDown,
  getThemeBoxShadow,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

import { StyledHowToGetMyCertificateProps } from './types';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'flex-start',
})`
  margin: 0;
`;

export const AlertToast = styled(Alert).attrs({
  variant: 'toast',
  state: 'danger',
  fluid: true,
})`
  margin-bottom: 48px;
`;

export const KnowMoreLink = styled(LinkButton).attrs({
  variant: 'text',
})`
  font-size: 16px;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const ContentContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 2,
})<StyledHowToGetMyCertificateProps>`
  ${({ $tipAlerIsOn }) =>
    $tipAlerIsOn &&
    `
      margin-top: 48px;

      ${breakpointDown('lg')`
        margin-top: 24px;
      `}
    `}
`;

export const Title = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Title)`
  margin-bottom: 42px;
  font-weight: ${getThemeFontWeight('medium')};
`;

export const Certificate = styled.img`
  width: 538px;
  height: 380px;
  margin: 42px 0;
  box-shadow: ${getThemeBoxShadow('md')};

  ${breakpointDown('sm')`
    width: 75vw;
    height: 53vw;
    margin: 24px 0;
  `}
`;

export const GetCertificateMessage = styled(Title)`
  margin-bottom: 16px;
`;

export const RestoreButton = styled(LinkButton).attrs({
  variant: 'primary',
})`
  margin-top: 16px;
`;
