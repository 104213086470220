import { RouterProvider as ReactRouterProvider } from 'react-router-dom';

import { Loader, RenderWhen } from '@abundance-brasil-wabi/sabi';

import useApplicationLoader from 'hooks/useApplicationLoader';
import NetworkError from 'pages/NetworkError';

import router from './router';

const RouterProvider: React.FC = () => {
  const { isLoading } = useApplicationLoader();

  if (!navigator.onLine) return <NetworkError />;

  return (
    <>
      <RenderWhen condition={isLoading}>
        <Loader isFullScreen />
      </RenderWhen>

      <RenderWhen condition={!isLoading}>
        <ReactRouterProvider router={router} />
      </RenderWhen>
    </>
  );
};

export default RouterProvider;
