import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { getErrorMessage } from 'api/http';
import {
  checkoutPayment,
  CheckoutPaymentArgs,
  CheckoutPaymentResponse,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';
import { useToast } from 'hooks/useToast';

import store from '../../store';
import { hideModal } from '../../store/modules/modal/actions';

export default function useCheckoutPayment(
  options?: MutationOptions<CheckoutPaymentResponse, CheckoutPaymentArgs>,
) {
  const { addToast } = useToast();

  return useMutation<
    CheckoutPaymentResponse,
    ErrorMessage,
    CheckoutPaymentArgs,
    unknown
  >(checkoutPayment, {
    onSuccess: ({ url }) => {
      window.location.href = url;
    },
    onError: (error) => {
      addToast({
        message: getErrorMessage(error),
        state: 'error',
      });

      store.dispatch(hideModal());
    },
    ...options,
  });
}
