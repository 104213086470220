import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import {
  createSubscriptionPlan,
  CreateSubscriptionPlanArgs,
  CreateSubscriptionPlanResponse,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useCreateSubscriptionPlan(
  options?: MutationOptions<
    CreateSubscriptionPlanResponse,
    CreateSubscriptionPlanArgs
  >,
) {
  return useMutation<
    CreateSubscriptionPlanResponse,
    ErrorMessage,
    CreateSubscriptionPlanArgs,
    unknown
  >(createSubscriptionPlan, options);
}
