import { AnyAction, combineReducers } from 'redux';

import { AppState } from '..';
import modal from './modal/reducer';
import otpValidation from './otpValidation/reducer';

const appReducer = combineReducers({
  otpValidation,
  modal,
});

const rootReducer = (state: AppState | undefined, action: AnyAction) => {
  return appReducer(state, action);
};
export default rootReducer;
