import { styled } from 'styled-components';

import { Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: 2,
})`
  width: 100%;
  margin-top: 24px;
`;
