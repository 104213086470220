import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import i18next from 'i18next';
import { treeQueryKeys } from 'state/tree';
import { useUserId } from 'state/user';

export default function useResetTreeFiles() {
  const { data: userId } = useUserId();
  const queryClient = useQueryClient();

  return useCallback((): void => {
    queryClient.removeQueries(
      treeQueryKeys.certificate(userId, i18next.language),
    );
    queryClient.removeQueries(
      treeQueryKeys.absorptionReport(userId, i18next.language),
    );
  }, [queryClient, userId]);
}
