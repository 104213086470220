import { withTranslation } from 'react-i18next';

import { Spacer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { IMAGE_URL } from 'constants/assets';

import * as S from './styles';

const AuroraVerdeHeader: React.FC<WithTranslation> = ({ t }) => {
  return (
    <S.Root>
      <S.Icon
        src={`${IMAGE_URL}/v1702780628/Assets/Partners/aurora-verde-with-background_b2hpug.png`}
        alt={t('iconAlt')}
      />

      <Spacer direction="column" align="flex-start" justify="center">
        <S.Name>{t('name')}</S.Name>
        <S.Title>{t('title')}</S.Title>
      </Spacer>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'auroraVerdeHeader',
})(AuroraVerdeHeader);
