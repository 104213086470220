import { forwardRef } from 'react';

import OTPValidationForm from './OTPValidationForm';
import * as S from './styles';

const OTPValidationModal: React.FC = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_, _ref) => {
    return (
      <S.Root>
        <OTPValidationForm />
      </S.Root>
    );
  },
);

export default OTPValidationModal;
