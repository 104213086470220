import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import {
  CreateSubscriptionPlanArgs,
  CreateSubscriptionPlanBody,
  CreateSubscriptionPlanResponse,
  ManageSubscriptionPlanArgs,
  ManageSubscriptionPlanBody,
  ManageSubscriptionPlanResponse,
  SubscriptionPlan,
  UpdateSubscriptionPlanArgs,
  UpdateSubscriptionPlanBody,
} from './types';

export async function createSubscriptionPlan(args: CreateSubscriptionPlanArgs) {
  const { body } = args;

  const { data } = await request.post<
    AxiosResponse,
    AxiosResponse<CreateSubscriptionPlanResponse>,
    CreateSubscriptionPlanBody
  >(`${abundanceAPI.V1.SUBSCRIPTION}/users/current/checkout-session`, body);

  return data;
}

export async function getCurrentUserSubscriptionPlan() {
  const { data } = await request.get<
    AxiosResponse,
    AxiosResponse,
    SubscriptionPlan
  >(`${abundanceAPI.V1.SUBSCRIPTION}/users/current`);

  return data;
}

export async function manageSubscriptionPlan(args: ManageSubscriptionPlanArgs) {
  const { body } = args;

  const { data } = await request.post<
    AxiosResponse,
    AxiosResponse<ManageSubscriptionPlanResponse>,
    ManageSubscriptionPlanBody
  >(`${abundanceAPI.V1.SUBSCRIPTION}/users/current/portal-session`, body);

  return data;
}

export async function updateSubscriptionPlan(args: UpdateSubscriptionPlanArgs) {
  const {
    body,
    params: { id },
  } = args;

  const { data } = await request.put<
    AxiosResponse,
    AxiosResponse,
    Pick<UpdateSubscriptionPlanBody, 'subscriptionId'>
  >(
    `${abundanceAPI.V1.SUBSCRIPTION}/${id}/users/current/checkout-session`,
    body,
  );

  return data;
}
