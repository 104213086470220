import { QueryCache, QueryClientConfig } from '@tanstack/react-query';

export const queryClientDefaults: QueryClientConfig = {
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
      enabled: false,
    },
  },
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (typeof query.meta?.errorMessage === 'string') {
        throw new Error(query.meta.errorMessage);
      }
    },
  }),
};
