import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  Divider,
  Spacer,
  TrophyIcon,
  useWindowSize,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useSetPageConfig } from 'components/Page';
import UserCertificate from 'components/PDF/UserCertificate';
import { useCertificate } from 'state/tree';

import { pushModal } from '../../store/modules/modal/actions';
import { ModalsList } from '../../store/modules/modal/types';
import * as S from './styles';

const MyCertificate: React.FC<WithTranslation> = ({ t }) => {
  const { isFetching } = useCertificate();
  const { width } = useWindowSize();
  const { breakpoints } = useTheme();
  const dispatch = useDispatch();

  useSetPageConfig({
    mode: 'fluid',
    override: {
      isLoading: isFetching,
    },
  });

  const openCertificateModal = useCallback((): void => {
    dispatch(pushModal({ name: ModalsList.USER_CERTIFICATE_MODAL }));
  }, [dispatch]);

  return (
    <S.Root>
      <S.Certificate>
        <UserCertificate />
      </S.Certificate>

      <S.Content>{t('youGotYourCertificate')}</S.Content>
      <S.Content>{t('certificateDescription')}</S.Content>

      <Divider margin={width >= breakpoints.values.sm ? '48px 0' : '24px 0'} />

      <S.ViewButton onPress={openCertificateModal}>
        <Spacer
          direction="row"
          align="center"
          justify="space-between"
          gap="8px"
        >
          <span>{t('view')}</span>
          <TrophyIcon />
        </Spacer>
      </S.ViewButton>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'myCertificate',
})(MyCertificate);
