import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getErrorMessage } from 'api/http';
import FormPageContainer from 'components/FormPageContainer';
import { useSetPageConfig } from 'components/Page';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import {
  useCarbonOffsetCalculationById,
  useCarbonOffsetEnrollmentById,
} from 'state/carbonOffset';

import CarbonOffsetCalculatorForm from './CarbonOffsetCalculatorForm';
import CarbonOffsetCalculatorSideBanner from './CarbonOffsetCalculatorSideBanner';

const CarbonOffsetCalculator: React.FC = () => {
  const [enrollmentId, calculationId] = useGetSearchParams([
    'enrollmentId',
    'calculationId',
  ]);
  const { isLoading: isEnrollmentLoading, error: enrollmentError } =
    useCarbonOffsetEnrollmentById(enrollmentId);
  const {
    data: calculation,
    isLoading: isCalculationLoading,
    error: calculationError,
  } = useCarbonOffsetCalculationById(calculationId);
  const { search } = useLocation();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const isLoading = useMemo(
    (): boolean =>
      Boolean(
        (enrollmentId && isEnrollmentLoading) ||
          (calculationId && isCalculationLoading),
      ),
    [calculationId, enrollmentId, isCalculationLoading, isEnrollmentLoading],
  );
  const error = useMemo(
    (): unknown => enrollmentError ?? calculationError,
    [calculationError, enrollmentError],
  );

  useSetPageConfig({
    mode: 'split',
    override: {
      sideContent: <CarbonOffsetCalculatorSideBanner />,
    },
  });

  useEffect(() => {
    if (!enrollmentId && !calculationId) {
      return navigate({
        pathname: getPathnameFromKey('signIn'),
      });
    }

    if (error) {
      addToast({
        message: getErrorMessage(error),
        state: 'error',
      });

      return navigate({
        pathname: getPathnameFromKey('signIn'),
      });
    }
  }, [addToast, calculationId, enrollmentId, error, isLoading, navigate]);

  return (
    <FormPageContainer
      isLoading={isLoading}
      to="registerYourProfile"
      search={search}
    >
      <CarbonOffsetCalculatorForm
        defaultValues={calculation?.carbonCalculationQuestions}
        enrollment={calculation?.enrollment}
      />
    </FormPageContainer>
  );
};

export default CarbonOffsetCalculator;
