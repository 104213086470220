import { styled } from 'styled-components';

import { breakpointDown, Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: '24px',
})`
  ${breakpointDown('lg')`
    gap: 16px;
  `}
`;
