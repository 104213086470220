import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

const Footer: React.FC<WithTranslation> = ({ t }) => {
  return (
    <div>
      <S.Message>
        {t('didntFind')}{' '}
        <S.Link to={getPathnameFromKey('support')}>
          {t('talkWithSpecialist')}
        </S.Link>
      </S.Message>
    </div>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'subscriptionPlans:footer',
})(Footer);
