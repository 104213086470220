import { isEmpty } from 'lodash';
import {
  MdAccountCircle,
  MdAnalytics,
  MdCorporateFare,
  MdEmojiEvents,
  MdLock,
  MdNaturePeople,
  MdOutlineAccountCircle,
  MdOutlineAnalytics,
  MdOutlineCorporateFare,
  MdOutlineEmojiEvents,
  MdOutlineLock,
  MdOutlineNaturePeople,
} from 'react-icons/md';
import { SideMenuItem } from 'types/common';

import { GetRightMenuNavbarProps } from './types';

export function getRightNavbarItems({
  isValidPhysicalPerson,
  hasBalance,
  syntropyLink,
}: GetRightMenuNavbarProps): SideMenuItem[] {
  return [
    {
      key: 'userSettings',
      icon: <MdOutlineAccountCircle />,
      activeIcon: <MdAccountCircle />,
    },
    {
      ...((!isValidPhysicalPerson && {
        key: 'companySettings',
        icon: <MdOutlineCorporateFare />,
        activeIcon: <MdCorporateFare />,
      }) as SideMenuItem),
    },
    {
      key: hasBalance ? 'mediaKit' : 'howToGetMyTokens',
      icon: <MdOutlineNaturePeople />,
      activeIcon: <MdNaturePeople />,
    },
    {
      key: hasBalance ? 'myCertificate' : 'howToGetMyCertificate',
      icon: <MdOutlineEmojiEvents />,
      activeIcon: <MdEmojiEvents />,
    },
    {
      ...((syntropyLink && {
        key: 'esgReport',
        icon: <MdOutlineAnalytics />,
        activeIcon: <MdAnalytics />,
      }) as SideMenuItem),
    },
    {
      key: 'changePassword',
      icon: <MdOutlineLock />,
      activeIcon: <MdLock />,
    },
  ].filter((item) => !isEmpty(item)) as SideMenuItem[];
}
