import { useMemo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  MdOutlineAssignmentInd,
  MdOutlineDirectionsCar,
  MdOutlineMapsHomeWork,
} from 'react-icons/md';

import { Button, Divider, FormComposer } from '@abundance-brasil-wabi/sabi';

import {
  CarbonCalculationQuestions,
  CarbonOffsetEnrollment,
} from 'api/services';
import { WithTranslation } from 'i18n/types';
import { Checkbox, CounterInput, Dropdown } from 'components/FormFields';
import LanguageSelector from 'components/LanguageSelector';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { useCarbonOffsetEnrollmentById } from 'state/carbonOffset';
import { checkFieldIsTouched } from 'utils/forms';

import * as S from './styles';
import { useCarbonOffsetCalculatorForm } from './useCarbonOffsetCalculatorForm';
import { useGetFormOptions } from './useGetFormOptions';

export type CarbonOffsetCalculatorFormProps = {
  defaultValues?: CarbonCalculationQuestions;
  enrollment?: CarbonOffsetEnrollment;
};

const CarbonOffsetCalculatorForm: React.FC<
  WithTranslation<CarbonOffsetCalculatorFormProps>
> = ({ defaultValues, enrollment, t }) => {
  const [enrollmentId] = useGetSearchParams(['enrollmentId']);
  const {
    i18n: { language },
  } = useTranslation();

  const { data: carbonOffsetEnrollmentInfo } =
    useCarbonOffsetEnrollmentById(enrollmentId);

  const {
    workModelAdopted,
    flightsFrequency,
    averageMonthlyElectricityBillCost,
    electricityFromRenewableSources,
    booleanQuestion,
    percentageOfWasteRecycled,
  } = useGetFormOptions();

  const {
    formState,
    control,
    isLoading,
    watch,
    getValues,
    setValue,
    onSubmit,
  } = useCarbonOffsetCalculatorForm({ defaultValues, enrollment });

  const isSubmitDisabled = useMemo(
    (): boolean => !formState.isValid || isLoading,
    [formState.isValid, isLoading],
  );

  return (
    <FormComposer
      fluid
      title={`${t('title')}, ${
        carbonOffsetEnrollmentInfo?.name ?? enrollment?.name
      }!`}
      subtitle={t('subtitle')}
      description={t('description')}
      sections={[
        {
          id: 'collaborators',
          title: (
            <S.SectionTitle>
              <MdOutlineAssignmentInd />
              <span>{t('collaboratorsTitle')}</span>
            </S.SectionTitle>
          ),
          description: t('collaboratorsDescription'),
          contents: [
            <CounterInput
              fluid
              key="numberOfEmployees"
              name="numberOfEmployees"
              label={t('numberOfEmployeesLabel')}
              placeholder={t('numberOfEmployeesPlaceholder')}
              defaultValue={getValues('numberOfEmployees')}
              isDisabled={isLoading}
              minValue={0}
              step={1}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="workModelAdopted"
              name="workModelAdopted"
              valueType="number"
              label={t('workModelAdoptedLabel')}
              placeholder={t('workModelAdoptedPlaceholder')}
              defaultValue={getValues('workModelAdopted')}
              options={workModelAdopted}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,
          ],
        },
        {
          id: 'transport',
          title: (
            <S.SectionTitle>
              <MdOutlineDirectionsCar />
              <span>{t('transportTitle')}</span>
            </S.SectionTitle>
          ),
          description: t('transportDescription'),
          contents: [
            <S.QuestionContainer key="fuelType" gap={4}>
              <S.QuestionTitle>{t('fuelLabel')}</S.QuestionTitle>

              <S.QuestionContainer gap={2}>
                <Checkbox
                  key="gasoline"
                  name="gasoline"
                  label={t('gasolineLabel')}
                  control={control}
                  isDisabled={isLoading}
                  onChange={() => setValue('gasolineLiters', undefined)}
                />

                {watch('gasoline') && (
                  <CounterInput
                    fluid
                    key="gasolineLiters"
                    name="gasolineLiters"
                    placeholder={t('gasolinePlaceholder')}
                    ariaLabel={t('gasolinePlaceholder')}
                    defaultValue={
                      checkFieldIsTouched(control, 'gasoline')
                        ? undefined
                        : getValues('gasolineLiters')
                    }
                    isDisabled={isLoading}
                    minValue={0}
                    formatOptions={{
                      style: 'unit',
                      unit: 'liter',
                      unitDisplay: 'long',
                    }}
                    locale={language}
                    {...{ control, formState }}
                  />
                )}
              </S.QuestionContainer>

              <S.QuestionContainer gap={2}>
                <Checkbox
                  key="diesel"
                  name="diesel"
                  label={t('dieselLabel')}
                  control={control}
                  isDisabled={isLoading}
                  onChange={() => setValue('dieselLiters', undefined)}
                />

                {watch('diesel') && (
                  <CounterInput
                    fluid
                    key="dieselLiters"
                    name="dieselLiters"
                    placeholder={t('dieselPlaceholder')}
                    ariaLabel={t('dieselPlaceholder')}
                    defaultValue={
                      checkFieldIsTouched(control, 'diesel')
                        ? undefined
                        : getValues('dieselLiters')
                    }
                    isDisabled={isLoading}
                    minValue={0}
                    formatOptions={{
                      style: 'unit',
                      unit: 'liter',
                      unitDisplay: 'long',
                    }}
                    locale={language}
                    {...{ control, formState }}
                  />
                )}
              </S.QuestionContainer>

              <S.QuestionContainer gap={2}>
                <Checkbox
                  key="ethanol"
                  name="ethanol"
                  label={t('ethanolLabel')}
                  control={control}
                  isDisabled={isLoading}
                  onChange={() => setValue('ethanolLiters', undefined)}
                />

                {watch('ethanol') && (
                  <CounterInput
                    fluid
                    key="ethanolLiters"
                    name="ethanolLiters"
                    placeholder={t('ethanolPlaceholder')}
                    ariaLabel={t('ethanolPlaceholder')}
                    defaultValue={
                      checkFieldIsTouched(control, 'ethanol')
                        ? undefined
                        : getValues('ethanolLiters')
                    }
                    isDisabled={isLoading}
                    minValue={0}
                    formatOptions={{
                      style: 'unit',
                      unit: 'liter',
                      unitDisplay: 'long',
                    }}
                    locale={language}
                    {...{ control, formState }}
                  />
                )}
              </S.QuestionContainer>

              <S.QuestionContainer gap={2}>
                <Checkbox
                  key="gnv"
                  name="gnv"
                  label={t('gnvLabel')}
                  control={control}
                  isDisabled={isLoading}
                  onChange={() => setValue('gnvLiters', undefined)}
                />

                {watch('gnv') && (
                  <CounterInput
                    fluid
                    key="gnvLiters"
                    name="gnvLiters"
                    placeholder={t('gnvPlaceholder')}
                    ariaLabel={t('gnvPlaceholder')}
                    defaultValue={
                      checkFieldIsTouched(control, 'gnv')
                        ? undefined
                        : getValues('gnvLiters')
                    }
                    isDisabled={isLoading}
                    minValue={0}
                    formatOptions={{
                      style: 'unit',
                      unit: 'liter',
                      unitDisplay: 'long',
                    }}
                    locale={language}
                    {...{ control, formState }}
                  />
                )}
              </S.QuestionContainer>

              <S.QuestionContainer gap={2}>
                <Checkbox
                  key="electricalVehicles"
                  name="electricalVehicles"
                  label={t('electricalVehiclesLabel')}
                  control={control}
                  isDisabled={isLoading}
                  onChange={() => setValue('electricalVehiclesKwH', undefined)}
                />

                {watch('electricalVehicles') && (
                  <CounterInput
                    fluid
                    key="electricalVehiclesKwH"
                    name="electricalVehiclesKwH"
                    placeholder={t('electricalVehiclesPlaceholder')}
                    ariaLabel={t('electricalVehiclesPlaceholder')}
                    defaultValue={
                      checkFieldIsTouched(control, 'electricalVehicles')
                        ? undefined
                        : getValues('electricalVehiclesKwH')
                    }
                    isDisabled={isLoading}
                    minValue={0}
                    {...{ control, formState }}
                  />
                )}
              </S.QuestionContainer>
            </S.QuestionContainer>,

            <CounterInput
              fluid
              key="averageMonthlySpendingOnTaxiAndTransportApps"
              name="averageMonthlySpendingOnTaxiAndTransportApps"
              label={t('averageMonthlySpendingOnTaxiAndTransportAppsLabel')}
              placeholder={t(
                'averageMonthlySpendingOnTaxiAndTransportAppsPlaceholder',
              )}
              defaultValue={getValues(
                'averageMonthlySpendingOnTaxiAndTransportApps',
              )}
              isDisabled={isLoading}
              minValue={0}
              formatOptions={{
                style: 'currency',
                currency: 'BRL',
              }}
              locale={language}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="annualFrequencyOfDomesticFlights"
              name="annualFrequencyOfDomesticFlights"
              valueType="number"
              label={t('annualFrequencyOfDomesticFlightsLabel')}
              placeholder={t('annualFrequencyOfDomesticFlightsPlaceholder')}
              defaultValue={getValues('annualFrequencyOfDomesticFlights')}
              options={flightsFrequency}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="annualFrequencyOfInternationalFlights"
              name="annualFrequencyOfInternationalFlights"
              valueType="number"
              label={t('annualFrequencyOfInternationalFlightsLabel')}
              placeholder={t(
                'annualFrequencyOfInternationalFlightsPlaceholder',
              )}
              defaultValue={getValues('annualFrequencyOfInternationalFlights')}
              options={flightsFrequency}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,
          ],
        },
        {
          id: 'office',
          title: (
            <S.SectionTitle>
              <MdOutlineMapsHomeWork />
              <span>{t('officeTitle')}</span>
            </S.SectionTitle>
          ),
          description: t('officeDescription'),
          contents: [
            <Dropdown
              fluid
              key="averageMonthlyElectricityBillCost"
              name="averageMonthlyElectricityBillCost"
              valueType="number"
              label={t('averageMonthlyElectricityBillCostLabel')}
              placeholder={t('selectAnOption')}
              defaultValue={getValues('averageMonthlyElectricityBillCost')}
              options={averageMonthlyElectricityBillCost}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="electricityFromRenewableSources"
              name="electricityFromRenewableSources"
              valueType="number"
              label={t('electricityFromRenewableSourcesLabel')}
              placeholder={t('selectAnOption')}
              defaultValue={getValues('electricityFromRenewableSources')}
              options={electricityFromRenewableSources}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="descriptionOfGreenAreasMaintained"
              name="descriptionOfGreenAreasMaintained"
              valueType="boolean"
              label={t('descriptionOfGreenAreasMaintainedLabel')}
              placeholder={t('selectAnOption')}
              defaultValue={getValues('descriptionOfGreenAreasMaintained')}
              options={booleanQuestion}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="treePlantingParticipation"
              name="treePlantingParticipation"
              valueType="boolean"
              label={t('treePlantingParticipationLabel')}
              placeholder={t('selectAnOption')}
              defaultValue={getValues('treePlantingParticipation')}
              options={booleanQuestion}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <CounterInput
              fluid
              key="averageMonthlyWasteSentToLandfillInTons"
              name="averageMonthlyWasteSentToLandfillInTons"
              label={t('averageMonthlyWasteSentToLandfillInTonsLabel')}
              placeholder={t(
                'averageMonthlyWasteSentToLandfillInTonsPlaceholder',
              )}
              defaultValue={getValues(
                'averageMonthlyWasteSentToLandfillInTons',
              )}
              isDisabled={isLoading}
              minValue={0}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="percentageOfWasteRecycled"
              name="percentageOfWasteRecycled"
              valueType="number"
              label={t('percentageOfWasteRecycledLabel')}
              placeholder={t('selectAnOption')}
              defaultValue={getValues('percentageOfWasteRecycled')}
              options={percentageOfWasteRecycled}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,
          ],
        },
        {
          id: 'final',
          contents: [
            <Button
              fluid
              key="submit"
              type="submit"
              variant="primary"
              isDisabled={isSubmitDisabled}
            >
              {t('calculate')}
            </Button>,

            <S.LegalText key="legalText">{t('legalText')}</S.LegalText>,

            <Divider key="divider" />,

            <LanguageSelector key="languageSelector" />,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'carbonOffsetCalculator:carbonOffsetCalculatorForm',
})(CarbonOffsetCalculatorForm);
