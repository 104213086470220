import { withTranslation } from 'react-i18next';

import { Banner } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useGetRandomSideBannerImage } from 'components/Page';

import * as S from './styles';

const ForestProducerRegistrationSideBanner: React.FC<WithTranslation> = ({
  t,
}): React.ReactNode => {
  const bannerImage = useGetRandomSideBannerImage();

  return (
    <Banner src={bannerImage}>
      <S.BannerHeader>
        <Banner.Title>{t('title')}</Banner.Title>

        <br />

        <Banner.Subtitle>{t('firstParagraph')}</Banner.Subtitle>

        <br />

        <Banner.Subtitle>{t('secondParagraph')}</Banner.Subtitle>

        <br />

        <Banner.Subtitle>{t('thirdParagraph')}</Banner.Subtitle>
      </S.BannerHeader>
    </Banner>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'forestProducerRegistration:forestProducerRegistrationSideBanner',
})(ForestProducerRegistrationSideBanner);
