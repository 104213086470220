import { omit } from 'lodash';
import { Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { TextInput } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import { DefaultFieldProps } from '../types';

const Input: React.FC<WithTranslation<DefaultFieldProps>> = ({
  name = 'input',
  type = 'text',
  label,
  secondaryLabel,
  placeholder,
  defaultValue = '',
  control,
  formState,
  fluid,
  startContent,
  endContent,
  isDisabled,
  onChange,
  t,
  ...props
}) => {
  const errors = formState?.errors?.[name];

  return (
    <Controller
      {...{ name, defaultValue, control }}
      render={({ field }) => {
        const { onChange: onValueChange } = field;

        return (
          <TextInput
            {...field}
            {...omit(props, 'i18n', 'tReady')}
            {...{
              type,
              label,
              secondaryLabel,
              placeholder,
              fluid,
              startContent,
              endContent,
              isDisabled,
            }}
            onChange={(value: unknown) => {
              onChange?.(value);
              onValueChange(value);
            }}
            aria-labelledby={label}
            state={errors ? 'danger' : 'none'}
            feedbackMessage={t(errors?.message)}
          />
        );
      }}
    />
  );
};

export default withTranslation()(Input);
