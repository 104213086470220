import { useCallback, useEffect, useMemo } from 'react';
import { produce } from 'immer';

import { useQueryClient } from '@tanstack/react-query';

import { queryClientDefaults } from 'components/QueryClientProvider';
import {
  checkIsValidPhysicalPerson,
  getIsAuthenticated,
} from 'state/auth/utils';
import { useCompany } from 'state/company';
import { useUser } from 'state/user';

export type UseApplicationLoader = {
  isLoading: boolean;
};

/**
 * Methods to manage the general loading state of the application.
 */
export default function useApplicationLoader(): UseApplicationLoader {
  const isAuthenticated = getIsAuthenticated();
  const isValidPhysicalPerson = checkIsValidPhysicalPerson();

  const { isLoading: isUserLoading, fetchStatus: userFetchStatus } =
    useUser(isAuthenticated);
  const { isLoading: isCompanyLoading, fetchStatus: companyFetchStatus } =
    useCompany();

  const queryClient = useQueryClient();

  const isLoading = useMemo((): boolean => {
    return (
      (isUserLoading && userFetchStatus !== 'idle') ||
      (!isValidPhysicalPerson &&
        isCompanyLoading &&
        companyFetchStatus !== 'idle')
    );
  }, [
    companyFetchStatus,
    isCompanyLoading,
    isUserLoading,
    isValidPhysicalPerson,
    userFetchStatus,
  ]);

  const setQueryClientDefaults = useCallback((): void => {
    const { defaultOptions } = produce(queryClientDefaults, (draft) => {
      if (!draft.defaultOptions?.queries) return;

      draft.defaultOptions.queries.enabled = isAuthenticated;
    });

    if (!defaultOptions) return;

    queryClient.setDefaultOptions(defaultOptions);
  }, [isAuthenticated, queryClient]);

  useEffect(() => {
    setQueryClientDefaults();
  }, [setQueryClientDefaults]);

  return { isLoading };
}
