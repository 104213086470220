import { withTranslation } from 'react-i18next';
import { SVG } from 'types/common';

import {
  Divider,
  DonateIcon,
  PersonaIcon,
  Stamp,
  StampWithTitle,
  TreeRoundedOutlineIcon,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { getPathnameFromKey } from 'router/utils';

import * as S from './styles';

export type VirtualForestCardProps = {
  id: string;
  title: string;
  description: string;
  typeDescription: string;
  backgroundImage: string;
  icon: string | SVG;
  balance: number;
  stamp: Stamp;
  participants: number;
};

const VirtualForestCard: React.FC<WithTranslation<VirtualForestCardProps>> = ({
  id,
  title,
  description,
  typeDescription,
  backgroundImage,
  icon,
  balance,
  stamp,
  participants,
  t,
}) => {
  return (
    <S.Root>
      <S.Header $backgroundImage={backgroundImage} />

      <S.IconContainer>
        <S.Icon src={icon as string} alt={t('logoImageAlt')} />
      </S.IconContainer>

      <S.Content>
        <S.Title>{title}</S.Title>

        <S.Description>
          {description.length > 150
            ? `${description.slice(0, 150)}...`
            : description}
        </S.Description>

        <StampWithTitle stamp={stamp} />

        <Divider margin="16px 0" />

        <S.Summary>
          <S.SummaryItems>
            {/* Plating */}
            <S.SummaryItem>
              <TreeRoundedOutlineIcon />
              <span>
                {t('planting')}:
                <b>
                  {' '}
                  {balance} {t(balance === 1 ? 'tree' : 'trees')}
                </b>
              </span>
            </S.SummaryItem>

            {/* Participants */}
            <S.SummaryItem>
              <PersonaIcon />
              <span>
                {t('participants')}:<b> {participants}</b>
              </span>
            </S.SummaryItem>

            {/* Type */}
            <S.SummaryItem>
              <DonateIcon />

              <S.SummaryItemDescription>
                {t('type')}:<b> {typeDescription}</b>
              </S.SummaryItemDescription>
            </S.SummaryItem>
          </S.SummaryItems>

          <S.AccessButton
            to={`${getPathnameFromKey('syntropyCommunities')}/${id}`}
          >
            {t('access')}
          </S.AccessButton>
        </S.Summary>
      </S.Content>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:virtualForests:virtualForestCards:syntropyCard',
})(VirtualForestCard);
