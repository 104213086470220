import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  Card,
  certificateStamp,
  CertificateStampsList,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useImpact } from 'state/tree';
import { formatDate } from 'utils/formatting';

import * as S from './styles';

export type InformationalCardProps = {
  icon: React.ReactNode;
  isStamp?: boolean;
  tagContent: string;
  defaultTagColor?: string;
  subtitle: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  isPrimaryButtonDisabled?: boolean;
  onPrimaryButtonPress: () => void;
  onSecondaryButtonPress: () => void;
};

const InformationalCard: React.FC<WithTranslation<InformationalCardProps>> = ({
  icon,
  isStamp = false,
  tagContent,
  defaultTagColor = '',
  subtitle,
  title,
  description,
  primaryButtonTitle,
  secondaryButtonTitle,
  isPrimaryButtonDisabled = false,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  t,
}) => {
  const { data: impact } = useImpact();
  const { balance, until, stamp } = impact || {};

  const handleRenderIcon = useCallback((): React.ReactNode => {
    if (!impact) return;

    if (!isStamp || !balance || until) return icon;

    const StampIcon = certificateStamp[stamp as keyof CertificateStampsList];

    return <StampIcon />;
  }, [balance, icon, impact, isStamp, stamp, until]);

  const getCorrectTagColor = useCallback((): string => {
    if (!impact) return '';

    if (!isStamp) return defaultTagColor;

    if (until) return 'grey-white';

    if (!balance) return 'grey';

    return stamp || '';
  }, [impact, isStamp, defaultTagColor, until, balance, stamp]);

  const getCorrectTagText = useCallback((): string => {
    if (!impact) return '';

    if (!isStamp) return tagContent;

    if (until)
      return `${stamp} ${t('until')} ${formatDate(until, 'shortDate')}`;

    if (!balance) return tagContent;

    return stamp || '';
  }, [balance, impact, isStamp, stamp, t, tagContent, until]);

  return (
    <S.Root>
      <S.Heading>
        <S.IconContainer>{handleRenderIcon()}</S.IconContainer>

        <S.HeadingContents>
          <S.Subtitle>{subtitle}</S.Subtitle>
          <S.Title>{title}</S.Title>
          <S.Tag $tag={getCorrectTagColor()}>{getCorrectTagText()}</S.Tag>
        </S.HeadingContents>
      </S.Heading>

      <Card.Content>
        <S.Description>{description}</S.Description>
      </Card.Content>

      <Card.Content fluid align="center" justify="space-between" gap={2}>
        <S.SecondaryButton onPress={onSecondaryButtonPress}>
          {secondaryButtonTitle}
        </S.SecondaryButton>

        <Button
          variant="secondary-outline"
          size={32}
          onPress={onPrimaryButtonPress}
          isDisabled={isPrimaryButtonDisabled}
        >
          {primaryButtonTitle}
        </Button>
      </Card.Content>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:informationalCards:informationalCard',
})(InformationalCard);
