import { Page } from 'react-pdf';
import { styled } from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export const PDFPage = styled(Page)`
  margin-bottom: -1.9px;

  // Overwriting react-pdf canvas size to make content responsive.
  & > canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;
