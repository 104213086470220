import { withTranslation } from 'react-i18next';

import { ProgressBar } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { plantingStages } from 'constants/plantingStages';
import { PlantingStages as PlantingStagesType } from 'constants/types';

import * as S from './styles';

const PlantingStages: React.FC<WithTranslation> = ({ t }) => {
  return (
    <S.Root>
      <S.Title>{t('stages')}</S.Title>

      <S.StagesContainer>
        {plantingStages.map(
          ({ percentage, target, year }: PlantingStagesType) => (
            <ProgressBar
              key={year}
              value={percentage}
              label={`${t('plantedTrees')} ${year}`}
              info={`${target} ${t('thousandTrees')}`}
            />
          ),
        )}
      </S.StagesContainer>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'plantingStages',
})(PlantingStages);
