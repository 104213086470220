import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';

import SignUpForm from '../SignUpForm';

const SignUpPersonal: React.FC<WithTranslation> = ({ t }) => {
  return (
    <SignUpForm
      isPhysicalPerson
      title={t('title')}
      subtitle={t('subtitle')}
      description={t('description')}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'signUp:signUpPersonal',
})(SignUpPersonal);
