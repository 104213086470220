import { QueryOptions } from 'types/react-query';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getMediaKit, Impact, MediaKit } from 'api/services';
import i18next from 'i18next';
import { useApplicationActions } from 'state/application';
import { treeQueryKeys } from 'state/tree';

import { mediaQueryKeys } from './queryKeys';
import { mediaKitSelector } from './selectors';
import { MediaKitQueryKey } from './types';

export default function useGetMediaKit<TData = MediaKit>(
  options?: QueryOptions<MediaKit | undefined, TData, MediaKitQueryKey>,
) {
  const { setFileLoading } = useApplicationActions();
  const queryClient = useQueryClient();

  return useQuery<MediaKit | undefined, unknown, TData, MediaKitQueryKey>(
    mediaQueryKeys.mediaKit(),
    async () => {
      const impact: Impact | undefined = queryClient.getQueryData(
        treeQueryKeys.impact(),
      );

      if (!impact) return;

      setFileLoading(true);

      const mediaKit = await getMediaKit({
        body: {
          data: {
            stamp: impact?.stamp,
            balance: impact?.balance,
          },
          language: i18next.language,
        },
      });

      return mediaKit;
    },
    {
      onSettled: () => {
        setFileLoading(false);
      },
      ...options,
    },
  );
}

export const useMediaKit = (enabled?: boolean) =>
  useGetMediaKit({
    select: mediaKitSelector,
    enabled,
  });
