import { useSetPageConfig } from 'components/Page';

import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword: React.FC = () => {
  useSetPageConfig({ mode: 'split' });

  return <ForgotPasswordForm />;
};

export default ForgotPassword;
