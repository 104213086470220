import { last } from 'lodash';

import { InstitutionalMenuItem, NavbarNames } from './navbars';
import { Navbar, NavbarAction, NavbarStore } from './types';

export const actionsSelector = ({ actions }: NavbarStore): NavbarAction =>
  actions;

export const navbarsQueueSelector = ({ state }: NavbarStore): Navbar[] =>
  state.navbarQueue;

export const hasNavbarSelector = ({ state }: NavbarStore): boolean =>
  Boolean(state.navbarQueue.length);

export const hasVisibleNavbarSelector = (
  store: NavbarStore,
): boolean | undefined => {
  const top = last(navbarsQueueSelector(store));

  return top?.show;
};

export const isNavbarOpenSelector = (
  store: NavbarStore,
  navbarName: NavbarNames,
): boolean => {
  const top = last(navbarsQueueSelector(store));

  return top?.name === navbarName && top?.show;
};

export const navbarParamsSelector = (
  store: NavbarStore,
  navbarName: NavbarNames,
):
  | object
  | {
      institutionalMenuItem: InstitutionalMenuItem;
    }
  | undefined => {
  const navbarConfig = navbarsQueueSelector(store).findLast(
    ({ name }) => name === navbarName,
  );

  return navbarConfig?.params;
};
