import { styled } from 'styled-components';

import { getThemeColor, Spacer } from '@abundance-brasil-wabi/sabi';

import { IMAGE_URL } from 'constants/assets';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: 100%;
  background: ${getThemeColor('gradient', 'default')};
  background-image: url(${`${IMAGE_URL}/v1702757644/Assets/Backgrounds/leaves-1_jljnqq.png`});
  background-image: url(${`${IMAGE_URL}/v1702757644/Assets/Backgrounds/leaves-1_jljnqq.png`}),
    ${getThemeColor('gradient', 'default')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Main = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'flex-start',
})`
  width: 100vw;
  padding: 24px;
  text-align: center;
  color: ${getThemeColor('white')};
  overflow: auto;
`;
