import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: 6,
})`
  width: 464px;
  position: relative;
  padding: 10px 16px;

  ${breakpointDown('lg')`
    gap: 16px;
  `}

  @media (max-width: 630px) {
    width: 100%;
    padding: 8px;
  }
`;

export const SubscriptionDescription = styled.div`
  width: 100%;
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const FooterContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 4,
})`
  width: 100%;
`;
