import { isNil } from 'lodash';
import { z } from 'zod';

import { fieldErrorMessages } from 'constants/forms';

export const carbonOffsetCalculatorFormSchema = z
  .object({
    numberOfEmployees: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    workModelAdopted: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    gasoline: z.boolean().optional(),
    gasolineLiters: z.any(),
    diesel: z.boolean().optional(),
    dieselLiters: z.any(),
    ethanol: z.boolean().optional(),
    ethanolLiters: z.any(),
    gnv: z.boolean().optional(),
    gnvLiters: z.any(),
    electricalVehicles: z.boolean().optional(),
    electricalVehiclesKwH: z.any(),
    averageMonthlySpendingOnTaxiAndTransportApps: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    annualFrequencyOfDomesticFlights: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    annualFrequencyOfInternationalFlights: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    averageMonthlyElectricityBillCost: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    electricityFromRenewableSources: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    descriptionOfGreenAreasMaintained: z.boolean(),
    treePlantingParticipation: z.boolean(),
    averageMonthlyWasteSentToLandfillInTons: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    percentageOfWasteRecycled: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
  })
  .refine(
    ({ gasoline, gasolineLiters }) =>
      !gasoline || (gasoline && Boolean(gasolineLiters)),
    {
      message: fieldErrorMessages.field.required,
      path: ['gasolineLiters'],
    },
  )
  .refine(
    ({ diesel, dieselLiters }) => !diesel || (diesel && Boolean(dieselLiters)),
    {
      message: fieldErrorMessages.field.required,
      path: ['dieselLiters'],
    },
  )
  .refine(
    ({ ethanol, ethanolLiters }) =>
      !ethanol || (ethanol && Boolean(ethanolLiters)),
    {
      message: fieldErrorMessages.field.required,
      path: ['ethanolLiters'],
    },
  )
  .refine(({ gnv, gnvLiters }) => !gnv || (gnv && Boolean(gnvLiters)), {
    message: fieldErrorMessages.field.required,
    path: ['gnvLiters'],
  })
  .refine(
    ({ electricalVehicles, electricalVehiclesKwH }) =>
      !electricalVehicles ||
      (electricalVehicles && Boolean(electricalVehiclesKwH)),
    {
      message: fieldErrorMessages.field.required,
      path: ['electricalVehiclesKwH'],
    },
  )
  .refine(
    ({ descriptionOfGreenAreasMaintained }) =>
      !isNil(descriptionOfGreenAreasMaintained),
    {
      message: fieldErrorMessages.field.required,
      path: ['descriptionOfGreenAreasMaintained'],
    },
  )
  .refine(
    ({ treePlantingParticipation }) => !isNil(treePlantingParticipation),
    {
      message: fieldErrorMessages.field.required,
      path: ['treePlantingParticipation'],
    },
  );
