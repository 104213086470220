import axios from 'axios';

import { BASE_URL_ABUNDANCE_API } from 'constants/env';

import {
  requestInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from './interceptors';

const axiosInstance = axios.create({
  baseURL: BASE_URL_ABUNDANCE_API,
});

axiosInstance.interceptors.request.use(requestInterceptor);

axiosInstance.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export { axiosInstance as request };
