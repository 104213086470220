import { useSetPageConfig } from 'components/Page';

import SignInForm from './SignInForm';

const SignIn: React.FC = () => {
  useSetPageConfig({ mode: 'split' });

  return <SignInForm />;
};

export default SignIn;
