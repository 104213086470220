import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import CalculationResults from 'components/CalculationResults';
import { useSetPageConfig } from 'components/Page';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { getPathnameFromKey } from 'router/utils';
import {
  useCarbonOffsetCalculationById,
  useCarbonOffsetEnrollmentById,
} from 'state/carbonOffset';

import CarbonOffsetResultsFooter from './CarbonOffsetResultsFooter';
import CarbonOffsetResultsHeader from './CarbonOffsetResultsHeader';
import * as S from './styles';

const CarbonOffsetResults: React.FC = () => {
  const [calculationId] = useGetSearchParams(['calculationId']);
  const navigate = useNavigate();

  useSetPageConfig({
    mode: 'expanded',
  });

  const { data: carbonOffsetCalculation, isLoading: isCalculationLoading } =
    useCarbonOffsetCalculationById(calculationId);
  const { data: enrollmentInfo, isLoading: isEnrollmentLoading } =
    useCarbonOffsetEnrollmentById(carbonOffsetCalculation?.enrollment?.id);

  const isLoading = isCalculationLoading || isEnrollmentLoading;

  useEffect(() => {
    if (!calculationId) {
      navigate({
        pathname: getPathnameFromKey('signIn'),
      });
    }
  }, [calculationId, navigate]);

  return (
    <S.Root>
      <RenderWhen condition={isLoading}>
        <S.Loader />
      </RenderWhen>

      <RenderWhen condition={!isLoading}>
        <CarbonOffsetResultsHeader name={enrollmentInfo?.name} />

        <CalculationResults calculation={carbonOffsetCalculation} />

        <CarbonOffsetResultsFooter enrollmentInfo={enrollmentInfo} />
      </RenderWhen>
    </S.Root>
  );
};

export default CarbonOffsetResults;
