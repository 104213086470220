import { withTranslation } from 'react-i18next';

import { Divider } from '@abundance-brasil-wabi/sabi';
import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import { useAbundanceWalletBalances } from 'state/abundanceWallet';
import { useImpactTons } from 'state/tree';

import * as S from './styles';

const WalletOverview: React.FC<WithTranslation> = ({ t }) => {
  const { data: balances } = useAbundanceWalletBalances();
  const { data: tons } = useImpactTons();

  return (
    <S.Root>
      <S.AccountBalance>
        R$ {formatToCurrency(balances?.checkingAccountBalance || 0)}
      </S.AccountBalance>

      <S.GeneralBalance>
        <S.BalanceSection>
          <S.Title>{t('trees')}</S.Title>
        </S.BalanceSection>
        <S.Value>{balances?.treeBalance}</S.Value>
      </S.GeneralBalance>

      <Divider margin="16px 0" />

      <S.GeneralBalance>
        <S.BalanceSection>
          <S.Title>ATKs</S.Title>
        </S.BalanceSection>
        <S.Value>{balances?.abundanceTokenBalance}</S.Value>
      </S.GeneralBalance>

      <Divider margin="16px 0" />

      <S.GeneralBalance>
        <S.BalanceSection>
          <S.Title>{t('co2Absorption')}</S.Title>
        </S.BalanceSection>
        <S.Value>{`${tons ? `< ${tons} T` : '0'}`}</S.Value>
      </S.GeneralBalance>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'wallet:walletOverview',
})(WalletOverview);
