import { StatusMessage } from 'api/services';
import { setTwoFactorAuthData } from 'state/auth/utils';
import { createSearchParams } from 'utils/formatting';

export function getSignInSearchParam(
  route: StatusMessage,
  email: string,
): string {
  switch (route) {
    case 'Lockedout': {
      return createSearchParams({
        email,
      });
    }

    default: {
      return '';
    }
  }
}

export function handleSaveSignInInfoInLocalStorage(
  email: string,
  phoneNumber: string,
): void {
  setTwoFactorAuthData({
    email,
    phoneNumber,
  });
}
