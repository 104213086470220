import { GalleryImage } from '@abundance-brasil-wabi/sabi';

import { IMAGE_URL } from './assets';

const locale = 'shared:constants:carouselImages';

export const AURORA_VERDE_MODAL_GALLERY_IMAGES: GalleryImage[] = [
  {
    src: `${IMAGE_URL}/v1702755131/Assets/Backgrounds/aurora-verde-worker-3_elekvn.png`,
    alt: `${locale}:auroraVerdeWorker3Alt`,
  },
  {
    src: `${IMAGE_URL}/v1702755189/Assets/Backgrounds/aurora-verde-worker-1_erlcqk.png`,
    alt: `${locale}:auroraVerdeWorker1Alt`,
  },
  {
    src: `${IMAGE_URL}/v1702755081/Assets/Backgrounds/aurora-verde-worker-2_fc2wsf.png`,
    alt: `${locale}:auroraVerdeWorker2Alt`,
  },
];
