import { withTranslation } from 'react-i18next';

import { SectionHeader } from '@abundance-brasil-wabi/sabi';
import { replaceValueInText } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import { useImpactTons } from 'state/tree';

const Header: React.FC<WithTranslation> = ({ t }) => {
  const { data: tons } = useImpactTons();

  return (
    <SectionHeader
      title={t('title')}
      subtitle={
        tons
          ? replaceValueInText(t('description'), `${tons} t`, 'XXXX')
          : t('noCarbon')
      }
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'impact:header',
})(Header);
