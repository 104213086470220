import {
  createSearchParams as baseCreateSearchParams,
  URLSearchParamsInit,
} from 'react-router-dom';

/**
 * Creates a URL search params string based on the provided input. Works similar
 * to `react-router-dom's` `createSearchParams`
 *
 * @param {URLSearchParamsInit} [init] - Is an object that can be used to initialize
 * the URLSearchParams object. It can be an object with key-value pairs representing
 * the initial parameters to be added to the URLSearchParams object.
 *
 * @returns The formatted search params string.
 */
export function createSearchParams(init?: URLSearchParamsInit): string {
  return baseCreateSearchParams(init).toString();
}
