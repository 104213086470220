import { Control, Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import { PhoneInput, PhoneInputProps } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import {
  getCountryCodeFromLanguage,
  getDefaultAppLanguage,
} from 'utils/handlers';

import * as S from './styles';

export type PhoneNumberProps = WithTranslation<PhoneInputProps & Control> & {
  value?: string;
  isDisabled?: boolean;
};

const PhoneNumber: React.FC<WithTranslation<PhoneNumberProps>> = ({
  name = 'input',
  label,
  value = '',
  country,
  control,
  formState,
  fluid,
  isDisabled,
  t,
}) => {
  const errors = formState?.errors?.[name];

  return (
    <S.Root>
      <Controller
        {...{ name, value, control }}
        render={({ field }) => {
          return (
            <PhoneInput
              {...field}
              {...{ label, value, fluid, isDisabled }}
              country={
                getCountryCodeFromLanguage(getDefaultAppLanguage()) ||
                country ||
                ''
              }
              state={errors ? 'danger' : 'none'}
              feedbackMessage={t(errors?.message)}
            />
          );
        }}
      />
    </S.Root>
  );
};

export default withTranslation()(PhoneNumber);
