import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import {
  RenderWhen,
  Sidebar,
  SidebarMode,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { getKeyFromPathname, getPathnameFromKey } from 'router/utils';
import { getIsAuthenticated } from 'state/auth/utils';
import { useNavbarActions } from 'state/navbar';
import { InstitutionalMenuItem } from 'state/navbar/navbars';

import useGetInstitutionalMenuContents from '../useGetInstitutionalMenuContents';
import { SIDE_MENU_ITEMS } from './constants';

export type SideMenuProps = {
  mode?: SidebarMode;
  className?: string;
};

const SideMenu: React.FC<WithTranslation<SideMenuProps>> = ({
  mode = 'compact',
  className,
  t,
}) => {
  const { switchNavbar, dequeueNavbar } = useNavbarActions();
  const { menuItems } = useGetInstitutionalMenuContents();
  const isAuthenticated = getIsAuthenticated();
  const isMobile = useIsMobileViewport();
  const location = useLocation();

  const handleInstitutionalItemSelection = useCallback(
    (selection: InstitutionalMenuItem): void => {
      switchNavbar({
        name: 'LeftMenuInstitutional',
        params: {
          institutionalMenuItem: selection,
        },
      });
    },
    [switchNavbar],
  );

  return (
    <Sidebar
      mode={mode}
      selectedKey={getKeyFromPathname(location.pathname)}
      onSelection={dequeueNavbar}
      className={className}
    >
      {isAuthenticated &&
        SIDE_MENU_ITEMS.map(({ key, icon, activeIcon }) => (
          <Sidebar.Item
            key={key}
            id={key}
            as={Link}
            to={getPathnameFromKey(key)}
            title={t(key)}
            {...{ icon, activeIcon }}
          />
        ))}

      <RenderWhen condition={isMobile}>
        <RenderWhen condition={isAuthenticated}>
          <Sidebar.Divider />
        </RenderWhen>

        {menuItems.map(({ key, title }) => (
          <Sidebar.Item
            key={key}
            id={key}
            title={title}
            allowSelection={false}
            onPress={handleInstitutionalItemSelection}
          />
        ))}
      </RenderWhen>
    </Sidebar>
  );
};

export default withTranslation('components', {
  keyPrefix: 'page:sideMenu',
})(SideMenu);
