import { pdfjs } from 'react-pdf';

import { BASE_URL_ABUNDANCE_API } from 'constants/env';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * It takes a pdf name, a virtual forest id, and a token, and returns a blob of the pdf.
 *
 * @param {string} pdf - string - the name of the pdf you want to get.
 * @param {string} virtualForestId - The id of the virtual forest you want to get the PDF for.
 * @param {string} token - The token you get from the login endpoint.
 *
 * @returns A blob.
 */
export async function getVirtualForestPDFPreview(
  pdf: string,
  virtualForestId: string,
  token: string,
): Promise<Blob> {
  return fetch(
    `${BASE_URL_ABUNDANCE_API}/v1/virtual-forests-trees/${virtualForestId}/${pdf}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => res.blob())
    .then((blob) => {
      return blob;
    });
}
