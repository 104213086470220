import { withTranslation } from 'react-i18next';

import { PDFIcon, RenderWhen } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { AuditDocument } from 'constants/types';
import { handleExportFile } from 'utils/handlers';

import { auditDocuments } from './constants';
import * as S from './styles';
import { AuditDocumentsSize } from './types';

export type AuditDocumentsProps = {
  className?: string;
  size?: AuditDocumentsSize;
};

const AuditDocuments: React.FC<WithTranslation<AuditDocumentsProps>> = ({
  className = '',
  size = 'normal',
  t,
}) => {
  return (
    <S.Root $size={size} className={className}>
      <S.Title>{t('documents')}</S.Title>

      <S.DocumentsWrapper $size={size}>
        <RenderWhen condition={!!auditDocuments.length}>
          {auditDocuments.map((document: AuditDocument) => (
            <S.DocumentsContainer key={document.responsible}>
              <S.DocumentTitle>{t(document.title)}</S.DocumentTitle>

              <S.DocumentRow>
                <S.Left $size={size}>
                  <S.ColumnItem>
                    <S.ItemTitle>{t('date')}</S.ItemTitle>
                    <S.ItemValue>{t(document.date)}</S.ItemValue>
                  </S.ColumnItem>

                  <S.ColumnItem>
                    <S.ItemTitle>{t('responsible')}</S.ItemTitle>
                    <S.ItemValue>{t(document.responsible)}</S.ItemValue>
                  </S.ColumnItem>
                </S.Left>

                <div>
                  <S.PreviewDocumentButton
                    onPress={() =>
                      handleExportFile(
                        t(document.fileToExport),
                        t(document.fileName),
                      )
                    }
                  >
                    <S.ExportText>{t('export')}</S.ExportText>
                    <PDFIcon />
                  </S.PreviewDocumentButton>
                </div>
              </S.DocumentRow>
            </S.DocumentsContainer>
          ))}
        </RenderWhen>
      </S.DocumentsWrapper>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'auditDocuments',
})(AuditDocuments);
