import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { getErrorMessage } from 'api/http';
import { WithTranslation } from 'i18n/types';
import ColorfulBackground from 'components/ColorfulBackground';
import LinkButton from 'components/LinkButton';
import { useSetPageConfig } from 'components/Page';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import { useConfirmEmail } from 'state/auth';

import * as S from './styles';

const EmailConfirmationSuccess: React.FC<WithTranslation> = ({ t }) => {
  const { mutate: confirmEmail } = useConfirmEmail();
  const { addToast } = useToast();

  useSetPageConfig({ mode: 'cover' });

  const [email, token] = useGetSearchParams(['email', 'token']);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!email && !token) {
      setIsLoading(true);

      return;
    }

    confirmEmail(
      {
        body: {
          email: email as string,
          token: token as string,
        },
      },
      {
        onError: (error) => {
          addToast({
            message: getErrorMessage(error),
            state: 'error',
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  }, [addToast, confirmEmail, email, token]);

  return (
    <ColorfulBackground>
      <S.Root>
        <S.Logo />

        <S.Title>{t('header')}</S.Title>

        <S.Description>{t('span')}</S.Description>

        <LinkButton
          variant="tertiary"
          to={getPathnameFromKey('signIn')}
          isLoading={isLoading}
          loadingMessage={t('loading')}
        >
          {t('login')}
        </LinkButton>
      </S.Root>
    </ColorfulBackground>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'emailConfirmationSuccess',
})(EmailConfirmationSuccess);
