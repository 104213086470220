import { forwardRef } from 'react';

import BuyTree from 'components/BuyTree';

import * as S from './styles';

const BuyTreesModal: React.FC = forwardRef(() => {
  return (
    <S.Root>
      <BuyTree returnURL="home" />
    </S.Root>
  );
});

export default BuyTreesModal;
