import { omit } from 'lodash';
import { Control, Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import {
  CounterInput as CounterInputBase,
  CounterInputProps as CounterInputPropsBase,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import { DefaultFieldProps } from '../types';

export type CounterInputProps = WithTranslation<
  DefaultFieldProps & Control & CounterInputPropsBase
> & {
  ariaLabel?: string;
};

const CounterInput: React.FC<WithTranslation<CounterInputProps>> = ({
  name = 'input',
  label,
  ariaLabel,
  secondaryLabel,
  placeholder,
  defaultValue,
  control,
  formState,
  fluid,
  isDisabled,
  internalButtons = true,
  step,
  formatOptions,
  onChange,
  t,
  ...props
}) => {
  const errors = formState?.errors?.[name];

  return (
    <Controller
      {...{ name, defaultValue, control }}
      render={({ field }) => {
        const { onChange: onValueChange } = field;

        return (
          <CounterInputBase
            {...omit(field, 'onChange', 'onBlur', 'value')}
            {...omit(props, 'i18n', 'tReady')}
            {...{
              label,
              secondaryLabel,
              placeholder,
              fluid,
              isDisabled,
              internalButtons,
              step,
              formatOptions,
              defaultValue,
            }}
            onChange={(value: unknown) => {
              onChange?.(value);
              onValueChange(value);
            }}
            aria-labelledby={ariaLabel ?? label}
            state={errors ? 'danger' : 'none'}
            feedbackMessage={t(errors?.message)}
          />
        );
      }}
    />
  );
};

export default withTranslation()(CounterInput);
