import { ModalsList } from '../../store/modules/modal/types';
import AuroraVerdeModal from './AuroraVerdeModal';
import BuyTreesModal from './BuyTreesModal';
import ClaimTokensModal from './ClaimTokensModal';
import OTPValidationModal from './OTPValidationModal';
import PasswordVerificationModal from './PasswordVerificationModal';
import SubscriptionPlanModal from './SubscriptionPlanModal';
import { ModalElement } from './types';
import UserAbsorptionReportModal from './UserAbsorptionReportModal';
import UserCertificateModal from './UserCertificateModal';
import VirtualForestAbsorptionReportModal from './VirtualForestAbsorptionReportModal';
import VirtualForestCertificateModal from './VirtualForestCertificateModal';

export const MODALS_LIST: ModalElement = {
  [ModalsList.AURORA_VERDE_MODAL]: {
    element: AuroraVerdeModal,
    shouldDisplayCloseButton: false,
    isFullScreenOnMobile: true,
    mobileTitle: 'auroraVerdeModal:mobileTitle',
    style: {
      padding: '0',
    },
  },
  [ModalsList.BUY_TREES_MODAL]: {
    element: BuyTreesModal,
    shouldDisplayCloseButton: true,
  },
  [ModalsList.CLAIM_TOKEN_MODAL]: {
    element: ClaimTokensModal,
    shouldDisplayCloseButton: true,
    elementsToIgnore: ['metamask-disclaimer-navbar'],
  },
  [ModalsList.OTP_VALIDATION_MODAL]: {
    element: OTPValidationModal,
    shouldDisplayCloseButton: true,
  },
  [ModalsList.PASSWORD_VERIFICATION_MODAL]: {
    element: PasswordVerificationModal,
    shouldDisplayCloseButton: true,
  },
  [ModalsList.SUBSCRIPTION_PLAN_MODAL]: {
    element: SubscriptionPlanModal,
    shouldDisplayCloseButton: true,
  },
  [ModalsList.USER_ABSORPTION_REPORT_MODAL]: {
    element: UserAbsorptionReportModal,
    shouldDisplayCloseButton: false,
    isFileHandler: true,
    style: {
      padding: '0',
      borderRadius: '0',
      maxWidth: '80%',
    },
  },
  [ModalsList.USER_CERTIFICATE_MODAL]: {
    element: UserCertificateModal,
    shouldDisplayCloseButton: false,
    isFileHandler: true,
    style: {
      padding: '0',
      borderRadius: '0',
      maxWidth: '80%',
    },
  },
  [ModalsList.VIRTUAL_FOREST_ABSORPTION_REPORT_MODAL]: {
    element: VirtualForestAbsorptionReportModal,
    shouldDisplayCloseButton: false,
    isFileHandler: true,
    style: {
      padding: '0',
      borderRadius: '0',
      maxWidth: '80%',
    },
  },

  [ModalsList.VIRTUAL_FOREST_CERTIFICATE_MODAL]: {
    element: VirtualForestCertificateModal,
    shouldDisplayCloseButton: false,
    isFileHandler: true,
    style: {
      padding: '0',
      borderRadius: '0',
      maxWidth: '80%',
    },
  },
};
