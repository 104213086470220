import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: '24px',
})`
  width: 100%;
  padding: 31px;
  padding-bottom: 31px;
  border-radius: 8px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};

  ${breakpointDown('lg')`
    gap: 16px;
    padding: 23px;
  `}
`;

export const Title = styled.span`
  letter-spacing: -0.4px;
  line-height: 27px;
  font-size: 18px;
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const StagesContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
  gap: 6,
})`
  width: 100%;
`;
