import { useCallback, useEffect, useState } from 'react';
import { keyBy } from 'lodash';
import { withTranslation } from 'react-i18next';

import { CardsList } from '@abundance-brasil-wabi/sabi';

import { SubscriptionPlan } from 'api/services';
import { WithTranslation } from 'i18n/types';
import useOnLanguageChange from 'hooks/useOnLanguageChange';
import { useToast } from 'hooks/useToast';
import useTransactionMessages from 'hooks/useTransactionMessages';
import { useGetCurrentUserSubscriptionPlan } from 'state/subscriptionPlan';

import { subscriptionPlanCards } from './constants';
import * as S from './styles';
import SubscriptionPlanCard from './SubscriptionPlanCard';

const SubscriptionPlanCardsList: React.FC<WithTranslation> = ({ t }) => {
  const [finalSubscriptionPlans, setFinalSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);

  const { addToast } = useToast();

  const parseSubscriptionPlans = useCallback(
    (data: SubscriptionPlan[]): void => {
      const responseByKey = keyBy(data, 'name');
      const subscriptionByKey = keyBy(subscriptionPlanCards, 'name');

      const finalSubscriptionPlansList: SubscriptionPlan[] = Object.keys(
        responseByKey,
      ).map((key: string) => {
        return {
          ...responseByKey[key],
          description: subscriptionByKey[key].description,
          title: `${responseByKey[key].units} ${t(
            `${responseByKey[key].units === 1 ? 'tree' : 'trees'}`,
          )}`,
          bonusUnits:
            t('bonusUnitsPlaceholder') + responseByKey[key].bonusUnits,
        };
      });

      setFinalSubscriptionPlans(finalSubscriptionPlansList);
    },
    [t],
  );

  const {
    data: subscriptionPlans,
    isLoading,
    refetch,
  } = useGetCurrentUserSubscriptionPlan({
    onSuccess: parseSubscriptionPlans,
    onError: () => {
      addToast({
        message: t('error'),
        state: 'error',
      });
    },
  });

  useTransactionMessages({ transactionType: 'subscribeToPlan' });

  useOnLanguageChange(() => {
    if (subscriptionPlans) parseSubscriptionPlans(subscriptionPlans);

    refetch();
  });

  useEffect(() => {
    if (!finalSubscriptionPlans.length && subscriptionPlans) {
      parseSubscriptionPlans(subscriptionPlans);
    }
  }, [finalSubscriptionPlans, parseSubscriptionPlans, subscriptionPlans]);

  return (
    <S.Root>
      <CardsList mode="full" isLoading={isLoading}>
        {finalSubscriptionPlans.map((plan) => (
          <SubscriptionPlanCard key={plan.name} {...plan} />
        ))}
      </CardsList>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix:
    'subscriptionPlans:subscriptionPlanCards:subscriptionPlanCardsList',
})(SubscriptionPlanCardsList);
