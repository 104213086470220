import { CarbonOffsetCalculation } from 'api/services';

import * as S from './styles';

export type CalculationResultsProps = {
  calculation?: CarbonOffsetCalculation;
};

const CalculationResults: React.FC<CalculationResultsProps> = ({
  calculation,
}) => {
  return (
    <S.Root>
      <S.OffsetSummary calculation={calculation} />

      <S.CompensationCalculator calculation={calculation} />
    </S.Root>
  );
};

export default CalculationResults;
