import { userQueryKey } from 'state/utils';

export const carbonOffestQueryKeys = {
  all: [{ scope: 'carbonOffset' }] as const,
  carbonOffsetEnrollment: (userId?: string, enrollmentId?: string) =>
    [
      {
        ...carbonOffestQueryKeys.all[0],
        ...userQueryKey(userId),
        enrollmentId,
        entity: 'carbonOffsetEnrollment',
      },
    ] as const,
  carbonOffsetCalculation: (userId?: string, calculationId?: string) =>
    [
      {
        ...carbonOffestQueryKeys.all[0],
        ...userQueryKey(userId),
        calculationId,
        entity: 'carbonOffsetCalculation',
      },
    ] as const,
  currentUserCarbonOffsetCalculation: (userId?: string) =>
    [
      {
        ...carbonOffestQueryKeys.all[0],
        ...userQueryKey(userId),
        entity: 'currentUserCarbonOffsetCalculation',
      },
    ] as const,
};
