import InformationalCardsList from 'components/Cards/InformationalCards';
import SummaryCardsList from 'components/Cards/SummaryCards';
import useSetPageConfig from 'components/Page/useSetPageConfig';
import useTransactionMessages from 'hooks/useTransactionMessages';

import ArticlesList from './ArticlesList';
import Banners, { HomeTopBanner } from './Banners';
import * as S from './styles';

const Home: React.FC = () => {
  useTransactionMessages({ transactionType: 'purchaseTree' });
  useSetPageConfig({
    mode: 'fluid',
    override: {
      topContent: <HomeTopBanner />,
    },
  });

  return (
    <S.Root>
      <Banners />

      <SummaryCardsList />

      <InformationalCardsList />

      <ArticlesList />
    </S.Root>
  );
};

export default Home;
