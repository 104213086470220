import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { Article, getArticlesList } from 'api/services';

import { articleQueryKeys } from './queryKeys';
import { articlesListSelector, hasArticlesListSelector } from './selectors';
import { ArticleListQueryKey } from './types';

export default function useGetArticleList<TData = Article[]>(
  options?: QueryOptions<Article[], TData, ArticleListQueryKey>,
) {
  return useQuery<Article[], unknown, TData, ArticleListQueryKey>(
    articleQueryKeys.articleList(),
    getArticlesList,
    options,
  );
}

export const useArticlesList = () =>
  useGetArticleList({
    select: articlesListSelector,
  });

export const useHasArticlesList = () =>
  useGetArticleList({
    select: hasArticlesListSelector,
  });
