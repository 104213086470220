import { z } from 'zod';

import { STRONG_PASSWORD } from '@abundance-brasil-wabi/utils';

import { fieldErrorMessages } from 'constants/forms';

export const createNewPasswordFormSchema = z
  .object({
    newPassword: z
      .string()
      .min(1, fieldErrorMessages.newPassword.required)
      .regex(
        new RegExp(STRONG_PASSWORD),
        fieldErrorMessages.newPassword.wrongPattern,
      ),
    newPasswordConfirm: z
      .string()
      .min(1, fieldErrorMessages.newPasswordConfirm.required)
      .regex(
        new RegExp(STRONG_PASSWORD),
        fieldErrorMessages.newPasswordConfirm.wrongPattern,
      ),
  })
  .refine(
    ({ newPassword, newPasswordConfirm }) => newPassword === newPasswordConfirm,
    {
      message: fieldErrorMessages.newPasswordConfirm.passwordsDontMatch,
      path: ['newPasswordConfirm'],
    },
  );
