import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Subject } from 'api/services';
import { DropdownOption } from 'components/FormFields/Dropdown/types';

export type UseGetFormOptions = {
  subjects: DropdownOption<Subject>[];
};

export function useGetFormOptions(): UseGetFormOptions {
  const { t } = useTranslation('pages', {
    keyPrefix: 'support:supportForm',
  });

  const subjects = useMemo((): DropdownOption<Subject>[] => {
    return [
      {
        value: 0,
        label: t('supportSubjects:doubt'),
      },
      {
        value: 1,
        label: t('supportSubjects:suggestion'),
      },
      {
        value: 2,
        label: t('supportSubjects:iWantToBeAPartner'),
      },
      {
        value: 3,
        label: t('supportSubjects:subscriptionPlans'),
      },
      {
        value: 4,
        label: t('supportSubjects:esgReport'),
      },
    ];
  }, [t]);

  return {
    subjects,
  };
}
