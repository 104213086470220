import { CertificateStampsList } from '@abundance-brasil-wabi/sabi';

import { IMAGE_URL } from './assets';

export const coinsList: CertificateStampsList = {
  village: `${IMAGE_URL}/v1702770570/Assets/Coins/village_rosyza.png`,
  family: `${IMAGE_URL}/v1702770569/Assets/Coins/family_tkurfe.png`,
  friend: `${IMAGE_URL}/v1702770569/Assets/Coins/friend_ceemtp.png`,
  global: `${IMAGE_URL}/v1702770569/Assets/Coins/global_nhzqyc.png`,
  nation: `${IMAGE_URL}/v1702770570/Assets/Coins/nation_w7wbce.png`,
  tribe: `${IMAGE_URL}/v1702770570/Assets/Coins/tribe_b9jzuj.png`,
  city: `${IMAGE_URL}/v1702770569/Assets/Coins/city_vf0uiy.png`,
  hero: `${IMAGE_URL}/v1702770569/Assets/Coins/hero_qz4bz5.png`,
};
