import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Link,
  TitleBar as BaseTitleBar,
  TitleBarItem,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import useGetInstitutionalMenuContents from '../useGetInstitutionalMenuContents';
import EndContent from './EndContent';
import TitleBarStartContent from './StartContent';
import * as S from './styles';

const TitleBar: React.FC<WithTranslation> = (): React.ReactNode => {
  const { menuItems, menuContents } = useGetInstitutionalMenuContents();
  const isMobile = useIsMobileViewport();

  const titleBarItems = useMemo(
    (): TitleBarItem[] =>
      !isMobile
        ? menuItems?.map(({ key, title: itemTitle }) => ({
            id: key,
            title: itemTitle,
            subItems: menuContents?.[key].items.map(
              ({ id, title, url, previewImageSrc }) => ({
                id,
                title,
                as: Link,
                href: url,
                target: '_blank',
                previewImageSrc,
              }),
            ),
          }))
        : [],
    [isMobile, menuContents, menuItems],
  );

  return (
    <S.Root>
      <BaseTitleBar
        startContent={
          <TitleBarStartContent
            hasInstitutionalMenuContents={Boolean(
              menuContents && Object.keys(menuContents).length,
            )}
          />
        }
        endContent={<EndContent />}
        items={titleBarItems}
      />
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'page:titleBar',
})(TitleBar);
