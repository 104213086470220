import { z } from 'zod';

import {
  STRONG_PASSWORD,
  VALID_EMAIL_FORMAT,
} from '@abundance-brasil-wabi/utils';

import { fieldErrorMessages } from 'constants/forms';
import { getPathnameFromKey } from 'router/utils';

import { SignInRedirectRoute } from './types';

export const signInFormSchema = z.object({
  email: z
    .string()
    .min(1, fieldErrorMessages.email.required)
    .regex(
      new RegExp(VALID_EMAIL_FORMAT),
      fieldErrorMessages.email.wrongPattern,
    ),
  password: z
    .string()
    .min(1, fieldErrorMessages.password.required)
    .regex(
      new RegExp(STRONG_PASSWORD),
      fieldErrorMessages.password.wrongPattern,
    ),
});

export const signInRedirectRoutes: SignInRedirectRoute = {
  Lockedout: getPathnameFromKey('locked'),
  NotAllowed: getPathnameFromKey('emailConfirmation'),
  RequiresTwoFactor: getPathnameFromKey('securityVerification'),
  Succeeded: getPathnameFromKey('home'),
};
