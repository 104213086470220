import { Trans, withTranslation } from 'react-i18next';

import {
  Divider,
  Spacer,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useSetPageConfig } from 'components/Page';
import { IMAGE_URL } from 'constants/assets';
import { abundanceURLs } from 'constants/externalURLs';
import { getPathnameFromKey } from 'router/utils';
import { useUserSyntropyLink } from 'state/user';

import * as S from './styles';

const ESGReport: React.FC<WithTranslation> = ({ t }) => {
  const isMobile = useIsMobileViewport();
  useSetPageConfig({ mode: 'fluid' });

  const { data: syntropyLink } = useUserSyntropyLink();

  return (
    <Spacer direction="column" align="center" justify="flex-start">
      <S.Content>
        <S.Header>
          <S.HeaderTitle>{t('header:whatIsIt')}</S.HeaderTitle>

          <S.HeaderDescription>{t('header:description')}</S.HeaderDescription>

          <S.EmtelImage
            src={`${IMAGE_URL}/v1702755747/Assets/Informational/Emtel/emtel-esg-report_djycow.png`}
            alt={t('header:emtelESGReportImageAlt')}
          />

          <S.ButtonsContainer>
            <S.HeaderButton to={getPathnameFromKey('syntropyReports')}>
              {t('header:discoverESGReportsList')}
            </S.HeaderButton>

            <S.HeaderLink href={syntropyLink || ''} target="_blank">
              {t('header:accessESGReport')}
            </S.HeaderLink>
          </S.ButtonsContainer>
        </S.Header>

        <Divider margin={!isMobile ? '40px 0' : '24px 0'} />

        <S.Tutorial>
          <S.Title>{t('tutorial:stepByStep')}</S.Title>

          <S.Description>
            <Trans
              i18nKey="pages:esgReport:tutorial:description"
              components={{
                anchor: (
                  <S.ExternalLink
                    href={abundanceURLs.contact}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </S.Description>

          <S.Steps>
            {/* First step */}
            <S.Step>{t('tutorial:steps:firstStep')}</S.Step>
            <S.StepDescription>
              <Trans
                i18nKey="pages:esgReport:tutorial:steps:firstStepDescription"
                components={{
                  anchor: (
                    <S.ExternalLink
                      href="https://abundancebrasil.com/wp-login.php"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </S.StepDescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756085/Assets/Informational/ESG%20Report/esg-report-first-step_c8w87n.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Second step */}
            <S.Step>{t('tutorial:steps:secondStep')}</S.Step>
            <S.StepDescription>
              {t('tutorial:steps:secondStepDescription')}
            </S.StepDescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702755947/Assets/Informational/ESG%20Report/esg-report-second-step_kzq7lz.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Third step */}
            <S.Step>{t('tutorial:steps:thirdStep')}</S.Step>
            <S.StepDescription>
              {t('tutorial:steps:thirdStepDescription')}
            </S.StepDescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756312/Assets/Informational/ESG%20Report/esg-report-third-step_oonpdp.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Fourth step */}
            <S.StepSubdescription>
              {t('tutorial:steps:fourthStepDescription')}
            </S.StepSubdescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756196/Assets/Informational/ESG%20Report/esg-report-fourth-step_jzh4h6.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Fifth step */}
            <S.StepSubdescription>
              {t('tutorial:steps:fifthStepDescription')}
            </S.StepSubdescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756461/Assets/Informational/ESG%20Report/esg-report-fifth-step_effnwt.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Sixth step */}
            <S.StepSubdescription>
              {t('tutorial:steps:sixthStepDescription')}
            </S.StepSubdescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756262/Assets/Informational/ESG%20Report/esg-report-sixth-step_vtjfpd.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Seventh step */}
            <S.StepSubdescription>
              {t('tutorial:steps:seventhStepDescription')}
            </S.StepSubdescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756418/Assets/Informational/ESG%20Report/esg-report-seventh-step_wuwbqs.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Eigth step */}
            <S.StepSubdescription>
              {t('tutorial:steps:eigthStepDescription')}
            </S.StepSubdescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756143/Assets/Informational/ESG%20Report/esg-report-eigth-step_kbm7mx.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            {/* Nineth step */}
            <S.StepSubdescription>
              {t('tutorial:steps:ninethStepDescription')}
            </S.StepSubdescription>
            <S.StepImage
              src={`${IMAGE_URL}/v1702755887/Assets/Informational/ESG%20Report/esg-report-nineth-step_j1u3wn.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            <Divider margin={!isMobile ? '32px 0' : '0 0 16px 0'} />

            {/* Tenth step */}
            <S.Title>{t('tutorial:editingUserProfileTitle')}</S.Title>
            <S.Description>
              {t('tutorial:editingUserProfileDescription')}
            </S.Description>
            <S.StepImage
              src={`${IMAGE_URL}/v1702755839/Assets/Informational/ESG%20Report/esg-report-tenth-step_q9dfdj.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            <Divider margin={!isMobile ? '32px 0' : '0 0 16px 0'} />

            {/* Eleventh step */}
            <S.Title>{t('tutorial:signOutTitle')}</S.Title>
            <S.Description>{t('tutorial:signOutDescription')}</S.Description>
            <S.StepImage
              src={`${IMAGE_URL}/v1702756359/Assets/Informational/ESG%20Report/esg-report-eleventh-step_inwweg.png`}
              alt={t('tutorial:stepImageAlt')}
            />

            <Divider margin={!isMobile ? '32px 0' : '0 0 16px 0'} />

            <S.Title>{t('tutorial:termsOfUseTitle')}</S.Title>
            <S.TermsDescription>
              {t('tutorial:termsOfUseDescription')}
            </S.TermsDescription>
          </S.Steps>
        </S.Tutorial>
      </S.Content>
    </Spacer>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'esgReport',
})(ESGReport);
