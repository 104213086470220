export default {
  changePassword: {
    changePasswordForm: {
      successChange: 'Password changed successfully',
      errorChange: 'Error while changing password',
      typePassword: 'Enter your password',
      typeNewPassword: 'Enter your new password',
      confirmNewPassword: 'Confirm your new password',
      changePassword: 'Change Password',
      loading: 'Loading...',
    },
  },

  carbonOffsetCalculator: {
    carbonOffsetCalculatorForm: {
      title: 'Hello',
      subtitle: 'For your company',
      description:
        'Answer the following questions to calculate your company’s carbon offset. If you are not sure about some topics, think of the closest possible answer.',
      collaboratorsTitle: 'Employees',
      collaboratorsDescription:
        'To calculate the impact of work outside the physical office (home office, coworking, or business trips), we consider emissions from waste, commuting, gas and electricity consumption, and equipment leaks. The working hours are considered to be 8 hours a day and 5 days a week.',
      transportTitle: 'Transport',
      transportDescription:
        'We consider commuting during work hours, as well as the transportation of company vehicles. To do this, we calculate the average gasoline prices for each state using data provided by ANP. We consider the emissions from average distances of domestic and international flights.',
      officeTitle: 'Office',
      officeDescription:
        'The next step is to simply proceed if your company does not have a physical office.',
      numberOfEmployeesLabel: 'Number of employees',
      numberOfEmployeesPlaceholder: 'Employees',
      workModelAdoptedLabel: 'What is the work model adopted?',
      workModelAdoptedPlaceholder: 'Work model',
      fuelLabel: 'What types of fuels are used by your fleet?',
      gasolineLabel: 'Gasoline',
      gasolinePlaceholder: 'Enter the monthly gasoline volume',
      dieselLabel: 'Diesel',
      dieselPlaceholder: 'Enter the monthly diesel volume',
      ethanolLabel: 'Ethanol',
      ethanolPlaceholder: 'Enter the monthly ethanol volume',
      gnvLabel: 'CNG',
      gnvPlaceholder: 'Enter the monthly CNG volume',
      electricalVehiclesLabel: 'Electric vehicles',
      electricalVehiclesPlaceholder:
        'Enter the monthly kWh of electric vehicles',
      averageMonthlySpendingOnTaxiAndTransportAppsLabel:
        'Average monthly expenditure with taxis and transport apps (R$)',
      averageMonthlySpendingOnTaxiAndTransportAppsPlaceholder:
        'Average monthly expenditure',
      annualFrequencyOfDomesticFlightsLabel:
        'How often do members of your company take round-trip domestic flights within Brazil in a year?',
      annualFrequencyOfDomesticFlightsPlaceholder:
        'Frequency of domestic flights',
      annualFrequencyOfInternationalFlightsLabel:
        'How often do members of your company take international round-trip flights in a year?',
      annualFrequencyOfInternationalFlightsPlaceholder:
        'Frequency of international flights',
      averageMonthlyElectricityBillCostLabel:
        'Average monthly cost of the office electricity bill (R$)',
      electricityFromRenewableSourcesLabel:
        'Is part or all of your company’s electricity sourced from renewable sources?',
      descriptionOfGreenAreasMaintainedLabel:
        'Does the company maintain natural green areas?',
      treePlantingParticipationLabel:
        'Has your company ever participated in or does participate in tree planting?',
      averageMonthlyWasteSentToLandfillInTonsLabel:
        'What is the average monthly amount in tons of waste sent to landfills?',
      averageMonthlyWasteSentToLandfillInTonsPlaceholder:
        'Enter the average monthly amount in tons',
      percentageOfWasteRecycledLabel: 'What percentage of waste is recycled?',
      selectAnOption: 'Select an option',
      legalText:
        'The results provided by our carbon calculation tool are estimates based on user-entered data. Abundance Brasil does not guarantee the accuracy of these estimates and is not responsible for decisions made based on them. This tool is for guidance use, aiming to facilitate engagement with decarbonization. We recommend specialized consultancy for accurate emissions analysis and mitigation actions. By using this tool, you acknowledge its estimative nature and agree to these terms.',
      calculate: 'Calculate your emissions',

      menuItems: {
        workModelAdopted: {
          full: 'Full on-site (5 days/week)',
          partial: 'Partial on-site (hybrid)',
          remote: 'Fully remote',
        },

        flightsFrequency: {
          itemOne: '1 - 10 trips per year',
          itemTwo: '11 - 20 trips per year',
          itemThree: '21 - 50 trips per year',
          itemFour: '51 - 100 trips per year',
          itemFive: 'More than 100 trips per year',
        },

        averageMonthlyElectricityBillCost: {
          low: 'Low: Like a small office or store - R$100',
          moderate:
            'Moderate: Like a factory or medium office building - R$500',
          high: 'High: Like large factories or corporate offices - R$2000',
        },

        electricityFromRenewableSources: {
          high: 'Yes, between 30% to 70%',
          average: 'Yes, more than 70%',
          none: 'No',
        },

        booleanQuestion: {
          yes: 'Yes',
          no: 'No',
        },

        percentageOfWasteRecycled: {
          itemOne: 'Between 10% to 50%',
          itemTwo: 'More than 50%',
          itemThree: '0%',
          itemFour: 'I don’t know',
        },
      },
    },

    carbonOffsetCalculatorSideBanner: {
      title: 'Want to reduce your impact and gain a competitive edge?',
      subtitle:
        'Calculate your carbon offset to start offsetting CO2 emissions and reinforce your commitment to the environment.',
      step: 'STEP',
      step1: 'Create a profile with your company’s data.',
      step2: 'Answer the questions to get an estimate of your emissions.',
      step3: 'Check the result and start your decarbonization journey.',
    },
  },

  carbonOffsetResults: {
    carbonOffsetResultsFooter: {
      doc: 'Aurora Verde Documentary',
      openAccountButtonDesktop:
        'Open your account and decarbonize your emissions later',
      openAccountButtonMobile: 'Decarbonize your emissions later',
    },

    carbonOffsetResultsHeader: {
      title: 'this is your company’s carbon offset',
      subtitle: 'For your company',
      description:
        'Now that you have an estimate of your carbon emissions, it becomes easier to create a decarbonization plan. How about starting to offset now and become an environmental restorer?',
    },
  },

  companySettings: {
    companySettingsForm: {
      loading: 'Loading...',
      accountOwner: 'Data of the person responsible for the account',
      fillYourPersonalData: 'Fill in the personal data',
      yourName: 'Your full name',
      name: 'Name',
      email: 'Email',
      emailPlaceholder: 'email@domain.com',
      phoneNumber: 'Telephone',
      extraInfo: 'Extra information',
      extraInfoDescription:
        'In order for us to get to know your company better and offer you a better ESG consultancy, fill in the extra information below.',
      address: 'Address',
      addressPlaceholder: 'Enter your address',
      sector: 'Sector',
      select: 'Select',
      industry: 'Industry',
      numberEmployees: 'Number of employees',
      annualBilling: 'Annual billing',
      dataUpdated: 'Company data has been updated',
      update: 'Update company data',

      sectors: {
        fashion: 'Fashion',
        industry: 'Industry',
        foodRetail: 'Food retail',
        foodAndDrinks: 'Food and drinks',
        healthAndCosmetics: 'Health and cosmetics',
        retailAndWholesale: 'Retail and wholesale',
      },

      industries: {
        production: 'Production',
        capital: 'Capital',
        consumption: 'Consumption',
        extractive: 'Extractive',
        transformation: 'Transformation',
        energetic: 'Energetic',
        food: 'Food',
        civilConstruction: 'Civil construction',
        informational: 'Informational',
      },

      employees: {
        upTo10: 'Up to 10',
        from10To50: 'From 10 to 50',
        from50To100: 'From 50 to 100',
        moreThan100: 'More than 100',
      },

      annualIncome: {
        upTo100: 'Up to 100 thousand',
        from100To500: 'From 100 to 500 thousand',
        from500To1Mil: 'From 500 to 1 million',
        from1MilTo5Mil: 'From 1 million to 5 millions',
        moreThan5Mil: 'More than 5 millions',
      },
    },
  },

  createNewPassword: {
    createNewPasswordForm: {
      title: 'Enter your new password',
      description: 'Instructions on creating the new password and characters',
      error: 'Error changing password.',
      newPassword: 'New password',
      newPasswordConfirm: 'Confirm the new password',
      save: 'Register new password',
    },
  },

  createNewPasswordConfirm: {
    emailSent: 'The email to create the new password has been sent',
    emailSentDescription:
      'We have sent a password reset email to {{email}} with instructions on how to reset your password.',
    ifNotReceiveEmail:
      'If you do not receive the email, please check that the email address you entered is correct, see our',
    faq: 'FAQ',
    contactSupport: 'or contact support',
    here: 'here',
  },

  createNewPasswordSuccess: {
    title: 'New password created successfully!',
    youCanAccessYourAccount: 'You can now access your account again',
    accessMyAccount: 'Access my account',
  },

  emailConfirmation: {
    loading: 'Loading',
    title: 'You need to confirm your email to access the platform',
    resendEmail: 'Resend email',
    resending: 'Resending email...',
    resent: 'Email resent',
    emailAccess: 'Access your email and follow the steps there.',
    login: 'Login',
  },

  emailConfirmationSuccess: {
    loading: 'Loading',
    header: 'Your registration email has been successfully confirmed!',
    span: 'Access the ESG Portal securely now.',
    login: 'Login',
  },

  esgReport: {
    header: {
      whatIsIt: 'What is it?',
      description:
        'The ESG report is one of the benefits that each Abundance Brasil partner gains when purchasing their trees. Our document already presents the ESG actions related to the Abundance Token (ATK), but companies can take advantage of the template to add other activities that are part of their strategic plan.',
      emtelESGReportImageAlt: "Emtel's ESG Report page.",
      discoverESGReportsList: 'Discover the list of ESG reports',
      accessESGReport: 'Access your ESG report',
    },
    tutorial: {
      stepByStep: 'Step by step for editing the ESG report',
      description:
        'Find out now how to customize your report. If you have any questions, please <anchor>contact us</anchor>.',
      stepImageAlt: 'WordPress page.',
      steps: {
        firstStep: '1º step',
        firstStepDescription:
          'Access the platform (WordPress) to edit your ESG report information: <anchor>https://abundancebrasil.com/wp-login.php</anchor>',
        secondStep: '2º step',
        secondStepDescription:
          'Enter the access information sent by email (login and password)',
        thirdStep: '3º step',
        thirdStepDescription:
          'Within WordPress, access the “Syntropy” page, clicking on the menu on the left to be able to customize the ESG report',
        fourthStepDescription: 'Hover over the company name ➡️ select “Edit”',
        fifthStepDescription:
          'Analyze the information you want to edit, as shown in the image below:',
        sixthStepDescription:
          'In “Main banner” a brief description about the company must be inserted',
        seventhStepDescription:
          'In the “About” section, click to enable content and fill in the descriptions',
        eigthStepDescription:
          "In the “Pilars” option, it is possible to detail the company's actions linked to ESG so that all initiatives are clear in the report, even those that are not related to Abundance Brazil",
        ninethStepDescription:
          '⚠️ATTENTION⚠️ After any changes, don\'t forget to select the "Update" option within the "Publish" section on the Syntropy page',
      },
      editingUserProfileTitle: 'Editing the user profile',
      editingUserProfileDescription:
        'To change user information, go to “Profile”. It is possible to change the name of the person in charge, link email, avatar, platform color palette and password',
      signOutTitle: 'Sign out',
      signOutDescription:
        'To leave the platform, click on the username in the upper right corner of the page and choose “sign out”',
      termsOfUseTitle: 'Terms of use',
      termsOfUseDescription:
        '*This document is customizable. Abundance Brasil is only responsible for the benefits generated directly from its ESG solution (acquisition of trees from the Aurora Verde Forest). The other activities included by the partners are under the responsibility of each company that is part of the Syntropy Ecosystem.',
    },
  },

  forestProducerRegistration: {
    forestProducerRegistrationForm: {
      title: 'Create Your Forest with Abundance Brazil',
      subtitle: 'Forest Producer',
      description:
        'We are funding new forests in Brazil, and your rural property could become one of our environmental restoration projects! To make this happen, please complete your registration with all the requested information below, and we will get in touch.',
      nameLabel: 'Name',
      namePlaceholder: 'Your Name',
      emailLabel: 'Email',
      emailPlaceholder: 'Your Email',
      tin: 'Taxpayer identification number',
      country: 'Country',
      phoneNumber: 'Phone',
      propertySizeLabel: 'Property Size (in hectares)',
      propertySizePlaceholder: 'Property Size (in hectares)',
      cityLabel: 'City',
      cityPlaceholder: 'City Name',
      stateLabel: 'State',
      statePlaceholder: 'State Name',
      carLabel: 'CAR Number (Rural Environmental Registry)',
      carPlaceholder: 'CAR',
      legalProtectionLabel:
        'Is the area under any legal protection or conservation program?',
      yes: 'Yes',
      no: 'No',
      vegetationLabel: 'What is the biome or native vegetation of the region?',
      vegetationPlaceholder: 'Describe here',
      managementPracticesLabel: 'Land management practices on the property',
      managementPracticesPlaceholder:
        'Briefly describe the current environmental care practices in place for the area.',
      socialIssuesLabel: 'Social Issues',
      socialIssuesPlaceholder:
        'Describe any relevant social issues that should be considered in this project.',
      send: 'Send',
      talkToConsultant: 'I want to talk to a consultant',
    },

    forestProducerRegistrationSideBanner: {
      title: 'Transform your rural property into a new forest',
      firstParagraph:
        "Hello! If you've come this far, it's because you're interested in planting trees in your area and participating in the creation of a high-governance forest project.",
      secondParagraph:
        "This way, we'll accelerate the planet's decarbonization process and help recover Brazilian biodiversity, in addition to all the other benefits involved.",
      thirdParagraph:
        'Please fill out the form so that we can evaluate the information and the feasibility of the project. Remember that it is essential to fill out all the requested fields. We will get back to you soon. Thank you!',
    },
  },

  forestProducerRegistrationSuccess: {
    title: 'Rural Property Registration Successful',
    firstParagraph:
      'Thank you for your interest in joining us in our mission to make the planet greener! We have received your information and will be in touch soon.',
    forMore: 'For more information, refer to the',
    faq: 'Frequently Asked Questions',
    contact: 'or contact our support',
    here: 'here',
  },

  forgotPassword: {
    forgotPasswordForm: {
      title: 'Forgot your password?',
      description:
        'We will send you an email with instructions to create a new password.',
      rememberedPassword: 'Did you remember your password?',
      email: 'Email',
      login: 'Login',
      send: 'Send',
    },
  },

  howToGetMyCertificate: {
    title: 'Environmental retorer certificate',
    notARestorer: 'You are not yet an Environmental Restorer.',
    knowMore: 'See more',
    whatIsTheCertificate: 'What is the Environmental Restorer Certificate?',
    certificateExplanation:
      'All Abundance Brazil partners who have purchased tree-backed tokens are contributing to our goal of restoring the Brazilian environment. So, when you purchase one or more Abundance Tokens, you receive a certificate that proves your participation in this movement and can be used to show the world that you (individual or legal entity) invest in sustainability.',
    certificateImageAlt: 'Example of Abundance certificate.',
    howToGetTheCertificate: 'How to acquire the Certificate?',
    howToGetExplanation:
      'The only way to get the Abundance Brazil Environmental Restorer Certificate is to buy tokens. The document does not have an extra cost, being part of the deliverables linked to the product. That is, this is an exclusive benefit for everyone who purchases the Abundance Token.',
    wantToGetTheCertificate:
      'Do you want to obtain your Environmental Restoration Certificate?',
    restoreNow: 'Restore now',
  },

  howToGetMyTokens: {
    title: 'Environmental restorer',
    youAreNotARestorer: 'You are not yet an Environmental Restorer.',
    abundanceTokenImageAlt: 'Green coin with three trees in the center.',
    firstSpan:
      'Become an Environmental Restorer by purchasing Abundance Tokens',
    firstParagraph:
      "Abundance Token is an opportunity to boost Brazil's environmental restoration and have an asset with a tendency to appreciate. The crypto asset is backed by trees, which are tokenized and generate carbon credits - a type of asset valued worldwide and that can be traded on the secondary market. This is an excellent chance to make a sustainable, safe and profitable investment!",
    restoreNow: 'Restore now',
    secondSpan: 'What else do you get when you buy the Abundance Token?',
    secondParagraph:
      'We are creating a true community of tree and forest owners. In addition to the urgency of helping to save our planet, this is a worldwide trend that is also part of the ESG (Environmental, Social and Governance) movement. Check out which are the deliverables that accompany the purchase of the Abundance Token:',
    thirdSpan: 'Stamps',
    thirdParagraph:
      'Access a complete media package to publicize your investment initiative and level up as you buy more trees.',
    stampsInfoImageAlt:
      'List of all Abundance stamps, from largest to smallest.',
    fourthSpan: 'Certificates',
    fourthParagraph: 'Prove to the world that you are a forest investor.',
    knowMore: 'See more',
    certificateImageAlt:
      'Abundance certificate list. One certificate focused in the center with two others in the background with blur.',
    fifthSpan: 'Reports',
    fifthParagraph: 'Track carbon absorption data related to your purchase.',
    reportsImageAlt:
      'List of Abundance reports. One report focused in the center with two others in the background with blur.',
    sixthSpan: 'Landing pages to work your Sustainable Marketing',
    sixthParagraph:
      "Have a personalized page to work your business's ESG as one of your differentiators so that all your stakeholders have access and transparency to your ESG strategy.",
    emtelImageAlt:
      "Image of Emtel's landing page. Photo of a truck on a road with the title 'ESG Strategy and Innovation' on the left side.",
    seventhSpan: 'Want to be an environmental restorer?',
  },

  impact: {
    title: 'Environmental restorer',
    notARestorer: 'You are not yet an Environmental Restorer.',
    knowMore: 'See more',
    chartImageAlt:
      'Graph showing the amount of carbon uptake by a tree by 2052.',

    carbonManagementCards: {
      compensationCarbonManagementCard: {
        title: 'Compensation',
        subtitle: 'Decarbonization',
        description:
          'Ideal for voluntary offsetting of own or third party carbon emissions. This category is indicated for individuals and companies that do not have legal obligations to offset carbon and would like to contribute to a more sustainable future',
        availableTitle: 'Available trees',
        co2Title: 'Absorbed CO2',
        actionButtonTitle: 'Compensate (Soon)',
      },

      sellCarbonManagementCard: {
        title: 'Sale',
        subtitle: 'Carbon credits today',
        description:
          'Ideal for carbon market investors. In this category, the carbon credits associated so far with their tokens can be sold and traded.',
        availableTitle: 'Available trees',
        co2Title: 'CO2 today',
        actionButtonTitle: 'Sell (Soon)',
      },
    },

    header: {
      title: 'Management of your carbon credits',
      description:
        'You have available XXXX of Carbon dioxide removed from the atmosphere.',
      noCarbon: "You don't have carbon dioxide available from the atmosphere.",
    },
  },

  locked: {
    lockedAccount: 'Your account has been blocked',
    emailSent:
      'We have sent a password reset email to {{email}} with instructions on how to reset your password for security reasons.',
    forMoreInfo: 'For more information see our',
    faq: 'FAQ',
    contactSupport: 'or contact support',
    here: 'here',
  },

  mediaKit: {
    loading: 'Loading Media Kit...',
    downloadError: 'Error while downloading.',
    coinImageAlt: 'Coin in green color with three trees in the center.',
    firstSpan: 'Media Kit',
    firstParagraph:
      'Along with the purchase of the Abundace Token, each buyer receives a media kit equivalent to the amount of trees purchased. From 10 to 100,000 tokens or more, you will receive custom stamps to promote on your communication channels as part of the Abundance Tribe - a community of people and companies that are actively contributing to the environmental restoration of our country. The stamps are like visual indicators related to values such as sustainability, abundance, connection with nature and innovation with purpose.',
    firstHeader: 'Applications',
    secondSpan: 'Circular',
    secondParagraph:
      'Add to your signatures, logos or profile pictures on social media.',
    stampImageAlt: 'Abundance Environmental Restorer Stamp.',
    thirdSpan: 'Horizontal',
    thirdParagraph:
      'Version with more information to flag the initiative on your website, for example.',
    fourthSpan: 'Vertical',
    fourthParagraph:
      'Another format of the most complete version to add to your channels.',
    preparingDownload: 'Preparing to download...',
    downloadButton: 'Download Media Kit',
  },

  mobileIntroduction: {
    restoring: 'Restoring the future of the planet now',
    performance: 'ESG in practice, with decarbonization.',
    accessAccount: 'Access your account',
    createAccount: "Don't have an account? Create now!",
    forYou: 'For you',
    forCompany: 'For your business',
    doubts: 'Questions about the ESG Portal?',
    book: 'Schedule a presentation',
  },

  myCertificate: {
    title: 'Environmental restorer certificate',
    youGotYourCertificate:
      'You have earned the Environmental Restorer Certificate by purchasing Abundance Tokens!',
    certificateDescription:
      'Our token is backed by trees that are part of this initiative to reforest Brazil, help recover the environment, generate biodiversity, social impact and abundance. Take advantage of this document to prove and share that you are part of this movement.',
    view: 'View',
  },

  networkError: {
    errorImageAlt: 'A dead tree with a dark background.',
    noInternet: 'No internet',
    verifyConnection: ' Check your connection ',
    tryAgain: ' and try again to save the planet',
  },

  notFound: {
    notFoundImageAlt: 'Number 404. Zero is a dead tree with a dark background.',
    treeErrorImageAlt: 'A dead tree with a dark background.',
    notFound: 'Page not found',
    sorry: 'Sorry, but the link is dead',
    return: 'Return to main page',
  },

  home: {
    articlesList: {
      title: 'Recent articles',
      seeAll: 'See all',
    },

    homeTopBanner: {
      message:
        'Check out the virtual tour of our forest and see the environmental restoration happening at Aurora Verde.',
      access: 'Access',
      title: 'Become an environmental restorer',
      subtitle:
        'Join our mission to restore the planet and contribute to the formation of new forests in Brazil',
      subscribe: 'Subscribe to plan',
      buy: 'Buy trees',
    },

    noCalculationBanner: {
      title: 'Offset your carbon emissions and help form new forests in Brazil',
      description: "You haven't calculated your carbon emissions yet",
      calculate: 'Calculate emissions',
    },
  },

  purchaseWithoutAccount: {
    purchaseWithoutAccountForm: {
      title: 'What was the email used to make the purchase?',
      description:
        "We'll send you an email to give you access to all your trees.",
      email: 'Email',
      send: 'Send',
    },
  },

  purchaseWithoutAccountConfirmation: {
    title: 'Done! Now check your email',
    emailSent:
      'We sent a link to {{email}} so that you can create your account and have access to all trees purchased.',
    forMoreInfo: 'For more information see our',
    faq: 'FAQ',
    contactSupport: 'or contact support',
    here: 'here',
  },

  registerYourProfile: {
    registerYourProfileForm: {
      title: 'Calculate your emissions',
      subtitle: 'For your company',
      description:
        'Knowing your carbon offset is an important step to start a sustainable journey. Shall we begin?',
      nameLabel: 'Name',
      namePlaceholder: 'Your name',
      emailLabel: 'Email',
      emailPlaceholder: 'Your email',
      continue: 'By continuing, you agree to the',
      terms: 'Terms of Use',
      and: 'and',
      policy: 'Privacy Policy',
      initiate: 'Start',
      dontCalculate: 'I don’t want to calculate',
    },

    registerYourProfileSideBanner: {
      title: "What is your company's environmental impact?",
      description:
        'Calculate your carbon offset to start offsetting CO2 emissions and reinforce your commitment to the environment.',
      step: 'Step',
      step1: 'Create a profile with your company data.',
      step2: 'Answer the questions to get an estimate of your emissions.',
      step3: 'Check the result and start your decarbonization journey.',
    },
  },

  securityVerification: {
    securityVerificationForm: {
      title: 'Security verification',
      description:
        'To secure your account, complete the following verification.',
      phoneVerification: 'Phone verification',
      codeResent: 'Code resent',
      typeCode: 'Enter the 6-digit code sent to',
      resendCode: 'Resend code',
      send: 'Send',
      securityNotAvailable: 'Security check not available?',
    },
  },

  selectAccountType: {
    title: 'Profile',
    description:
      'Select your profile to join our community of environmental restorers.',
    accountTypes: 'Account types',
    forYouTitle: 'For you',
    forYouLabel: 'If everyone does their part, we can save the planet.',
    forYourCompanyTitle: 'For your company',
    forYourCompanyLabel: 'Join a group of companies that make a difference.',
    forestProducerTitle: 'Forest producer',
    forestProducerLabel:
      'Register and one of our consultants will get in touch.',
    continue: 'Continue',
    signInMessage: 'Already have an account? Sign in',

    selectAccountTypeSideBanner: {
      title:
        'Offset your carbon emissions and help create new forests in Brazil.',
      subtitle:
        'Create an account and acquire your Abundance Tokens to access various benefits:',
      certificate: 'Environmental Restorer Certificate',
      stamp: 'Sustainability stamps',
      report: 'CO2 impact report',
      tour: 'Virtual tour through the forest',
      esg: 'Customized ESG report',
      doubts: 'Questions? Talk',
      here: 'here',
      consultants: 'with one of our consultants',
    },
  },

  signIn: {
    signInForm: {
      title: 'Access your account',
      description: 'Enter your details below',
      email: 'Email',
      password: 'Password',
      login: 'Login',
      orContinueWith: 'or continue with',
      purchaseWithoutAccount: 'I purchased without account',
    },
  },

  signUp: {
    signUpCompany: {
      title: 'Create your account',
      subtitle: 'For your business',
      description: 'Enter the details below for registration of a Legal Entity',
    },

    signUpForm: {
      name: 'Your name',
      namePlaceholder: 'Name',
      email: 'Email',
      emailPlaceholder: 'myemail@email.com',
      companyName: 'Company name',
      companyEmailPlaceholder: 'myemail@company.com',
      phoneNumber: 'Phone',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      howDidYouGetHere: 'Where did you hear about Abundance Brazil?',
      choose: 'Select an option',
      sendUpdates: 'Send me updates, product information and ESG market.',
      createNotice: 'I declare that I have read, understood and agree with the',
      termsAndConditions: 'Terms and Conditions',
      and: 'and',
      privacyPolicy: 'Privacy Policy',
      enroll: 'when registering and creating my account.',
      createAccount: 'Create account',
      press: 'Press',
      recommendation: 'Recommendation',
    },

    signUpPersonal: {
      title: 'Create your account',
      subtitle: 'For you',
      description: 'Enter the details below for registration of an Individual',
    },
  },

  subscriptionPlans: {
    footer: {
      didntFind: "Can't find your ideal subscription plan?",
      talkWithSpecialist: 'Talk to one of our experts.',
      updatePlanButton: 'Update plan',
    },

    header: {
      noPlanMessage: {
        message:
          'Choose your recurring shopping experience and check out the benefits of each option.',
      },

      planDescriptionMessage: {
        currentPlan: 'Current plan',
        singularPlan: 'monthly tree',
        pluralPlan: 'monthly trees',
      },
    },

    subscriptionPlanCards: {
      subscriptionPlanCard: {
        change: 'Change plan',
        subscribe: 'Sign plan',
        loading: 'Loading',

        planBenefits: {
          tree: 'Tree',
          carbon: 'Carbon',
          potential: 'Potential in 2052',
        },

        upperSection: {
          coinIconAlt: "Abundance's coin.",
        },

        oneTree: {
          title: '1 tree',
          description:
            'For people and companies that want to buy their first tree or one more in their part of the forest.',
        },

        tenTree: {
          title: '10 trees',
          description:
            'For those who are starting to invest in environmental restoration and want to be friends with nature.',
        },

        fifthyTrees: {
          title: '50 trees',
          description:
            'For those who know the importance of encouraging small and new plantings and want to have a mini forest.',
        },

        oneHundredTrees: {
          title: '100 trees',
          description:
            'For people and companies that want to be a reference in the process of environmental restoration in Brazil.',
        },
      },

      subscriptionPlanCardsList: {
        loading: 'Loading',
        error: 'Error loading subscrition plans.',
        bonusUnitsPlaceholder: 'Win +',
        tree: 'tree',
        trees: 'trees',
      },
    },
  },

  support: {
    supportForm: {
      error: 'Error while sending the message. Please try again.',
      messageSent: 'Message sent!',
      thanks: 'Thank you for contacting us!',
      talkSoon: 'One of our consultants will respond shortly.',
      talkWithUs: 'Talk to one of our consultants',
      subject: 'Subject',
      message: 'Message',
      send: 'Send',

      supportSubjects: {
        doubt: 'Doubt',
        suggestion: 'Suggestion',
        iWantToBeAPartner: 'I want to be a partner',
        subscriptionPlans: 'Subscription plans',
        esgReport: 'ESG Report',
      },
    },
  },

  syntropy: {
    pageBanner: {
      talkWithConsultant: 'Talk to one of our consultants',
    },

    syntropyPageTabs: {
      reports: 'Reports',
      communities: 'Communities',
    },

    reports: {
      descriptionTitle: 'What are Reports?',
      descriptionText:
        "The ESG report is a way to demonstrate your company's initiatives in relation to the three ESG pillars (Environment, Social and Governance). In addition to the benefits associated with purchased trees, you can customize your report to include other information and ensure greater transparency about implemented good practices.",
      bannerTitle: "Don't have your ESG report yet?",
      bannerDescription:
        'Buy trees, receive your access and be part of our ecosystem.',

      syntropyCard: {
        companyIconAlt: 'Company logo.',
        lastPurchase: 'Last purchase: ',
        trees: 'trees',
      },
    },

    virtualForests: {
      descriptionTitle: 'What are Communities?',
      descriptionText:
        'These are our partners and environmental restorers, who are generating socio-environmental impact with their forests. Take the opportunity to generate new connections and business opportunities!',
      bannerTitle: "Don't have your community yet?",
      bannerDescription:
        'Buy trees, receive your access and be part of our ecosystem.',

      virtualForestCards: {
        syntropyCard: {
          logoImageAlt: 'Virtual forest logo.',
          planting: 'Planting',
          tree: 'tree',
          trees: 'trees',
          participants: 'Participants',
          type: 'Type',
          access: 'Access',
        },
      },
    },

    forestDetails: {
      banner: {
        project: 'Aurora verde forest',
        beARestorer: 'Be an environmental restorer.',
        buyTrees: 'Buy trees and generate abundance.',
        treesAmount: '100 thousand trees planted in 2022.',
        buyNow: 'Buy now',
      },

      header: {
        header: {
          back: 'Back',
          logoImageAlt: 'Virtual forest logo.',
          start: 'Start',
          planting: 'Planting',
          report: 'Impact Report',
          certificate: 'Restorative Forest Certificate',
        },

        summary: {
          planting: 'Planting',
          tree: 'tree',
          trees: 'trees',
          participants: 'Participants',
          type: 'Type',
        },
      },

      planting: {
        planting: {
          title: 'Aurora Verde',
          firstSubtitle:
            'The Aurora Verde project is a successful case in the purpose of greening Brazil through environmental restoration.',
          firstText:
            'Contribute to this initiative that helps the environment: invest in tokens that correspond to a tree, receive the carbon credit linked to it and monitor the development of your part of the forest.',
          secondSubtitle: '1 million trees',
          secondText:
            'Our goal is to elevate and accelerate the best that Brazil can have: sustainability and environmental abundance. <br/><br/> Abundance Tokens are tree-backed and issued to preserve each tree, ensuring it lives through its lifecycle. A tree captures, on average, 0.15 to 0.3 tons of CO2 over a period of 20 to 30 years. Thus, we can achieve significant value by forming a forest from issuing a million tokens. <br/><br/> Invest in environmental restoration and preservation of the environment when you acquire your token and have your share of Aurora Verde!',
        },
      },

      start: {
        guardian: {
          guardian: 'Forest Guardian',
          since: 'Virtual Forest since',
        },

        start: {
          about: 'About',
          benefits: 'Benefits',
        },
      },
    },
  },

  userSettings: {
    legalPerson: {
      legalPersonForm: {
        loading: 'Loading...',
        profile: 'Profile',
        keepYourDataUpdated: 'Keep your profile data always up to date.',
        name: 'Company name',
        email: 'Company email',
        emailPlaceholder: 'email@domain.com',
        phoneNumber: 'Phone number',
        language: 'Language',
        taxIdentification: 'Taxpayer identification',
        fillYourData: 'Fill in the details below',
        country: 'Country',
        number: 'Number',
        tin: 'Taxpayer identification number',
        update: 'Update account details',
        subscriptionPlan: 'Subscription plan',
        subscriptionPlanDescription: 'For recurring purchases.',
        success: 'Account details have been updated',
      },
    },

    physicalPerson: {
      physicalPersonForm: {
        loading: 'Loading...',
        profile: 'Profile',
        keepYourDataUpdated: 'Keep your profile data always up to date.',
        name: 'Your full name',
        email: 'Email',
        emailPlaceholder: 'email@domain.com',
        phoneNumber: 'Phone number',
        language: 'Language',
        taxIdentification: 'Taxpayer identification',
        fillYourData: 'Fill in the details below',
        country: 'Country',
        number: 'Number',
        tin: 'Taxpayer identification number',
        update: 'Update account details',
        subscriptionPlan: 'Subscription plan',
        subscriptionPlanDescription: 'For recurring purchases.',
        success: 'Account details have been updated',
      },
    },
  },

  wallet: {
    purchaseHistory: {
      credits: 'Credits',
      value: 'Value',
      status: 'Status',
      date: 'date',
    },

    walletOverview: {
      treesImageAlt:
        'Three trees, one in the center in front of the other two in the back.',
      cloudsImageAlt: 'Cloud charged with three drops falling.',
      trees: 'Trees',
      co2Absorption: 'Absorbed CO2',
    },

    walletTransactionsHistory: {
      purchased: 'Purchased',
      claimed: 'Claimed',
    },
  },
};
