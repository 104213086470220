import { DEFAULT_STORAGE_NAMESPACE } from 'constants/storage';
import { LocalStorageKey } from 'constants/types';

/**
 * It takes a key and an item to store, and returns the item to store
 * if it exists, or undefined if it doesn't.
 *
 * @param {LocalStorageKey} key - LocalStorageKey - The key to store the item under.
 * @param {T} itemToStore - The item to store in local storage.
 *
 * @returns The itemToStore is being returned.
 */
export function setItemInLocalStorage<T>(
  key: LocalStorageKey,
  itemToStore: T,
): T | undefined {
  localStorage.setItem(
    `${DEFAULT_STORAGE_NAMESPACE}${key}`,
    JSON.stringify(itemToStore),
  );

  return itemToStore;
}

/**
 * It gets an item from localStorage, parses it, and returns it.
 *
 * @param {LocalStorageKey} key - LocalStorageKey - the key of the item you want to retrieve from localStorage.
 *
 * @returns The value of the key in localStorage.
 */
export function getItemFromLocalStorage<T = unknown>(
  key: LocalStorageKey,
): T | undefined {
  const storedItem = localStorage.getItem(`${DEFAULT_STORAGE_NAMESPACE}${key}`);

  return storedItem ? JSON.parse(storedItem) : undefined;
}

/**
 * Remove an item from localStorage by key.
 *
 * @param {LocalStorageKey} key - LocalStorageKey - The key of the item you want to remove from local storage.
 *
 * @returns The key that was removed from localStorage.
 */
export function removeItemFromLocalStorage(
  key: LocalStorageKey,
): LocalStorageKey {
  localStorage.removeItem(`${DEFAULT_STORAGE_NAMESPACE}${key}`);

  return key;
}
