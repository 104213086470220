import { forwardRef, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Divider, RenderWhen } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import AuroraVerdeHeader from 'components/AuroraVerdeHeader';
import { TaxInformationForm } from 'components/Forms';
import PurchaseButtonWithDisclaimer from 'components/PurchaseButtonWithDisclaimer';
import { useIsUpdatingSubscriptionPlan } from 'state/application';
import { useSubscription } from 'state/subscriptionPlan';
import { useUser } from 'state/user';

import { modalParamsSelector } from '../../../store/modules/modal/selectors';
import * as S from './styles';
import SubscriptionSummary from './SubscriptionSummary';

export type SubscriptionPlanModalProps = {
  id?: string;
  tonsUntil2052?: string;
  packagePrice?: number;
  units?: number;
};

const SubscriptionPlanModal: React.FC<WithTranslation> = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ t }, _ref) => {
    const { subscribeToPlan } = useSubscription();
    const { data: user } = useUser();
    const isUpdatingSubscriptionPlan = useIsUpdatingSubscriptionPlan();

    const { id, tonsUntil2052, packagePrice, units } =
      useSelector(modalParamsSelector);
    const { country, taxIdentificationNumber } = user || {};
    const [disclaimerAgreed, setDisclaimerAgreed] = useState(false);
    const [userCountry, setUserCountry] = useState(country);
    const [userTaxIdentificationNumber, setUserTaxIdentificationNumber] =
      useState(taxIdentificationNumber);
    const [isValid, setIsValid] = useState(!!taxIdentificationNumber);
    const [isSubscribing, setIsSubscribing] = useState(false);

    const handlePurchasePlan = useCallback((): void => {
      setIsSubscribing(true);

      subscribeToPlan(
        id,
        country || userCountry || '',
        taxIdentificationNumber || userTaxIdentificationNumber || '',
        'subscription-plans',
      );
    }, [
      country,
      id,
      subscribeToPlan,
      taxIdentificationNumber,
      userCountry,
      userTaxIdentificationNumber,
    ]);

    return (
      <S.Root>
        <AuroraVerdeHeader />

        <S.SubscriptionDescription>
          <S.Title>{t('title')}</S.Title>

          <S.Description>
            {units} {t(units === 1 ? 'tree' : 'trees')}
          </S.Description>
        </S.SubscriptionDescription>

        <SubscriptionSummary
          tonsUntil2052={tonsUntil2052 || ''}
          packagePrice={packagePrice || 0}
        />

        <RenderWhen condition={!taxIdentificationNumber}>
          <TaxInformationForm
            country={userCountry || ''}
            onCountryChange={setUserCountry}
            onTinChange={setUserTaxIdentificationNumber}
            onValidChange={setIsValid}
          />
        </RenderWhen>

        <PurchaseButtonWithDisclaimer
          isDisabled={
            !disclaimerAgreed ||
            !isValid ||
            isUpdatingSubscriptionPlan ||
            isSubscribing
          }
          disclaimerAgreed={disclaimerAgreed}
          isLoading={isUpdatingSubscriptionPlan}
          buttonText={t('subscribe')}
          onDisclaimerAgreed={setDisclaimerAgreed}
          onSubmit={handlePurchasePlan}
        />

        <S.FooterContainer>
          <Divider />

          <S.Description>{t('footerText')}</S.Description>
        </S.FooterContainer>
      </S.Root>
    );
  },
);

export default withTranslation('components', {
  keyPrefix: 'modal:subscriptionModal:subscriptionModal',
  withRef: true,
})(SubscriptionPlanModal);
