import { withTranslation } from 'react-i18next';

import { formatToCurrency } from '@abundance-brasil-wabi/utils';

import { WithTranslation } from 'i18n/types';
import { formatedTreeValue } from 'constants/treeValue';

import * as S from './styles';

export type SubscriptionSummaryProps = {
  packagePrice: number;
  tonsUntil2052: string;
};

const SubscriptionSummary: React.FC<
  WithTranslation<SubscriptionSummaryProps>
> = ({ tonsUntil2052, packagePrice, t }) => {
  return (
    <S.Root>
      <S.SummaryLine>
        <S.SummaryTitle>{t('carbon2052')}</S.SummaryTitle>
        <S.Value>{tonsUntil2052.toLowerCase()}CO2</S.Value>
      </S.SummaryLine>

      <S.SummaryLine>
        <S.SummaryTitle>{t('unity')}</S.SummaryTitle>
        <S.Value>R$ {formatedTreeValue}</S.Value>
      </S.SummaryLine>

      <S.SummaryLine>
        <S.SummaryTitle>Total</S.SummaryTitle>
        <S.Total>R$ {formatToCurrency(packagePrice)}</S.Total>
      </S.SummaryLine>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'modal:subscriptionModal:subscriptionSummary',
})(SubscriptionSummary);
