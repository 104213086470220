import { createSelector } from 'reselect';

import { AppState } from '../..';
import { OTPValidationState } from './types';

const otpValidationState = (state: AppState): OTPValidationState =>
  state.otpValidation;

export const otpValidationSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation,
);

export const otpValidationPurposeSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.purpose,
);

export const otpValidationAPIMethodSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.apiMethod,
);

export const otpValidationPasswordSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.password,
);

export const otpValidationRequestDataSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.requestData,
);

export const otpValidationPasswordSuccessSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.passwordSuccess,
);

export const otpValidationTokenSuccessSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.tokenSuccess,
);

export const otpValidationHasErrorSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.hasError,
);

export const otpValidationOTPValidatedSuccessfullySelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.otpValidatedSuccessfully,
);

export const otpValidationRemoteOTPValidationDataSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.remoteOTPValidationData,
);

export const otpValidationOTPValidationResponseSelector = createSelector(
  otpValidationState,
  (otpValidation: OTPValidationState) => otpValidation.otpValidationResponse,
);
