import { withTranslation } from 'react-i18next';

import {
  Button,
  FormComposer,
  Loader,
  RenderWhen,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Dropdown, Input, PhoneNumber } from 'components/FormFields';
import StatusIndicator from 'components/ResponsibleConsultant/StatusIndicator';
import supportedLanguages from 'constants/supportedLanguages';
import { SupportedLanguages } from 'constants/types';

import { usePhysicalPersonForm } from './usePhysicalPersonForm';

const PhysicalPersonForm: React.FC<WithTranslation> = ({ t }) => {
  const {
    formState,
    control,
    countriesList,
    country,
    isLoading,
    isUpdating,
    getValues,
    onCountryChange,
    onTinChange,
    handleSubmit,
    onSubmit,
  } = usePhysicalPersonForm();

  return (
    <>
      <RenderWhen condition={isLoading}>
        <Loader message={t('loading')} />
      </RenderWhen>

      <RenderWhen condition={!isLoading}>
        <FormComposer
          fluid
          sections={[
            {
              id: 'profile',
              title: t('profile'),
              description: t('keepYourDataUpdated'),
              contents: [
                <Input
                  fluid
                  key="name"
                  name="name"
                  label={t('name')}
                  placeholder={t('name')}
                  isDisabled={isUpdating}
                  {...{ control, formState }}
                />,

                <Input
                  fluid
                  key="email"
                  name="email"
                  type="email"
                  label={t('email')}
                  placeholder={t('emailPlaceholder')}
                  isDisabled={isUpdating}
                  {...{ control, formState }}
                />,

                <PhoneNumber
                  fluid
                  key="phoneNumber"
                  name="phoneNumber"
                  label={t('phoneNumber')}
                  value={getValues('phoneNumber')}
                  isDisabled={isUpdating}
                  {...{ control, formState }}
                />,

                <Dropdown
                  fluid
                  key="language"
                  name="language"
                  label={t('language')}
                  placeholder={t('language')}
                  defaultValue={getValues('language')}
                  options={Object.keys(supportedLanguages).map(
                    (currentLanguage: string) => ({
                      value: currentLanguage,
                      label:
                        supportedLanguages[
                          currentLanguage as SupportedLanguages
                        ].title,
                    }),
                  )}
                  isDisabled={isUpdating}
                  {...{ control, formState }}
                />,
              ],
            },
            {
              id: 'taxpayer',
              title: t('taxIdentification'),
              description: t('fillYourData'),
              contents: [
                <Dropdown
                  fluid
                  key="country"
                  name="country"
                  label={t('country')}
                  placeholder={t('country')}
                  defaultValue={country}
                  options={countriesList}
                  onSelectionChange={onCountryChange}
                  isDisabled={isUpdating}
                  {...{ control, formState }}
                />,

                <Input
                  fluid
                  key="taxIdentificationNumber"
                  name="taxIdentificationNumber"
                  label={t('tin')}
                  placeholder={t('tin')}
                  onChange={onTinChange}
                  isDisabled={isUpdating}
                  {...{ control, formState }}
                />,
              ],
            },
            {
              id: 'subscription',
              title: t('subscriptionPlan'),
              description: t('subscriptionPlanDescription'),
              contents: [<StatusIndicator key="status" />],
            },
            {
              id: 'submit',
              contents: [
                <Button
                  size={48}
                  key="submit"
                  type="submit"
                  variant="primary"
                  isDisabled={
                    !formState.isValid || isUpdating || !formState.isDirty
                  }
                >
                  {t('update')}
                </Button>,
              ],
            },
          ]}
          onSubmit={handleSubmit(onSubmit)}
        />
      </RenderWhen>
    </>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'userSettings:physicalPerson:physicalPersonForm',
})(PhysicalPersonForm);
