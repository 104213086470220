import { styled } from 'styled-components';

import {
  breakpointDown,
  Button,
  ContentsHeader,
  getThemeColor,
  InfoTile,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 6px;
  background-color: ${getThemeColor('default')};

  ${breakpointDown('xl')`
    flex-direction: column;
    align-items: flex-start;
  `}

  ${breakpointDown('sm')`
    gap: 16px;
    padding: 24px;
  `}
`;

export const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Tile = styled(InfoTile)`
  width: 80px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 32px;

  ${breakpointDown('sm')`
    width: 64px;
    height: 54px;
  `}

  ${breakpointDown('xxxs')`
    width: 48px;
    height: 44px;
    font-size: 18px;
  `}
`;

export const Contents = styled(ContentsHeader)`
  flex: 1;

  & > :not(:first-child) {
    margin-top: 8px;
  }
`;

export const CalculateButton = styled(Button).attrs({
  size: 32,
})`
  ${breakpointDown('sm')`
    width: 100%;
  `}
`;
