import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';

import PageBanner from '../PageBanner';
import PageDescription from '../PageDescription';
import VirtualForestCardsList from './VirtualForestCards';

const VirtualForests: React.FC<WithTranslation> = ({ t }) => {
  return (
    <>
      <PageDescription
        title={t('descriptionTitle')}
        description={t('descriptionText')}
      />

      <PageBanner
        title={t('bannerTitle')}
        description={t('bannerDescription')}
      />

      <VirtualForestCardsList />
    </>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:virtualForests',
})(VirtualForests);
