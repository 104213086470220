import FormPageContainer from 'components/FormPageContainer';
import { useSetPageConfig } from 'components/Page';

import ForestProducerRegistrationForm from './ForestProducerRegistrationForm';
import ForestProducerRegistrationSideBanner from './ForestProducerRegistrationSideBanner';

const ForestProducerRegistration: React.FC = () => {
  useSetPageConfig({
    mode: 'split',
    override: {
      sideContent: <ForestProducerRegistrationSideBanner />,
    },
  });

  return (
    <FormPageContainer to="selectAccountType">
      <ForestProducerRegistrationForm />
    </FormPageContainer>
  );
};

export default ForestProducerRegistration;
