import createSagaMiddleware from 'redux-saga';

import { configureStore } from '@reduxjs/toolkit';

import { createModalMiddleware } from './middlewares/modalMiddleware';
import { ModalState } from './modules/modal/types';
import { OTPValidationState } from './modules/otpValidation/types';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import initialStoreState from './modules';

const modalMiddleware = createModalMiddleware();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, modalMiddleware];

export type AppState = {
  otpValidation: OTPValidationState;
  modal: ModalState;
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialStoreState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([...middlewares]),
});

sagaMiddleware.run(rootSaga);

export default store;
