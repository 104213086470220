import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import networkError from 'assets/network-error.png';

import * as S from './styles';

const NetworkError: React.FC<WithTranslation> = ({ t }) => {
  return (
    <S.Root>
      <S.Container>
        <S.Content>
          <S.Logo src={networkError} alt={t('errorImageAlt')} />

          <S.Title>{t('noInternet')}</S.Title>

          <S.Body>
            {t('verifyConnection')}
            <br />
            {t('tryAgain')}
          </S.Body>
        </S.Content>
      </S.Container>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'networkError',
})(NetworkError);
