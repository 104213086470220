import { styled } from 'styled-components';

import { breakpointDown } from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  ${breakpointDown('sm')`
    gap: 16px;
  `}
`;
