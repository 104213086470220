import { withTranslation } from 'react-i18next';
import {
  MdCardMembership,
  MdOutlineComputer,
  MdOutlineEmojiEvents,
  MdOutlineLeaderboard,
  MdOutlineSummarize,
} from 'react-icons/md';

import { Banner, Link } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useGetRandomSideBannerImage } from 'components/Page';
import { abundanceURLs } from 'constants/externalURLs';

import * as S from './styles';

const SelectAccountTypeSideBanner: React.FC<WithTranslation> = ({
  t,
}): React.ReactNode => {
  const bannerImage = useGetRandomSideBannerImage();

  return (
    <Banner src={bannerImage}>
      <Banner.Header>
        <Banner.Title>{t('title')}</Banner.Title>
        <Banner.Subtitle>{t('subtitle')}</Banner.Subtitle>
      </Banner.Header>

      <Banner.Content>
        <S.ContentText>
          <MdCardMembership size={24} />
          {t('certificate')}
        </S.ContentText>
        <S.ContentText>
          <MdOutlineEmojiEvents size={24} />
          {t('stamp')}
        </S.ContentText>
        <S.ContentText>
          <MdOutlineSummarize size={24} />
          {t('report')}
        </S.ContentText>
        <S.ContentText>
          <MdOutlineComputer size={24} />
          {t('tour')}
        </S.ContentText>
        <S.ContentText>
          <MdOutlineLeaderboard size={24} />
          {t('esg')}
        </S.ContentText>
      </Banner.Content>

      <Banner.Content>
        <S.FooterText>
          {t('doubts')}{' '}
          <Link href={abundanceURLs.contact} target="_blank">
            {t('here')}
          </Link>{' '}
          {t('consultants')}
        </S.FooterText>
      </Banner.Content>
    </Banner>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'selectAccountType:selectAccountTypeSideBanner',
})(SelectAccountTypeSideBanner);
