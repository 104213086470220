import { Control, Controller, FieldValues, FormState } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { FormsState } from 'types/forms';

import { Dropdown as BaseDropdown, Item } from '@abundance-brasil-wabi/sabi';
import { Key } from '@react-types/shared';

import { WithTranslation } from 'i18n/types';

import { DefaultFieldProps } from '../types';
import { DropdownOption, ValueType } from './types';
import { getParseKey } from './utils';

export type DropdownProps = WithTranslation<
  Omit<DefaultFieldProps, 'defaultValue'>
> & {
  defaultValue?: string | number;
  valueType?: ValueType;
  control: Control<FormsState, unknown>;
  formState: FormState<FieldValues>;
  options: DropdownOption[];
};

const Dropdown: React.FC<DropdownProps> = ({
  name = 'input',
  valueType = 'string',
  label,
  placeholder,
  defaultValue = '',
  control,
  formState,
  fluid,
  isDisabled,
  options,
  onSelectionChange,
  t,
}) => {
  const errors = formState?.errors?.[name];

  return (
    <Controller
      {...{ name, defaultValue, control }}
      render={({ field }) => {
        const { value, onChange } = field;

        return (
          <BaseDropdown
            {...field}
            {...{
              label,
              placeholder,
              fluid,
              isDisabled,
            }}
            selectedKey={
              typeof value !== 'string' ? value.toString() : (value as Key)
            }
            state={errors ? 'danger' : 'none'}
            feedbackMessage={t(errors?.message as string)}
            aria-labelledby={label ?? placeholder}
            onSelectionChange={(key) => {
              const parsedKey = getParseKey(key, valueType);

              onSelectionChange?.(parsedKey);
              onChange(parsedKey);
            }}
            // Prevents premature validation when hovering over the
            // list elements or scrolling the dropdown.
            onBlur={() => null}
          >
            {options.map((option) => (
              <Item key={option.value} textValue={option.label}>
                {option.label}
              </Item>
            ))}
          </BaseDropdown>
        );
      }}
    />
  );
};

export default withTranslation()(Dropdown);
