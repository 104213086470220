import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { getIsAuthenticated } from 'state/auth/utils';
import { useEventTracking } from 'state/eventTracking';
import { useUser } from 'state/user';

const AppLayout: React.FC = () => {
  const { setUserTrackingInfo } = useEventTracking();
  const { data: user } = useUser();

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (user && isAuthenticated) {
      setUserTrackingInfo({
        userId: user.id,
        email: user.email,
      });
    }
  }, [isAuthenticated, setUserTrackingInfo, user]);

  return <Outlet />;
};

export default AppLayout;
