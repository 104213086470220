import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { useToast } from 'hooks/useToast';

import { validatePasswordAndPurposeRequest } from '../../../../store/modules/otpValidation/actions';
import { otpValidationSelector } from '../../../../store/modules/otpValidation/selectors';
import { passwordVerificationFormSchema } from './constants';
import { PasswordVerificationFormSchema } from './types';

export type UsePasswordVerificationForm =
  FormReturn<PasswordVerificationFormSchema> & {
    isLoading: boolean;
    onSubmit: () => void;
  };

export function usePasswordVerificationForm(): UsePasswordVerificationForm {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('components', {
    keyPrefix: 'modal:passwordVerificationModal:passwordVerificationForm',
  });
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const { purpose, hasError } = useSelector(otpValidationSelector);

  const form = useForm<PasswordVerificationFormSchema>({
    resolver: zodResolver(passwordVerificationFormSchema),
    mode: 'all',
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    async ({
      verifyPassword,
    }: PasswordVerificationFormSchema): Promise<void> => {
      setIsLoading(true);

      dispatch(validatePasswordAndPurposeRequest(purpose, verifyPassword));
    },
    [dispatch, purpose],
  );

  useEffect(() => {
    if (hasError) {
      setIsLoading(false);

      addToast({
        message: t('error'),
        state: 'error',
      });
    }
  }, [addToast, hasError, t]);

  return {
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    ...form,
  };
}
