import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import i18next from 'i18next';
import { useApplicationActions } from 'state/application';
import { getAuthToken } from 'state/auth/utils';
import { useUserId } from 'state/user';
import { getUserPDFPreview } from 'utils/handlers';

import { treeQueryKeys } from './queryKeys';
import { certificateSelector } from './selectors';
import { CertificateQueryKey } from './types';

export default function useGetCertificate<TData = Blob>(
  options?: QueryOptions<Blob, TData, CertificateQueryKey>,
) {
  const { setFileLoading } = useApplicationActions();
  const { data: userId } = useUserId();
  const token = getAuthToken();

  return useQuery<Blob, unknown, TData, CertificateQueryKey>(
    treeQueryKeys.certificate(userId, i18next.language),
    async () => {
      setFileLoading(true);

      const result = await getUserPDFPreview('CERTIFICATE', token);

      setFileLoading(false);

      return result;
    },
    {
      ...options,
    },
  );
}

export const useCertificate = () =>
  useGetCertificate({
    select: certificateSelector,
  });
