import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeBoxShadow,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Link,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Content = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
})`
  width: 100%;
`;

export const Header = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 6,
})`
  width: 100%;

  ${breakpointDown('lg')`
    gap: 16px;
  `}
`;

export const HeaderTitle = styled(Heading).attrs({
  level: 4,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const HeaderDescription = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('regular')};
`;

export const EmtelImage = styled.img`
  width: 739px;
  max-width: 100%;
  margin: 34px 0;

  ${breakpointDown('lg')`
    width: 100%;
    margin: 0;
  `}
`;

export const ButtonsContainer = styled(Spacer).attrs({
  direction: 'row',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 6,
})`
  ${breakpointDown('lg')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      justify: 'center',
      gap: '8px',
    })};

    width: 100%;

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

export const HeaderButton = styled(LinkButton).attrs({
  variant: 'secondary-outline',
  size: 40,
})`
  ${breakpointDown('lg')`
    width: 100%;
    justify-content: center;
  `}
`;

export const HeaderLink = styled(Link).attrs({
  variant: 'primary',
  size: 40,
})`
  ${breakpointDown('lg')`
    width: 100%;
    justify-content: center;
  `}
`;

export const Title = styled(Heading).attrs({
  level: 5,
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Description = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const ExternalLink = styled(Link)`
  height: auto;
  display: contents;
  font-size: 16px;
  font-weight: ${getThemeFontWeight('bold')};
  line-break: anywhere;
`;

export const Tutorial = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 2,
})`
  width: 100%;

  ${breakpointDown('lg')`
    gap: 0px;
  `}
`;

export const Steps = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 2,
})`
  width: 100%;
  margin-top: 32px;

  ${breakpointDown('lg')`
    margin-top: 24px;
  `}
`;

export const Step = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('bold')};
  text-transform: uppercase;
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const TermsDescription = styled(Text).attrs({
  level: 'body',
})`
  width: 100%;
  margin-top: 14px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${breakpointDown('lg')`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

export const StepDescription = styled(Text).attrs({
  level: 'body',
})`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 18px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const StepSubdescription = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const StepImage = styled.img`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 32px;
  filter: ${getThemeBoxShadow('md')};

  ${breakpointDown('lg')`
    margin-bottom: 16px;
  `}
`;
