import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import {
  purchaseWithoutAccount,
  PurchaseWithoutAccountArgs,
} from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function usePurchaseWithoutAccount(
  options?: MutationOptions<void, PurchaseWithoutAccountArgs>,
) {
  return useMutation<void, ErrorMessage, PurchaseWithoutAccountArgs, unknown>(
    purchaseWithoutAccount,
    options,
  );
}
