import { css, styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import BaseSideMenu from './SideMenu';
import { StyledChildrenProps, StyledContentsProps } from './types';

export const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  & > * {
    width: 100%;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: auto;
  flex: 1;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

export const Side = styled.aside`
  height: 100%;

  & > * {
    height: 100%;
  }

  ${breakpointDown('lg')`
    display: none;
  `}
`;

export const Contents = styled.div<StyledContentsProps>`
  width: ${({ $mode, $sideContentWidth }) =>
    $mode === 'split' ? `calc(100% - ${$sideContentWidth}px)` : '100%'};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $mode }) =>
    $mode === 'fluid' ? 'flex-start' : 'center'};

  & > * {
    width: 100%;
    height: ${({ $mode }) => ($mode === 'cover' ? '100%' : 'auto')};
  }

  ${breakpointDown('lg')`
    width: 100%;
  `}
`;

export const Children = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: 0,
})<StyledChildrenProps>`
  position: relative;
  padding: ${({ $mode }) => ($mode === 'cover' ? '0' : '64px')};

  ${({ $isLoaded }) =>
    !$isLoaded &&
    css`
      height: 100%;
    `};

  ${breakpointDown('lg')`
    padding: ${({ $mode }) => ($mode === 'cover' ? '0' : '24px')};
  `}
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const Cover = styled(Spacer).attrs({
  align: 'flex-start',
  justify: 'center',
  gap: 0,
})`
  width: 100%;
  height: 100%;
`;

export const Fluid = styled(Spacer).attrs({
  align: 'flex-start',
  justify: 'center',
  gap: 0,
})`
  width: 100%;
  height: 100%;

  ${breakpointDown('lg')`
    align-items: flex-start;
    padding: 40px;
  `}

  ${breakpointDown('sm')`
    padding: 0;
  `}
`;

export const FluidContents = styled.div`
  width: 100%;
  height: 100%;
`;

export const Expanded = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
  gap: 0,
})`
  width: 1544px;
  max-width: 100%;
  height: 100%;
  padding: 52px;

  ${breakpointDown('lg')`
    align-items: flex-start;
    padding: 76px 92px;
  `};

  ${breakpointDown('sm')`
    padding: 0;
  `}
`;

export const Split = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
  gap: 0,
})`
  width: 100%;
  max-width: 644px;
  height: 100%;

  ${breakpointDown('lg')`
    align-items: flex-start;
    padding: 40px 0;
  `}

  ${breakpointDown('sm')`
    max-width: 100%;
    padding: 0;
  `}
`;

export const SideMenu = styled(BaseSideMenu)`
  width: 88px;
  border-right: 1px solid ${getThemeColor('border', 'default', 'primary')};
`;
