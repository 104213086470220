import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { GetMediaKitArgs, GetMediaKitBody, MediaKit } from './types';

export async function getMediaKit(args: GetMediaKitArgs) {
  const { body } = args;

  const { data } = await request.post<
    MediaKit,
    AxiosResponse<MediaKit>,
    GetMediaKitBody
  >(`${abundanceAPI.V1.MEDIA}/image/media-kit/preview`, body);

  return data;
}
