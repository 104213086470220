import { QueryOptions } from 'types/react-query';

import { useQuery } from '@tanstack/react-query';

import { getVirtualForestById, VirtualForest } from 'api/services';

import { virtualForestQueryKeys } from './queryKeys';
import { virtualForestByIdSelector } from './selectors';
import { VirtualForestByIdQueryKey } from './types';

export default function useGetVirtualForestById<TData = VirtualForest>(
  id: string,
  options?: QueryOptions<VirtualForest, TData, VirtualForestByIdQueryKey>,
) {
  return useQuery<VirtualForest, unknown, TData, VirtualForestByIdQueryKey>(
    virtualForestQueryKeys.virtualForestById(id),
    () => getVirtualForestById({ params: { id } }),
    options,
  );
}

export const useVirtualForestById = (id: string) =>
  useGetVirtualForestById(id, {
    select: virtualForestByIdSelector,
  });
