export default {
  changePassword: {
    changePasswordForm: {
      successChange: 'Senha alterada com sucesso',
      errorChange: 'Erro ao alterar senha',
      typePassword: 'Digite sua senha',
      typeNewPassword: 'Digite sua nova senha',
      confirmNewPassword: 'Confirme sua nova senha',
      changePassword: 'Alterar senha',
      loading: 'Carregando...',
    },
  },

  carbonOffsetCalculator: {
    carbonOffsetCalculatorForm: {
      title: 'Olá',
      subtitle: 'Para sua empresa',
      description:
        'Responda as seguintes perguntas para calcular a pegada de carbono da sua empresa. Se não tiver certeza sobre alguns tópicos, pense na resposta mais aproximada possível.',
      collaboratorsTitle: 'Colaboradores',
      collaboratorsDescription:
        'Para calcular o impacto do trabalho fora do escritório físico (home office, coworking ou viagens a trabalho), consideramos as emissões de resíduos, deslocamento para o trabalho, consumo de gás e energia elétrica e escapes de equipamentos. A jornada de trabalho é considerada de 8 horas por dia e 5 dias por semana.',
      transportTitle: 'Transporte',
      transportDescription:
        'Consideramos o deslocamento no horário de trabalho, bem como o transporte dos veículos da empresa. Para fazer isso, calculamos os preços médios da gasolina para cada estado usando os dados fornecidos pela ANP. Consideramos as emissões das distâncias médias de voos domésticos e estrangeiros.',
      officeTitle: 'Escritório',
      officeDescription:
        'A próxima etapa é simplesmente seguir caso sua empresa não tenha escritório físico.',
      numberOfEmployeesLabel: 'Número de colaboradores',
      numberOfEmployeesPlaceholder: 'Colaboradores',
      workModelAdoptedLabel: 'Como é o modelo de trabalho adotado?',
      workModelAdoptedPlaceholder: 'Modelo de trabalho',
      fuelLabel: 'Quais tipos de combustíveis são utilizados pela sua frota?',
      gasolineLabel: 'Gasolina',
      gasolinePlaceholder: 'Insira a litragem mensal de gasolina',
      dieselLabel: 'Diesel',
      dieselPlaceholder: 'Insira a litragem mensal de diesel',
      ethanolLabel: 'Etanol',
      ethanolPlaceholder: 'Insira a litragem mensal de etanol',
      gnvLabel: 'GNV',
      gnvPlaceholder: 'Insira a litragem mensal de GNV',
      electricalVehiclesLabel: 'Veículos elétricos',
      electricalVehiclesPlaceholder:
        'Insira o KwH mensal dos veículos elétricos',
      averageMonthlySpendingOnTaxiAndTransportAppsLabel:
        'Gasto médio mensal com táxi e aplicativos de transporte (R$)',
      averageMonthlySpendingOnTaxiAndTransportAppsPlaceholder:
        'Gasto médio mensal',
      annualFrequencyOfDomesticFlightsLabel:
        'Com que frequência os membros da sua empresa realizam viagens de avião dentro do Brasil (ida e volta) durante um ano?',
      annualFrequencyOfDomesticFlightsPlaceholder:
        'Frequência de viagens nacionais',
      annualFrequencyOfInternationalFlightsLabel:
        'Com que frequência os membros da sua empresa realizam viagens de avião internacionais (ida e volta) durante um ano?',
      annualFrequencyOfInternationalFlightsPlaceholder:
        'Frequência de viagens internacionais',
      averageMonthlyElectricityBillCostLabel:
        'Custo médio mensal da conta de luz do escritório (R$)',
      electricityFromRenewableSourcesLabel:
        'Parte ou a totalidade da eletricidade da sua empresa é proveniente de fontes renováveis?',
      descriptionOfGreenAreasMaintainedLabel:
        'Existem áreas verdes naturais que são mantidas pela empresa?',
      treePlantingParticipationLabel:
        'Sua empresa já participou ou participa de plantio de árvores?',
      averageMonthlyWasteSentToLandfillInTonsLabel:
        'Qual a quantidade média em toneladas de resíduos destinada a aterros sanitários mensalmente?',
      averageMonthlyWasteSentToLandfillInTonsPlaceholder:
        'Insira a quantidade média em toneladas',
      percentageOfWasteRecycledLabel:
        'Qual a porcentagem dos resíduos que são reciclados?',
      selectAnOption: 'Selecione uma opção',
      legalText:
        'Os resultados fornecidos por nossa ferramenta de cálculo de carbono são estimativas baseadas nos dados inseridos pelo usuário. A Abundance Brasil não garante a precisão dessas estimativas e não se responsabiliza por decisões tomadas com base nelas. Esta ferramenta é para uso orientativo, visando facilitar o engajamento scom a descarbonização. Recomendamos consultoria especializada para análises precisas de emissões e ações de mitigação. Ao usar esta ferramenta, você reconhece sua natureza estimativa e concorda com estes termos.',
      calculate: 'Calcular suas emissões',

      menuItems: {
        workModelAdopted: {
          full: 'Presencial integral (5 dias/semana)',
          partial: 'Presencial parcial (híbrido)',
          remote: 'Totalmente remoto',
        },

        flightsFrequency: {
          itemOne: '1 - 10 viagens por ano',
          itemTwo: '11 - 20 viagens por ano',
          itemThree: '21 - 50 viagens por ano',
          itemFour: '51 - 100 viagens por ano',
          itemFive: 'Mais de 100 viagens por ano',
        },

        averageMonthlyElectricityBillCost: {
          low: 'Baixo: Como um pequeno escritório ou loja - R$100',
          moderate:
            'Moderado: Como uma fábrica ou edifício de escritórios médio - R$500',
          high: 'Alto: Como grandes fábricas ou escritórios corporativos - R$2000',
        },

        electricityFromRenewableSources: {
          high: 'Sim, entre 30% a 70%',
          average: 'Sim, mais de 70%',
          none: 'Não',
        },

        booleanQuestion: {
          yes: 'Sim',
          no: 'Não',
        },

        percentageOfWasteRecycled: {
          itemOne: 'Entre 10% a 50%',
          itemTwo: 'Mais de 50%',
          itemThree: '0%',
          itemFour: 'Não sei',
        },
      },
    },

    carbonOffsetCalculatorSideBanner: {
      title: 'Quer reduzir seu impacto e ganhar um diferencial competitivo?',
      subtitle:
        'Calcule sua pegada de carbono para começar a compensar as emissões de CO2 e reforce seu compromisso com o meio ambiente.',
      step: 'PASSO',
      step1: 'Crie um perfil com os dos dados da sua empresa.',
      step2: 'Responda as perguntas para ter uma estimativa das suas emissões.',
      step3: 'Confira o resultado e inicie sua jornada de descarbonização.',
    },
  },

  carbonOffsetResults: {
    carbonOffsetResultsFooter: {
      doc: 'Documentário aurora verde',
      openAccountButtonDesktop:
        'Abra a sua conta e descarbonize suas emissões mais tarde',
      openAccountButtonMobile: 'Descarbonize suas emissões mais tarde',
    },

    carbonOffsetResultsHeader: {
      title: 'esta é a pegada de carbono da sua empresa',
      subtitle: 'Para sua empresa',
      description:
        'Agora que você já tem uma estimativa das suas emissões de carbono, fica mais fácil criar um plano de descarbonização. Que tal começar a compensar agora e se tornar um restaurador ambiental?',
    },
  },

  companySettings: {
    companySettingsForm: {
      loading: 'Carregando...',
      accountOwner: 'Dados do responsável pela conta',
      fillYourPersonalData: 'Preencha os dados pessoais',
      yourName: 'Seu nome completo',
      name: 'Nome',
      email: 'E-mail',
      emailPlaceholder: 'email@domínio.com.br',
      phoneNumber: 'Telefone',
      extraInfo: 'Informações extras',
      extraInfoDescription:
        'Para conhecermos melhor a sua empresa e oferecermos uma melhor consultoria ESG, preencha as informações extras abaixo.',
      address: 'Endereço',
      addressPlaceholder: 'Digite o seu endereço',
      sector: 'Setor',
      select: 'Selecione',
      industry: 'Indústria',
      numberEmployees: 'Número de funcionários',
      annualBilling: 'Faturamento anual',
      dataUpdated: 'Os dados da empresa foram atualizados',
      update: 'Atualizar dados da empresa',

      sectors: {
        fashion: 'Moda',
        industry: 'Indústria',
        foodRetail: 'Varejo de alimentos',
        foodAndDrinks: 'Alimentos e bebidas',
        healthAndCosmetics: 'Saúde e cosméticos',
        retailAndWholesale: 'Varejo e atacado',
      },

      industries: {
        production: 'Produção',
        capital: 'Capital',
        consumption: 'Consumo',
        extractive: 'Extrativa',
        transformation: 'Transformação',
        energetic: 'Energética',
        food: 'Alimentícia',
        civilConstruction: 'Construção civil',
        informational: 'Informacional',
      },

      employees: {
        upTo10: 'Até 10',
        from10To50: 'De 10 a 50',
        from50To100: 'De 50 a 100',
        moreThan100: 'Mais de 100',
      },

      annualIncome: {
        upTo100: 'Até 100 mil',
        from100To500: 'De 100 a 500 mil',
        from500To1Mil: 'De 500 a 1 milhão',
        from1MilTo5Mil: 'De 1 milhão a 5 milhões',
        moreThan5Mil: 'Mais de 5 milhões',
      },
    },
  },

  createNewPassword: {
    createNewPasswordForm: {
      title: 'Insira a sua nova senha',
      description: 'Instruções sobre a criação da nova senha e caracteres',
      error: 'Erro ao alterar senha.',
      newPassword: 'Nova senha',
      newPasswordConfirm: 'Confirme a nova senha',
      save: 'Cadastrar nova senha',
    },
  },

  createNewPasswordConfirm: {
    emailSent: 'O e-mail para criar a nova senha foi enviado',
    emailSentDescription:
      'Enviamos um e-mail de redefinição de senha para {{email}} com instruções sobre como redefinir sua senha.',
    ifNotReceiveEmail:
      'Se você não receber o e-mail, verifique se o endereço de e-mail inserido está correto, consulte nossas',
    faq: 'Perguntas frequentes',
    contactSupport: 'ou entre em contato com o suporte',
    here: 'aqui',
  },

  createNewPasswordSuccess: {
    title: 'Nova senha criada com sucesso!',
    youCanAccessYourAccount: 'Agora você pode acessar novamente a sua conta',
    accessMyAccount: 'Acessar minha conta',
  },

  emailConfirmation: {
    loading: 'Carregando',
    title: 'Você precisa confirmar o seu e-mail para acessar a plataforma',
    resendEmail: 'Reenviar e-mail',
    resending: 'Reenviando e-mail...',
    resent: 'E-mail reenviado',
    emailAccess: 'Acesse o seu e-mail e siga os passos por lá.',
    login: 'Login',
  },

  emailConfirmationSuccess: {
    loading: 'Carregando',
    header: 'Seu e-mail de cadastro foi confirmado com sucesso!',
    span: 'Acesse agora mesmo o Portal ESG com segurança.',
    login: 'Faça o login',
  },

  esgReport: {
    header: {
      whatIsIt: 'O que é?',
      description:
        'O relatório ESG é um dos benefícios que cada parceiro da Abundance Brasil ganha ao comprar suas árvores. O nosso documento já apresenta as ações ESG relacionadas ao Abundance Token (ATK), mas as empresas podem aproveitar o template para adicionar outras atividades que fazem parte do seu plano estratégico.',
      emtelESGReportImageAlt: 'Página de Relatório ESG da Emtel.',
      discoverESGReportsList: 'Conheça a lista de relatórios ESG',
      accessESGReport: 'Acesse seu relatório ESG',
    },
    tutorial: {
      stepByStep: 'Passo a passo para edição do relatório ESG',
      description:
        'Descubra agora como personalizar o seu relatório. Se tiver alguma dúvida, entre em <anchor>contato conosco</anchor>.',
      stepImageAlt: 'Página do WordPress.',
      steps: {
        firstStep: '1º passo',
        firstStepDescription:
          'Acesse a plataforma (WordPress) para poder editar as informações do seu relatório ESG: <anchor>https://abundancebrasil.com/wp-login.php</anchor>',
        secondStep: '2º passo',
        secondStepDescription:
          'Insira as informações de acesso enviadas por e-mail (login e senha)',
        thirdStep: '3º passo',
        thirdStepDescription:
          'Dentro do WordPress, acesse a página “Syntropy”, clicando no menu à esquerda para conseguir personalizar o relatório ESG',
        fourthStepDescription:
          'Passe o mouse sobre o nome da empresa ➡️ selecionar “Editar”',
        fifthStepDescription:
          'Analise as informações que deseja editar, conforme imagem abaixo:',
        sixthStepDescription:
          'Em “Banner principal” deve ser inserido uma breve descrição sobre a empresa',
        seventhStepDescription:
          'Na seção “Sobre”, clicar para habilitar conteúdo e preencher as descrições',
        eigthStepDescription:
          'Na opção “Pilares”, é possível detalhar as ações da empresa ligadas à ESG para que todas as iniciativas fiquem claras no relatório, mesmo aquelas que não são relacionadas com a Abundance Brasil',
        ninethStepDescription:
          '⚠️ATENÇÃO⚠️ Após qualquer alteração, não esqueça de selecionar a opção “Atualizar” dentro da seção “Publicar” na página do Syntropy',
      },
      editingUserProfileTitle: 'Editando o perfil do usuário',
      editingUserProfileDescription:
        'Para alterar as informações do usuário, vá em “Perfil”. É possível alterar nome do responsável, e-mail de vínculo, avatar, paleta de cores da plataforma e senha',
      signOutTitle: 'Sair',
      signOutDescription:
        'Para sair da plataforma, clique no nome do usuário no canto superior direito da página e opte por “sair”',
      termsOfUseTitle: 'Termos de uso',
      termsOfUseDescription:
        '*Este documento é personalizável. A Abundance Brasil se responsabiliza somente pelos benefícios gerados diretamente a partir da sua solução ESG (aquisição de árvores da Floresta Aurora Verde). As demais atividades incluídas pelos parceiros ficam sob responsabilidade de cada empresa que faz parte do Ecossistema Syntropy.',
    },
  },

  forestProducerRegistration: {
    forestProducerRegistrationForm: {
      title: 'Crie a sua Floresta com a Abundance Brasil',
      subtitle: 'Produtor florestal',
      description:
        'Estamos financiando novas florestas no Brasil e a sua propriedade rural pode se tornar um dos nossos projetos de restauração ambiental! Para isso, complete seu cadastro com todos os dados solicitados a seguir e entraremos em contato.',
      nameLabel: 'Nome',
      namePlaceholder: 'Seu Nome',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Seu e-mail',
      tin: 'Número de identificação fiscal',
      country: 'País',
      phoneNumber: 'Telefone',
      propertySizeLabel: 'Tamanho da propriedade (em hectares)',
      propertySizePlaceholder: 'Tamanho da propriedade (em hectares)',
      cityLabel: 'Cidade',
      cityPlaceholder: 'Nome da cidade',
      stateLabel: 'Estado',
      statePlaceholder: 'Nome do Estado',
      carLabel: 'Número do CAR (Cadastro Ambiental Rural)',
      carPlaceholder: 'CAR',
      legalProtectionLabel:
        'A área está sob alguma proteção legal ou programa de conservação?',
      yes: 'Sim',
      no: 'Não',
      vegetationLabel: 'Qual o bioma ou vegetação nativa da região?',
      vegetationPlaceholder: 'Descreva aqui',
      managementPracticesLabel: 'Práticas de manejo na propriedade',
      managementPracticesPlaceholder:
        'Descreva brevemente quais são os cuidados ambientais com a área praticados atualmente',
      socialIssuesLabel: 'Questões Sociais',
      socialIssuesPlaceholder:
        'Descreva se há questões sociais relevantes a serem consideradas nesse projeto',
      send: 'Enviar',
      talkToConsultant: 'Quero falar com um consultor',
    },

    forestProducerRegistrationSideBanner: {
      title: 'Transforme sua propriedade rural em uma nova floresta',
      firstParagraph:
        'Olá! Se você chegou até aqui é porque tem interesse em plantar árvores na sua área e participar da criação de um projeto de alta governança florestal.',
      secondParagraph:
        'Assim, vamos acelerar o processo de descarbonização do planeta e ajudar a recuperar a biodiversidade brasileira, além de todos os outros benefícios envolvidos.',
      thirdParagraph:
        'Por favor, preencha o formulário para que possamos avaliar as informações e a viabilidade do projeto, lembrando que é imprescindível preencher todos os campos solicitados. Em breve retornaremos o contato. Obrigado!',
    },
  },

  forestProducerRegistrationSuccess: {
    title: 'Cadastro de propriedade rural realizado com sucesso',
    firstParagraph:
      'Agradecemos pelo interesse em se juntar a nós na missão de esverdear o planeta! Recebemos os seus dados e em breve vamos retomar o contato.',
    forMore: 'Para mais informações, consulte as',
    faq: 'Perguntas frequentes',
    contact: 'ou entre em contato com o nosso suporte',
    here: 'aqui',
  },

  forgotPassword: {
    forgotPasswordForm: {
      title: 'Esqueceu sua senha?',
      description:
        'Enviaremos um e-mail com as instruções para criar uma nova senha',
      rememberedPassword: 'Lembrou da sua senha?',
      email: 'E-mail',
      login: 'Faça o login',
      send: 'Enviar',
    },
  },

  howToGetMyCertificate: {
    title: 'Certificado restaurador ambiental',
    notARestorer: 'Você ainda não é um Restaurador Ambiental.',
    knowMore: 'Saiba mais',
    whatIsTheCertificate: 'O que é o Certificado Restaurador Ambiental?',
    certificateExplanation:
      'Todos os parceiros da Abundance Brasil que compraram tokens lastreados em árvores estão contribuindo com a nossa meta de restaurar o meio ambiente brasileiro. Então, ao adquirir um ou mais Abundance Tokens, você recebe um certificado que comprova sua participação nesse movimento e pode ser usado para mostrar ao mundo que você (pessoa física ou jurídica) investe em sustentabilidade.',
    certificateImageAlt: 'Exemplo de certificado da Abundance.',
    howToGetTheCertificate: 'Como adquirir o Certificado?',
    howToGetExplanation:
      'O único meio de conseguir o Certificado restaurador Ambiental da Abundance Brasil é comprando tokens. O documento não tem um custo extra, fazendo parte dos entregáveis vinculados ao produto. Ou seja, esse é um benefício exclusivo para todos que adquirem o Abundance Token.',
    wantToGetTheCertificate:
      'Deseja obter seu Certificado Restaurador Ambiental?',
    restoreNow: 'Restaure agora',
  },

  howToGetMyTokens: {
    title: 'Restaurador ambiental',
    youAreNotARestorer: 'Você ainda não é um Restaurador Ambiental.',
    abundanceTokenImageAlt: 'Moeda verde com três árvores no centro.',
    firstSpan: 'Torne-se um Restaurador Ambiental comprando Abundance Tokens',
    firstParagraph:
      'Abundance Token é uma oportunidade de impulsionar a restauração ambiental do Brasil e ter um ativo com tendência à valorização. O criptoativo é lastreado em árvores, que são tokenizadas e geram créditos de carbono - um tipo de ativo valorizado no mundo inteiro e que pode ser negociado em mercado secundário. Essa é uma excelente chance de fazer um investimento sustentável, seguro e lucrativo!',
    restoreNow: 'Restaure agora',
    secondSpan: 'O que mais você ganha ao comprar o Abundance Token?',
    secondParagraph:
      'Estamos criando uma verdadeira comunidade de donos de árvores e florestas. Além da urgência de ajudar a salvar o nosso planeta, essa é uma tendência mundial que também faz parte do movimento ESG (Environmental, Social and Governance). Confira quais são os entregáveis que acompanham a compra do Abundance Token:',
    thirdSpan: 'Selos',
    thirdParagraph:
      'Acesse um pacote completo de mídias para divulgar a sua iniciativa de investimento e avance de nível conforme você compra mais árvores.',
    stampsInfoImageAlt:
      'Lista de todos os selos da Abundance, do maior ao menor.',
    fourthSpan: 'Certificados',
    fourthParagraph:
      'Comprove para o mundo que você é um investidor de florestas.',
    knowMore: 'Saiba mais',
    certificateImageAlt:
      'Lista de certificados da Abundance. Um certificado focado ao centro com outros dois ao fundo com desfoque.',
    fifthSpan: 'Relatórios',
    fifthParagraph:
      'Acompanhe os dados de absorção de carbono relacionado à sua compra.',
    reportsImageAlt:
      'Lista de relatórios da Abundance. Um relatório focado ao centro com outros dois ao fundo com desfoque.',
    sixthSpan: 'Landingpages para trabalhar o seu Marketing Sustentável',
    sixthParagraph:
      'Tenha uma página personalizada para trabalhar o ESG do seu negócio como um dos seus diferenciais para que todos os seus stakeholders tenham acesso e transparência à sua estratégia ESG.',
    emtelImageAlt:
      "Imagem da landing page da Emtel. Foto de um caminhão em uma estrada com o título 'Estratégia e inovação em ESG' no lado esquerdo.",
    seventhSpan: 'Quer ser um restaurador ambiental?',
  },

  impact: {
    title: 'Restaurador ambiental',
    notARestorer: 'Você ainda não é um Restaurador Ambiental.',
    knowMore: 'Saiba mais',
    chartImageAlt:
      'Gráfico que mostra a quantidade de absorção de carbono de um árvore até 2052.',

    carbonManagementCards: {
      compensationCarbonManagementCard: {
        title: 'Compensação',
        subtitle: 'Descarbonização',
        description:
          'Ideal para a compensação voluntária das emissões de carbono próprias ou de terceiros. Essa categoria é indicada para pessoas físicas e jurídicas que não tem obrigações legais para a compensação de carbono e gostariam de contribuir para um futuro mais sustentável',
        availableTitle: 'Árvores disponíveis',
        co2Title: 'CO2 absorvidos',
        actionButtonTitle: 'Compensar (Em breve)',
      },

      sellCarbonManagementCard: {
        title: 'Venda',
        subtitle: 'Créditos de carbono hoje',
        description:
          'Ideal para investidores de mercado de carbono. Nessa categoria o créditos de carbono associados até o momento pelos os seus tokens poderão ser vendidos e comercializados.',
        availableTitle: 'Árvores disponíveis',
        co2Title: 'CO2 hoje',
        actionButtonTitle: 'Vender (Em breve)',
      },
    },

    header: {
      title: 'Gestão dos seus créditos de carbono',
      description:
        'Você tem disponível XXXX de Gás Carbônico retirado da atmosfera.',
      noCarbon: 'Voce não tem Gás Carbônico disponivel retirado da atmosfera. ',
    },
  },

  locked: {
    lockedAccount: 'Sua conta foi bloqueada',
    emailSent:
      'Enviamos um e-mail de redefinição de senha para {{email}} com instruções sobre como redefinir sua senha por motivos de segurança.',
    forMoreInfo: 'Para mais informações consulte nossas',
    faq: 'Perguntas frequentes',
    contactSupport: 'ou entre em contato com o suporte',
    here: 'aqui',
  },

  mediaKit: {
    loading: 'Carregando Media Kit...',
    downloadError: 'Erro ao fazer download.',
    coinImageAlt: 'Moeda na cor verde com três árvores ao centro.',
    firstSpan: 'Media Kit',
    firstParagraph:
      'Junto com a compra do Abundace Token, cada comprador recebe um kit de mídias equivalente à quantidade de árvores adquiridas. De 10 a 100.000 tokens ou mais, você vai receber selos personalizados para divulgar nos seus canais de comunicação como parte da Abundance Tribe - uma comunidade de pessoas e empresas que estão contribuindo ativamente com a restauração ambiental do nosso país. Os selos são como indicadores visuais relacionados a valores como sustentabilidade, abundância, conexão com a natureza e inovação com propósito.',
    firstHeader: 'Aplicações',
    secondSpan: 'Circular',
    secondParagraph:
      'Adicione às suas assinaturas, logomarcas ou imagens de perfil nas redes sociais.',
    stampImageAlt: 'Selo de restaurador ambiental da Abundance.',
    thirdSpan: 'Horizontal',
    thirdParagraph:
      'Versão com mais informações para sinalizar a iniciativa no seu site, por exemplo.',
    fourthSpan: 'Vertical',
    fourthParagraph:
      'Outro formato da versão mais completa para adicionar nos seus canais.',
    preparingDownload: 'Preparando download...',
    downloadButton: 'Download Media Kit',
  },

  mobileIntroduction: {
    restoring: 'Restaurando o futuro do planeta agora',
    performance: 'ESG na prática, com descarbonização.',
    accessAccount: 'Acesse sua conta',
    createAccount: 'Não possui conta? Abra já a sua!',
    forYou: 'Para você',
    forCompany: 'Para sua empresa',
    doubts: 'Dúvidas sobre o Portal ESG?',
    book: 'Agende uma apresentação',
  },

  myCertificate: {
    title: 'Certificado restaurador ambiental',
    youGotYourCertificate:
      'Você conquistou o Certificado de Restaurador Ambiental comprando Abundance Tokens!',
    certificateDescription:
      'Nosso token é lastreado em árvores que fazem parte dessa iniciativa de reflorestar o Brasil, ajudar a recuperar o meio ambiente, gerar biodiversidade, impacto social e abundância. Aproveite esse documento para comprovar e compartilhar que você faz parte desse movimento.',
    view: 'Visualizar',
  },

  networkError: {
    errorImageAlt: 'Uma árvore morta com um fundo escuro.',
    noInternet: 'Sem internet',
    verifyConnection: ' Verifique a sua conexão ',
    tryAgain: ' e tente novamente salvar o planeta',
  },

  notFound: {
    notFoundImageAlt:
      'Número 404. O zero é uma árvore morta com um fundo escuro.',
    treeErrorImageAlt: 'Uma árvore morta com um fundo escuro.',
    notFound: 'Página não encontrada',
    sorry: 'Desculpe-nos, mas o link está morto',
    return: 'Retornar para a página principal',
  },

  home: {
    articlesList: {
      title: 'Artigos recentes',
      seeAll: 'Ver todos',
    },

    homeTopBanner: {
      message:
        'Confira o tour virtual pela nossa floresta e veja a restauração ambiental acontecendo na Aurora Verde.',
      access: 'Acesse',
      title: 'Seja um restaurador do meio ambiente',
      subtitle:
        'Junte-se à nossa missão de restaurar o planeta e contribua com a formação de novas florestas no Brasil',
      subscribe: 'Assinar plano',
      buy: 'Comprar árvores',
    },

    noCalculationBanner: {
      title:
        'Compense suas emissões de carbono e ajude a formar novas florestas no Brasil',
      description: 'Você ainda não calculou as suas emissões de carbono',
      calculate: 'Calcular emissões',
    },
  },

  purchaseWithoutAccount: {
    purchaseWithoutAccountForm: {
      title: 'Qual foi o e-mail utilizado para realizar a compra?',
      description:
        'Enviaremos um e-mail para que você tenha acesso à todas as suas árvores.',
      email: 'E-mail',
      send: 'Enviar',
    },
  },

  purchaseWithoutAccountConfirmation: {
    title: 'Pronto! Agora verifique o seu e-mail',
    emailSent:
      'Enviamos um link para {{email}} para que você crie sua conta e tenha acesso à todas as árvores compradas.',
    forMoreInfo: 'Para mais informações consulte nossas',
    faq: 'Perguntas frequentes',
    contactSupport: 'ou entre em contato com o suporte',
    here: 'aqui',
  },

  registerYourProfile: {
    registerYourProfileForm: {
      title: 'Calcule suas emissões',
      subtitle: 'Para sua empresa',
      description:
        'Conhecer sua pegada de carbono é um passo importante para iniciar uma jornada sustentável. Vamos começar?',
      nameLabel: 'Nome',
      namePlaceholder: 'Seu nome',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Seu e-mail',
      continue: 'Ao continuar, você está de acordo com os',
      terms: 'Termos de Uso',
      and: 'e',
      policy: 'Política de Privacidade',
      initiate: 'Iniciar',
      dontCalculate: 'Não quero calcular',
    },

    registerYourProfileSideBanner: {
      title: 'Qual o impacto ambiental da sua empresa?',
      description:
        'Calcule sua pegada de carbono para começar a compensar as emissões de CO2 e reforce seu compromisso com o meio ambiente.',
      step: 'Passo',
      step1: 'Crie um perfil com os dos dados da sua empresa.',
      step2: 'Responda as perguntas para ter uma estimativa das suas emissões.',
      step3: 'Confira o resultado e inicie sua jornada de descarbonização.',
    },
  },

  securityVerification: {
    securityVerificationForm: {
      title: 'Verificação de segurança',
      description: 'Para proteger sua conta, conclua a seguinte verificação.',
      phoneVerification: 'Verificação por telefone',
      codeResent: 'Código reenviado',
      typeCode: 'Digite o código de 6 dígitos enviado para',
      resendCode: 'Reenviar código',
      send: 'Enviar',
      securityNotAvailable: 'A verificação de segurança não está disponível?',
    },
  },

  selectAccountType: {
    title: 'Perfil',
    description:
      'Selecione seu perfil para fazer parte da nossa comunidade de restauradores ambientais.',
    accountTypes: 'Tipos de conta',
    forYouTitle: 'Para você',
    forYouLabel: 'Se cada um fizer a sua parte, podemos salvar o planeta.',
    forYourCompanyTitle: 'Para a sua empresa',
    forYourCompanyLabel:
      'Junte-se a um grupo de empresas que fazem a diferença.',
    forestProducerTitle: 'Produtor florestal',
    forestProducerLabel:
      'Faça seu cadastro e um dos nossos consultores entrará em contato.',
    continue: 'Continuar',
    signInMessage: 'Já possui conta? Faça o seu login',

    selectAccountTypeSideBanner: {
      title:
        'Compense suas emissões de carbono e ajude a formar novas florestas no Brasil.',
      subtitle:
        'Crie uma conta e adquira seus Abundance Tokens para ter acesso a diversos benefícios:',
      certificate: 'Certificado Restaurador Ambiental',
      stamp: 'Selos de sustentabilidade',
      report: 'Relatório de impacto de CO2',
      tour: 'Tour virtual pela floresta',
      esg: 'Relatório ESG personalizado',
      doubts: 'Dúvidas? Fale',
      here: 'aqui',
      consultants: 'com um de nossos consultores',
    },
  },

  signIn: {
    signInForm: {
      title: 'Acesse sua conta',
      description: 'Digite seus detalhes abaixo',
      email: 'E-mail',
      password: 'Senha',
      login: 'Entrar',
      orContinueWith: 'ou continue com',
      purchaseWithoutAccount: 'Comprei e não tenho conta',
    },
  },

  signUp: {
    signUpCompany: {
      title: 'Abra sua conta',
      subtitle: 'Para sua empresa',
      description: 'Digite os detalhes abaixo para cadastro de Pessoa Jurídica',
    },

    signUpForm: {
      name: 'Seu nome',
      namePlaceholder: 'Nome',
      email: 'E-mail',
      emailPlaceholder: 'meuemail@email.com',
      companyName: 'Nome da empresa',
      companyEmailPlaceholder: 'meuemail@empresa.com',
      phoneNumber: 'Telefone',
      password: 'Senha',
      passwordConfirm: 'Confirmar senha',
      howDidYouGetHere: 'Onde você ouviu falar da Abundance Brasil?',
      choose: 'Selecione uma opção',
      sendUpdates:
        'Envie-me atualizações, informações do produto e mercado ESG.',
      createNotice: 'Declaro que li, compreendi e concordo com os',
      termsAndConditions: 'Termos e Condições',
      and: 'e',
      privacyPolicy: 'Política de Privacidade',
      enroll: 'ao realizar o cadastro e criar a minha conta.',
      createAccount: 'Criar conta',
      press: 'Imprensa',
      recommendation: 'Indicação',
    },

    signUpPersonal: {
      title: 'Abra sua conta',
      subtitle: 'Para você',
      description: 'Digite os detalhes abaixo para cadastro de Pessoa Física',
    },
  },

  subscriptionPlans: {
    footer: {
      didntFind: 'Não encontrou seu plano de assinatura ideal?',
      talkWithSpecialist: 'Fale com um de nossos especialistas.',
      updatePlanButton: 'Atualizar plano',
    },

    header: {
      noPlanMessage: {
        message:
          'Escolha a sua experiência de compra recorrente e confira os benefícios de cada opção.',
      },

      planDescriptionMessage: {
        currentPlan: 'Plano atual',
        singularPlan: 'árvore mensal',
        pluralPlan: 'árvores mensais',
      },
    },

    subscriptionPlanCards: {
      subscriptionPlanCard: {
        change: 'Alterar plano',
        subscribe: 'Assinar plano',
        loading: 'Carregando',

        planBenefits: {
          tree: 'Árvore',
          carbon: 'Carbono',
          potential: 'Potencial em 2052',
        },

        upperSection: {
          coinIconAlt: 'Moeda da Abundance.',
        },

        oneTree: {
          title: '1 árvore',
          description:
            'Para pessoas e empresas que querem comprar sua primeira árvore ou mais uma à sua parte da floresta.',
        },

        tenTree: {
          title: '10 árvores',
          description:
            'Para quem está começando a investir em restauração ambiental e quer ser amigo(a) da natureza.',
        },

        fifthyTrees: {
          title: '50 árvores',
          description:
            'Para quem sabe a importância de incentivar pequenos e novos plantios e quer ter uma mini floresta.',
        },

        oneHundredTrees: {
          title: '100 árvores',
          description:
            'Para pessoas e empresas que querem ser referência no processo de restauração ambiental do Brasil.',
        },
      },

      subscriptionPlanCardsList: {
        loading: 'Carregando',
        error: 'Erro ao carregar lista de planos de assinaturas.',
        bonusUnitsPlaceholder: 'Ganhe +',
        tree: 'árvore',
        trees: 'árvores',
      },
    },
  },

  support: {
    supportForm: {
      error: 'Erro ao enviar mensagem. Por favor, tente novamente.',
      messageSent: 'Mensagem enviada!',
      thanks: 'Obrigado por entrar em contato conosco!',
      talkSoon: 'Um de nossos consultores responderá em breve.',
      talkWithUs: 'Fale com um de nossos consultores',
      subject: 'Assunto',
      message: 'Mensagem',
      send: 'Enviar',

      supportSubjects: {
        doubt: 'Dúvida',
        suggestion: 'Sugestão',
        iWantToBeAPartner: 'Quero ser um parceiro',
        subscriptionPlans: 'Planos de assinatura',
        esgReport: 'Relatório ESG',
      },
    },
  },

  syntropy: {
    pageBanner: {
      talkWithConsultant: 'Fale com um de nossos consultores',
    },

    syntropyPageTabs: {
      reports: 'Relatórios',
      communities: 'Comunidades',
    },

    reports: {
      descriptionTitle: 'O que são Relatórios?',
      descriptionText:
        'O relatório ESG é uma forma de demonstrar as iniciativas da sua empresa em relação aos três pilares ESG (Meio Ambiente, Social e Governança). Além dos benefícios associados às árvores adquiridas, você pode personalizar o seu relatório para incluir outras informações e garantir maior transparência sobre as boas práticas implementadas.',
      bannerTitle: 'Ainda não possui seu relatório ESG?',
      bannerDescription:
        'Compre árvores, receba o seu acesso e faça parte do nosso ecossistema.',

      syntropyCard: {
        companyIconAlt: 'Logo da empresa.',
        lastPurchase: 'Última aquisição: ',
        trees: 'árvores',
      },
    },

    virtualForests: {
      descriptionTitle: 'O que são Comunidades?',
      descriptionText:
        'Estes são nossos parceiros e restauradores ambientais, que estão gerando impacto socioambiental com suas florestas. Aproveite para gerar novas conexões e oportunidades de negócio!',
      bannerTitle: 'Ainda não possui sua comunidade?',
      bannerDescription:
        'Compre árvores, receba o seu acesso e faça parte do nosso ecossistema.',

      virtualForestCards: {
        syntropyCard: {
          logoImageAlt: 'Logo da floresta virtual.',
          planting: 'Plantio',
          tree: 'árvore',
          trees: 'árvores',
          participants: 'Participantes',
          type: 'Tipo',
          access: 'Acessar',
        },
      },
    },

    forestDetails: {
      banner: {
        project: 'Floresta aurora verde',
        beARestorer: 'Seja um restaurador ambiental.',
        buyTrees: 'Compre árvores e gere abundância.',
        treesAmount: '100 mil árvores plantadas em 2022.',
        buyNow: 'Comprar agora',
      },

      header: {
        header: {
          back: 'Voltar',
          logoImageAlt: 'Logo da floresta virtual.',
          start: 'Início',
          planting: 'Plantio',
          report: 'Relatório de Impacto',
          certificate: 'Certificado Floresta Restauradora',
        },

        summary: {
          planting: 'Plantio',
          tree: 'árvore',
          trees: 'árvores',
          participants: 'Participantes',
          type: 'Tipo',
        },
      },

      planting: {
        planting: {
          title: 'Aurora Verde',
          firstSubtitle:
            'O projeto Aurora Verde é um case de sucesso no propósito de esverdear o Brasil por meio da restauração ambiental.',
          firstText:
            'Contribua com essa iniciativa que ajuda o meio ambiente: invista em tokens que correspondem a uma árvore, receba o crédito de carbono atrelado à ela e acompanhe o desenvolvimento da sua parte da floresta.',
          secondSubtitle: '1 milhão de árvores',
          secondText:
            'Nossa meta é elevar e acelerar o que o Brasil pode ter de melhor: sustentabilidade e abundância ambiental. <br/><br/> Os Abundance Tokens são lastreados em árvores e emitidos para preservar cada uma delas, garantindo que cumpram seu ciclo de vida. Uma árvore sequestra, em média, de 0,15 a 0,3 tonelada de CO2 em um prazo entre 20 a 30 anos. Assim, podemos alcançar um valor significativo ao formar uma floresta a partir da emissão de um milhão de tokens. <br/><br/> Invista na restauração ambiental e na preservação do meio ambiente ao adquirir o seu token e tenha a sua parte da Aurora Verde!',
        },
      },

      start: {
        guardian: {
          guardian: 'Guardião da Floresta',
          since: 'Floresta Virtual desde',
        },

        start: {
          about: 'Sobre',
          benefits: 'Benefícios',
        },
      },
    },
  },

  userSettings: {
    legalPerson: {
      legalPersonForm: {
        loading: 'Carregando...',
        profile: 'Perfil',
        keepYourDataUpdated:
          'Mantenha seus dados de perfil sempre atualizados.',
        name: 'Nome da empresa',
        email: 'E-mail da empresa',
        emailPlaceholder: 'email@domínio.com.br',
        phoneNumber: 'Telefone',
        language: 'Idioma',
        taxIdentification: 'Identificação fiscal',
        fillYourData: 'Preencha os dados abaixo',
        country: 'País',
        number: 'Número',
        tin: 'Número de identificação fiscal',
        update: 'Atualizar dados de conta',
        subscriptionPlan: 'Plano de assinatura',
        subscriptionPlanDescription: 'Para compras recorrentes.',
        success: 'Os dados da conta foram atualizados',
      },
    },

    physicalPerson: {
      physicalPersonForm: {
        loading: 'Carregando...',
        profile: 'Perfil',
        keepYourDataUpdated:
          'Mantenha seus dados de perfil sempre atualizados.',
        name: 'Seu nome completo',
        email: 'E-mail',
        emailPlaceholder: 'email@domínio.com.br',
        phoneNumber: 'Telefone',
        language: 'Idioma',
        taxIdentification: 'Identificação fiscal',
        fillYourData: 'Preencha os dados abaixo',
        country: 'País',
        number: 'Número',
        tin: 'Número de identificação fiscal',
        update: 'Atualizar dados de conta',
        subscriptionPlan: 'Plano de assinatura',
        subscriptionPlanDescription: 'Para compras recorrentes.',
        success: 'Os dados da conta foram atualizados',
      },
    },
  },

  wallet: {
    purchaseHistory: {
      credits: 'Créditos',
      value: 'Valor',
      status: 'Status',
      date: 'Data',
    },

    walletOverview: {
      treesImageAlt:
        'Três árvores, um ao centro a frente de outras duas na parte de trás.',
      cloudsImageAlt: 'Nuvem carregada com três gotas caindo.',
      trees: 'Árvores',
      co2Absorption: 'CO2 absorvidos',
    },

    walletTransactionsHistory: {
      purchased: 'Comprados',
      claimed: 'Resgatados',
    },
  },
};
