import { styled } from 'styled-components';

import { getThemeFontWeight, Text } from '@abundance-brasil-wabi/sabi';

export const Step = styled(Text).attrs({
  level: 'caption',
})`
  text-transform: uppercase;
  font-weight: ${getThemeFontWeight('bold')};
`;

export const StepText = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('regular')};
`;
