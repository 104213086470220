import { z } from 'zod';

import { fieldErrorMessages } from 'constants/forms';

export const supportFormSchema = z
  .object({
    subject: z
      .number({ invalid_type_error: fieldErrorMessages.dropdown.required })
      .min(0, fieldErrorMessages.dropdown.required),
    message: z.string().min(1, fieldErrorMessages.field.required),
  })
  .refine(
    ({ message }) => {
      return message.trim().length > 0;
    },
    {
      message: fieldErrorMessages.field.required,
      path: ['message'],
    },
  );
