import { KeyStringValue } from 'types/common';

import { getInitialsFromTextWithMultipleWords } from '@abundance-brasil-wabi/utils';

import {
  Account2FAInfo,
  AuthTokenInfo,
  Claim,
  SignInResult,
  UserInfo,
} from 'api/services';
import { getIsUserValidPhysicalPerson } from 'state/user/utils';
import {
  getCookie,
  getItemFromSessionStorage,
  removeCookie,
  removeItemFromLocalStorage,
  removeItemFromSessionStorage,
  setItemInSessionStorage,
} from 'utils/handlers';

export function getTwoFactorAuthData(): Account2FAInfo | undefined {
  return getItemFromSessionStorage('twoFactorAuthData');
}

export function setTwoFactorAuthData(data?: Partial<Account2FAInfo>): void {
  setItemInSessionStorage('twoFactorAuthData', data);
}

export function removeTwoFactorAuthData(): void {
  removeItemFromSessionStorage('twoFactorAuthData');
}

export function getAuthToken(): string {
  return getCookie('token') || '';
}

export function getIsAuthenticated(): boolean {
  return !!getAuthToken();
}

export function convertClaimsToObject(claims: Claim[]): KeyStringValue {
  const claimsObject = claims.reduce((claim: KeyStringValue, item: Claim) => {
    claim[item.type.split('/').at(-1) || item.type] = item.value;

    return claim;
  }, {});

  return claimsObject;
}

export function getUserInfo(data: SignInResult): UserInfo {
  const info = data.authResponse.userToken;
  const claims = convertClaimsToObject(info.claims);

  const userData: UserInfo = {
    user: {
      id: info.id,
      name: claims.name,
      email: info.email,
      phoneNumber: claims.mobilephone,
      shortName: getInitialsFromTextWithMultipleWords(claims.name),
      isValidPhysicalPerson: claims.IsValidPhysicalPerson === 'True',
    },
    token: data.authResponse.accessToken,
    statusMessage: data.statusMessage,
  };

  return userData;
}

export function checkIsValidPhysicalPerson(data?: SignInResult): boolean {
  if (!data) return Boolean(getIsUserValidPhysicalPerson());

  const claims = convertClaimsToObject(data.authResponse.userToken.claims);

  return claims.IsValidPhysicalPerson === 'True';
}

export function removeAuthEntriesFromStorage(): void {
  removeCookie('token');
  removeItemFromLocalStorage('isValidPhysicalPerson');
}

export function decodeJWTToken(token?: string): AuthTokenInfo | undefined {
  if (!token) return;

  const payload = token.split('.')[1];

  // See https://stackoverflow.com/a/70851350 atob in node has been
  // deprecated but atob in the browser environment has not.
  // Accessing atob on window avoids tsc telling us it's deprecated.
  return JSON.parse(window.atob(payload));
}

export function getUserIdFromToken(): string | undefined {
  const tokenInfo = decodeJWTToken(getAuthToken());

  if (!tokenInfo) return;

  return tokenInfo.sub;
}
