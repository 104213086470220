import { ChangeEvent, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  MdOutlineAccountCircle,
  // MdOutlineAgriculture,
  MdOutlineStore,
} from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  ContentsHeader,
  Divider,
  RadioInput,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import LanguageSelector from 'components/LanguageSelector';
import { useSetPageConfig } from 'components/Page';
import { getPathnameFromKey } from 'router/utils';

import SelectAccountTypeSideBanner from './SelectAccountTypeSideBanner';
import * as S from './styles';
import { AccountType } from './types';

const SelectAccountType: React.FC<WithTranslation> = ({
  t,
}): React.ReactNode => {
  const [accountType, setAccountType] = useState<AccountType | undefined>();

  const navigate = useNavigate();
  const { search } = useLocation();

  useSetPageConfig({
    mode: 'split',
    override: {
      sideContent: <SelectAccountTypeSideBanner />,
    },
  });

  const handleOnChange = useCallback(
    (e: string | ChangeEvent<HTMLInputElement>): void => {
      setAccountType(e as AccountType);
    },
    [],
  );

  const handleContinue = useCallback((): void => {
    switch (accountType) {
      case 'forYou':
        navigate({ pathname: getPathnameFromKey('signUpPersonal'), search });

        break;

      case 'forYourBusiness':
        navigate({
          pathname: getPathnameFromKey('registerYourProfile'),
          search,
        });

        break;

      case 'forestProducer':
        navigate({
          pathname: getPathnameFromKey('forestProducerRegistration'),
          search,
        });

        break;

      default:
    }
  }, [accountType, navigate, search]);

  return (
    <S.Contents>
      <ContentsHeader title={t('title')} description={t('description')} />

      <S.Main>
        <S.OptionsContainer
          aria-label={t('accountTypes')}
          onChange={handleOnChange}
          fluid
        >
          <RadioInput
            startContent={
              <S.IconContainer>
                <MdOutlineAccountCircle />
              </S.IconContainer>
            }
            title={t('forYouTitle')}
            label={t('forYouLabel')}
            aria-label={t('forYouTitle')}
            value="forYou"
            hideRadio
            fluid
          />

          <RadioInput
            startContent={
              <S.IconContainer>
                <MdOutlineStore />
              </S.IconContainer>
            }
            title={t('forYourCompanyTitle')}
            label={t('forYourCompanyLabel')}
            aria-label={t('forYourCompanyTitle')}
            value="forYourBusiness"
            hideRadio
            fluid
          />

          {/* TODO ABD-80: Uncomment this when forest producer flow is ready. */}
          {/* <RadioInput
            startContent={
              <S.IconContainer>
                <MdOutlineAgriculture />
              </S.IconContainer>
            }
            title={t('forestProducerTitle')}
            label={t('forestProducerLabel')}
            aria-label={t('forestProducerTitle')}
            value="forestProducer"
            hideRadio
            fluid
          /> */}
        </S.OptionsContainer>
      </S.Main>

      <Button fluid isDisabled={!accountType} onPress={handleContinue}>
        {t('continue')}
      </Button>

      <S.SignInLink to={getPathnameFromKey('signIn')}>
        {t('signInMessage')}
      </S.SignInLink>

      <Divider />

      <S.LanguageSelectorContainer>
        <LanguageSelector />
      </S.LanguageSelectorContainer>
    </S.Contents>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'selectAccountType',
})(SelectAccountType);
