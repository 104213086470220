import { styled } from 'styled-components';

import {
  Dropdown as DropdownBase,
  getThemeColor,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Dropdown = styled(DropdownBase)`
  min-height: auto;
  padding-right: 12px;
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
