import { useCallback, useMemo } from 'react';

import {
  EventTrackingContextType,
  TrackUserSignIn,
  TrackUserSignInProps,
} from '../types';
import { EVENTS } from './constants';
import {
  clearUserTrackingInfo,
  setUserTrackingInfo,
  trackEvent,
} from './utils';

export default function useMixpanelEventTracking(): EventTrackingContextType {
  const trackUserSignIn: TrackUserSignIn = useCallback(
    (properties: TrackUserSignInProps) =>
      trackEvent({
        eventName: EVENTS.signIn,
        properties,
      }),
    [],
  );

  return useMemo(
    () => ({
      setUserTrackingInfo,
      clearUserTrackingInfo,
      trackUserSignIn,
    }),
    [trackUserSignIn],
  );
}
