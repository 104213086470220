import { MutationOptions } from 'types/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateUser, UpdateUserArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

import { userQueryKeys } from './queryKeys';
import { useUserId } from './useGetUser';

export default function useUpdateUser(
  options?: MutationOptions<void, UpdateUserArgs>,
) {
  const { data: userId } = useUserId();
  const queryClient = useQueryClient();

  return useMutation<void, ErrorMessage, UpdateUserArgs, unknown>(updateUser, {
    onMutate: async () => {
      await queryClient.cancelQueries(userQueryKeys.user(userId));
    },
    onSettled: () => {
      queryClient.invalidateQueries(userQueryKeys.user(userId));
    },
    ...options,
  });
}
