import { z } from 'zod';

import { VALID_EMAIL_FORMAT } from '@abundance-brasil-wabi/utils';

import { fieldErrorMessages } from 'constants/forms';

export const forgotPasswordFormSchema = z.object({
  email: z
    .string()
    .min(1, fieldErrorMessages.email.required)
    .regex(
      new RegExp(VALID_EMAIL_FORMAT),
      fieldErrorMessages.email.wrongPattern,
    ),
});
