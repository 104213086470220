import { produce } from 'immer';
import { Reducer } from 'redux';

import { OTPValidationAction, OTPValidationState, RequestData } from './types';

const INITIAL_STATE: OTPValidationState = {
  purpose: '',
  apiMethod: 'put',
  password: '',
  requestData: {} as RequestData,
  passwordSuccess: false,
  tokenSuccess: false,
  hasError: false,
  otpValidatedSuccessfully: false,
  remoteOTPValidationData: null,
  otpValidationResponse: null,
};

const otpValidation: Reducer<OTPValidationState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case OTPValidationAction.OTP_PURPOSE: {
        draft.purpose = action.payload.purpose;

        break;
      }

      case OTPValidationAction.API_METHOD: {
        draft.apiMethod = action.payload.method;

        break;
      }

      case OTPValidationAction.REQUEST_DATA: {
        draft.requestData = action.payload.requestData;

        break;
      }

      case OTPValidationAction.PASSWORD_SUCCESS: {
        draft.password = action.payload.password;
        draft.passwordSuccess = action.payload.passwordSuccess;

        break;
      }

      case OTPValidationAction.TOKEN_SUCCESS: {
        draft.tokenSuccess = action.payload.tokenSuccess;

        break;
      }

      case OTPValidationAction.HAS_ERROR: {
        draft.hasError = action.payload.hasError;

        break;
      }

      case OTPValidationAction.OTP_VALIDATION_SUCCESS: {
        draft.otpValidatedSuccessfully =
          action.payload.otpValidatedSuccessfully;

        break;
      }

      case OTPValidationAction.REMOTE_OTP_VALIDATION: {
        draft.remoteOTPValidationData = action.payload.validationData;

        break;
      }

      case OTPValidationAction.VALIDATION_RESPONSE: {
        draft.otpValidationResponse = action.payload.response;

        break;
      }

      case OTPValidationAction.OTP_VALIDATION_RESET: {
        draft.purpose = '';
        draft.apiMethod = 'put';
        draft.password = '';
        draft.requestData = {} as RequestData;
        draft.passwordSuccess = false;
        draft.tokenSuccess = false;
        draft.hasError = false;
        draft.otpValidatedSuccessfully = false;
        draft.remoteOTPValidationData = null;
        draft.otpValidationResponse = null;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default otpValidation;
