import { styled } from 'styled-components';

import {
  breakpointDown,
  getThemeColor,
  getThemeFontWeight,
  Heading,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${breakpointDown('lg')`
    gap: 8px
  `}
`;

export const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  ${breakpointDown('md')`
    gap: 8px
  `}
`;

export const Title = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Result = styled(Text).attrs({
  level: 'subtitle',
})`
  text-align: end;
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Value = styled(Heading).attrs({
  level: 5,
})`
  white-space: nowrap;
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'primary', 'default')};
`;
