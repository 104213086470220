import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getTwoFactorAuthData } from 'state/auth/utils';

import { getPathnameFromKey } from '..';

const OnlyWithSecurityCodeRequested: React.FC = () => {
  const storedUserInfo = getTwoFactorAuthData();

  if (!storedUserInfo)
    return <Navigate to={getPathnameFromKey('signIn')} replace />;

  return <Outlet />;
};

export default OnlyWithSecurityCodeRequested;
