import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import {
  CarbonOffsetCalculation,
  CarbonOffsetEnrollment,
  CreateCarbonOffsetCalculationArgs,
  CreateCarbonOffsetCalculationBody,
  CreateCarbonOffsetCalculationResponse,
  CreateCarbonOffsetEnrollmentArgs,
  CreateCarbonOffsetEnrollmentBody,
  CreateCarbonOffsetEnrollmentResponse,
  GetCarbonOffsetCalculationArgs,
  GetCarbonOffsetEnrollmentArgs,
  UpdateCarbonOffsetCalculationArgs,
  UpdateCarbonOffsetCalculationBody,
  UpdateCarbonOffsetCalculationResponse,
  UpdateCarbonOffsetCalculationWithSliderArgs,
  UpdateCarbonOffsetCalculationWithSliderBody,
} from './types';

export async function createCarbonOffsetEnrollment(
  args: CreateCarbonOffsetEnrollmentArgs,
) {
  const { body } = args;

  const { data } = await request.post<
    AxiosResponse,
    AxiosResponse<CreateCarbonOffsetEnrollmentResponse>,
    CreateCarbonOffsetEnrollmentBody
  >(`${abundanceAPI.V1.CARBON_OFFSET}/enrollments`, body);

  return data;
}

export async function getCarbonOffsetEnrollment(
  args: GetCarbonOffsetEnrollmentArgs,
) {
  const {
    params: { id },
  } = args;

  const { data } = await request.get<
    AxiosResponse,
    AxiosResponse,
    CarbonOffsetEnrollment
  >(`${abundanceAPI.V1.CARBON_OFFSET}/enrollments/${id}`);

  return data;
}

export async function getCurrentUserCarbonOffsetEnrollment() {
  const { data } = await request.get<
    AxiosResponse,
    AxiosResponse,
    CarbonOffsetEnrollment
  >(`${abundanceAPI.V1.CARBON_OFFSET}/enrollments/users/current`);

  return data;
}

export async function createCarbonOffsetCalculation(
  args: CreateCarbonOffsetCalculationArgs,
) {
  const { body } = args;

  const { data } = await request.post<
    AxiosResponse,
    AxiosResponse<CreateCarbonOffsetCalculationResponse>,
    CreateCarbonOffsetCalculationBody
  >(`${abundanceAPI.V1.CARBON_OFFSET}/calculations`, body);

  return data;
}

export async function updateCarbonOffsetCalculation(
  args: UpdateCarbonOffsetCalculationArgs,
) {
  const {
    body,
    params: { id },
  } = args;

  const { data } = await request.put<
    void,
    AxiosResponse<CarbonOffsetCalculation>,
    UpdateCarbonOffsetCalculationBody
  >(`${abundanceAPI.V1.CARBON_OFFSET}/calculations/${id}`, body);

  return data;
}

export async function updateCarbonOffsetCalculationWithSlider(
  args: UpdateCarbonOffsetCalculationWithSliderArgs,
) {
  const {
    body,
    params: { id },
  } = args;

  const { data } = await request.put<
    AxiosResponse,
    AxiosResponse<UpdateCarbonOffsetCalculationResponse>,
    UpdateCarbonOffsetCalculationWithSliderBody
  >(`${abundanceAPI.V1.CARBON_OFFSET}/calculations/${id}/with-slider`, body);

  return data;
}

export async function getCarbonOffsetCalculation(
  args: GetCarbonOffsetCalculationArgs,
) {
  const {
    params: { id },
  } = args;

  const { data } = await request.get<
    AxiosResponse,
    AxiosResponse,
    CarbonOffsetCalculation
  >(`${abundanceAPI.V1.CARBON_OFFSET}/calculations/${id}`);

  return data;
}

export async function getCurrentUserCarbonOffsetCalculation() {
  const { data } = await request.get<
    AxiosResponse,
    AxiosResponse,
    CarbonOffsetCalculation
  >(`${abundanceAPI.V1.CARBON_OFFSET}/calculations/users/current`);

  return data;
}
