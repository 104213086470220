import { withTranslation } from 'react-i18next';

import { circularSmallStamp, Spacer } from '@abundance-brasil-wabi/sabi';

import { Syntropy } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { formatDate } from 'utils/formatting';

import * as S from './styles';

const SyntropyCard: React.FC<WithTranslation<Syntropy>> = ({
  title,
  sector,
  stamp = 'friend',
  trees,
  lastPurchase,
  link,
  backgroundImage,
  icon,
  t,
}) => {
  const StampIcon = circularSmallStamp[stamp || 'friend'];

  return (
    <S.Root>
      <S.SyntropyLink href={link}>
        <S.Contents>
          <S.Header>
            <Spacer direction="row" align="center" justify="flex-start" gap={2}>
              <S.Icon src={icon as string} alt={t('companyIconAlt')} />

              <Spacer direction="column" align="flex-start" justify="center">
                <S.Title>{title}</S.Title>

                <S.Sector>{sector}</S.Sector>
              </Spacer>
            </Spacer>

            <Spacer direction="row" align="center" justify="flex-start">
              <StampIcon width="24px" height="24px" />

              <S.StampText $stamp={stamp}>{stamp}</S.StampText>
            </Spacer>
          </S.Header>

          <S.BackgroundImage $backgroundImage={backgroundImage} />

          <S.Footer>
            <S.LastPurchase>
              {t('lastPurchase')}

              <S.LastPurchaseDate>
                {formatDate(lastPurchase, 'shortDate')}
              </S.LastPurchaseDate>
            </S.LastPurchase>

            <S.TreesAmount $stamp={stamp}>
              {trees} {t('trees')}
            </S.TreesAmount>
          </S.Footer>
        </S.Contents>
      </S.SyntropyLink>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:reports:syntropyCard',
})(SyntropyCard);
