import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useApplicationActions } from 'state/application';
import { useEventTracking } from 'state/eventTracking';
import { useNavbarActions } from 'state/navbar';

import { removeAuthEntriesFromStorage } from './utils';

export default function useSignOut() {
  const { resetApplicationState } = useApplicationActions();
  const { clearUserTrackingInfo } = useEventTracking();
  const { dequeueNavbar } = useNavbarActions();
  const queryClient = useQueryClient();

  return useCallback((): void => {
    // Invalidating all queries.
    queryClient.invalidateQueries();
    queryClient.removeQueries();

    // Cleaning up storage.
    removeAuthEntriesFromStorage();

    // Reseting states.
    resetApplicationState();
    dequeueNavbar();

    // Reseting tracking information.
    clearUserTrackingInfo();
  }, [
    clearUserTrackingInfo,
    dequeueNavbar,
    queryClient,
    resetApplicationState,
  ]);
}
