import { styled } from 'styled-components';

import {
  Button,
  Checkbox,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Link,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: 2,
})`
  width: 100%;

  @media (max-width: 440px) {
    ${createFlexDisplay({
      direction: 'column',
      align: 'flex-start',
      justify: 'center',
      gap: '16px',
    })};

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const DisclaimerSection = styled.div`
  max-width: 250px;
`;

export const Disclaimer = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: 2,
})`
  line-height: 17px;
  font-size: 13px;
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const CheckboxContainer = styled(Checkbox)`
  width: 100%;
`;

export const TermsButton = styled(Link).attrs({
  variant: 'text',
  target: '_blank',
})`
  display: contents;
  font-size: 13px;
  font-weight: ${getThemeFontWeight('bold')};
`;

export const BuyButton = styled(Button).attrs({
  variant: 'primary',
})`
  @media (max-width: 440px) {
    width: 100%;
  }
`;
