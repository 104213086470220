import { withTranslation } from 'react-i18next';

import { Banner, Spacer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { useGetRandomSideBannerImage } from 'components/Page';

import * as S from './styles';

const RegisterYourProfileSideBanner: React.FC<WithTranslation> = ({
  t,
}): React.ReactNode => {
  const bannerImage = useGetRandomSideBannerImage();

  return (
    <Banner src={bannerImage}>
      <Banner.Header>
        <Banner.Title>{t('title')}</Banner.Title>

        <Banner.Subtitle>{t('description')}</Banner.Subtitle>
      </Banner.Header>

      <Banner.Content>
        <Spacer direction="column">
          <S.Step>{t('step')} 1</S.Step>

          <S.StepText>{t('step1')}</S.StepText>
        </Spacer>
      </Banner.Content>

      <Banner.Content>
        <Spacer direction="column">
          <S.Step>{t('step')} 2</S.Step>

          <S.StepText>{t('step2')}</S.StepText>
        </Spacer>
      </Banner.Content>

      <Banner.Content>
        <Spacer direction="column">
          <S.Step>{t('step')} 3</S.Step>

          <S.StepText>{t('step3')}</S.StepText>
        </Spacer>
      </Banner.Content>
    </Banner>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'registerYourProfile:registerYourProfileSideBanner',
})(RegisterYourProfileSideBanner);
