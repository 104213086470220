import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeColor,
  getThemeFontWeight,
  Text,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Message = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${breakpointDown('xxs')`
    ${createFlexDisplay({
      direction: 'column',
      align: 'center',
      gap: 2,
    })};

    text-align: center;
  `}
`;

export const Link = styled(LinkButton).attrs({
  variant: 'text',
})`
  display: inherit;
  padding: 0;
  font-weight: ${getThemeFontWeight('bold')};
  font-size: 16px;
  text-decoration: underline;
`;
