import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormReturn } from 'types/forms';

import { zodResolver } from '@hookform/resolvers/zod';

import { getErrorMessage } from 'api/http';
import {
  CarbonCalculationQuestions,
  CarbonOffsetEnrollment,
} from 'api/services';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import {
  useCreateCarbonOffsetCalculation,
  useUpdateCarbonOffsetCalculation,
} from 'state/carbonOffset';
import { createSearchParams } from 'utils/formatting';

import { carbonOffsetCalculatorFormSchema } from './constants';
import { CarbonOffsetCalculatorFormSchema } from './types';
import {
  getCarbonOffsetCalculationPayload,
  getDefaultCarbonOffsetCalculationValues,
} from './utils';

export type UseCarbonOffsetCalculatorConfig = {
  defaultValues?: CarbonCalculationQuestions;
  enrollment?: CarbonOffsetEnrollment;
};

export type UseCarbonOffsetCalculatorForm =
  FormReturn<CarbonOffsetCalculatorFormSchema> & {
    isLoading: boolean;
    onSubmit: () => void;
  };

export function useCarbonOffsetCalculatorForm({
  defaultValues,
  enrollment,
}: UseCarbonOffsetCalculatorConfig): UseCarbonOffsetCalculatorForm {
  const [enrollmentId, calculationId] = useGetSearchParams([
    'enrollmentId',
    'calculationId',
  ]);
  const {
    mutateAsync: createCarbonOffsetCalculation,
    isLoading: isCreatingCalculation,
  } = useCreateCarbonOffsetCalculation();
  const {
    mutateAsync: updateCarbonOffsetCalculation,
    isLoading: isUpdatingCalculation,
  } = useUpdateCarbonOffsetCalculation();

  const form = useForm<CarbonOffsetCalculatorFormSchema>({
    resolver: zodResolver(carbonOffsetCalculatorFormSchema),
    mode: 'all',
    defaultValues: getDefaultCarbonOffsetCalculationValues(defaultValues),
  });

  const { addToast } = useToast();
  const navigate = useNavigate();

  const isLoading = useMemo(
    (): boolean => isCreatingCalculation || isUpdatingCalculation,
    [isCreatingCalculation, isUpdatingCalculation],
  );

  const { handleSubmit } = form;

  const submit = useCallback(
    (data: CarbonOffsetCalculatorFormSchema): void => {
      const isUpdating = Boolean(defaultValues && enrollment);

      const payload = getCarbonOffsetCalculationPayload(
        isUpdating ? enrollment?.id || '' : enrollmentId,
        data,
      );

      if (!payload) return;

      (isUpdating
        ? updateCarbonOffsetCalculation
        : createCarbonOffsetCalculation)(
        {
          body: payload,
          params: isUpdating
            ? {
                id: calculationId,
              }
            : {},
        },
        {
          onSuccess: (response) => {
            navigate({
              pathname: getPathnameFromKey('carbonOffsetResults'),
              search: createSearchParams({
                calculationId: response?.id || '',
              }),
            });
          },
          onError: (error) => {
            addToast({
              message: getErrorMessage(error),
              state: 'error',
            });
          },
        },
      );
    },
    [
      addToast,
      calculationId,
      createCarbonOffsetCalculation,
      defaultValues,
      enrollment,
      enrollmentId,
      navigate,
      updateCarbonOffsetCalculation,
    ],
  );

  return {
    isLoading,
    onSubmit: handleSubmit(submit),
    ...form,
  };
}
