import { Button, Card, Divider } from '@abundance-brasil-wabi/sabi';

import * as S from './styles';

export type CarbonManagementCardProps = {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  description: React.ReactNode;
  availableTitle: React.ReactNode;
  availableValue: React.ReactNode;
  co2Title: React.ReactNode;
  co2Value: React.ReactNode;
  actionButtonTitle: React.ReactNode;
  isActionButtonDisabled: boolean;
  onActionButtonPress?: () => void;
};

const CarbonManagementCard: React.FC<CarbonManagementCardProps> = ({
  title,
  subtitle,
  icon,
  description,
  availableTitle,
  availableValue,
  co2Title,
  co2Value,
  actionButtonTitle,
  isActionButtonDisabled,
  onActionButtonPress,
}) => {
  return (
    <S.Root>
      <S.Content>
        <S.ContentsHeading>
          <S.Subtitle>{title}</S.Subtitle>

          <S.Title>{subtitle}</S.Title>
        </S.ContentsHeading>

        <S.IconContainer>{icon}</S.IconContainer>
      </S.Content>

      <Card.Content>
        <S.Description>{description}</S.Description>
      </Card.Content>

      <S.Description>
        <S.Content>
          <span>{availableTitle}</span>

          <b>{availableValue}</b>
        </S.Content>

        <Divider margin="8px 0" />

        <S.Content>
          <span>{co2Title}</span>

          <b>{co2Value}</b>
        </S.Content>
      </S.Description>

      <S.ButtonContainer>
        <Button
          variant="secondary-outline"
          size={32}
          isDisabled={isActionButtonDisabled}
          onPress={onActionButtonPress}
        >
          {actionButtonTitle}
        </Button>
      </S.ButtonContainer>
    </S.Root>
  );
};

export default CarbonManagementCard;
