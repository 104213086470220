import { styled } from 'styled-components';

import { getThemeColor } from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled(LinkButton).attrs({
  variant: 'text-static',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  font-size: 17px;
  gap: 8px;
  color: ${getThemeColor('text', 'default', 'secondary')};
`;
