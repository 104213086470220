import { styled } from 'styled-components';

import { breakpointDown, InfoTile, Spacer } from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  gap: 4,
})`
  width: 100%;
`;

export const ScopeInfo = styled(InfoTile)`
  ${breakpointDown('xxl')`
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `}
`;

export const EndContent = styled(InfoTile.EndContent)`
  display: flex;
  align-items: center;
  gap: 16px;

  ${breakpointDown('xxl')`
    gap: 8px;
    margin-top: 8px;
  `};

  ${breakpointDown('xxs')`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

export const Amount = styled.div`
  min-width: 62px;
  padding: 4px 10px;
  border: 1px solid;
  text-align: center;
`;
