import { useCallback } from 'react';
import { isNil } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  MdOutlineAssignmentInd,
  MdOutlineDirectionsCar,
  MdOutlineMapsHomeWork,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { getErrorMessage } from 'api/http';
import { WithTranslation } from 'i18n/types';
import { useToast } from 'hooks/useToast';
import { getPathnameFromKey } from 'router/utils';
import { useCreateCarbonOffsetEnrollment } from 'state/carbonOffset';
import { useUser, useUserRestorationProfile } from 'state/user';
import { createSearchParams } from 'utils/formatting';
import { getDefaultAppLanguage } from 'utils/handlers';

import * as S from './styles';

const NoCalculationBanner: React.FC<WithTranslation> = ({ t }) => {
  const { mutateAsync: createCarbonOffsetEnrollment, isLoading } =
    useCreateCarbonOffsetEnrollment();
  const { data: user } = useUser();
  const { data: restorationProfile } = useUserRestorationProfile();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleStartCalculationFlow = useCallback((): void => {
    if (!user || isNil(restorationProfile)) return;

    createCarbonOffsetEnrollment(
      {
        body: {
          name: user?.name,
          email: user?.email,
          language: getDefaultAppLanguage(),
          restorationProfile,
        },
      },
      {
        onSuccess: (response) => {
          navigate({
            pathname: getPathnameFromKey('carbonOffsetCalculator'),
            search: createSearchParams({
              enrollmentId: response.id,
            }),
          });
        },
        onError: (error) => {
          addToast({
            message: getErrorMessage(error),
            state: 'error',
          });
        },
      },
    );
  }, [
    addToast,
    createCarbonOffsetEnrollment,
    navigate,
    restorationProfile,
    user,
  ]);

  return (
    <S.Root>
      <S.TilesContainer>
        <S.Tile colorTheme="tertiary">
          <MdOutlineAssignmentInd />
        </S.Tile>

        <S.Tile colorTheme="secondary">
          <MdOutlineDirectionsCar />
        </S.Tile>

        <S.Tile colorTheme="warning">
          <MdOutlineMapsHomeWork />
        </S.Tile>
      </S.TilesContainer>

      <S.Contents title={t('title')} description={t('description')} />

      <S.CalculateButton
        onPress={handleStartCalculationFlow}
        isDisabled={isLoading}
      >
        {t('calculate')}
      </S.CalculateButton>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'home:noCalculationBanner',
})(NoCalculationBanner);
