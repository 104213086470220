import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import FormPageContainer from 'components/FormPageContainer';
import { useSetPageConfig } from 'components/Page';
import useGetSearchParams from 'hooks/useGetSearchParams';
import {
  useCarbonOffsetCalculationById,
  useCarbonOffsetEnrollmentById,
} from 'state/carbonOffset';

import RegisterYourProfileForm from './RegisterYourProfileForm';
import RegisterYourProfileSideBanner from './RegisterYourProfileSideBanner';

const RegisterYourProfile: React.FC<WithTranslation> = (): React.ReactNode => {
  const [enrollmentId, calculationId] = useGetSearchParams([
    'enrollmentId',
    'calculationId',
  ]);

  const {
    data: carbonOffsetEnrollment,
    isLoading: isCarbonOffsetEnrollmentLoading,
  } = useCarbonOffsetEnrollmentById(enrollmentId);
  const {
    data: carbonOffsetCalculation,
    isLoading: isCarbonOffsetCalculationLoading,
  } = useCarbonOffsetCalculationById(calculationId);

  const isLoading = useMemo(
    (): boolean =>
      (Boolean(enrollmentId) && Boolean(isCarbonOffsetEnrollmentLoading)) ||
      (Boolean(calculationId) && Boolean(isCarbonOffsetCalculationLoading)),
    [
      calculationId,
      enrollmentId,
      isCarbonOffsetCalculationLoading,
      isCarbonOffsetEnrollmentLoading,
    ],
  );

  useSetPageConfig({
    mode: 'split',
    override: {
      sideContent: <RegisterYourProfileSideBanner />,
    },
  });

  return (
    <FormPageContainer isLoading={isLoading} to="selectAccountType">
      <RegisterYourProfileForm
        hasDefaultInfo={Boolean(
          carbonOffsetCalculation || carbonOffsetEnrollment,
        )}
      />
    </FormPageContainer>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'registerYourProfile',
})(RegisterYourProfile);
