import { AxiosResponse } from 'axios';

import { abundanceAPI } from 'api/constants';
import { request } from 'api/http';

import { GetVirtualForestByIdArgs, VirtualForest } from './types';

export async function getVirtualForestList() {
  const { data } = await request.get<
    VirtualForest[],
    AxiosResponse<VirtualForest[]>,
    never
  >(`${abundanceAPI.V1.VIRTUAL_FOREST}`);

  return data;
}

export async function getVirtualForestById(args: GetVirtualForestByIdArgs) {
  const {
    params: { id },
  } = args;

  const { data } = await request.get<
    VirtualForest,
    AxiosResponse<VirtualForest>,
    never
  >(`${abundanceAPI.V1.VIRTUAL_FOREST}/${id}`);

  return data;
}

export async function getCurrentVirtualForests() {
  const { data } = await request.get<
    VirtualForest[],
    AxiosResponse<VirtualForest[]>,
    never
  >(`${abundanceAPI.V1.VIRTUAL_FOREST}/users/current`);

  return data;
}
