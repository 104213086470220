import { forwardRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { Gallery, Spacer } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import AuditDocuments from 'components/AuditDocuments';
import ESGActing from 'components/ESGActing';
import GoogleMaps from 'components/GoogleMaps';
import PlantingStages from 'components/PlantingStages';
import ResponsibleConsultant from 'components/ResponsibleConsultant';
import { IMAGE_URL } from 'constants/assets';
import { AURORA_VERDE_MODAL_GALLERY_IMAGES } from 'constants/galleryImages';

import { hideModal } from '../../../store/modules/modal/actions';
import AuroraVerdeHeader from './AuroraVerdeHeader';
import * as S from './styles';

const AuroraVerdeModal: React.FC<WithTranslation> = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ t }, _ref) => {
    const dispatch = useDispatch();

    const closeAuroraVerdeModal = useCallback((): void => {
      dispatch(hideModal());
    }, [dispatch]);

    return (
      <S.Root>
        <S.CloseButton onPress={closeAuroraVerdeModal}>
          <MdClose />
        </S.CloseButton>

        <AuroraVerdeHeader />

        <S.VideoContainer>
          <S.VideoIframe
            width="1120"
            height="550"
            src="https://www.youtube.com/embed/xl21YId7yXY?controls=0"
            title="Floresta Aurora Verde"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </S.VideoContainer>

        <S.Contents>
          <Spacer
            direction="column"
            align="flex-start"
            justify="center"
            gap={3}
          >
            <S.Title>{t('descriptionTitle')}</S.Title>

            <S.Paragraph $alignToCenter>{t('firstParagraph')}</S.Paragraph>

            <S.SectionTitle>{t('oneMillionTrees')}</S.SectionTitle>

            <S.Paragraph>{t('secondParagraph')}</S.Paragraph>

            <S.Paragraph>{t('thirdParagraph')}</S.Paragraph>

            <S.Paragraph>{t('fourthParagraph')}</S.Paragraph>
          </Spacer>

          <Gallery images={AURORA_VERDE_MODAL_GALLERY_IMAGES} />

          <GoogleMaps />

          <PlantingStages />

          <S.ImpactImage
            src={`${IMAGE_URL}/v1702757099/Assets/Informational/Impact/impact-until-2052-desktop_qctjlt.svg`}
            alt={t('impactImageAlt')}
          />

          <AuditDocuments />

          <ESGActing />

          <ResponsibleConsultant />
        </S.Contents>
      </S.Root>
    );
  },
);

export default withTranslation('components', {
  keyPrefix: 'modal:auroraVerdeModal',
  withRef: true,
})(AuroraVerdeModal);
