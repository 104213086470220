import { withTranslation } from 'react-i18next';

import { WithTranslation } from 'i18n/types';
import ColorfulBackground from 'components/ColorfulBackground';
import { useSetPageConfig } from 'components/Page';
import { abundanceURLs } from 'constants/externalURLs';
import useGetSearchParams from 'hooks/useGetSearchParams';

import * as S from './styles';

const Locked: React.FC<WithTranslation> = ({ t }) => {
  useSetPageConfig({ mode: 'cover' });

  const [email] = useGetSearchParams(['email']);

  return (
    <ColorfulBackground>
      <S.Root>
        <S.Logo />

        <S.Title>{t('lockedAccount')}</S.Title>

        <S.Body>{t('emailSent', { email })}</S.Body>

        <S.Body>
          {t('forMoreInfo')}{' '}
          <S.LinkButton $isUpperCase target="_blank" href={abundanceURLs.faq}>
            {t('faq')}
          </S.LinkButton>{' '}
          {t('contactSupport')}{' '}
          <S.LinkButton
            $isUpperCase={false}
            target="_blank"
            href={abundanceURLs.contact}
          >
            {t('here')}
          </S.LinkButton>
          .
        </S.Body>
      </S.Root>
    </ColorfulBackground>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'locked',
})(Locked);
