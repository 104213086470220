import { withTranslation } from 'react-i18next';

import {
  FacebookFIcon,
  Footer as BaseFooter,
  InstagramOutlineIcon,
  LinkedInIcon,
  SpotifyIcon,
  XIcon,
  YoutubeIcon,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { abundanceURLs, socialURLs } from 'constants/externalURLs';

const Footer: React.FC<WithTranslation> = ({ t }): React.ReactNode => {
  return (
    <BaseFooter
      social={
        <>
          <BaseFooter.SocialMedia href={socialURLs.facebook}>
            <FacebookFIcon />
          </BaseFooter.SocialMedia>

          <BaseFooter.SocialMedia href={socialURLs.instagram}>
            <InstagramOutlineIcon />
          </BaseFooter.SocialMedia>

          <BaseFooter.SocialMedia href={socialURLs.linkedin}>
            <LinkedInIcon />
          </BaseFooter.SocialMedia>

          <BaseFooter.SocialMedia href={socialURLs.spotify}>
            <SpotifyIcon />
          </BaseFooter.SocialMedia>

          <BaseFooter.SocialMedia href={socialURLs.youtube}>
            <YoutubeIcon />
          </BaseFooter.SocialMedia>

          <BaseFooter.SocialMedia href={socialURLs.x}>
            <XIcon />
          </BaseFooter.SocialMedia>
        </>
      }
      links={
        <>
          <BaseFooter.Link href={abundanceURLs.privacyPolicy}>
            {t('privacyPolicy')}
          </BaseFooter.Link>

          <BaseFooter.Link href={abundanceURLs.termsAndConditions}>
            {t('termsAndConditions')}
          </BaseFooter.Link>

          <BaseFooter.Link href={abundanceURLs.faq}>{t('faq')}</BaseFooter.Link>

          <BaseFooter.Link href={abundanceURLs.cookies}>
            {t('cookies')}
          </BaseFooter.Link>
        </>
      }
      message={t('copyright')}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'page:footer',
})(Footer);
