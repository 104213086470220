import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import i18next from 'i18next';

import { virtualForestQueryKeys } from './queryKeys';

export default function useResetVirtualForestFiles() {
  const queryClient = useQueryClient();

  return useCallback(
    (virtualForestId: string): void => {
      queryClient.removeQueries(
        virtualForestQueryKeys.virtualForestCertificate(
          virtualForestId,
          i18next.language,
        ),
      );
      queryClient.removeQueries(
        virtualForestQueryKeys.virtualForestAbsorptionReport(
          virtualForestId,
          i18next.language,
        ),
      );
    },
    [queryClient],
  );
}
