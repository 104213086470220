import { Impact } from 'api/services';

export const absorptionReportSelector = (data: Blob): Blob => data;

export const certificateSelector = (data: Blob): Blob => data;

export const impactSelector = (data: Impact): Impact => data;

export const impactTonsSelector = (data: Impact): number => data.tons;

export const hasImpactBalanceSelector = (data: Impact): boolean =>
  !!data.balance;

export const treeAccountBalanceSelector = (data: number): number => data;
