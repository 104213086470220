import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';

import { pushModal } from '../../../../store/modules/modal/actions';
import { ModalsList } from '../../../../store/modules/modal/types';
import * as S from './styles';
import { BannerSize } from './types';

export type BannerProps = {
  size?: BannerSize;
};

const Banner: React.FC<WithTranslation<BannerProps>> = ({
  size = 'normal',
  t,
}) => {
  const dispatch = useDispatch();

  const openBuyTreeModal = useCallback((): void => {
    dispatch(pushModal({ name: ModalsList.BUY_TREES_MODAL }));
  }, [dispatch]);

  return (
    <S.Root $size={size}>
      <S.Titles>
        <S.UpperTitle>{t('project')}</S.UpperTitle>

        <S.MiddleTitle $size={size}>
          {t('beARestorer')}
          <br />
          {t('buyTrees')}
        </S.MiddleTitle>

        <S.LowerTitle $size={size}>{t('treesAmount')}</S.LowerTitle>
      </S.Titles>

      <Button
        variant="primary"
        size={size === 'normal' ? '56' : '32'}
        onPress={openBuyTreeModal}
      >
        {t('buyNow')}
      </Button>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:forestDetails:banner',
})(Banner);
