import { withTranslation } from 'react-i18next';

import { Button, MessageIcon, RenderWhen } from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import { Dropdown } from 'components/FormFields';

import * as S from './styles';
import { useGetFormOptions } from './useGetFormOptions';
import { useSupportForm } from './useSupportForm';

const SupportForm: React.FC<WithTranslation> = ({ t }) => {
  const { subjects } = useGetFormOptions();

  const { formState, control, getValues, isLoading, hasSubmitted, onSubmit } =
    useSupportForm();

  return (
    <>
      <RenderWhen condition={hasSubmitted}>
        <S.SuccessContainer>
          <MessageIcon width={41} height={33} />
          <S.SuccessTitle>{t('messageSent')}</S.SuccessTitle>
          <S.SuccessContent>{t('thanks')}</S.SuccessContent>
          <S.SuccessContent>{t('talkSoon')}</S.SuccessContent>
        </S.SuccessContainer>
      </RenderWhen>

      <RenderWhen condition={!hasSubmitted}>
        <S.SuportContainer>
          <S.FormContainer
            fluid
            title={t('talkWithUs')}
            sections={[
              {
                id: 'support-form-main',
                contents: [
                  <Dropdown
                    fluid
                    key="subject"
                    name="subject"
                    valueType="number"
                    placeholder={t('subject')}
                    defaultValue={getValues('subject')}
                    options={subjects}
                    isDisabled={isLoading}
                    {...{ control, formState }}
                  />,

                  <S.TextAreaInput
                    key="message"
                    name="message"
                    placeholder={t('message')}
                    isDisabled={isLoading}
                    {...{ control, formState }}
                  />,

                  <Button
                    key="submit"
                    type="submit"
                    isDisabled={!formState.isValid || isLoading}
                  >
                    {t('send')}
                  </Button>,
                ],
              },
            ]}
            onSubmit={onSubmit}
          />
        </S.SuportContainer>
      </RenderWhen>
    </>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'support:supportForm',
})(SupportForm);
