import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pushModal } from 'src/store/modules/modal/actions';
import { ModalsList } from 'src/store/modules/modal/types';

import { WithTranslation } from 'i18n/types';
import { IMAGE_URL } from 'constants/assets';
import { useImpact } from 'state/tree';

import InformationalCard from './InformationalCard';

const AuroraVerdeInformationalCard: React.FC<WithTranslation> = ({ t }) => {
  const { data: impact } = useImpact();
  const dispatch = useDispatch();

  const { balance, until } = impact || {};
  const isDisabled = !balance && !until;

  const openAuroraVerdeModal = useCallback((): void => {
    dispatch(pushModal({ name: ModalsList.AURORA_VERDE_MODAL }));
  }, [dispatch]);

  const openBuyTreeModal = useCallback((): void => {
    dispatch(pushModal({ name: ModalsList.BUY_TREES_MODAL }));
  }, [dispatch]);

  return (
    <InformationalCard
      icon={
        <img
          src={`${IMAGE_URL}/v1702780628/Assets/Partners/aurora-verde-with-background_b2hpug.png`}
          alt={t('iconAlt')}
        />
      }
      tagContent={t('tagContent')}
      defaultTagColor="green"
      subtitle={t('subtitle')}
      title={t('title')}
      description={t('description')}
      primaryButtonTitle={
        isDisabled
          ? t('primaryButton:disabledText')
          : t('primaryButton:enabledText')
      }
      onPrimaryButtonPress={openBuyTreeModal}
      secondaryButtonTitle={t('secondaryButton:text')}
      onSecondaryButtonPress={openAuroraVerdeModal}
    />
  );
};

export default withTranslation('components', {
  keyPrefix: 'cards:informationalCards:auroraVerdeInformationalCard',
})(AuroraVerdeInformationalCard);
