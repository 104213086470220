import moment from 'moment';

import i18next from 'i18next';
import supportedLanguages from 'constants/supportedLanguages';
import {
  DateFormat,
  SupportedLanguage,
  SupportedLanguages,
} from 'constants/types';

import 'moment/min/locales.min';

export type ConfigureLocale = {
  currentLanguage: string;
  languageSettings: SupportedLanguage;
};

/**
 * It configures the locale for the current language.
 *
 * @returns An object with two properties: currentLanguage and languageSettings.
 */
const configureLocale = (): ConfigureLocale => {
  const currentLanguage = i18next.language;
  const languageSettings =
    supportedLanguages[currentLanguage as SupportedLanguages];

  moment.locale(currentLanguage);
  moment.updateLocale(currentLanguage, {
    months: languageSettings.months,
  });

  return { currentLanguage, languageSettings };
};

/**
 * It takes a date and a format and returns a string with the date formatted in
 * the current language.
 *
 * @param {string | Date} date - Represents a date string or a `Date` object.
 * If no value is provided, it defaults to the current date and time.
 * @param {DateFormat} [format=fullDate] - Used to specify the desired format in
 * which the date should be formatted. If nothing is provided, it default to the
 * `fullDate` format.
 *
 * @returns The formatted date string based on the input `date` and `format` parameters.
 */
export function formatDate(
  date: string | Date = new Date(Date.now()),
  format: DateFormat = 'fullDate',
): string {
  const localeConfiguration = configureLocale();

  return moment(date)
    .locale(localeConfiguration.currentLanguage)
    .format(localeConfiguration.languageSettings.dateFormats[format]);
}
