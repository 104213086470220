import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';
import { Key } from '@react-types/shared';

import { WithTranslation } from 'i18n/types';
import TabNavigator from 'components/TabNavigator';
import { TabNavigatorItem } from 'components/TabNavigator/types';
import useTabNavigation from 'hooks/useTabNavigation';

import * as S from './styles';
import { ESGActingSize } from './types';

type TabItem = 'environment' | 'social' | 'governance';

export type ESGActingProps = {
  className?: string;
  size?: ESGActingSize;
};

const ESGActing: React.FC<WithTranslation<ESGActingProps>> = ({
  className,
  size = 'normal',
  t,
}) => {
  const { activeTab, setActiveTab } = useTabNavigation<Key>({
    defaultTabId: 'environment',
  });

  const tabItems = useMemo((): TabNavigatorItem<TabItem>[] => {
    return [
      {
        id: 'environment',
        label: t('environment'),
      },
      {
        id: 'social',
        label: t('social'),
      },
      {
        id: 'governance',
        label: t('governance'),
      },
    ];
  }, [t]);

  return (
    <S.Root $size={size} className={className}>
      <S.Title $size={size}>{t('esgActing')}</S.Title>

      <TabNavigator
        items={Object.values(tabItems)}
        activeTab={activeTab}
        setActive={setActiveTab}
      />

      <RenderWhen condition={activeTab === 'environment'}>
        <S.ListItems $size={size}>
          <li>{t('environmentItem:firstli')}</li>
          <li>{t('environmentItem:secondli')}</li>
          <li>{t('environmentItem:thirdli')}</li>
          <li>{t('environmentItem:fourthli')}</li>
          <li>{t('environmentItem:fifthli')}</li>
          <li>{t('environmentItem:sixthli')}</li>
          <li>{t('environmentItem:seventhli')}</li>
        </S.ListItems>
      </RenderWhen>

      <RenderWhen condition={activeTab === 'social'}>
        <S.ListItems $size={size}>
          <li>{t('socialItem:firstli')}</li>
          <li>{t('socialItem:secondli')}</li>
          <li>{t('socialItem:thirdli')}</li>
        </S.ListItems>
      </RenderWhen>

      <RenderWhen condition={activeTab === 'governance'}>
        <S.ListItems $size={size}>
          <li>{t('governanceItem:firstli')}</li>
          <li>{t('governanceItem:secondli')}</li>
          <li>{t('governanceItem:thirdli')}</li>
          <li>{t('governanceItem:fourthli')}</li>
        </S.ListItems>
      </RenderWhen>
    </S.Root>
  );
};

export default withTranslation('components', {
  keyPrefix: 'esgActing',
})(ESGActing);
