import { Navigate, Outlet } from 'react-router-dom';

import useUserInfoLoader from 'components/Page/useUserInfoLoader';
import useApplicationLoader from 'hooks/useApplicationLoader';
import { getPathnameFromKey } from 'router/utils';
import { getIsAuthenticated } from 'state/auth/utils';

/**
 * Private routes can only be accessed by users that are authenticated.
 */
const Private: React.FC = () => {
  const { isLoading } = useApplicationLoader();
  const { isUserInfoLoaded } = useUserInfoLoader();
  const isAuthenticated = getIsAuthenticated();

  if (!isAuthenticated)
    return <Navigate to={getPathnameFromKey('signIn')} replace />;

  return <Outlet context={{ isAppLoaded: isUserInfoLoaded || isLoading }} />;
};

export default Private;
