import { styled } from 'styled-components';

import {
  breakpointDown,
  FormComposer,
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

import { TextArea } from 'components/FormFields';

export const SuccessContainer = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: 487px;
  padding: 24px;
  border: 1px solid ${getThemeColor('border', 'primary', 'default')};
  border-radius: 8px;
  text-align: center;

  & > * {
    color: ${getThemeColor('text', 'success')};
  }
`;

export const SuccessTitle = styled(Text).attrs({
  level: 'subtitle',
})`
  margin-top: 8px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: ${getThemeFontWeight('bold')};
`;

export const SuccessContent = styled(Text).attrs({
  level: 'bodySmall',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const SuportContainer = styled.div`
  width: 100%;
  padding: 24px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('white')};

  ${breakpointDown('lg')`
    padding: 0;
    border: none;
  `}
`;

export const FormContainer = styled(FormComposer)`
  & > :first-child {
    margin-bottom: 0;
  }
`;

export const TextAreaInput = styled(TextArea).attrs({ fluid: true })`
  height: 240px;
  min-height: 240px;
`;
