import { withTranslation } from 'react-i18next';

import { RenderWhen } from '@abundance-brasil-wabi/sabi';

import { VirtualForest } from 'api/services';
import { WithTranslation } from 'i18n/types';
import Description from 'components/Description';

import Banner from '../Banner';
import Guardian from './Guardian';
import * as S from './styles';

const Start: React.FC<WithTranslation<VirtualForest>> = (props) => {
  const { benefits, description, t } = props;

  return (
    <S.Root>
      <S.Contents>
        <S.Left>
          <RenderWhen condition={!!description}>
            <Description.Root>
              <Description.Section title={t('about')} text={description} />
            </Description.Root>
          </RenderWhen>

          <RenderWhen condition={!!benefits}>
            <Description.Root>
              <Description.Section title={t('benefits')} text={benefits} />
            </Description.Root>
          </RenderWhen>

          <Banner size="small" />
        </S.Left>

        <S.Right>
          <Guardian {...props} />
        </S.Right>
      </S.Contents>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:forestDetails:start:start',
})(Start);
