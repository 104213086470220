import { PathConfiguration } from './types';

export const freePaths = {
  carbonOffsetCalculator: { path: '/carbon-offset-calculator' },
  carbonOffsetResults: { path: '/carbon-offset-calculator/results' },
} satisfies Readonly<Record<string, PathConfiguration>>;

export const publicPaths = {
  mobileIntroduction: { path: '/mobile-introduction' },
  signIn: { path: '/sign-in' },
  locked: { path: '/locked' },
  purchaseWithoutAccount: { path: '/purchase-without-account' },
  purchaseWithoutAccountConfirmation: {
    path: '/purchase-without-account/confirmation',
  },
  securityVerification: { path: '/security-verification' },
  emailConfirmation: { path: '/email-confirmation' },
  emailConfirmationSuccess: { path: '/email-confirmation/success' },
  signUpCompany: { path: '/sign-up/company' },
  signUpPersonal: { path: '/sign-up/personal' },
  createNewPassword: { path: '/create-new-password' },
  createNewPasswordConfirm: { path: '/create-new-password/confirm' },
  createNewPasswordSuccess: { path: '/create-new-password/success' },
  forgotPassword: { path: '/forgot-password' },
  selectAccountType: { path: '/select-account-type' },
  registerYourProfile: { path: '/register-your-profile' },
  forestProducerRegistration: { path: '/forest-producer-registration' },
  forestProducerRegistrationSuccess: {
    path: '/forest-producer-registration/success',
  },
} satisfies Readonly<Record<string, PathConfiguration>>;

export const privatePaths = {
  articles: { path: '/articles' },
  howToGetMyCertificate: { path: '/how-to-get-my-certificate' },
  myCertificate: { path: '/my-certificate' },
  howToGetMyTokens: { path: '/how-to-get-my-tokens' },
  mediaKit: { path: '/media-kit' },
  esgReport: { path: '/esg-report' },
  impact: { path: '/impact' },
  home: { path: '/home' },
  changePassword: { path: '/change-password' },
  companySettings: { path: '/company-settings' },
  userSettings: { path: '/user-settings' },
  subscriptionPlans: { path: '/subscription-plans' },
  support: { path: '/support' },
  wallet: { path: '/wallet' },
  syntropy: { path: '/syntropy' },
  syntropyReports: { path: '/syntropy/reports' },
  syntropyCommunities: { path: '/syntropy/communities' },
  syntropyCommunitiesDetails: { path: '/syntropy/communities/:id' },
  virtualTour: { path: '/virtual-tour' },
} satisfies Readonly<Record<string, PathConfiguration>>;

export default { ...freePaths, ...publicPaths, ...privatePaths };
