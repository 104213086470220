import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Spacer,
  Text,
} from '@abundance-brasil-wabi/sabi';

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
`;

export const SummaryLine = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'space-between',
  gap: '16px',
})`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${getThemeColor('border', 'default', 'primary')};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const SummaryTitle = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;

export const Value = styled(Text).attrs({
  level: 'body',
})`
  font-weight: ${getThemeFontWeight('medium')};
  color: ${getThemeColor('text', 'default', 'secondary')};
`;

export const Total = styled(Text).attrs({
  level: 'subtitle',
})`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};
`;
