import { withTranslation } from 'react-i18next';

import { RenderWhen, useIsMobileViewport } from '@abundance-brasil-wabi/sabi';

import { CarbonOffsetEnrollment } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { abundanceURLs } from 'constants/externalURLs';
import { getPathnameFromKey } from 'router/utils';
import { getIsAuthenticated } from 'state/auth/utils';

import * as S from './styles';

export type CarbonOffsetResultsFooterProps = {
  enrollmentInfo?: CarbonOffsetEnrollment;
};

const CarbonOffsetResultsFooter: React.FC<
  WithTranslation<CarbonOffsetResultsFooterProps>
> = ({ enrollmentInfo, t }) => {
  const isMobile = useIsMobileViewport();
  const isAuthenticated = getIsAuthenticated();

  return (
    <S.Root>
      <S.LinksContainer>
        <S.LinkElement href={abundanceURLs.faq}>FAQ</S.LinkElement>

        <S.LinkElement href="https://youtu.be/xl21YId7yXY">
          {t('doc')}
        </S.LinkElement>
      </S.LinksContainer>

      <RenderWhen condition={!isAuthenticated}>
        <S.OpenAccountButton
          to={getPathnameFromKey('signUpCompany')}
          search={`email=${encodeURIComponent(
            enrollmentInfo?.email || 0,
          )}&name=${encodeURIComponent(enrollmentInfo?.name || 0)}`}
          variant="secondary-outline"
        >
          {!isMobile
            ? t('openAccountButtonDesktop')
            : t('openAccountButtonMobile')}
        </S.OpenAccountButton>
      </RenderWhen>
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'carbonOffsetResults:carbonOffsetResultsFooter',
})(CarbonOffsetResultsFooter);
