import { styled } from 'styled-components';

import { Spacer } from '@abundance-brasil-wabi/sabi';

export const Loader = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: 100%;
  position: relative;
  top: 32px;
`;

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  gap: '0',
})`
  width: 100%;
  flex: 1 0 auto;
`;

export const ForestDetails = styled.div`
  width: 100%;
  position: relative;
`;
