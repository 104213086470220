import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Divider,
  RenderWhen,
  Spacer,
  StampWithTitle,
} from '@abundance-brasil-wabi/sabi';

import { VirtualForest } from 'api/services';
import { WithTranslation } from 'i18n/types';
import { formatDate } from 'utils/formatting';

import SocialMediaLink from '../SocialMediaLink';
import * as S from './styles';

const Guardian: React.FC<WithTranslation<VirtualForest>> = (props) => {
  const {
    stamp,
    customerName,
    customerFacebook,
    customerInstagram,
    customerLinkedIn,
    customerSpotify,
    customerYouTube,
    customerTwitter,
    customerWebsite,
    since,
    t,
  } = props;

  const getLink = useCallback((): string => {
    if (!customerWebsite) return '';

    const newLink = customerWebsite?.split('https://');

    if (newLink[0]) return newLink[0];

    return newLink[1];
  }, [customerWebsite]);

  const checkHasSocialLink = useCallback((): boolean => {
    if (
      customerFacebook ||
      customerInstagram ||
      customerLinkedIn ||
      customerSpotify ||
      customerYouTube ||
      customerTwitter
    )
      return true;

    return false;
  }, [
    customerFacebook,
    customerInstagram,
    customerLinkedIn,
    customerSpotify,
    customerTwitter,
    customerYouTube,
  ]);

  return (
    <S.Root>
      <Spacer direction="column">
        <S.Guardian>{t('guardian')}</S.Guardian>

        <S.GuardianName>{customerName}</S.GuardianName>
      </Spacer>

      <RenderWhen condition={checkHasSocialLink()}>
        <div>
          <SocialMediaLink {...props} />
        </div>
      </RenderWhen>

      <RenderWhen condition={!!customerWebsite}>
        <S.WebsiteLink href={customerWebsite}>{getLink()}</S.WebsiteLink>
      </RenderWhen>

      <Divider />

      <Spacer direction="column">
        <S.SinceTitle>{t('since')}</S.SinceTitle>

        <S.SinceDescription>
          {formatDate(since, 'shortDate')}
        </S.SinceDescription>
      </Spacer>

      <StampWithTitle stamp={stamp} />
    </S.Root>
  );
};

export default withTranslation('pages', {
  keyPrefix: 'syntropy:forestDetails:start:guardian',
})(Guardian);
