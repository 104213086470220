import { useCallback, useMemo } from 'react';

import {
  AbundanceBrasilDefaultMixedLogo,
  RenderWhen,
  Spacer,
  useIsMobileViewport,
} from '@abundance-brasil-wabi/sabi';

import { getPathnameFromKey } from 'router/utils';
import { getIsAuthenticated } from 'state/auth/utils';
import { useNavbarActions } from 'state/navbar';

import * as S from './styles';

export type TitleBarStartContentProps = {
  hasInstitutionalMenuContents: boolean;
};

const TitleBarStartContent: React.FC<TitleBarStartContentProps> = ({
  hasInstitutionalMenuContents,
}): React.ReactNode => {
  const { enqueueNavbar } = useNavbarActions();
  const isMobile = useIsMobileViewport();
  const isAuthenticated = getIsAuthenticated();

  const shouldRenderMenuButton = useMemo(
    (): boolean =>
      isMobile &&
      (isAuthenticated || (!isAuthenticated && hasInstitutionalMenuContents)),
    [hasInstitutionalMenuContents, isAuthenticated, isMobile],
  );

  const handleOpenLeftMenuNavbar = useCallback((): void => {
    enqueueNavbar({
      name: 'LeftMenu',
    });
  }, [enqueueNavbar]);

  return (
    <Spacer align="center" justify="center" gap="16px">
      <RenderWhen condition={shouldRenderMenuButton}>
        <S.MenuButton variant="text-static" onPress={handleOpenLeftMenuNavbar}>
          <S.MenuIcon />
        </S.MenuButton>
      </RenderWhen>

      <S.AbundanceLinkIcon to={getPathnameFromKey('home')}>
        {isMobile ? (
          <S.MobileLogo width={146} height={32} />
        ) : (
          <AbundanceBrasilDefaultMixedLogo width={184} height={40} />
        )}
      </S.AbundanceLinkIcon>
    </Spacer>
  );
};

export default TitleBarStartContent;
