import { externalEndpoints } from 'api/constants';

import { Article, ArticleResponse } from './types';

export async function getArticlesList() {
  const response = await fetch(externalEndpoints.ARTICLE)
    .then((resp) => resp.json())
    .then((data): Article[] => {
      return data.map((currentArticle: ArticleResponse) => {
        return {
          title: currentArticle.title.rendered,
          description: currentArticle.yoast_head_json.og_description,
          image:
            currentArticle.yoast_head_json?.og_image?.[0]?.url ||
            currentArticle.featured_image_src,
          link: currentArticle.yoast_head_json.og_url,
        };
      });
    });

  return response;
}
