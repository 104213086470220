import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { forgotPassword, ForgotPasswordArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useChangePassword(
  options?: MutationOptions<void, ForgotPasswordArgs>,
) {
  return useMutation<void, ErrorMessage, ForgotPasswordArgs, unknown>(
    forgotPassword,
    options,
  );
}
