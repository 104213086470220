import { PlantingStages } from './types';

export const plantingStages: PlantingStages[] = [
  {
    year: '2022',
    target: '100',
    percentage: 100,
  },
  {
    year: '2023',
    target: '100',
    percentage: 100,
  },
  {
    year: '2024',
    target: '100',
    percentage: 0,
  },
  {
    year: '2025',
    target: '100',
    percentage: 0,
  },
  {
    year: '2026',
    target: '100',
    percentage: 0,
  },
  {
    year: '2027',
    target: '100',
    percentage: 0,
  },
  {
    year: '2028',
    target: '100',
    percentage: 0,
  },
  {
    year: '2029',
    target: '100',
    percentage: 0,
  },
  {
    year: '2030',
    target: '100',
    percentage: 0,
  },
];
