import { CardsList } from '@abundance-brasil-wabi/sabi';

import { useVirtualForestList } from 'state/virtualForest';

import VirtualForestCard from './VirtualForestCard';

const VirtualForestCardsList: React.FC = () => {
  const { data: virtualForestsList = [], isLoading } = useVirtualForestList();

  return (
    <CardsList mode="full" isLoading={isLoading}>
      {virtualForestsList.map((virtualForest) => (
        <VirtualForestCard key={virtualForest.id} {...virtualForest} />
      ))}
    </CardsList>
  );
};

export default VirtualForestCardsList;
