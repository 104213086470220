import { useCallback } from 'react';
import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { AuthArgs, signIn, SignInResult } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useAuthDefault(
  options?: MutationOptions<SignInResult, AuthArgs>,
) {
  const mutationFn = useCallback(async ({ body }: AuthArgs) => {
    const { email, pass } = body;

    const user = await signIn({
      body: {
        email,
        password: pass,
      },
    });

    return user;
  }, []);

  return useMutation<SignInResult, ErrorMessage, AuthArgs, unknown>(
    mutationFn,
    options,
  );
}
