import { QueryOptions } from 'types/react-query';

import { getInitialsFromTextWithMultipleWords } from '@abundance-brasil-wabi/utils';
import { useQuery } from '@tanstack/react-query';

import { Company, getCompany } from 'api/services';
import { getIsAuthenticated } from 'state/auth/utils';
import { getIsUserValidPhysicalPerson } from 'state/user/utils';

import { companyQueryKeys } from './queryKeys';
import { companySelector, companyShortNameSelector } from './selectors';
import { CompanyQueryKey } from './types';

export default function useGetCompany<TData = Company>(
  options?: QueryOptions<Company, TData, CompanyQueryKey>,
) {
  const isAuthenticated = getIsAuthenticated();
  const enabled = getIsUserValidPhysicalPerson() === false && isAuthenticated;

  return useQuery<Company, unknown, TData, CompanyQueryKey>(
    companyQueryKeys.company(),
    async () => {
      const data = await getCompany();

      const company = {
        ...data,
        shortName: getInitialsFromTextWithMultipleWords(data.name),
      };

      return company;
    },
    {
      ...options,
      enabled,
    },
  );
}

export const useCompany = () =>
  useGetCompany({
    select: companySelector,
  });

export const useCompanyShortName = () =>
  useGetCompany({ select: companyShortNameSelector });
