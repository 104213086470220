import { Navigate, Outlet } from 'react-router-dom';

import { useHasImpactBalance } from 'state/tree';

import { getPathnameFromKey } from '..';

const OnlyWithNoBalance: React.FC = () => {
  const { data: hasImpactBalance, isLoading: impactLoading } =
    useHasImpactBalance();

  if (hasImpactBalance && !impactLoading)
    return <Navigate to={getPathnameFromKey('home')} replace />;

  return <Outlet />;
};

export default OnlyWithNoBalance;
