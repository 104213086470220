import { styled } from 'styled-components';

import {
  breakpointDown,
  createFlexDisplay,
  getThemeFontWeight,
  Link,
} from '@abundance-brasil-wabi/sabi';

import LinkButton from 'components/LinkButton';

export const Root = styled.div`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'space-between',
    gap: 4,
  })}

  width: 100%;

  ${breakpointDown('lg')`
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  `}
`;

export const LinksContainer = styled.div`
  ${createFlexDisplay({
    direction: 'row',
    align: 'center',
    justify: 'center',
    gap: 8,
  })}

  text-align: center;

  ${breakpointDown('xl')`
    gap: 16px;
  `}

  ${breakpointDown('lg')`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const LinkElement = styled(Link).attrs({
  target: '_blank',
})`
  font-size: 10px;
  font-weight: ${getThemeFontWeight('bold')};
  text-transform: uppercase;
`;

export const OpenAccountButton = styled(LinkButton)`
  height: auto;
  padding: 12px 16px;

  ${breakpointDown('lg')`
    width: 100%
  `}

  ${breakpointDown('sm')`
    margin-top: 8px;
  `}
`;
