import { Navigate, Outlet } from 'react-router-dom';

import { checkIsValidPhysicalPerson } from 'state/auth/utils';

import { getPathnameFromKey } from '..';

const OnlyLegalPerson: React.FC = () => {
  const isValidPhysicalPerson = checkIsValidPhysicalPerson();

  if (isValidPhysicalPerson)
    return <Navigate to={getPathnameFromKey('home')} replace />;

  return <Outlet />;
};

export default OnlyLegalPerson;
