import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import { StyledESGActingProps } from './types';

function getRootSizeStyle({ $size }: StyledESGActingProps) {
  switch ($size) {
    case 'small': {
      return `
        gap: 8px;
      `;
    }

    default: {
      return `
        gap: 24px;
      `;
    }
  }
}

function getTitleSizeStyle({ $size }: StyledESGActingProps) {
  switch ($size) {
    case 'small': {
      return `
        letter-spacing: -0.4px;
        line-height: 22px;
        font-size: 18px;
      `;
    }

    default: {
      return `
        letter-spacing: -0.4px;
        line-height: 27px;
        font-size: 20px;
      `;
    }
  }
}

function getListItemsSizeStyle({ $size }: StyledESGActingProps) {
  switch ($size) {
    case 'small': {
      return `
        padding: 0 24px;
        margin-top: 8px;

        & > li {
          letter-spacing: -0.4px;
          line-height: 16px;
          font-size: 13px;
        }
      `;
    }

    default: {
      return `
        padding: 0 24px;

        & > li {
          letter-spacing: -0.4px;
          line-height: 28px;
          font-size: 14px;
        }

        @media (max-width: 330px) {
          & > li {
            font-size: 12px;
          }
        }
      `;
    }
  }
}

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})<StyledESGActingProps>`
  width: 100%;
  padding: 23px;
  padding-bottom: 31px;
  border-radius: 8px;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};

  ${getRootSizeStyle}
`;

export const Title = styled.span<StyledESGActingProps>`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};

  ${getTitleSizeStyle}
`;

export const ListItems = styled.ul<StyledESGActingProps>`
  & > li {
    font-weight: ${getThemeFontWeight('regular')};
    color: ${getThemeColor('text', 'default', 'secondary')};
  }

  ${getListItemsSizeStyle}
`;
