import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';

import {
  GOOGLE_TAG_ID,
  HOTJAR_ID,
  HOTJAR_VERSION,
  MIXPANEL_TOKEN,
} from './constants/env';
// Font family
import '@fontsource/bai-jamjuree/400.css';
import '@fontsource/bai-jamjuree/500.css';
import '@fontsource/bai-jamjuree/600.css';

// Internationalization
import 'i18n';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: GOOGLE_TAG_ID,
};

if (window.location.hostname !== 'localhost')
  TagManager.initialize(tagManagerArgs);

// Sentry
Sentry.init({
  dsn: 'https://335e3c4c49894f9b846e17a6e4b300c0@o1362372.ingest.sentry.io/6667732',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.NODE_ENV,
});

// Mixpanel
if (!MIXPANEL_TOKEN) throw new Error('Missing Mixpanel token.');

mixpanel.init(MIXPANEL_TOKEN, {
  batch_requests: false,
});

// Hotjar
if (import.meta.env.NODE_ENV === 'production')
  Hotjar.init(HOTJAR_ID, HOTJAR_VERSION);
