import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { downloadMediaKit } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useDownloadMediaKit(
  options?: MutationOptions<Blob, void>,
) {
  return useMutation<Blob, ErrorMessage, void, unknown>(
    downloadMediaKit,
    options,
  );
}
