import { AbundanceURLs, SocialURLs, StripeURLs } from './types';

export const abundanceBaseURL = 'https://abundancebrasil.com';

export const abundanceURLs: Record<AbundanceURLs, string> = {
  termsAndConditions: `${abundanceBaseURL}/termos-e-condicoes/`,
  privacyPolicy: `${abundanceBaseURL}/politica-de-privacidade/`,
  auroraVerde: `${abundanceBaseURL}/client/auroraverde/`,
  contact: `${abundanceBaseURL}/abundance-brasil/contato/`,
  cookies: `${abundanceBaseURL}/cookies/`,
  blog: `${abundanceBaseURL}/blog/`,
  faq: `${abundanceBaseURL}/perguntas-frequentes/`,
};

export const socialURLs: Record<SocialURLs, string> = {
  instagram: 'https://www.instagram.com/abundancebrasil/',
  facebook: 'https://www.facebook.com/abundancebrasil',
  linkedin: 'https://www.linkedin.com/company/abundance-brasil/',
  spotify:
    'https://open.spotify.com/playlist/4HyWmsjNecsFehBz9w8lgI?si=5kQCCiilQiaeHWEmQYFIMA&nd=1',
  youtube: 'https://www.youtube.com/channel/UCailf-2geV777rlsl_Og70A',
  x: 'https://x.com/abundancebrasil',
};

export const stripeURLs: Record<StripeURLs, string> = {
  termsAndConditions: 'https://stripe.com/en-br/legal/checkout',
};
