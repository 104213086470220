import { useVirtualForestAbsorptionReport } from 'state/virtualForest';

import PDF from '../PDF';

export type VirtualForestAbsorptionReport = {
  virtualForestId: string;
};

const VirtualForestAbsorptionReport: React.FC<
  VirtualForestAbsorptionReport
> = ({ virtualForestId }) => {
  const { data: absorptionImpactFile } =
    useVirtualForestAbsorptionReport(virtualForestId);

  if (!absorptionImpactFile) return null;

  return <PDF pdfFile={absorptionImpactFile} />;
};

export default VirtualForestAbsorptionReport;
