// Config
export const MAX_PURCHASE_TREE_AMOUNT = import.meta.env
  .VITE_APP_MAX_PURCHASE_TREE_AMOUNT;

export const TREE_VALUE = import.meta.env.VITE_APP_TREE_VALUE;

// URLs
export const BASE_URL_ABUNDANCE_API = import.meta.env
  .VITE_APP_BASE_URL_ABUNDANCE_API;

export const BASE_URL_MEDIA_API = import.meta.env.VITE_APP_BASE_URL_MEDIA_API;

// Google
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY;

export const GOOGLE_TAG_ID = import.meta.env.VITE_APP_GOOGLE_TAG_ID;

export const GOOGLE_RECAPTCHA_KEY_ID = import.meta.env
  .VITE_APP_GOOGLE_RECAPTCHA_KEY_ID;

// Mixpanel
export const MIXPANEL_TOKEN = import.meta.env.VITE_APP_MIXPANEL_TOKEN;

// Hotjar
export const HOTJAR_ID = import.meta.env.VITE_APP_HOTJAR_ID;
export const HOTJAR_VERSION = import.meta.env.VITE_APP_HOTJAR_VERSION;
