import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ContentsHeaderProps,
  FormComposer,
  useToggle,
} from '@abundance-brasil-wabi/sabi';

import { WithTranslation } from 'i18n/types';
import {
  Checkbox,
  Dropdown,
  Input,
  Password,
  PhoneNumber,
} from 'components/FormFields';
import LanguageSelector from 'components/LanguageSelector';
import ReCaptcha from 'components/ReCaptcha';
import { abundanceURLs } from 'constants/externalURLs';

import * as S from './styles';
import { useGetFormOptions } from './useGetFormOptions';
import { useSignUpForm } from './useSignUpForm';

export type SignUpFormProps = ContentsHeaderProps & {
  isPhysicalPerson?: boolean;
};

const SignUpForm: React.FC<WithTranslation<SignUpFormProps>> = ({
  title,
  subtitle,
  description,
  isPhysicalPerson = false,
  t,
}) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { value: agreedWithConditions, toggle: toggleAgreedWithConditions } =
    useToggle(false);

  const { formState, control, getValues, isLoading, onSubmit } = useSignUpForm({
    isPhysicalPerson,
  });
  const { socialMedia } = useGetFormOptions();

  return (
    <FormComposer
      {...{ title, subtitle, description }}
      fluid
      sections={[
        {
          id: 'sign-up-form-main',
          contents: [
            <Input
              fluid
              key="name"
              name="name"
              label={isPhysicalPerson ? t('name') : t('companyName')}
              placeholder={t('namePlaceholder')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Input
              fluid
              key="email"
              name="email"
              type="email"
              label={t('email')}
              placeholder={
                isPhysicalPerson
                  ? t('emailPlaceholder')
                  : t('companyEmailPlaceholder')
              }
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <PhoneNumber
              fluid
              key="phoneNumber"
              name="phoneNumber"
              label={t('phoneNumber')}
              value={getValues('phoneNumber')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Password
              fluid
              key="password"
              name="password"
              label={t('password')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Password
              fluid
              key="passwordConfirm"
              name="passwordConfirm"
              label={t('passwordConfirm')}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Dropdown
              fluid
              key="howDidYouGetHere"
              name="howDidYouGetHere"
              label={t('howDidYouGetHere')}
              placeholder={t('choose')}
              defaultValue={getValues('howDidYouGetHere')}
              options={socialMedia}
              isDisabled={isLoading}
              {...{ control, formState }}
            />,

            <Checkbox
              key="subscribeToNewsletter"
              name="subscribeToNewsletter"
              label={t('sendUpdates')}
              control={control}
              isDisabled={isLoading}
            />,

            <Checkbox
              key="conditions"
              name="conditions"
              label={
                <span>
                  {t('createNotice')}{' '}
                  <S.LinkButton
                    href={abundanceURLs.termsAndConditions}
                    target="_blank"
                  >
                    {t('termsAndConditions')}
                  </S.LinkButton>{' '}
                  {t('and')}{' '}
                  <S.LinkButton
                    href={abundanceURLs.privacyPolicy}
                    target="_blank"
                  >
                    {t('privacyPolicy')}
                  </S.LinkButton>{' '}
                  {t('enroll')}
                </span>
              }
              control={control}
              isDisabled={isLoading}
              onChange={toggleAgreedWithConditions}
            />,

            <ReCaptcha key="recaptcha" verifiedAction={setIsCaptchaVerified} />,

            <Button
              fluid
              key="submit"
              type="submit"
              isDisabled={
                !formState.isValid ||
                !isCaptchaVerified ||
                !agreedWithConditions ||
                isLoading
              }
            >
              {t('createAccount')}
            </Button>,

            <S.LanguageSelectorContainer key="languageSelector">
              <LanguageSelector />
            </S.LanguageSelectorContainer>,
          ],
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default withTranslation('pages', {
  keyPrefix: 'signUp:signUpForm',
})(SignUpForm);
