import { isValidPhoneNumber } from 'react-phone-number-input';
import { CountryCode } from 'types/country';
import { z } from 'zod';

import { VALID_EMAIL_FORMAT } from '@abundance-brasil-wabi/utils';

import { fieldErrorMessages } from 'constants/forms';

export const companySettingsFormSchema = z
  .object({
    name: z.string().min(1, fieldErrorMessages.name.required),
    email: z
      .string()
      .min(1, fieldErrorMessages.email.required)
      .regex(
        new RegExp(VALID_EMAIL_FORMAT),
        fieldErrorMessages.email.wrongPattern,
      ),
    country: z.string().optional(),
    phoneNumber: z.string().optional(),
    address: z.string().nullable().optional(),
    sector: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    industry: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    numberEmployees: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
    annualBilling: z
      .number({ invalid_type_error: fieldErrorMessages.field.required })
      .min(0, fieldErrorMessages.field.required),
  })
  .refine(
    ({ phoneNumber = '', country }) =>
      isValidPhoneNumber(phoneNumber, country as CountryCode),
    {
      message: fieldErrorMessages.phoneNumber.invalid,
      path: ['phoneNumber'],
    },
  )
  .refine(({ address }) => address, {
    message: fieldErrorMessages.address.required,
    path: ['address'],
  });
