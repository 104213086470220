import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useOnUpdateUserSettings } from 'state/auth';
import { getIsAuthenticated } from 'state/auth/utils';
import { useChangeLanguage } from 'state/user';
import { setUserLanguage } from 'state/user/utils';

import { useToast } from './useToast';

export type UseLanguage = {
  changeAppLanguage: (
    newLanguage: string,
    shouldChangeOnServer?: boolean,
  ) => Promise<void>;
  isLoading: boolean;
};

/**
 * Returns a function that changes the language of the app.
 *
 * @returns An object with a function called changeAppLanguage.
 */
export default function useLanguage(): UseLanguage {
  const onUpdateUserSettings = useOnUpdateUserSettings();
  const isAuthenticated = getIsAuthenticated();

  const { t } = useTranslation('shared', {
    keyPrefix: 'hooks:useLanguage',
  });
  const { addToast } = useToast();
  const { mutateAsync: changeLanguage, isLoading } = useChangeLanguage();

  const changeAppLanguage = useCallback(
    async (newLanguage: string, shouldChangeOnServer = true): Promise<void> => {
      if (newLanguage === i18n.language) return;

      if (isAuthenticated && shouldChangeOnServer)
        await changeLanguage(
          {
            body: {
              language: newLanguage,
            },
          },
          {
            onError: () => {
              addToast({
                message: t('error'),
                state: 'error',
              });
            },
          },
        );

      i18n.changeLanguage(newLanguage);

      setUserLanguage(newLanguage);

      document.documentElement.setAttribute('lang', newLanguage);

      onUpdateUserSettings();
    },
    [addToast, changeLanguage, isAuthenticated, onUpdateUserSettings, t],
  );

  return { changeAppLanguage, isLoading };
}
