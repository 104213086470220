import { MutationOptions } from 'types/react-query';

import { useMutation } from '@tanstack/react-query';

import { resendTwoFactorCode, ResendTwoFactorCodeArgs } from 'api/services';
import { ErrorMessage } from 'api/services/types';

export default function useResendTwoFactorCode(
  options?: MutationOptions<void, ResendTwoFactorCodeArgs>,
) {
  return useMutation<void, ErrorMessage, ResendTwoFactorCodeArgs, unknown>(
    resendTwoFactorCode,
    options,
  );
}
