import {
  HideModalAction,
  Modal,
  PushModalAction,
  SetOnHideModalValueAction,
} from 'components/Modal/types';

import { ModalAction } from './types';

export type ToggleModalState = {
  type: string;
};

export function pushModal(modal: Modal): PushModalAction {
  return {
    type: ModalAction.PUSH_MODAL,
    payload: modal,
  };
}

export function setOnHideModalValue(modal: Modal): SetOnHideModalValueAction {
  return {
    type: ModalAction.SET_ON_HIDE_MODAL_VALUE,
    payload: modal,
  };
}

export function hideModal(modal?: Modal): HideModalAction {
  return {
    type: ModalAction.HIDE_MODAL,
    payload: modal,
  };
}

export function toggleModalState(): ToggleModalState {
  return {
    type: ModalAction.TOGGLE_MODAL,
  };
}
