import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import { StyledClaimHistoryProps } from './types';

function getStatusStyle({ $status }: StyledClaimHistoryProps) {
  switch ($status) {
    case 0: {
      return `
        color: ${getThemeColor('text', 'primary', 'default')};
      `;
    }

    default: {
      return ``;
    }
  }
}

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'center',
  justify: 'flex-start',
})`
  width: 100%;
`;

export const Loader = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'center',
})`
  min-height: 284px;
  padding: 24px;
`;

export const History = styled.div<StyledClaimHistoryProps>`
  width: 100%;
  max-height: 284px;
  border-top: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-bottom: 1px solid ${getThemeColor('border', 'default', 'primary')};
  overflow: auto;

  &::-webkit-scrollbar-track {
    margin-top: 64px;
  }

  ${({ $isLoading }) =>
    $isLoading &&
    `
      overflow: 'hidden';
    `}
`;

export const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    width: 132px;
    display: table-cell;
    padding: 24px 8px;
    text-align: left;
    border-bottom: 1px solid ${getThemeColor('border', 'default', 'primary')};
  }

  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
    background-color: ${getThemeColor('white')};
  }

  th {
    text-transform: uppercase;
    line-height: 15px;
    font-size: 12px;
    font-weight: ${getThemeFontWeight('bold')};
    color: ${getThemeColor('text', 'default', 'primary')};
  }

  td {
    overflow: auto;

    &:last-of-type {
      text-align: center;
    }
  }
`;

export const THead = styled.thead`
  position: sticky;
  top: 0;
`;

export const Status = styled.td<StyledClaimHistoryProps>`
  font-size: 14px;
  font-weight: ${getThemeFontWeight('bold')};
  text-transform: uppercase;

  ${getStatusStyle}
`;
