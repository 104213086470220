import { styled } from 'styled-components';

import {
  getThemeColor,
  getThemeFontWeight,
  Spacer,
} from '@abundance-brasil-wabi/sabi';

import { StyledResponsibleConsultantProps } from './types';

function getRootSizeStyle({ $size }: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
        gap: 8px;
        padding: 24px;
      `;
    }

    default: {
      return `
        gap: 24px;
        padding: 24px;
      `;
    }
  }
}

function getTitleSizeStyle({ $size }: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
        letter-spacing: -0.4px;
        line-height: 22px;
        font-size: 18px;
      `;
    }

    default: {
      return `
        letter-spacing: -0.4px;
        line-height: 27px;
        font-size: 20px;

        @media (max-width: 370px) {
          font-size: 16px;
        }
      `;
    }
  }
}

function getConsultantInfoSizeStyle({
  $size,
}: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
        gap: 8px;
      `;
    }

    default: {
      return `
        gap: 16px;
      `;
    }
  }
}

function getAvatarSizeStyle({ $size }: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
        width: 48px;
        height: 48px;
      `;
    }

    default: {
      return `
        width: 72px;
        height: 72px;

        @media (max-width: 370px) {
          width: 68px;
          height: 68px;
        }
      `;
    }
  }
}

function getConsultantNameSizeStyle({
  $size,
}: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
        line-height: 20px;
        font-size: 16px;
      `;
    }

    default: {
      return `
        line-height: 22px;
        font-size: 18px;

        @media (max-width: 370px) {
          font-size: 16px;
          line-height: 18px;
        }

        @media (max-width: 345px) {
          font-size: 14px;
        }
      `;
    }
  }
}

function getConsultantRoleSizeStyle({
  $size,
}: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
        line-height: 16px;
        font-size: 13px;
      `;
    }

    default: {
      return `
        line-height: 26px;
        font-size: 14px;

        @media (max-width: 370px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media (max-width: 345px) {
          font-size: 12px;
        }
      `;
    }
  }
}

function getConsultantContactSizeStyle({
  $size,
}: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
          gap: 8px;
        `;
    }

    default: {
      return `
          gap: 16px;
        `;
    }
  }
}

function getContactSizeStyle({ $size }: StyledResponsibleConsultantProps) {
  switch ($size) {
    case 'small': {
      return `
          & > span {
            line-height: 16px;
            font-size: 13px;
          }
        `;
    }

    default: {
      return `
          & > span {
            line-height: 16px;
            font-size: 14px;
          }

          @media (max-width: 370px) {
            gap: 8px;
          }
        `;
    }
  }
}

export const Root = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})<StyledResponsibleConsultantProps>`
  width: 100%;
  border: 1px solid ${getThemeColor('border', 'default', 'primary')};
  border-radius: 8px;
  background-color: ${getThemeColor('white')};

  ${getRootSizeStyle}
`;

export const Title = styled.span<StyledResponsibleConsultantProps>`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'default', 'primary')};

  ${getTitleSizeStyle}
`;

export const ConsultantInfo = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'center',
})<StyledResponsibleConsultantProps>`
  ${getConsultantInfoSizeStyle}
`;

export const Avatar = styled.img<StyledResponsibleConsultantProps>`
  border-radius: 50%;

  ${getAvatarSizeStyle}
`;

export const ConsultantName = styled.span<StyledResponsibleConsultantProps>`
  font-weight: ${getThemeFontWeight('bold')};
  color: ${getThemeColor('text', 'secondary', 'default')};

  ${getConsultantNameSizeStyle}
`;

export const ConsultantRole = styled.span<StyledResponsibleConsultantProps>`
  font-weight: ${getThemeFontWeight('regular')};
  color: ${getThemeColor('text', 'default', 'secondary')};

  ${getConsultantRoleSizeStyle}
`;

export const ConsultantContact = styled(Spacer).attrs({
  direction: 'column',
  align: 'flex-start',
  justify: 'center',
})<StyledResponsibleConsultantProps>`
  ${getConsultantContactSizeStyle}
`;

export const Contact = styled(Spacer).attrs({
  direction: 'row',
  align: 'center',
  justify: 'center',
  gap: '4px',
})<StyledResponsibleConsultantProps>`
  & > span {
    font-weight: ${getThemeFontWeight('regular')};
    color: ${getThemeColor('text', 'default', 'secondary')};
    line-break: anywhere;
  }

  ${getContactSizeStyle}
`;
