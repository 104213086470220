import { Control, FieldValues } from 'react-hook-form';

/**
 * TChecks if a specific field in a form control has been touched.
 *
 * @param control - It contains methods and properties to interact with form
 * fields, such as getting the state of a specific field.
 * @param {string} field - Represents the  name of the field you want to check
 * if it has been touched or not.
 *
 * @returns A boolean value indicating whether the specified field in the form
 * control has been touched or not.
 */
export function checkFieldIsTouched<TFieldValues extends FieldValues>(
  control: Control<TFieldValues>,
  field: string,
): boolean {
  return control.getFieldState(field as never).isTouched;
}
